import React, {useContext, useEffect, useState} from "react";
import {AccountContext} from "../context/AccountProvider";
import {getPincodeDetails, updateAccountById} from "../service/accountApi";
import {translateTextToDevnagri} from "../service/translateApi";
import {t} from "i18next";
import {Modal} from "./Modal";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Transition} from "@headlessui/react";
import {countryCodes} from "../data/CountryCode";

export const EditProfileForm = (props) => {
    const {showEditPersonalProfileForm, setShowEditPersonalProfileForm, accountDetails, setAccountDetails} = props;
    const bloodGroups = ["A +", "A -", "B +", "B -", "O +", "O -", "AB +", "AB -"];

	const {account, setAccount} = useContext(AccountContext);
	const [error, setError] = useState({});

	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

	const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
    const [isMaritalStatusDropdownOpen, setIsMaritalStatusDropdownOpen] = useState(false);
    const [isSideOfFamilyDropdownOpen, setIsSideOfFamilyDropdownOpen] = useState(false);
    const [isBloodGroupDropdownOpen, setIsBloodGroupDropdownOpen] = useState(false);

	// const [filteredFamilyTreeNames, setFilteredFamilyTreeNames] = useState(familyTreeNames);
	// const [isFamilyTreeNameDropdownOpen, setIsFamilyTreeNameDropdownOpen] = useState(false);

    const [isCountryCodeDropdownOpen, setIsCountryCodeDropdownOpen] = useState(false);
    const [isHomePhoneCountryCodeDropdownOpen, setIsHomePhoneCountryCodeDropdownOpen] = useState(false);
    const [isOfficePhoneCountryCodeDropdownOpen, setIsOfficePhoneCountryCodeDropdownOpen] = useState(false);

    const [filteredCountryDetails, setFilteredCountryDetails] = useState(countryCodes);
	const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

	useEffect(() => {
		console.log("(EditProfileForm) Account: ", accountDetails);
	}, []);

	function extractDate(dateString) {
		if (!dateString) {
			return null;
		}
		const dateObject = new Date(dateString);
		const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	const [profile, setProfile] = useState({
		email: accountDetails?.email,

		firstName: accountDetails?.firstName,
		middleName: accountDetails?.middleName,

		firstNameDevanagariScript: accountDetails?.firstNameDevanagariScript,
		middleNameDevanagariScript: accountDetails?.middleNameDevanagariScript,

		dateOfBirth: extractDate(accountDetails?.dateOfBirth),
		gender: accountDetails?.gender,
		bloodGroup: accountDetails?.bloodGroup,

		phone: {
            charCode: accountDetails?.phone?.charCode || 'IN',
            dialingCode: accountDetails?.phone?.dialingCode || '+91',
            countryName: accountDetails?.phone?.countryName || 'India',
            number: accountDetails?.phone?.number || '',
		},

		homePhone: {
            charCode: accountDetails?.homePhone?.charCode || 'IN',
            dialingCode: accountDetails?.homePhone?.dialingCode || '+91',
            countryName: accountDetails?.homePhone?.countryName || 'India',
            number: accountDetails?.homePhone?.number || '',
		},

		officePhone: {
            charCode: accountDetails?.officePhone?.charCode || 'IN',
            dialingCode: accountDetails?.officePhone?.dialingCode || '+91',
            countryName: accountDetails?.officePhone?.countryName || 'India',
            number: accountDetails?.officePhone?.number || '',
		},

        maritalStatus: accountDetails?.maritalStatus || 'Single',
        sideOfFamily: accountDetails?.sideOfFamily || '',

        firstNameAfterMarriage: accountDetails?.firstNameAfterMarriage || '',
        spouseNameAfterMarriage: accountDetails?.spouseNameAfterMarriage || '',
        lastNameAfterMarriage: accountDetails?.lastNameAfterMarriage || '',

        firstNameBeforeMarriage: accountDetails?.firstNameBeforeMarriage || '',
        fathersNameBeforeMarriage: accountDetails?.fathersNameBeforeMarriage || '',
        lastNameBeforeMarriage: accountDetails?.lastNameBeforeMarriage || '',

		dateOfMarriage: extractDate(accountDetails?.dateOfMarriage) || null,

        familyTreeName: accountDetails?.familyTreeName || '',

        biography: accountDetails?.biography || '',

		address: {
            addressLine1: accountDetails?.address?.addressLine1 || '',
            pincode: accountDetails?.address?.pincode || '',
            city: accountDetails?.address?.city || '',
            state: accountDetails?.address?.state || '',
            country: accountDetails?.address?.country || '',
            countryCode: accountDetails?.address?.countryCode || '',
		},

        imageUrl: accountDetails?.imageUrl || '',
        imageUrlBg: accountDetails?.imageUrlBg || '',
	});

	const translateToDevnagri = (e) => {
        if (e.target.name === 'firstName' || e.target.name === 'middleName') {
			if (e.target.value) {
				translateTextToDevnagri(e.target.value).then((res) => {
					console.log("(EditPersonalProfile.jsx) Translation Response: ", res);
					setProfile({
						...profile,
                        [e.target.name + 'DevanagariScript']: res.result
					});
					setError({
						...error,
                        [e.target.name + 'DevanagariScript']: ""
					});
				});
			}
		}
    }

	function checkAge(dateOfBirth) {
		// Convert the date of birth to a Date object
		const birthDate = new Date(dateOfBirth);

		// Calculate the current age in years
		const today = new Date();
		const age = today.getFullYear() - birthDate.getFullYear();

		// Check if the age is less than 13 or greater than 70
        return (age < 13 || age > 70);
	}

	const handleGenderSelect = (gender) => {
		setIsGenderDropdownOpen(false);
		setProfile({
			...profile,
            gender: gender
		});
    }

	const maritalStatusLabel = (maritalStatus) => {
		switch (maritalStatus) {
            case 'Single':
				return t("PROFILE_FORM.MARITAL_STATUS.SINGLE");
            case 'Married':
				return t("PROFILE_FORM.MARITAL_STATUS.MARRIED");
            case 'Widowed':
				return t("PROFILE_FORM.MARITAL_STATUS.WIDOWED");
            case 'Divorced':
				return t("PROFILE_FORM.MARITAL_STATUS.DIVORCED");
            case 'Minor':
				return t("PROFILE_FORM.MARITAL_STATUS.MINOR");
			default:
				return t("PROFILE_FORM.MARITAL_STATUS.SINGLE");
		}
    }

	const handleMaritalStatusSelect = (maritalStatus) => {
		setIsMaritalStatusDropdownOpen(false);
		setProfile({
			...profile,
            maritalStatus: maritalStatus
		});
    }

	const handleSideOfFamilySelect = (sideOfFamily) => {
		setIsSideOfFamilyDropdownOpen(false);
		setProfile({
			...profile,
            sideOfFamily: sideOfFamily
		});
    }

	// const handleFamilyTreeNameSelect = (familyTreeName) => {
	//     setIsFamilyTreeNameDropdownOpen(false);
	//     setProfile({
	//         ...profile,
	//         familyTreeName: familyTreeName
	//     });
	// }

	useEffect(() => {
        if (profile.gender === 'Female') {
			setProfile({
				...profile,
                sideOfFamily: 'MaherVashin'
            })
		}
	}, [profile.gender]);

	const isNumberValid = (phone) => {
		let reg = /^\d+$/;
		return reg.test(phone);
    }

	const validatePhoneNumber = (e) => {
        if ((!isNumberValid(e.target.value)) || e.target.value.length !== 10) {
            if (e.target.name === 'officePhone' || e.target.name === 'homePhone') {
				if (e.target.value === "") {
					setError({
						...error,
                        [e.target.name]: ""
					});
				}
			} else {
				if (e.target.value === "") {
					setError({
						...error,
                        [e.target.name]: t("PROFILE_FORM.PHONE_NUMBER.REQUIRED")
					});
				} else {
					setError({
						...error,
                        [e.target.name]: t("PROFILE_FORM.PHONE_NUMBER.INVALID")
					});
				}
			}
		}
    }

	const validateEmail = (email) => {
		return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
	};

	const validateEmailId = (e) => {
		if (!validateEmail(e.target.value)) {
			setError({
				...error,
                [e.target.name]: t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.INVALID_ERROR")
			});
		} else {
			setError({
				...error,
                [e.target.name]: ""
            })
		}
    }

	const handleBloodGroupSelect = (bloodGroup) => {
		setIsBloodGroupDropdownOpen(false);
		setProfile({
			...profile,
            bloodGroup: bloodGroup
		});
    }

	const validatePincode = (e) => {
        if (profile.address.addressLine1 !== '') {
			if (e.target.value.length !== 6) {
				setError({
					...error,
                    [e.target.name]: t("PROFILE_FORM.PINCODE.INVALID")
				});
			} else {
				setError({
					...error,
                    [e.target.name]: ""
				});
			}
		}
    }

	const handleCountrySelect = (countryDetails) => {
		setIsCountryDropdownOpen(false);
		setProfile({
			...profile,
			address: {
				...profile.address,
				country: countryDetails.countryName,
                countryCode: countryDetails.countryCode
            }
		});

		setError({
			...error,
			country: "",
			state: "",
            city: ""
        })
    }

	useEffect(() => {
        if (profile.address.pincode.length === 6 && profile.address.country !== '' && profile.address.state === '' && profile.address.city === '') {
			console.log("(EditPersonalProfile) Pincode: ", profile.address.pincode);
			console.log("(EditPersonalProfile) Country: ", profile.address.country);
			// fetchPincodeDetails(profile.address.pincode, profile.address.country);
            getPincodeDetails(profile.address.pincode, profile.address.countryCode).then((response) => {
				console.log("(EditPersonalProfile) Pincode Details: ", response);
				if (response?.success) {
					setProfile({
						...profile,
						address: {
							...profile.address,
							city: response.data[0]?.city,
                            state: response.data[0]?.state
                        }
					});
				}
			});
		}
	}, [profile.address.pincode, profile.address.country]);

	const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

		setProfile({
			...profile,
            [e.target.name]: e.target.value
		});

		setError({
			...error,
            [e.target.name]: ""
		});
    }

	const validate = () => {
		let err = {};

        if (!accountDetails.deceased && profile.email === '') {
            err['email'] = t("PROFILE_FORM.EMAIL.REQUIRED");
		}

        if (profile.familyTreeName === '') {
            err['familyTreeName'] = t("PROFILE_FORM.FAMILY_TREE_NAME.ERROR");
		}

        if (profile.firstName === '') {
            err['firstName'] = t("PROFILE_FORM.NAME.ENGLISH.FIRST_NAME_ERROR");
		}

        if (profile.middleName === '') {
            err['middleName'] = t("PROFILE_FORM.NAME.ENGLISH.MIDDLE_NAME_ERROR");
		}

        if (profile.firstNameDevanagariScript === '') {
            err['firstNameDevanagariScript'] = t("PROFILE_FORM.NAME.DEVANAGRI.FIRST_NAME_ERROR");
		}

        if (profile.middleNameDevanagariScript === '') {
            err['middleNameDevanagariScript'] = t("PROFILE_FORM.NAME.DEVANAGRI.MIDDLE_NAME_ERROR");
		}

        if (profile.gender === '') {
            err['gender'] = t("PROFILE_FORM.GENDER.ERROR");
		}

        if (profile.dateOfBirth === '') {
            err['dateOfBirth'] = t("PROFILE_FORM.DOB.ERROR");
		}

        if (profile.maritalStatus === 'Married' || profile.maritalStatus === 'Widowed' || profile.maritalStatus === 'Divorced') {
            if (profile.dateOfMarriage === '') {
                err['dateOfMarriage'] = t("PROFILE_FORM.DOM.ERROR");
			}

            if (profile.gender === 'Female') {
				if (profile.sideOfFamily === "MaherVashin") {
                    if (profile.firstNameAfterMarriage === '') {
                        err['firstNameAfterMarriage'] = t("PROFILE_FORM.NAME.AFTER_MARRIAGE.FIRST_NAME_ERROR");
					}

                    if (profile.spouseNameAfterMarriage === '') {
                        err['spouseNameAfterMarriage'] = t("PROFILE_FORM.NAME.AFTER_MARRIAGE.SPOUSE_NAME_ERROR");
					}

                    if (profile.lastNameAfterMarriage === '') {
                        err['lastNameAfterMarriage'] = t("PROFILE_FORM.NAME.AFTER_MARRIAGE.LAST_NAME_ERROR");
					}
				} else {
                    if (profile.firstNameBeforeMarriage === '') {
                        err['firstNameBeforeMarriage'] = t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FIRST_NAME_ERROR");
					}

                    if (profile.fathersNameBeforeMarriage === '') {
                        err['fathersNameBeforeMarriage'] = t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FATHERS_NAME_ERROR");
					}

                    if (profile.lastNameBeforeMarriage === '') {
                        err['lastNameBeforeMarriage'] = t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.LAST_NAME_ERROR");
					}
				}
			}
		}

		if (!accountDetails.deceased && !checkAge(profile.dateOfBirth)) {
            if (profile.phone.number === '') {
                err['phone'] = t("PROFILE_FORM.PHONE_NUMBER.REQUIRED");
            } else if (profile.phone.number?.length !== 10 && !isNumberValid(profile.phone.number)) {
                err['phone'] = t("PROFILE_FORM.PHONE_NUMBER.INVALID");
			}
		}

        if (!accountDetails.deceased && profile.homePhone.number !== '' && profile.homePhone.number?.length !== 10 && !isNumberValid(profile.homePhone.number)) {
            err['homePhone'] = t("PROFILE_FORM.HOME_PHONE_NUMBER.INVALID");
		}

        if (!accountDetails.deceased && profile.officePhone.number !== '' && profile.officePhone.number?.length !== 10 && !isNumberValid(profile.officePhone.number)) {
            err['officePhone'] = t("PROFILE_FORM.OFFICE_PHONE_NUMBER.INVALID");
		}

		// if (profile.homePhone.number === '') {
		//     profile.homePhone = {
		//         charCode: 'IN',
		//         dialingCode: '+91',
		//         countryName: 'India',
		//         number: ''
		//     }
		// }
		//
		// if (profile.officePhone.number === '') {
		//     profile.officePhone = {
		//         charCode: 'IN',
		//         dialingCode: '+91',
		//         countryName: 'India',
		//         number: ''
		//     }
		// }

        if (!accountDetails.deceased && profile.address.addressLine1 !== '') {
            if (profile.address.pincode === '') {
                err['pincode'] = t("PROFILE_FORM.PINCODE.REQUIRED");
			}

            if (profile.address.city === '') {
                err['city'] = t("PROFILE_FORM.CITY.REQUIRED");
			}

            if (profile.address.state === '') {
                err['state'] = t("PROFILE_FORM.STATE.REQUIRED");
			}

            if (profile.address.country === '') {
                err['country'] = t("PROFILE_FORM.COUNTRY.REQUIRED");
			}
		}

        if (!accountDetails.deceased && profile.address.addressLine1 === '') {
			if (
                profile.address.pincode !== '' ||
                profile.address.city !== '' ||
                profile.address.state !== '' ||
                profile.address.country !== '' ||
                profile.address.countryCode !== ''
			) {
                err['addressLine1'] = t("PROFILE_FORM.ADDRESS.REQUIRED");

                if (profile.address.pincode === '') {
                    err['pincode'] = t("PROFILE_FORM.PINCODE.REQUIRED");
				}

                if (profile.address.city === '') {
                    err['city'] = t("PROFILE_FORM.CITY.REQUIRED");
				}

                if (profile.address.state === '') {
                    err['state'] = t("PROFILE_FORM.STATE.REQUIRED");
				}

                if (profile.address.country === '') {
                    err['country'] = t("PROFILE_FORM.COUNTRY.REQUIRED");
				}
			}
		}

		return err;
    }

	const handleSubmit = () => {
		console.log("Handle Submit Begin!");

		setIsSaveButtonDisabled(true);
		const err = validate();

		if (Object.keys(err).length > 0) {
			setError(err);
			setIsSaveButtonDisabled(false);
			return;
		}

		// Populate new profile data
		const updatedAccountDetails = {
			...profile,
            imageUrl: profile.imageUrl.length === 2 ? (profile.firstName[0] + profile.middleName[0]) : profile.imageUrl,
		};

		// Update accountDetails with the new profile data
		updateAccountById(accountDetails?._id, updatedAccountDetails)
			.then((response) => {
                console.log("(EditPersonalProfile) Update Account By Id Response: ", response);

				if (response?.success) {
					if (accountDetails._id === account._id) {
						setAccount(response.data);
					} else {
						setAccountDetails(response.data);
					}
					setShowEditPersonalProfileForm(false);
					setIsSaveButtonDisabled(false);
					setError({});
				}
			})
			.catch((error) => {
                console.log("(EditPersonalProfile) Update Account By Id Error: ", error);
			});
	};

	return (
		<Modal isOpen={showEditPersonalProfileForm}>
			{
				<div className="min-w-[35vw] px-[1.5vw] py-[2vh] bg-white rounded-lg">
					{/* Header */}
					<div className={"max-h-[10vh]"}>
						{/* Header Title | Close Button */}
						<div className={"flex flex-row items-center justify-between"}>
							{/* Form Title */}
							<h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
								{t("PROFILE_FORM.EDIT_TITLE")}
							</h2>

							{/* Close Button */}
							<button
								onClick={() => {
									setShowEditPersonalProfileForm(false);
									setError({});
								}}
								type="button"
								className="flex items-center justify-center
                                        rounded-lg w-[2vw] h-[2vw]
                                        bg-transparent hover:bg-gray-200
                                        text-sm text-gray-400 hover:text-gray-900"
							>
								<img
									className={"w-[75%] h-[75%]"}
									src={"/assets/close-icon.png"}
									alt={"Close"}
									title={"Close"}
								/>
							</button>
						</div>

						{/* Divider */}
                        <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
					</div>

					{/* Form Fields */}
                    <div className={"no-scrollbar overflow-y-scroll max-h-[60vh] pt-[1.5vh]"}>
						{/* Email Input */}
                        {
                            !accountDetails.deceased && (
								<fieldset>
									<div className="mb-[2vh] flex-1 flex flex-col gap-2">
										<label
											htmlFor="email"
											className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
										>
											{t("PROFILE_FORM.EMAIL.LABEL")}
										</label>
										<input
											type="email"
											id="email"
                                            name='email'
											className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
											value={profile.email}
											onChange={handleFormData}
											onBlur={validateEmailId}
											required
										/>
                                        {
                                            error['email'] &&
                                            <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR" id="error">
                                                {error['email']}
											</p>
                                        }
									</div>
								</fieldset>
                            )
                        }

						{/* Name in English */}
						<fieldset>
							<div className="mb-[2vh] flex flex-col gap-2">
								<label
									className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									htmlFor="firstName"
								>
									{t("PROFILE_FORM.NAME.ENGLISH.LABEL")}
								</label>
								<div className="flex justify-between gap-4">
									{/* First Name */}
									<div className="flex-1 flex flex-col">
										<input
											id="firstName"
											className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                            name='firstName'
                                            placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.FIRST_NAME")}
											value={profile.firstName}
											onChange={handleFormData}
											onBlur={translateToDevnagri}
										/>
                                        {
                                            error['firstName'] &&
											<span
												className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
												id="error"
											>
                                                    {error['firstName']}
                                                </span>
                                        }
									</div>

									{/* Father's Name */}
									<div className="flex-1 flex flex-col">
										<input
											className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
											id="middleName"
                                            name='middleName'
                                            placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME")}
											value={profile.middleName}
											onChange={handleFormData}
											onBlur={translateToDevnagri}
										/>
                                        {
                                            error['middleName'] &&
                                            <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                               id="error">
                                                {error['middleName']}
											</p>
                                        }
									</div>
								</div>
							</div>
						</fieldset>

						{/* Name in Devnagari */}
						<fieldset>
							<div className="mb-[2vh] flex flex-col gap-2">
								<label
									className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									htmlFor="firstNameDevanagariScript"
								>
									{t("PROFILE_FORM.NAME.DEVANAGRI.LABEL")}
								</label>
								<div className="flex justify-between gap-4">
									{/* First Name in Devnagari */}
									<div className={"flex-1 flex flex-col"}>
										<input
											className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] items-center justify-center rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
											id="firstNameDevanagariScript"
                                            name='firstNameDevanagariScript'
                                            placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.FIRST_NAME")}
											value={profile.firstNameDevanagariScript}
											onChange={handleFormData}
										/>
                                        {
                                            error['firstNameDevanagariScript'] &&
                                            <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                               id="error">
                                                {error['firstNameDevanagariScript']}
											</p>
                                        }
									</div>

									{/* Father's Name in Devnagari */}
									<div className={"flex-1 flex flex-col"}>
										<input
											className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                                inline-flex items-center justify-center rounded-md
                                                px-[0.5vw] text-[1vw] outline-none
                                                border-2 border-gray-300 focus:border-[#5F0F40]"
											id="middleNameDevanagariScript"
                                            name='middleNameDevanagariScript'
                                            placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME")}
											value={profile.middleNameDevanagariScript}
											onChange={handleFormData}
											onBlur={translateToDevnagri}
										/>
                                        {
                                            error['middleNameDevanagariScript'] &&
                                            <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                               id="error">
                                                {error['middleNameDevanagariScript']}
											</p>
                                        }
									</div>
								</div>
							</div>
						</fieldset>

						{/*  DOB | Gender | Blood Group */}
						<fieldset>
							<div className="mb-[2vh] flex flex-row justify-between gap-4">
								{/* Date of Birth */}
								<div className="flex flex-col gap-2">
									<label
                                        className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
                                        htmlFor='dateOfBirth'
									>
										{t("PROFILE_FORM.DOB.LABEL")}
									</label>
									<div className="relative w-full">
										<input
											type="date"
                                            name='dateOfBirth'
											required
											value={profile.dateOfBirth}
											onChange={handleFormData}
											className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                    flex-1 items-center justify-center rounded-md px-[0.5vw] text-[1vw]
                                                    leading-none outline-none border-2 border-gray-300
                                                    focus:border-[#5F0F40]`}
										/>
                                        {
                                            error['dateOfBirth'] &&
                                            <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                               id="error">
                                                {error['dateOfBirth']}
											</p>
                                        }
									</div>
								</div>

								{/* Gender */}
								<div className="flex-1 flex flex-col gap-2">
                                    <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="gender">
										{t("PROFILE_FORM.GENDER.LABEL")}
									</label>

									<div className="relative w-full flex flex-col">
										<button
											onBlur={() => setIsGenderDropdownOpen(false)}
											className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                                rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                            onClick={() => setIsGenderDropdownOpen(!isGenderDropdownOpen)}
										>
                                            <div className={"flex-1 flex flex-row items-center gap-2"}>
												<p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                    {
                                                        profile.gender === "Male"
															? t("ADD_NODE_FORM.GENDER.MALE")
                                                            : t("ADD_NODE_FORM.GENDER.FEMALE")
                                                    }
												</p>
												<img
													className={"w-[1.5vw] aspect-auto"}
                                                    src={profile.gender === "Male" ? "/assets/male-gender-icon.png" : "/assets/female-gender-icon.png"}
													alt={profile.gender === "Male" ? "Male" : "Female"}
												/>
											</div>
											<ChevronDownIcon
												className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                    ${isGenderDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
												aria-hidden="true"
												stroke="#292D32"
												strokeWidth={"0.1vh"}
											/>
										</button>

										{/* Gender Dropdown */}
										<div className={"relative"}>
											<Transition show={isGenderDropdownOpen}>
												<div
													className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                            origin-top-right rounded-md shadow-2xl 
                                                            border border-gray-700 overflow-y-scroll
                                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                                            data-[leave]:duration-75 data-[leave]:ease-in`}
												>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleGenderSelect("Male")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.GENDER.MALE")}
														</p>
													</button>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleGenderSelect("Female")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.GENDER.FEMALE")}
														</p>
													</button>
												</div>
											</Transition>
										</div>
									</div>
								</div>

								{/* Blood Group */}
                                {
                                    accountDetails.deceased
                                        ?
										<div className="flex flex-col gap-2">
											<label
                                                className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
                                                htmlFor='dateOfDeath'
											>
												{t("PROFILE_FORM.DOD.LABEL")}
											</label>
											<div className="relative w-full">
												<input
													type="date"
                                                    name='dateOfDeath'
													required
													value={profile.dateOfDeath}
													onChange={handleFormData}
													className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                        flex-1 items-center justify-center rounded-md px-[0.5vw] text-[1vw]
                                                        leading-none outline-none border-2 border-gray-300
                                                        focus:border-[#5F0F40]"
												/>
                                                {
                                                    error['dateOfDeath'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['dateOfDeath']}
													</p>
                                                }
											</div>
										</div>
                                        :
										<div className={"flex-1 flex flex-col gap-2"}>
                                            <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                                   htmlFor="phone">
												{t("PROFILE_FORM.BLOOD_GROUP.LABEL")}
											</label>
											<div className="relative w-full flex flex-col">
												<button
													onBlur={() => setIsBloodGroupDropdownOpen(false)}
													className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                                rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                                    onClick={() => setIsBloodGroupDropdownOpen(!isBloodGroupDropdownOpen)}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{profile.bloodGroup}
													</p>
													<ChevronDownIcon
														className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                    ${isBloodGroupDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
														aria-hidden="true"
														stroke="#292D32"
														strokeWidth={"0.1vh"}
													/>
												</button>

												{/* Blood Group Dropdown */}
												<div className={"relative"}>
													<Transition show={isBloodGroupDropdownOpen}>
														<div
															className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                            origin-top-right rounded-md shadow-2xl 
                                                            border border-gray-700 overflow-y-scroll
                                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                                            data-[leave]:duration-75 data-[leave]:ease-in`}
														>
                                                            {
                                                                bloodGroups.map((bloodGroup, index) => (
																	<button
																		key={index}
																		className="w-full min-h-[5vh] max-h-[5vh]
                                                                    cursor-pointer px-[0.5vw] hover:bg-gray-300
                                                                    flex items-center justify-between gap-1"
                                                                        onClick={() => handleBloodGroupSelect(bloodGroup)}
																	>
																		<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																			{bloodGroup}
																		</p>
																	</button>
                                                                ))
                                                            }
														</div>
													</Transition>
												</div>
											</div>
										</div>
                                }
							</div>
						</fieldset>

						{/* Mobile Number | Marital Status */}
						<fieldset>
							<div className={`mb-[2vh] flex flex-row gap-4`}>
								{/* Date of Death | Mobile Number Input */}
                                {
                                    accountDetails.deceased
                                        ?
										<div className={"flex-1 flex flex-col gap-2"}>
                                            <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                                   htmlFor="phone">
												{t("PROFILE_FORM.BLOOD_GROUP.LABEL")}
											</label>
											<div className="relative w-full flex flex-col">
												<button
													onBlur={() => setIsBloodGroupDropdownOpen(false)}
													className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                                rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                                    onClick={() => setIsBloodGroupDropdownOpen(!isBloodGroupDropdownOpen)}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{profile.bloodGroup}
													</p>
													<ChevronDownIcon
														className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                    ${isBloodGroupDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
														aria-hidden="true"
														stroke="#292D32"
														strokeWidth={"0.1vh"}
													/>
												</button>

												{/* Blood Group Dropdown */}
												<div className={"relative"}>
													<Transition show={isBloodGroupDropdownOpen}>
														<div
															className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                            origin-top-right rounded-md shadow-2xl 
                                                            border border-gray-700 overflow-y-scroll
                                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                                            data-[leave]:duration-75 data-[leave]:ease-in`}
														>
                                                            {
                                                                bloodGroups.map((bloodGroup, index) => (
																	<button
																		key={index}
																		className="w-full min-h-[5vh] max-h-[5vh]
                                                                    cursor-pointer px-[0.5vw] hover:bg-gray-300
                                                                    flex items-center justify-between gap-1"
                                                                        onClick={() => handleBloodGroupSelect(bloodGroup)}
																	>
																		<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																			{bloodGroup}
																		</p>
																	</button>
                                                                ))
                                                            }
														</div>
													</Transition>
												</div>
											</div>
										</div>
                                        :
										<div className={"flex-1 flex flex-col gap-2"}>
											<label
												className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
												htmlFor="phone"
											>
                                                {
                                                    checkAge(profile.dateOfBirth)
														? t("PROFILE_FORM.PHONE_NUMBER.LABEL")
                                                        : t("PROFILE_FORM.PHONE_NUMBER.LABEL_REQUIRED")
                                                }
											</label>
											<div className="relative w-full flex flex-col">
												<div
													className="flex flex-row justify-between items-center
                                                min-h-[5vh] max-h-[5vh] rounded-md border-2 border-gray-300"
												>
													<button
														onBlur={() => setIsCountryCodeDropdownOpen(false)}
														className="cursor-pointer bg-gray-300 min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    rounded-tl-md rounded-bl-md flex items-center justify-between gap-1"
                                                        onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
													>
														<img
															src={`/assets/country_flags/${profile.phone.charCode}.png`}
															className={"w-[1vw] aspect-auto"}
															alt={profile.phone.countryName}
														/>
														<p
															className={"font-SOHNE_REGULAR text-[0.75vw]"}
															aria-selected={"false"}
														>
															{profile.phone.dialingCode}
														</p>
														<ChevronDownIcon
															// onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
															className={`h-[0.75vw] w-[0.75vw] transition-all duration-150 ease-in-out
                                                        ${isCountryCodeDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
															aria-hidden="true"
															stroke="#292D32"
															strokeWidth={"0.1vh"}
														/>
													</button>
													<input
														type="phone"
														name="phone"
														className="min-h-[5vh] max-h-[5vh] w-full flex-1 px-[0.5vw]
                                                    font-SOHNE_MEDIUM text-[1vw] outline-none bg-transparent rounded-tr-md rounded-br-md"
														placeholder="7734567890"
														value={profile.phone.number || ""}
														onChange={(e) => {
															setProfile({
																...profile,
																phone: {
																	...profile.phone,
                                                                    number: e.target.value
                                                                }
															});

															setError({
																...error,
                                                                [e.target.name]: ""
															});
														}}
														onBlur={validatePhoneNumber}
														required
													/>
												</div>

												{/* Country Code Dropdown */}
												<div className={"relative"}>
													<Transition show={isCountryCodeDropdownOpen}>
														<div
															className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                        origin-top-right rounded-md shadow-2xl 
                                                        border border-gray-700 overflow-y-scroll
                                                        data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                        data-[enter]:duration-100 data-[enter]:ease-out 
                                                        data-[leave]:duration-75 data-[leave]:ease-in`}
														>
                                                            {
                                                                countryCodes.map((country, index) => (
																	<button
																		key={index}
																		className="w-full cursor-pointer min-h-[4vh] max-h-[4vh]
                                                                    px-[0.5vw] flex items-center gap-2 hover:bg-gray-300"
																		onClick={() => {
																			setIsCountryCodeDropdownOpen(false);
																			setProfile({
																				...profile,
																				phone: {
																					...profile.phone,
																					charCode: country.countryCode,
																					dialingCode: country.countryDialingCode,
																					countryName: country.countryName,
                                                                                }
																			});
																		}}
																	>
																		<img
																			src={country.countryFlagSrc}
																			className={"w-[1vw] aspect-auto"}
																			alt={country.countryName}
																		/>
																		<p
                                                                            className={"font-SOHNE_REGULAR text-[0.75vw] text-left"}
																			aria-selected={"false"}
																		>
																			{country.countryName}
																		</p>
																		<p
																			className={"font-SOHNE_REGULAR text-[0.75vw]"}
																			aria-selected={"false"}
																		>
                                                                            {'(' + country.countryDialingCode + ')'}
																		</p>
																	</button>
                                                                ))
                                                            }
														</div>
													</Transition>
												</div>
											</div>
                                            {
                                                error['phone'] &&
                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                   id="error">
                                                    {error['phone']}
												</p>
                                            }
										</div>
                                }

								{/* Marital Status */}
                                <div className={`${accountDetails.deceased ? 'flex-1' : 'w-1/4'} flex flex-col gap-2`}>
									<label
										className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
										htmlFor="maritalStatus"
									>
										<img
											src={"/assets/marriage-icon.png"}
											className={"w-[1.5vw] aspect-auto"}
											alt={"Marriage"}
											title={"Marriage"}
										/>
										{t("PROFILE_FORM.MARITAL_STATUS.LABEL")}
									</label>
									<div className="relative w-full flex flex-col">
										<button
											onBlur={() => setIsMaritalStatusDropdownOpen(false)}
											className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                                rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                            onClick={() => setIsMaritalStatusDropdownOpen(!isMaritalStatusDropdownOpen)}
										>
											<p className={"font-SOHNE_REGULAR text-[1vw]"}>
												{maritalStatusLabel(profile.maritalStatus)}
											</p>
											<ChevronDownIcon
												className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                    ${isMaritalStatusDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
												aria-hidden="true"
												stroke="#292D32"
												strokeWidth={"0.1vh"}
											/>
										</button>

										{/* Marital Status Dropdown */}
										<div className={"relative"}>
											<Transition show={isMaritalStatusDropdownOpen}>
												<div
													className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                            origin-top-right rounded-md shadow-2xl 
                                                            border border-gray-700 overflow-y-scroll
                                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                                            data-[leave]:duration-75 data-[leave]:ease-in`}
												>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleMaritalStatusSelect("Minor")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.MARITAL_STATUS.MINOR")}
														</p>
													</button>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleMaritalStatusSelect("Single")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.MARITAL_STATUS.SINGLE")}
														</p>
													</button>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleMaritalStatusSelect("Married")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.MARITAL_STATUS.MARRIED")}
														</p>
													</button>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
                                                        onClick={() => handleMaritalStatusSelect("Divorced")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                            {
                                                                profile.gender === "Male"
																	? t("PROFILE_FORM.MARITAL_STATUS.WIDOWER")
                                                                    : t("PROFILE_FORM.MARITAL_STATUS.WIDOWED")
                                                            }
														</p>
													</button>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleMaritalStatusSelect("Widowed")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.MARITAL_STATUS.DIVORCED")}
														</p>
													</button>
												</div>
											</Transition>
										</div>
                                        {
                                            error['maritalStatus'] &&
                                            <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                               id="error">
                                                {error['maritalStatus']}
											</p>
                                        }
									</div>
								</div>

								{/* Date of Marriage */}
                                {
                                    (profile.maritalStatus === 'Married' || profile.maritalStatus === 'Widowed' || profile.maritalStatus === 'Divorced') &&
									<div className="flex flex-col gap-2">
										<label
                                            className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
                                            htmlFor='dateOfMarriage'
										>
											{t("PROFILE_FORM.DOM.LABEL")}
										</label>
										<div className="relative w-full">
											<input
												type="date"
                                                name='dateOfMarriage'
												required
												value={profile.dateOfMarriage}
												onChange={handleFormData}
												className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                            flex-1 items-center justify-center rounded-md px-[0.5vw] text-[1vw]
                                                            leading-none outline-none border-2 border-gray-300
                                                            focus:border-[#5F0F40]`}
											/>
                                            {
                                                error['dateOfMarriage'] &&
                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                   id="error">
                                                    {error['dateOfMarriage']}
												</p>
                                            }
										</div>
									</div>
                                }
							</div>
						</fieldset>

						{/* Side Of Family | Name Before / After Marriage */}
                        {
                            (profile.maritalStatus === 'Married' || profile.maritalStatus === 'Widowed' || profile.maritalStatus === 'Divorced')
                            && profile.gender === "Female" && (
								<fieldset>
									<div className={"flex justify-between gap-4"}>
										{/* Side Of Family */}
										<div className="w-1/4 flex flex-col gap-2">
											<label
												className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
												htmlFor="maritalStatus"
											>
												{t("PROFILE_FORM.SIDE_OF_FAMILY.LABEL")}
											</label>
											<div className="relative w-full flex flex-col">
												<button
                                                    disabled={profile.gender === "Female" &&
                                                        (profile.maritalStatus === 'Single' || profile.maritalStatus === 'Minor')}
													onBlur={() => setIsSideOfFamilyDropdownOpen(false)}
													className={`flex flex-row justify-between items-center 
                                                            min-h-[5vh] max-h-[5vh] px-[0.5vw] gap-1 
                                                            rounded-md border-2 border-gray-300`}
                                                    onClick={() => setIsSideOfFamilyDropdownOpen(!isSideOfFamilyDropdownOpen)}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {
                                                            profile.sideOfFamily !== "" &&
															(profile.sideOfFamily === "MaherVashin"
																? t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")
                                                                : t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN"))
                                                        }
													</p>
													<ChevronDownIcon
														className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                                ${isSideOfFamilyDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
														aria-hidden="true"
														stroke="#292D32"
														strokeWidth={"0.1vh"}
													/>
												</button>

												{/* Side Of Family Dropdown */}
												<div className={"relative"}>
													<Transition show={isSideOfFamilyDropdownOpen}>
														<div
															className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                                origin-top-right rounded-md shadow-2xl 
                                                                border border-gray-700 overflow-y-scroll
                                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                                data-[leave]:duration-75 data-[leave]:ease-in`}
														>
															<button
																className={`w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                                    flex items-center justify-between gap-1 
                                                                    hover:bg-gray-300`}
                                                                onClick={() => handleSideOfFamilySelect("MaherVashin")}
															>
																<p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                                    {t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")}
																</p>
															</button>
															<button
																className={`w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                                    flex items-center justify-between gap-1 
                                                                    hover:bg-gray-300`}
                                                                onClick={() => handleSideOfFamilySelect("SasurVashin")}
															>
																<p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                                    {t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN")}
																</p>
															</button>
														</div>
													</Transition>
												</div>
											</div>
										</div>

										{/* Name Before Marriage */}
                                        {
                                            profile.sideOfFamily === "SasurVashin" && (
												<div className="mb-[2vh] flex flex-col gap-2">
													<label
														className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
														htmlFor="nameBeforeMarriage"
													>
														{t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.LABEL")}
													</label>

													<div className="flex justify-between gap-2">
														{/* First Name */}
														<div className={"flex-1 flex flex-col"}>
															<input
																id="firstNameBeforeMarriage"
																type="text"
																className={`font-SOHNE_REGULAR w-full min-h-[5vh] max-h-[5vh]
                                                                    inline-flex items-center justify-center rounded-md
                                                                    px-[0.5vw] text-[1vw] outline-none
                                                                    border-2 border-gray-300 focus:border-[#5F0F40]`}
                                                                placeholder={t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FIRST_NAME_PLACEHOLDER")}
                                                                name='firstNameBeforeMarriage'
																value={profile.firstNameBeforeMarriage || ""}
																onChange={handleFormData}
															/>
                                                            {
                                                                error['firstNameBeforeMarriage'] &&
                                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                                   id="error">
                                                                    {error['firstNameBeforeMarriage']}
																</p>
                                                            }
														</div>

														{/* Father's Name */}
														<div className={"flex-1 flex flex-col"}>
															<input
																id="fathersNameBeforeMarriage"
																type="text"
																className={`font-SOHNE_REGULAR w-full min-h-[5vh] max-h-[5vh]
                                                                    inline-flex items-center justify-center rounded-md
                                                                    px-[0.5vw] text-[1vw] outline-none
                                                                    border-2 border-gray-300 focus:border-[#5F0F40]`}
                                                                placeholder={t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FATHERS_NAME_PLACEHOLDER")}
                                                                name='fathersNameBeforeMarriage'
																value={profile.fathersNameBeforeMarriage || ""}
																onChange={handleFormData}
															/>
                                                            {
                                                                error['fathersNameBeforeMarriage'] &&
                                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                                   id="error">
                                                                    {error['fathersNameBeforeMarriage']}
																</p>
                                                            }
														</div>

														{/* Last Name */}
														<div className={"flex-1 flex flex-col"}>
															<input
																id="lastNameBeforeMarriage"
																type="text"
																className={`font-SOHNE_REGULAR w-full min-h-[5vh] max-h-[5vh]
                                                                    inline-flex items-center justify-center rounded-md
                                                                    px-[0.5vw] text-[1vw] outline-none
                                                                    border-2 border-gray-300 focus:border-[#5F0F40]`}
                                                                placeholder={t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.LAST_NAME_PLACEHOLDER")}
                                                                name='lastNameBeforeMarriage'
																value={profile.lastNameBeforeMarriage || ""}
																onChange={handleFormData}
															/>
                                                            {
                                                                error['lastNameBeforeMarriage'] &&
                                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                                   id="error">
                                                                    {error['lastNameBeforeMarriage']}
																</p>
                                                            }
														</div>
													</div>
												</div>
                                            )
                                        }

										{/* Name After Marriage */}
                                        {
                                            profile.sideOfFamily === "MaherVashin" && (
												<div className="mb-[2vh] flex flex-col gap-2">
													<label
														className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
														htmlFor="nameAfterMarriage"
													>
														{t("PROFILE_FORM.NAME.AFTER_MARRIAGE.LABEL")}
													</label>

													<div className="flex justify-between gap-2">
														{/* First Name */}
														<div className={"flex-1 flex flex-col"}>
															<input
																id="firstNameAfterMarriage"
																type="text"
																className={`font-SOHNE_REGULAR w-full min-h-[5vh] max-h-[5vh]
                                                                    inline-flex items-center justify-center rounded-md
                                                                    px-[0.5vw] text-[1vw] outline-none
                                                                    border-2 border-gray-300 focus:border-[#5F0F40]`}
                                                                placeholder={t("PROFILE_FORM.NAME.AFTER_MARRIAGE.FIRST_NAME_PLACEHOLDER")}
                                                                name='firstNameAfterMarriage'
																value={profile.firstNameAfterMarriage || ""}
																onChange={handleFormData}
															/>
                                                            {
                                                                error['firstNameAfterMarriage'] &&
                                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                                   id="error">
                                                                    {error['firstNameAfterMarriage']}
																</p>
                                                            }
														</div>

														{/* Spouse Name */}
														<div className={"flex-1 flex flex-col"}>
															<input
																id="spouseNameAfterMarriage"
																type="text"
																className={`font-SOHNE_REGULAR w-full min-h-[5vh] max-h-[5vh]
                                                                    inline-flex items-center justify-center rounded-md
                                                                    px-[0.5vw] text-[1vw] outline-none
                                                                    border-2 border-gray-300 focus:border-[#5F0F40]`}
                                                                placeholder={t("PROFILE_FORM.NAME.AFTER_MARRIAGE.SPOUSE_NAME_PLACEHOLDER")}
                                                                name='spouseNameAfterMarriage'
																value={profile.spouseNameAfterMarriage || ""}
																onChange={handleFormData}
															/>
                                                            {
                                                                error['spouseNameAfterMarriage'] &&
                                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                                   id="error">
                                                                    {error['spouseNameAfterMarriage']}
																</p>
                                                            }
														</div>

														{/* Last Name */}
														<div className={"flex-1 flex flex-col"}>
															<input
																id="lastNameAfterMarriage"
																type="text"
																className={`font-SOHNE_REGULAR w-full min-h-[5vh] max-h-[5vh]
                                                                    inline-flex items-center justify-center rounded-md
                                                                    px-[0.5vw] text-[1vw] outline-none
                                                                    border-2 border-gray-300 focus:border-[#5F0F40]`}
                                                                placeholder={t("PROFILE_FORM.NAME.AFTER_MARRIAGE.LAST_NAME_PLACEHOLDER")}
                                                                name='lastNameAfterMarriage'
																value={profile.lastNameAfterMarriage || ""}
																onChange={handleFormData}
															/>
                                                            {
                                                                error['lastNameAfterMarriage'] &&
                                                                <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                                   id="error">
                                                                    {error['lastNameAfterMarriage']}
																</p>
                                                            }
														</div>
													</div>
												</div>
                                            )
                                        }
									</div>
								</fieldset>
                            )
                        }

						{/* Biography */}
						<fieldset>
							<div className="mb-[2vh] flex flex-col gap-2">
								<label
									className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									htmlFor="bio"
								>
									{t("PROFILE_FORM.BIO.LABEL")}
								</label>
								<textarea
									id="description"
									rows={2}
									className={`no-scrollbar h-[9vh] w-full rounded-md
                                            px-[0.5vw] py-[1vh] text-[1vw] font-SOHNE_REGULAR
                                            outline-none border-2 border-gray-300 focus:border-[#5F0F40]`}
									placeholder={t("PROFILE_FORM.BIO.PLACEHOLDER")}
                                    name='biography'
									value={profile.biography || ""}
									onChange={handleFormData}
								/>
							</div>
						</fieldset>

                        {
                            !accountDetails.deceased && (
								<>
									{/* Address | Pincode */}
									<fieldset>
										<div className="mb-[2vh] flex gap-4">
											{/* Address Line */}
											<div className="flex-1 flex flex-col gap-2">
												<label
													className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
													htmlFor="addressLine1"
												>
													{t("PROFILE_FORM.ADDRESS.LABEL")}
												</label>
												<input
													id="addressLine1"
													className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                                    name='addressLine1'
													placeholder={t("PROFILE_FORM.ADDRESS.PLACEHOLDER")}
													value={profile.address.addressLine1}
													onChange={(e) => {
														setProfile({
															...profile,
															address: {
																...profile.address,
                                                                addressLine1: e.target.value
                                                            }
														});

														setError({
															...error,
                                                            [e.target.name]: ""
														});
													}}
												/>
                                                {
                                                    error['addressLine1'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['addressLine1']}
													</p>
                                                }
											</div>

											{/* Pincode */}
											<div className="w-1/3 flex flex-col gap-2">
												<label
													className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
													htmlFor="pincode"
												>
													{t("PROFILE_FORM.PINCODE.LABEL")}
												</label>
												<input
													type={"number"}
													max={6}
													className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
													id="pincode"
                                                    name='pincode'
													value={profile.address.pincode}
													onChange={(e) => {
														setProfile({
															...profile,
															address: {
																...profile.address,
                                                                pincode: e.target.value
                                                            }
														});

														setError({
															...error,
                                                            [e.target.name]: ""
														});
													}}
													onBlur={validatePincode}
												/>
                                                {
                                                    error['pincode'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['pincode']}
													</p>
                                                }
											</div>
										</div>
									</fieldset>

									{/* Country | City | State */}
									<fieldset>
										<div className="mb-[2vh] flex gap-4">
											{/* Country */}
											<div className={"flex-1 flex flex-col gap-2"}>
												<label
													className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
													htmlFor="country"
												>
													{t("PROFILE_FORM.COUNTRY.LABEL")}
												</label>
												<div className="relative w-full flex flex-col">
													{/* Country Value */}
													<div
														onBlur={() => setIsCountryDropdownOpen(false)}
														className={`flex flex-row justify-between items-center 
                                                    px-[0.5vw] gap-1 min-h-[5vh] max-h-[5vh] rounded-md 
                                                    border-2 ${isCountryDropdownOpen ? 'border-[#5F0F40]' : 'border-gray-300'}`}
                                                        onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
													>
														{/*<p className={"text-nowrap font-SOHNE_REGULAR text-[1vw]"}>*/}
														{/*    {profile.address.country}*/}
														{/*</p>*/}
														<input
															// disabled={!((profile.address.pincode.length === 6) && profile.address.country)}
															id="country"
															className="w-full h-full font-SOHNE_MEDIUM text-[1vw] leading-none outline-none"
															name="country"
															value={profile.address.country}
															onChange={(e) => {
																if (e.target.value === "") {
																	setFilteredCountryDetails(countryCodes);
																} else {
                                                                    console.log("Country Input Value: ", e.target.value);
																	setFilteredCountryDetails(
                                                                        countryCodes
                                                                            .filter((country) => {
                                                                                return country.countryName.toLowerCase().includes(e.target.value.toLowerCase());
																			})
																	);
																}

																setProfile({
																	...profile,
																	address: {
																		...profile.address,
                                                                        country: e.target.value
                                                                    }
																});

																setError({
																	...error,
                                                                    [e.target.name]: ""
																});
															}}
														/>
														<ChevronDownIcon
															className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                        ${isCountryDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
															aria-hidden="true"
															stroke="#292D32"
															strokeWidth={"0.1vh"}
														/>
													</div>

													{/* Country Dropdown */}
													<div className={"relative"}>
														<Transition show={isCountryDropdownOpen}>
															<div
																className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                        origin-top-right rounded-md shadow-2xl 
                                                        border border-gray-700 overflow-y-scroll no-scrollbar
                                                        data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                        data-[enter]:duration-100 data-[enter]:ease-out 
                                                        data-[leave]:duration-75 data-[leave]:ease-in`}
															>
                                                                {
                                                                    filteredCountryDetails.length > 0
                                                                        ? filteredCountryDetails.map((countryDetails, index) => (
																			<button
																				key={index}
																				className={`w-full min-h-[5vh] max-h-[5vh]
                                                                        px-[0.5vw] hover:bg-gray-300
                                                                        flex items-center gap-1`}
                                                                                onClick={() => handleCountrySelect(countryDetails)}
																			>
                                                                                <p className={"text-left text-nowrap font-SOHNE_REGULAR text-[1vw]"}>
																					{countryDetails.countryName}
																				</p>
																			</button>
                                                                        )) : (
																			<button
																				className={`w-full min-h-[5vh] max-h-[5vh]
                                                                        px-[0.5vw] hover:bg-gray-300
                                                                        flex items-center gap-1`}
                                                                                onClick={() => setIsCountryDropdownOpen(false)}
																			>
                                                                                <p className={"text-left text-nowrap font-SOHNE_REGULAR text-[1vw]"}>
																					{profile.address.country}
																				</p>
																			</button>
                                                                        )
                                                                }
															</div>
														</Transition>
													</div>
												</div>
                                                {
                                                    error['country'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['country']}
													</p>
                                                }
											</div>

											{/* State */}
											<div className="flex-1 flex flex-col gap-2">
												<label
													className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
													htmlFor="state"
												>
													{t("PROFILE_FORM.STATE.LABEL")}
												</label>
												<input
													// disabled={!((profile.address.pincode.length === 6) && profile.address.country)}
													id="state"
													className="w-full font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                                    name='state'
													value={profile.address.state}
													onChange={(e) => {
														setProfile({
															...profile,
															address: {
																...profile.address,
                                                                state: e.target.value
                                                            }
														});

														setError({
															...error,
                                                            [e.target.name]: ""
														});
													}}
												/>
                                                {
                                                    error['state'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['state']}
													</p>
                                                }
											</div>

											{/* City */}
											<div className="flex-1 flex flex-col gap-2">
												<label
													className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
													htmlFor="city"
												>
													{t("PROFILE_FORM.CITY.LABEL")}
												</label>
												<input
													// disabled={!((profile.address.pincode.length === 6) && profile.address.country)}
													id="city"
													className="w-full font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[0.5vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                                    name='city'
													value={profile.address.city}
													onChange={(e) => {
														setProfile({
															...profile,
															address: {
																...profile.address,
                                                                city: e.target.value
                                                            }
														});

														setError({
															...error,
                                                            [e.target.name]: ""
														});
													}}
												/>
                                                {
                                                    error['city'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['city']}
													</p>
                                                }
											</div>
										</div>
									</fieldset>

									{/* Home | Office Phone Number */}
									<fieldset>
										<div className={"mb-[2vh] flex gap-4"}>
											{/* Home Phone Number */}
											<div className={"flex-1 flex flex-col gap-2"}>
                                                <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                                                       htmlFor="homePhone">
													{t("PROFILE_FORM.HOME_PHONE_NUMBER.LABEL")}
												</label>
												<div className="relative w-full flex flex-col">
													<div
														className="flex flex-row justify-between items-center
                                                min-h-[5vh] max-h-[5vh] rounded-md border-2 border-gray-300"
													>
														<button
                                                            onBlur={() => setIsHomePhoneCountryCodeDropdownOpen(false)}
															className="cursor-pointer bg-gray-300 min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    rounded-tl-md rounded-bl-md flex items-center justify-between gap-1"
                                                            onClick={() => setIsHomePhoneCountryCodeDropdownOpen(!isHomePhoneCountryCodeDropdownOpen)}
														>
															<img
																src={`/assets/country_flags/${profile.homePhone.charCode}.png`}
																className={"w-[1vw] aspect-auto"}
																alt={profile.homePhone.countryName}
															/>
															<p
																className={"font-SOHNE_REGULAR text-[0.75vw]"}
																aria-selected={"false"}
															>
																{profile.homePhone.dialingCode}
															</p>
															<ChevronDownIcon
																// onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
																className={`h-[0.75vw] w-[0.75vw] transition-all duration-150 ease-in-out
                                                ${isHomePhoneCountryCodeDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
																aria-hidden="true"
																stroke="#292D32"
																strokeWidth={"0.1vh"}
															/>
														</button>
														<input
															type="phone"
															name="homePhone"
															className={`min-h-[5vh] max-h-[5vh] w-full flex-1 
                                                        px-[0.5vw] font-SOHNE_MEDIUM text-[1vw] outline-none 
                                                        bg-transparent rounded-tr-md rounded-br-md`}
															placeholder="7734567890"
															value={profile.homePhone.number || ""}
															onChange={(e) => {
																setProfile({
																	...profile,
																	homePhone: {
																		...profile.homePhone,
                                                                        number: e.target.value
                                                                    }
																});

																setError({
																	...error,
                                                                    [e.target.name]: ""
																});
															}}
															onBlur={validatePhoneNumber}
															required
														/>
													</div>

													{/* Country Code Dropdown */}
													<div className={"relative"}>
														<Transition show={isHomePhoneCountryCodeDropdownOpen}>
															<div
																className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                    origin-top-right rounded-md shadow-2xl 
                                                    border border-gray-700 overflow-y-scroll
                                                    data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                    data-[enter]:duration-100 data-[enter]:ease-out 
                                                    data-[leave]:duration-75 data-[leave]:ease-in`}
															>
                                                                {
                                                                    countryCodes.map((country, index) => (
																		<button
																			key={index}
																			className="w-full cursor-pointer min-h-[4vh] max-h-[4vh]
                                                                    px-[0.5vw] flex items-center gap-2 hover:bg-gray-300"
																			onClick={() => {
																				setIsCountryCodeDropdownOpen(false);
																				setProfile({
																					...profile,
																					homePhone: {
																						...profile.homePhone,
																						charCode: country.countryCode,
																						dialingCode: country.countryDialingCode,
																						countryName: country.countryName,
                                                                                    }
																				});
																			}}
																		>
																			<img
																				src={country.countryFlagSrc}
																				className={"w-[1vw] aspect-auto"}
																				alt={country.countryName}
																			/>
																			<p
                                                                                className={"font-SOHNE_REGULAR text-[0.75vw] text-left"}
																				aria-selected={"false"}
																			>
																				{country.countryName}
																			</p>
																			<p
                                                                                className={"font-SOHNE_REGULAR text-[0.75vw]"}
																				aria-selected={"false"}
																			>
                                                                                {'(' + country.countryDialingCode + ')'}
																			</p>
																		</button>
                                                                    ))
                                                                }
															</div>
														</Transition>
													</div>
												</div>
                                                {
                                                    error['homePhone'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['homePhone']}
													</p>
                                                }
											</div>

											{/* Office Phone Number */}
											<div className={"flex-1 flex flex-col gap-2"}>
												<label
													className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
													htmlFor="officePhone"
												>
													{t("PROFILE_FORM.OFFICE_PHONE_NUMBER.LABEL")}
												</label>
												<div className="relative w-full flex flex-col">
													<div
														className="flex flex-row justify-between items-center
                                        min-h-[5vh] max-h-[5vh] rounded-md border-2 border-gray-300"
													>
														<button
                                                            onBlur={() => setIsOfficePhoneCountryCodeDropdownOpen(false)}
															className="cursor-pointer bg-gray-300 min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                            rounded-tl-md rounded-bl-md flex items-center justify-between gap-1"
                                                            onClick={() => setIsOfficePhoneCountryCodeDropdownOpen(!isOfficePhoneCountryCodeDropdownOpen)}
														>
															<img
																src={`/assets/country_flags/${profile.officePhone.charCode}.png`}
																className={"w-[1vw] aspect-auto"}
																alt={profile.officePhone.countryName}
															/>
															<p
																className={"font-SOHNE_REGULAR text-[0.75vw]"}
																aria-selected={"false"}
															>
																{profile.officePhone.dialingCode}
															</p>
															<ChevronDownIcon
																// onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
																className={`h-[0.75vw] w-[0.75vw] transition-all duration-150 ease-in-out
                                                        ${isOfficePhoneCountryCodeDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
																aria-hidden="true"
																stroke="#292D32"
																strokeWidth={"0.1vh"}
															/>
														</button>
														<input
															type={"number"}
															name="officePhone"
															className="min-h-[5vh] max-h-[5vh] w-full flex-1 px-[0.5vw]
                                                    font-SOHNE_MEDIUM text-[1vw] outline-none bg-transparent rounded-tr-md rounded-br-md"
															placeholder="7734567890"
															value={profile.officePhone.number || ""}
															onChange={(e) => {
																setProfile({
																	...profile,
																	officePhone: {
																		...profile.officePhone,
                                                                        number: e.target.value
                                                                    }
																});

																setError({
																	...error,
                                                                    [e.target.name]: ""
																});
															}}
															onBlur={validatePhoneNumber}
															required
														/>
													</div>

													{/* Country Code Dropdown */}
													<div className={"relative"}>
                                                        <Transition show={isOfficePhoneCountryCodeDropdownOpen}>
															<div
																className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                        origin-top-right rounded-md shadow-2xl 
                                                        border border-gray-700 overflow-y-scroll
                                                        data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                        data-[enter]:duration-100 data-[enter]:ease-out 
                                                        data-[leave]:duration-75 data-[leave]:ease-in`}
															>
                                                                {
                                                                    countryCodes.map((country, index) => (
																		<button
																			key={index}
																			className="w-full cursor-pointer min-h-[4vh] max-h-[4vh]
                                                                    px-[0.5vw] flex items-center gap-2 hover:bg-gray-300"
																			onClick={() => {
																				setIsCountryCodeDropdownOpen(false);
																				setProfile({
																					...profile,
																					officePhone: {
																						...profile.officePhone,
																						charCode: country.countryCode,
																						dialingCode: country.countryDialingCode,
																						countryName: country.countryName,
                                                                                    }
																				});
																			}}
																		>
																			<img
																				src={country.countryFlagSrc}
																				className={"w-[1vw] aspect-auto"}
																				alt={country.countryName}
																			/>
																			<p
                                                                                className={"font-SOHNE_REGULAR text-[0.75vw] text-left"}
																				aria-selected={"false"}
																			>
																				{country.countryName}
																			</p>
																			<p
                                                                                className={"font-SOHNE_REGULAR text-[0.75vw]"}
																				aria-selected={"false"}
																			>
                                                                                {'(' + country.countryDialingCode + ')'}
																			</p>
																		</button>
                                                                    ))
                                                                }
															</div>
														</Transition>
													</div>
												</div>
                                                {
                                                    error['officePhone'] &&
                                                    <p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
                                                       id="error">
                                                        {error['officePhone']}
													</p>
                                                }
											</div>
										</div>
									</fieldset>
								</>
                            )
                        }
					</div>

					{/* Footer */}
					<div className={"max-h-[10vh]"}>
						{/* Divider */}
                        <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1.5vh]"}/>

						<div className="flex justify-end">
							{/* Submit Button */}
							<button
								disabled={isSaveButtonDisabled}
								onClick={handleSubmit}
								className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                                    bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                                    disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                                    pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
							>
								{t("PROFILE_FORM.SAVE")}
								<svg
									viewBox="0 0 6 6"
									fill="none"
									id={"arrow-icon"}
									className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M3 1L5 3L3 5"
										id={"arrow-icon-tip"}
										stroke="black"
										strokeLinecap="square"
									/>
									<path
										d="M1 3L4 3"
										id={"arrow-icon-line"}
										stroke="black"
										strokeLinecap="square"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			}
		</Modal>
	);
}