import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";


export const Chapter1 = () => {
  	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
  return (
    <div>
      <Header />
      <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
        <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
          प्रस्तावना
        </h1>
        <div
          key="para-1"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR "
        >
          <p className="mb-5 leading-relaxed text-justify">
            <span className="pl-6 sm:pl-8">पाश्चात्य</span> देशात आपल्यासारखी
            जातीजमातींची कल्पना नाही. परंतु कुलाची कल्पना दिसते. घर आणि घराणे या
            कल्पना सर्वत्र दिसतात. सर्वांना आपल्या घराचा आणि घराण्याचा अभिमानही
            असतो. सार्थ अभिमान बाळगणे गैरही म्हणता येत नाही. त्या अभिमानातून
            व्यक्तीच्या मनात महत्त्वाकांक्षा उत्पन्न होऊन उन्नतीची ओढ लागते.
            याचा विचार न करताच गका कुलाचा इतिहास संकलित करणे ही संकुचित वृत्ती
            आहे असा समज रुढ झालेला दिसतो. या गैरसमजामुळेच अनेक व्यक्ती आपल्या
            वंशाची माहिती देण्यास उत्सुक नसतात. काही असहकार करतात तर काही
            विरोधही करतात.
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8">राष्ट्राचा</span> इतिहास त्या
            काळातल्या घराण्यांच्या इतिहासातूनच प्रत्यक्षात येत असतो. राष्ट्राचा
            इतिहास म्हणजे राजकीय घटना-घडामोडींची कहाणी अशी पूर्वी कल्पना होती.
            आता इतिहासाची कक्षा विस्तृत झाली आहे. त्यात राजकीय विषयाबरोबरच
            सामाजिक, आर्थिक, भौगोलिक आणि आंतरराष्ट्रीय संबंधांचा विचार करण्यात
            येतो. त्यामुळे राष्ट्राच्या इतिहासात सामाजिक स्थितीलाही स्थान
            असल्यामुळे कुळांचा इतिहासही अंतर्भूत होतो. राष्ट्रीय स्तरावरील विचार
            येथे वगळला तरी महाराष्ट्राच्या इतिहासाला विस्तृत आणि सर्वांगीण
            स्वरूप येण्यासाठी कुळांचे खरे इतिहास आवश्यकच ठरतात. या शतकात
            कुळांचे, घराण्यांचे जे इतिहास प्रसिद्ध झाले ते फक्त कोकणस्थ ब्राह्मण
            घराण्यांचेच याच धर्तीवर मराठा आणि इतर सर्व समाजांचे वृत्तान्त
            प्रसिद्ध होणे जरूर आहे. देशस्थ ब्राह्मणांनी हे कार्य का केले नाही हे
            समजत नाही.
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8"> कुलवृत्तान्त</span> हा इतिहासाचाच गक
            प्रकार आहे. इतिहास हा नेहमीच उद्बोधक असतो. आपल्या देशात जवळ जवळ सर्व
            क्षेत्रांत पूर्वी नेत्रदीपक प्रगती झालेली असली तरी इतिहासाची
            उपेक्षाच झालेली आहे.
          </p>
        </div>
        <div
          key="para-2"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR"
        >
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> या कुलवृत्तान्ताच्या </span>कल्पनेला
            मूर्त स्वरूप देण्याचा मान वाई येथील रहिवासी श्री.गोविंद विनायक आपटे
            यांच्याकडे जातो. त्यांनी ख्रिस्ताब्द १९१४ मध्ये ‘आपटे घराण्याचा
            इतिहास‌’ प्रकाशात आणला. त्यांनीच सन १९१६ ला ‘बरवे घराण्याचा इतिहास‌’
            लिहून प्रसिद्ध केला. या इतिहासाचा प्रारंभ श्री.िंत्रबक रामचंद्र
            वैद्य यांनी स्वामीभक्तीने केला. श्री.मल्हार दादाजी बरवे हे बाळाजी
            विनाथ या पहिल्या पेशव्यांचे मेव्हणे. त्या बरवे यांचे सेवक वैद्य.
            त्यातून श्री.पेंडसे यांनी बरवे यांचा कुलवृत्तान्त प्रसिद्ध केला.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">आपल्या </span>पूर्वजांचा इतिहास
            लिहिण्याच्या कल्पनेने श्री.रामचंद्र सहस्रबुद्धे यांनी सन १८९० मध्ये
            काही माहिती नोंदून ठेवली होती. त्यात भर पडत पडत सन १९१७ मध्ये ‘वाईकर
            सहस्रबुद्धे घराण्याचा इतिहास‌’ या नावाचा ग्रंथ प्रसिद्ध करण्यात आला.
            त्याची चवथी आवृत्ती शंभर वर्षांनी म्हणजे १९९० या वर्षी प्रकाशित
            झाली.
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8"> या वृत्तान्ताचे</span> शीर्षक
            प्रारंभी ‘घराण्याचा इतिहास‌’ असे होते. परंतु सन १९३६ मध्ये ‘थत्ते
            घराण्याचा इतिहास‌’ हा ग्रंथ ‘थत्ते कुलवृत्तान्त‌’ हे शीर्षक देऊन
            प्रसिद्ध झाला. त्यानंतर इतर शीर्षके देऊन असे कुलवृत्तान्त प्रसिद्ध
            झाले. बरवे घराण्याच्या इतिहास-ग्रंथावर अभिप्राय देतान शि.म.परांजपे
            यांनी ‘वंशचरित्र‌’ असे म्हटले. पुढे ‘भिडे कुल-वृत्त‌’, ‘ओक-कुल
            व्यक्तिपरिचय‌’ अशी शीर्षके देण्यात आली. सन १९९४ मध्ये टिळक घराण्याचा
            इतिहास प्रसिद्ध झाला. त्याला सर्वस्वी वेगळे, स्वाभिमानदर्शक ‘‘आम्ही
            टिळक‌’’ असे नाव देण्यात आले.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">सर्व </span>कुलवृत्तान्ताचा आधार
            ‘गोत्र‌’ हा असतो. पूर्वी आपल्याकडे आडनावांची पद्धत नसली तरी सगळे
            कुलवृत्तान्त मात्र आडनावे घेऊनच प्रसिद्ध झालेले आहेत. बाळाजी विनाथ
            िंकवा गकामागून गक पेशवे पद भूषविलेल्यांना भट असे आडनाव लावण्यात येत
            नव्हते. व्यक्तीचा परिचय गोत्राधारे करण्यात येत असे. आडनावांची पद्धत
            रूढ झाल्यामुळे विशिष्ट गोत्रांची विशिष्ट आडनावे लावणाऱ्यांचे
            कुलवृत्तान्त तयार होऊ लागले. त्यामुळे गोखले कुलवृत्तान्त म्हटला तरी
            त्यात रसदेवरून, रास्ते, पालकर, बढे इत्यादि आडनावांचा अंतर्भाव झाला.
            देवधर कुलवृत्तान्तात ढमढेरे-दीक्षित आडनावे साहजिकच अंतर्भूत झाली.
          </p>
        </div>
        <div
          key="para-3"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR"
        >
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> ही</span> आडनावे कशी अस्तित्वात आली,
            ती कशावरून पडली हे सापडत नाही. गोखले या आडनावाची उपपत्तीही मिळालेली
            नाही. गकूण पाच प्रकाराने गोखले हे आडनाव कसे आले याचा विचार होऊनही
            निर्णायक असा निष्कर्ष निघालेला नाही. तसेच विष्णुबोवा ब्रह्मचारी या
            पूर्वाश्रमीच्या गोखले या प्रभावशाली साधूचे नाव वंशावळीत जोडता आलेले
            नाही.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> प्रस्तुत</span> जोगळेकर या
            आडनावाचीही व्युत्पत्ति सापडलेली नाही. त्याचे आश्चर्य िकवा विषाद
            वाटण्याचे कारण नाही.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> गोखले</span> हे नाव वडार समाजात
            आढळते. टिळक हे आडनाव असलेल्या गका महाराने सांगितले की पूर्वी मधूकर
            गेणूजी टिळक या गेवजी महार असे आडनाव म्हणून नोंद होत असे. कारण जातच
            आडनाव म्हणून नोंदली जात असे. मूळ कागदापत्रावर टिळक हे आडनाव
            गकोणिसाव्या शतकापासून नोंदण्यात आले आहे.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">टिळक</span> खोत होते आणि खोतांकडे
            वंशपरंपरेने कामे करणारे त्यांच्या आडनावानेच ते ओळखले जात. त्यामुळे
            टिळक हे नाव रूढ झाले असावे त्याचप्रमाणे गोखले हे वडार समाजात सापडते.
            गांधींच्या चळवळीने प्रभावित झालेल्या महाराष्ट्रातील वडार समाजाने
            त्यांच्या गुरूंचे म्हणजे गुरूंचे गुरू गोपाळ कृष्ण गोखले यांचे आडनाव
            आदराने लावण्यास सुरुवात केली असे समाज भूषण श्री.पु.पां.उपाख्य
            बाबूराव गोखले यांनी म्हटले आहे.{" "}
          </p>
        </div>
        <div
          key="para-4"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR"
        >
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8">दलित </span>समाजात अण्णाभाऊ साठे आणि
            रामदास आठवले अशी ब्राह्मण समाजाची आडनावे लावलेली दिसतात. अशा म्हणजे
            गोखले, टिळक, साठे, आठवले (आणि असल्यास तत्सम) नावांच्या कारणमीमांसेचा
            शोध समाजशास्त्रज्ञांनी घेणे इष्ट होईल.
          </p>
        </div>
        <div
          key="para-5"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR "
        >
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> कुलवृत्तान्ताच्या</span> कामात
            साहित्यिक आणि इतिहासाचे अभ्यासक िंकवा संशोधकही फारसे पडलेले आढळत
            नाहीत. हे कार्य कुलाचा अभिमान आणि अनुषंगाने सामाजिक कार्याची आवड आणि
            आस्था असणाऱ्या माणसांनी केलेले आहे. यासाठी जरूर ती माहिती
            पुरविण्याची अनास्था आणि काही विरोध अशा अडचणींना तोंड देत मिळेल ती
            माहिती संकलित करावी लागली. तिची सत्यासत्यता पडताळून बघता आली नाही
            म्हणजे ती साधार नाही असे मला सुचवावयाचे नाही. वंशवृक्षाबद्दल तसा
            आक्षेप घेता येणार नाही. क्वचित गखादी चूक सापडलीच तरी ती नगण्य होय.
            अतिशयोक्तीचा िंकवा इतर प्रमाद संभवतो तो कर्तबगारीच्या वर्णनाच्या
            संदर्भात तपशिलात कुलवृत्तान्तासंबंधी बहसंख्य मंडळीत गैरसमज पसरलेले
            आढळतात. अशी जंत्रीवजा पुस्तके कोती वृत्ति दर्शवितात. अशा प्रयत्नांची
            जरुरी नाही आणि त्यांचा उपयोगही नाही. अशा मतांच्या मंडळींना करता आला
            तर वर उल्लेखिलेला दोषारोपच करता येतो म्हणून मी तो येथे मांडला आहे.
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8"> या कार्यात</span> सद्भावनेने काम
            करणारे कुठे सापडलेच तर तो दोषही यथावकाश दूर करतील असा मला वािस
            वाटतो.
          </p>
        </div>
        <div
          key="para-6"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR "
        >
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> कुलवृत्तान्ताचा </span>उपयोग काय असे
            ज्यांना वाटत असेल त्यांना खालील उदाहरणे विचार करावयास लावतील.
            त्यामुळे ती येथेच नमूद करणे योग्य होईल.
          </p>
          <ol className="  ml-8 sm:ml-12 md:ml-16 leading-relaxed">
            <li className="mb-4">
              १) गका परदेशात स्थायिक झालेल्या गृहस्थाला जमीन विकत घ्यावयाची
              होती. जमीन मालकाच्या कुलवृत्तान्ताच्या आधाराने घराण्याची अचूक
              माहिती त्याने मिळविली. संबंधित व्यक्तींच्या भेटीगाठी घेऊन हा
              व्यवहार पूर्ण केला. या माहितीमुळे जमिनीच्या व्यवहारात उणीव न राहता
              काम सुलभ झाले. (केळकर कुलवृत्तान्त )
            </li>

            <li className="mb-4">
              {" "}
              २) माझी पत्नी डॉ.कमल गोखले यांना गका जुन्या घराण्याची कागदपत्रे
              मिळाली. त्यात काही महत्त्वाची पत्रे सापडली. त्यामुळे त्या
              गृहस्थांनी संपूर्ण वंशवृक्ष करण्याची विनंती केली. सुदैवाने
              त्याच्या आधारावर आणि वतन-पत्रावर त्यांना दोन मंदिरे आणि त्यासाठी
              असलेली जमीन यांचा सहज कबजा मिळू शकला.
            </li>

            <li className="mb-4">
              ३) गोिंवद वल्लभ पंताचे घराणे मराठेशाहीत उत्तरेत स्थायिक झाले.
              त्यांचे चिरंजीव माजी मंत्री श्री.के.सी.पंत महाराष्ट्रात आले असताना
              त्यांना आपल्या मूळ गावाला जाण्याची इच्छा झाली. परंतु त्यांना
              वंशावळ, वगैरे माहिती फारशी नसल्यामुळे त्यांनी माझ्याजवळ चौकशी
              केली. तेव्हा मदत करता आली नाही. कुलवृत्तान्तात ती दिलेली असते तशी
              माहिती त्यांना असती तर त्यांची मनीषा सहज पूर्ण झाली असती. त्यानंतर
              त्यांनी प्रयत्न केला की नाही ते कळले नाही.
            </li>
          </ol>
        </div>
        <div
          key="para-7"
          className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR"
        >
          <p className="m-5">
            <span className="mb-5 leading-relaxed">आठ</span> कोटी
            महाराष्ट्राच्या लोकसंख्येत विद्यमान जोगळेकरांची संख्या आठ-दहा हजार
            म्हणजे खरोखरच अत्यल्प आहे. असे असूनही सैनिकी सेवा, प्रशासन, शिक्षण,
            संगीत, साहित्य, नृत्य, नाट्य, व्यापार-उद्योग अशा अनेक क्षेत्रांत
            जोगळेकर मंडळींचा सहभाग आहे हे अभिनंदनीय आहे. मला आवर्जून
            त्र्यंबकच्या वासुदेव भगवंत जोगळेकर यांचा येथे उल्लेख केला पाहिजे.
            १८५७ च्या स्वातंत्र्यसमरात त्यांनी अनेक मर्दुमकीची कामे केली. येथे
            त्यांची दिलेली हकीकत जोगळेकर कुळाला विशेष शोभा देणारी आहे हौतात्म्य
            स्वीकारताना प्रथेप्रमाणे त्यांनी शेवटची इच्छा व्यक्त केली.
            त्याप्रमाणे त्र्यंबकेर देवस्थानच्या व्यवस्थापनाची - शिवसेवेची
            वासुदेवरावांची अंतिम इच्छा मान्य करण्यात आली, त्यानुसार ती कामगिरी
            जोगळेकर घराण्याकडेच आहे{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">या</span> शूर हतात्म्याप्रमाणेच
            जलशास्र विषयाचे संशोधक पद्मश्री दिगंबर वासुदेव जोगळेकर यांच्या
            कार्याची सविस्तर माहिती देण्यात आली आहे. श्रेष्ठ साहित्यिक सदाशिव
            आत्माराम जोगळेकर यांच्या आणि त्यांच्या श्रृंगार नायिका, गाथासप्तशती,
            सह्याद्री या अविस्मरणीय पण दुर्मिळ ग्रंथांच्या परिचयाकडे वाचकांचे
            विशेष लक्ष वेधणे मला महत्त्वाचे वाटते. जोगळेकर घराण्यातील अनेक
            नामवंत आणि कर्तृत्ववान व्यक्तींची माहितीही छायाचित्रांसह वाचावयास
            मिळते.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">गकविसाव्या</span> शतकात परदेशात जाऊन
            स्थायिक होणाऱ्या कोकणस्थ ब्राह्मणांचे प्रमाण फार वाढण्याची लक्षणे
            दिसू लागली आहेत. दोन शतकांपूर्वी नशीब काढण्यासाठी कोकणातील कुटुंबे
            बाहेर पडली. घाटावर आली परंतु कर्तबगारी करीत िंहदुस्थानातच राहिली.
            नव्या पिढीचा दृष्टिकोन बदलत चालला आहे. त्यांना अमेरिका, कॅनडा,
            ऑस्ट्रेलियामधील झगमगत्या जीवनाचे, तेथील पैशाचे आणि सुखसोयींचे आकर्षण
            आहे. अतिसमृद्धतेमुळेच आलेले तेथील दोष अनुभवास आले, राजकीय कारणाने
            टप्पू खावे लागले िंकवा देशप्रेम जागृत झाल्यावर अनेक वर्षांनी
            मातृभूमीस परत यावे लागले तर दूर गेलेल्या, विस्मृतीत गेलेल्या
            सगेसोयऱ्यांचा शोध घेताना अशा कुलवृत्तान्ताचा माग घ्यावा लागेल{" "}
          </p>
          <span className="m-10">कै.श्री.पु.गोखले</span>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr />
        </div>
      </div>
    </div>
  );
};
