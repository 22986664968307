import React, {Fragment, useContext, useEffect, useState} from "react";
import {AccountContext} from "../context/AccountProvider";
import Cookies from "universal-cookie";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {generateOtp, matchOtp} from "../service/userOtpApi";
import {toast} from "react-toastify";

export const Login = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const {setAccount, setCookie} = useContext(AccountContext);

    const [emailId, setEmailId] = useState("");

    const [isSendEmailOtpButtonDisabled, setIsSendEmailOtpButtonDisabled] = useState(true);
    const [isSendEmailOtpSuccess, setIsSendEmailOtpSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [otpData, setOtpData] = useState({});

    const [otp, setOtp] = useState("");

    const [isVerifyOtpButtonDisabled, setIsVerifyOtpButtonDisabled] = useState(true);

    const validateEmail = (email) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    const validateEmailId = (e) => {
        if (e.target.value !== "") {
            if (!validateEmail(e.target.value)) {
                setErrorMessage(t("REGISTER_PAGE.EMAIL_INPUT.ERROR.INVALID_INPUT"));
            } else {
                setIsSendEmailOtpButtonDisabled(false);
                setErrorMessage("");
            }
        } else {
            setIsSendEmailOtpButtonDisabled(true);
            setErrorMessage("");
        }
    }

    useEffect(() => {
        if (validateEmail(emailId)) {
            setIsSendEmailOtpButtonDisabled(false);
        } else {
            setIsSendEmailOtpButtonDisabled(true);
        }
    }, [emailId]);

    useEffect(() => {
        if (otp.length === 6 && otp) {
            setIsVerifyOtpButtonDisabled(false);
        } else {
            setIsVerifyOtpButtonDisabled(true);
        }
    }, [otp]);

    const sendOtpHandler = () => {
        setIsSendEmailOtpButtonDisabled(true);
        generateOtp(emailId, "email", "login")
            .then((result) => {
                console.log("(Login.jsx) Send Email OTP Result: ", result);

                if (result.success) {
                    setOtpData(result.data);
                    setIsSendEmailOtpSuccess(true);
                    toast.success(result.message)
                }
            })
            .catch((error) => {
                console.log("(Login.jsx) Error while sending OTP: ", error);
                setIsSendEmailOtpSuccess(false);
                toast.error(error.data.message);
            })
            .finally(() => {
                setIsSendEmailOtpButtonDisabled(false);
            });
    }

    const matchOtpHandler = () => {
        if (otpData && otpData.userId && otp) {
            toast.dismiss();
            matchOtp(otpData.userId, otp, "login")
                .then((result) => {
                    console.log("Match OTP Result in Login Component: ", result);

                    if (result.success) {
                        if (result.data) {
                            cookies.set("email", result.data.email, {path: "/"});
                            setAccount((prevState) => ({
                                ...prevState,
                                ...result.data,
                            }));
                            setCookie(cookies.get("email"));
                            toast.success(result.message);
                        } else {
                            toast.info(result.message);
                        }
                    } else {
                        toast.error(result.message);
                    }
                })
                .catch((error) => {
                    console.log("(Login.jsx) Error while matching OTP: ", error);
                    toast.error(error.data.data.message);
                });
        }
    }

    return (
        <>
            <div className="h-[100vh] w-full flex flex-row items-center justify-center">
                <div className={"flex-1 h-full"}>
                    <img
                        src="/kulvratant_landingPage/banner_amhi_joglekar 1920.jpg"
                        alt=""
                        className="h-full object-cover shadow-lg"
                    />
                </div>
                <div className="flex flex-1 flex-col items-center justify-center">
                    <h1 className="text-center leading-[1.25] mb-[2vh] text-[3vw] text-transparent font-SOHNE_EXTRA_BOLD bg-clip-text bg-yellow-400 bg-gradient-to-br from-yellow-400 to-red-500">
                        {t("LOGIN_PAGE.GREETING")}
                    </h1>

                    <div className="w-[25vw] flex flex-col gap-6 items-center">

                        {/* Email ID */}
                        <div className="w-full">
                            <label
                                htmlFor="email"
                                className="block text-[1vw] font-SOHNE_MEDIUM leading-6 text-gray-900"
                            >
                                {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.LABEL")}
                            </label>
                            <div className="flex gap-2 mt-[1.5vh]">
                                <input
                                    type="email"
                                    name="email"
                                    value={emailId}
                                    onChange={(e) => {
                                        setEmailId(e.target.value);
                                        setErrorMessage("");
                                    }}
                                    id="email"
                                    className={`flex-1 text-[1vw] leading-tight font-SOHNE_LIGHT text-gray-900 
                                            py-[1vh] px-[1.25vw] shadow-sm 
                                            border border-gray-300 outline-none rounded-full 
                                            focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40] 
                                            transition-all duration-300 ease-in-out`}
                                    onBlur={validateEmailId}
                                />
                                <button
                                    disabled={isSendEmailOtpButtonDisabled}
                                    onClick={sendOtpHandler}
                                    className={`${!isSendEmailOtpButtonDisabled && 'arrow-button'} 
                                        inline-flex items-center bg-[#D26A53] 
                                        text-[1vw] text-white leading-tight font-SOHNE_MEDIUM  
                                        hover:ring-[#5F0F40] hover:ring-offset-1 hover:ring-4 
                                        disabled:cursor-not-allowed disabled:bg-slate-400/50 
                                        disabled:ring-0 disabled:ring-offset-0
                                        rounded-full pl-[1.25vw] pr-[0.75vw] py-[1.5vh] 
                                        transition-all duration-300 ease-in-out`}
                                >
                                    {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.SEND_OTP")}
                                    <svg
                                        viewBox="0 0 6 6"
                                        fill="none"
                                        id={"arrow-icon"}
                                        className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 1L5 3L3 5"
                                            id={"arrow-icon-tip"}
                                            stroke="white"
                                            strokeLinecap="square"
                                        />
                                        <path
                                            d="M1 3L4 3"
                                            id={"arrow-icon-line"}
                                            stroke="white"
                                            strokeLinecap="square"
                                        />
                                    </svg>
                                </button>
                            </div>

                            {errorMessage && (
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {errorMessage}
                                </p>
                            )}
                        </div>

                        {isSendEmailOtpSuccess && (
                            <div className="w-full">
                                <label
                                    htmlFor="email"
                                    className="font-SOHNE_MEDIUM block text-[1vw] leading-6 text-gray-900"
                                >
                                    {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.ENTER_OTP")}
                                </label>
                                <div className="flex gap-2 mt-[1.5vh]">
                                    <input
                                        type={"number"}
                                        name="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        id="otp"
                                        className={`flex-1 text-[1vw] leading-tight font-SOHNE_LIGHT text-gray-900 
                                            py-[1.5vh] px-[1.25vw] shadow-sm 
                                            border border-gray-300 outline-none rounded-full 
                                            focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40] 
                                            transition-all duration-300 ease-in-out`}
                                    />
                                    <button
                                        disabled={isVerifyOtpButtonDisabled}
                                        onClick={matchOtpHandler}
                                        className={`${isVerifyOtpButtonDisabled ? '' : 'arrow-button'}
                                            inline-flex items-center bg-[#D26A53]
                                            rounded-full pl-[1.25vw] pr-[0.75vw] py-[1.5vh] 
                                            text-[1vw] text-white leading-tight font-SOHNE_MEDIUM  
                                            hover:ring-[#5F0F40] hover:ring-offset-1 hover:ring-4
                                            transition-all duration-300 ease-in-out 
                                            disabled:cursor-not-allowed disabled:bg-slate-400/50
                                            disabled:ring-0 disabled:ring-offset-0`}
                                    >
                                        {t("LOGIN_PAGE.EMAIL_PHONE_INPUT.VERIFY_OTP")}
                                        <svg
                                            viewBox="0 0 6 6"
                                            fill="none"
                                            id={"arrow-icon"}
                                            className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3 1L5 3L3 5"
                                                id={"arrow-icon-tip"}
                                                stroke="white"
                                                strokeLinecap="square"
                                            />
                                            <path
                                                d="M1 3L4 3"
                                                id={"arrow-icon-line"}
                                                stroke="white"
                                                strokeLinecap="square"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Divider */}
                        <p className={"font-SOHNE_MEDIUM"}>
                            {t("LOGIN_PAGE.OR")}
                        </p>

                        {/* Register */}
                        <div className={"inline-flex items-center justify-between w-full"}>
                            <button
                                onClick={() => navigate('/register')}
                                type="button"
                                role="link"
                                tabIndex={0}
                                aria-label="Register"
                                className={`arrow-button flex flex-1 items-center justify-center gap-[0.5vw]
                                    bg-[#610329] text-[#000022] px-[1vw] py-[1.5vh]
                                    border-2 border-white rounded-full
                                    hover:ring-[#5F0F40] hover:ring-offset hover:ring-4
                                    transition-all duration-300 ease-in-out
                                    hover:bg-[#000022] hover:text-white`}
                            >
                                <p className={"font-SOHNE_MEDIUM text-[1vw] leading-tight text-white"}>
                                    {t("LOGIN_PAGE.REGISTER")}
                                </p>
                                <svg
                                    viewBox="0 0 6 6"
                                    fill="none"
                                    id={"arrow-icon"}
                                    className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 1L5 3L3 5"
                                        id={"arrow-icon-tip"}
                                        stroke="white"
                                        strokeLinecap="square"
                                    />
                                    <path
                                        d="M1 3L4 3"
                                        id={"arrow-icon-line"}
                                        stroke="white"
                                        strokeLinecap="square"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Dialog for Adding a New Account in Single Login / Registration Flow */}
            {/*<Transition show={isOpen}>*/}
            {/*    <div*/}
            {/*        className="min-h-full w-screen flex items-center justify-center fixed inset-0 z-10*/}
            {/*        overflow-y-scroll no-scrollbar bg-black/75 backdrop-blur-lg*/}
            {/*        data-[closed]:opacity-0*/}
            {/*        data-[enter]:duration-75 data-[enter]:data-[closed]:ease-out*/}
            {/*        data-[leave]:duration-300 data-[leave]:data-[closed]:ease-out"*/}
            {/*    >*/}
            {/*        <TransitionChild>*/}
            {/*            <div*/}
            {/*                className="transform z-50 h-fit w-full max-w-lg rounded-xl*/}
            {/*                ring-4 ring-[#050A0F] p-[1vw] bg-black/5*/}
            {/*                data-[enter]:duration-100 data-[enter]:data-[closed]:scale-75*/}
            {/*                data-[leave]:duration-300 data-[leave]:data-[closed]:scale-95"*/}
            {/*            >*/}
            {/*                <h2 className="font-SOHNE_MEDIUM text-xl text-white">*/}
            {/*                    {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.TITLE")}*/}
            {/*                </h2>*/}
            {/*                <p className="my-[2vh] text-md text-white/70 font-SOHNE_REGULAR leading-relaxed">*/}
            {/*                <span className={"font-SOHNE_MONO_MEDIUM underline"}>*/}
            {/*                    {emailWithNoAccount}*/}
            {/*                </span>*/}
            {/*                    {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.DESCRIPTION")}*/}
            {/*                </p>*/}
            {/*                <div className="mt-[1vh] flex flex-row items-center gap-x-[1vw]">*/}
            {/*                    <button*/}
            {/*                        disabled={yesButtonDisabled}*/}
            {/*                        onClick={addNewAccount}*/}
            {/*                        className="font-SOHNE_MEDIUM inline-flex items-center arrow-button bg-[#D26A53] leading-tight hover:ring-white hover:ring-2 disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full pl-[1.25vw] pr-[0.75vw] py-[1vh] text-white transition-all duration-300 ease-in-out"*/}
            {/*                    >*/}
            {/*                        {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.OPTION_1")}*/}
            {/*                        <svg*/}
            {/*                            viewBox="0 0 6 6"*/}
            {/*                            fill="none"*/}
            {/*                            id={"arrow-icon"}*/}
            {/*                            className={"h-[1vw] w-[1vw] ml-[0.75vw]"}*/}
            {/*                            xmlns="http://www.w3.org/2000/svg"*/}
            {/*                        >*/}
            {/*                            <path*/}
            {/*                                d="M3 1L5 3L3 5"*/}
            {/*                                id={"arrow-icon-tip"}*/}
            {/*                                stroke="white"*/}
            {/*                                strokeLinecap="square"*/}
            {/*                            />*/}
            {/*                            <path*/}
            {/*                                d="M1 3L4 3"*/}
            {/*                                id={"arrow-icon-line"}*/}
            {/*                                stroke="white"*/}
            {/*                                strokeLinecap="square"*/}
            {/*                            />*/}
            {/*                        </svg>*/}
            {/*                    </button>*/}
            {/*                    <button*/}
            {/*                        disabled={yesButtonDisabled}*/}
            {/*                        className="inline-flex items-center gap-2 rounded-full ring-inset ring-2 ring-white py-[1vh] px-[2vw] text-SM font-SOHNE_MEDIUM text-white focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white transition-all duration-300 ease-in-out"*/}
            {/*                        onClick={() => setIsOpen(false)}*/}
            {/*                    >*/}
            {/*                        {t("LOGIN_PAGE.NO_ACCOUNT_FOUND.OPTION_2")}*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </TransitionChild>*/}
            {/*    </div>*/}
            {/*</Transition>*/}
        </>
    )
}