import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";


export const Chapter4 = () => {
  	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
  return (
    <div>
      <Header />
      <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
        <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
          ऋणनिर्देश
        </h1>
        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8"> ही</span> दसरी आवृत्ती आज प्रसिद्ध
            होत आहे ते अनेकांच्या दीर्घ प्रयत्नाचे व परिश्रमाचे फलित होय.
            श्री.विजय केशव, परशुराम गंगाधर यांनी तर गेली अनेक वर्षे याच गका
            कामाचा ध्यास घेतला. त्यांना श्री.दिलीप शरंद्र,श्री.विवेक
            गंगाधर,श्री.अनिल गंगाधर, श्री.विजय परशराम, श्री.प्रकाश त्रिंबक, अशा
            अनेकांचे सतत सहकार्य लाभले. मुंबई येथील श्री.शशिकांत लक्ष्मण,
            नाशिकचे श्री. प्र.शि.(मामासाहेब),माधव आत्माराम, सुधीर अंबादास
            यांनीही सर्वतोपरी मदत करण्यात नेहमीच उत्साह दाखविला. कुलवृत्तान्त
            ग्रंथातील मुख्य नोंदींच्या लेखनाचे व वंशावळी तयार करण्याचे कष्टाचे
            काम श्री.श्याम जोगळेकर, श्री.विजय जोगळेकर, यांनी सर्वांनी केले. या
            सर्वांचे आभार मानणे योग्य होणार नाही. कारण जोगळेकर कुलाच्या
            अभिमानाने व कर्तव्यभावनेने हे सामूहिक कार्य या सर्वांनी चिकाटीने पार
            पाडले याची नोंद करणे योग्य होईल. विशेषत: विजय केशव व परशुराम (श्याम)
            गंगाधर यांनी ठरलेल्या वेळापत्रकानुसार या आवृत्तीचे काम पूर्ण करायचेच
            असा निर्धार करून या कामाचा ध्यास घेतला नसता तर हे काम अजूनही
            रेंगाळले असते.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">जोगळेकर</span> कुलातील उद्योजक,
            व्यापारी व दुकानदार यांना आवाहन-पत्रे पाठवून त्यांच्याकडून जाहिराती
            मिळवण्याचे काम अनेकांनी चिकाटीने पार पाडले त्यामुळे थोडे अर्थसाहाय्य
            झालेच, पण भावी पिढ्यांना जोगळेकरांपैकी उद्योजक कोण होते हे कळेल व
            त्या क्षेत्रात पुढे पाऊल टाकण्याची प्रेरणा मिळेल असा विश्वास वाटतो.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">ग्रंथाचे</span> मुद्रण सुबक
            होण्यासाठी कल्पक व अनुभवी मुद्रकाची गरज होती. हे काम उत्तम प्रकारे
            पार पाडल्याबद्दल श्री.प्रवीण जोशी व श्री.विनय दीक्षित यांचे आम्ही
            आभारी आहोत.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">ज्यांनी </span>आपल्या माहितीचे
            फॉर्म्स वेळेवर भरून पाठविले नाहीत, त्यांना हा सुव्यवस्थित ग्रंथ
            पाहून माहिती देण्याची प्रेरणा मिळेल असे वाटते. तसे झाल्यास योग्य
            त्या स्वरूपात पुरवणी काढणे शक्य होईल. जोगळेकर कुलवृत्तान्त समिती या
            पुढेही कार्य करीत राहणार असल्यामुळे या ग्रंथाची अद्ययावत पढील
            आवृत्ती काढण्याचा सुयोग येवो ही इच्छा व्यक्त करून जोगळेकर -
            बंधुभगिनींच्या हाती हा ग्रंथ देत आहोत.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">जोगळेकर</span> कुलातील सध्याच्या
            कार्यकर्त्यांनी ही आवृत्ती झाल्यावर तिथेच न थांबता या समितीचे काम
            यापुढेही चालू ठेवण्याचा निर्णय घेतला आहे. जोगळेकर कुलवृत्तान्त समिती
            नोंदणीकृत संस्था असल्यामुळे तिची वार्षिक साधारण सभा होत राहीलच.
            प्रस्तुत ग्रंथात ज्या त्रुटी राहिल्या असतील त्या भरून काढणे, नवनवीन
            माहिती मिळवून कुलवृत्तान्त ग्रंथ अद्ययावत करणे, वेगवेगळ्या गावी
            जोगळेकरांचे वार्षिक संमेलन भरविणे, समाजोपयोगी नवे उपक्रम सुरू करणे,
            जोगळेकर कुलातील बंधूभगिनींच्या (यात माहेरवाशिणींचाही समावेश होतो)
            हिताच्या योजना राबविणे अशा अनेक गोष्टी करण्यासारख्या आहेत. सध्याच्या
            पिढीने आपली जबाबदारी पार पाडली आहे. भावी काळातील आव्हाने
            स्वीकारण्यासाठी नवीन पिढी पुढे सरसावेल असा विश्वास वाटतो.
          </p>
          <p className="mb-5 leading-relaxed ">
            {" "}
            <span className="pl-6 sm:pl-8"> जोगळेकर</span> कुलवृत्तान्त समितीचे
            सर्व लहानथोर कार्यकर्ते, सभासद, हितिंचतक, जाहिरातदार, देणगीदार
            बंधूभगिनींचे मन:पूर्वक आभार व सर्वांना हार्दिक शुभेच्छा{" "}
          </p>
          <div className="ml-20">
            <span>अनिल गंगाधर जोगळेकर</span>
            <br />
            <span> अध्यक्ष</span>
            <br />
            <span> जोगळेकर कुलवृत्तान्त समिती</span>
          </div>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr />
        </div>
      </div>
    </div>
  );
};
