import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";


export const Chapter6 = () => {
	  	// Scroll to Top function
		  const scrollToTop = () => {
			window.scrollTo({ top: 0, behavior: "smooth" });
		};
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					जोगळेकर कुलवृत्तान्त समिती
					<br />
					घटना व नियमावली
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
					<p className="text-center mb-2">- परिशिष्ट “ब”-</p>
					<p className="text-center">
						- जोगळेकर कुलवृत्तान्त समिती - या संस्थेचे ज्ञापन
					</p>
					<div className="flex items-center justify-center mt-6">
						<table className="table-auto w-[1000px] text-justify">
							<tbody>
								<tr className="">
									<td className="px-5 py-3  ">
										1) संस्थेचे नाव{" "}
									</td>
									<td className="px-5 py-3 ">
										: जोगळेकर कुलवृत्तान्त समिती
									</td>
								</tr>
								<tr>
									<td className="px-5 py-3  ">
										{" "}
										2)संस्थेचे कार्यालयाचा पत्ता{" "}
									</td>
									<td className="px-5 py-3 ">
										: द्वारा श्री.विजय केशव जोगळेकर 1210/क,
										शिवाजीनगर, ऑफ आपटे रोड, पुणे - 411 004.
										फोन नं. 25532164
									</td>
								</tr>
								<tr>
									<td className="px-5 py-3">
										{" "}
										3)समितीचे उद्देश{" "}
									</td>
									<td className="px-4 py-3">
										:अत्री गोत्रीय चित्तपावन व अन्य जोगळेकर
										कुटुंबातील व्यक्तीमध्ये ऐक्य
										साधण्यासाठी, तसेच समाजातील समितीस योग्य
										वाटतील अशा गरजू व्यक्तींच्या शैक्षणिक,
										सामाजिक, वैद्यकीय, सांस्कृतिक तसेच
										सर्वांगीण विकासासाठी.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"> 4) शैक्षणिक </td>
									<td className="px-4 py-3">
										: वर निर्देशित केलेल्या गरजू व्यक्तींना
										पूर्व प्राथमिक ते उच्च शिक्षणासाठी योग्य
										आर्थिक कवा इतर (कॅश ॲन्ड काइंड) स्वरूपात
										मदत करणे.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"> 5) सामाजिक </td>
									<td className="px-4 py-3">
										: वर निर्देशित केलेल्या व समितीस योग्य
										वाटतील अशा समाजातील अनाथ, अपंग, निराधार
										वा आर्थिकदृष्ट्‌या कमकुवत व्यक्तींसाठी
										रोजगार उपलब्ध होईल कवा त्यांना
										पुनर्वसनाच्या दृष्टींनी योग्य ती मदत
										करणे, नैसर्गिक आपत्तीत (भूकंप, पूर
										इत्यादी) सापडलेल्या व्यक्तींना मदत करणे.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"> 6)वैद्यकीय </td>
									<td className="px-4 py-3">
										:वर निर्देशित केलेल्या व्यक्तींना
										गरजेनुसार वैद्यकीय तपासणी, औषधोपचार कवा
										शल्यचिकित्सेसाठी (ऑपरेशनसाठी) आर्थिक कवा
										अन्य (रक्त देणे, शुश्रुषेसाठी नर्स
										पुरविणे वगैरे) स्वरूपात मदत करणे.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3">7)सांस्कृतिक </td>
									<td className="px-4 py-3">
										:राष्ट्रीय भावनेतून विविध ठिकाणी संमेलने
										भरविणे, मेळावे घेणे, व्याख्याने आयोजित
										करणे, सांस्कृतिक कार्यक्रम आयोजित करणे.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"> 8) क्रीडा </td>
									<td className="px-4 py-3">
										:जोगळेकर कुटुंबातील मुला - मुलींना
										क्रीडा क्षेत्रात पुढे येण्यास प्रोत्साहन
										देणे, मार्गदर्शन करणे व योग्य ती मदत
										करणे.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3"> 9) कर्तव्य </td>
									<td className="px-4 py-3">
										:अत्री गोत्रीय चित्तपावन व अन्य जोगळेकर
										कुटुंबातील व्यक्तींचा कुलवृत्तान्त लिहून
										प्रसिद्ध करणे.
									</td>
								</tr>
								<tr>
									<td className="px-4 py-3">10) घोषणा</td>
									<td className="px-4 py-3">
										:जोगळेकर कुलवृत्तान्त समितीचा कोणत्याही
										राजकीय पक्षाशी कवा चळवळीशी प्रत्यक्ष
										अथवा अप्रत्यक्ष संबंध राहणार नाही.
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR text-center">
						<p className="mb-2">-परिशिष्ट “क”-</p>
						<p>- या संस्थेचे घटना, नियम व नियमावली</p>
						<div className="flex items-center justify-center mt-6">
							<table className="table-auto w-[1000px] text-justify">
								<tbody>
									<tr>
										<td className="px-4 py-3">
											{" "}
											1) संस्थेचे नाव{" "}
										</td>
										<td className="px-4 py-3">
											:जोगळेकर कुलवृत्तान्त समिती
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											संस्थेचे <br />
											कार्यालयाचा पत्ता
										</td>
										<td className="px-4 py-3">
											: द्वारा श्री.विजय केशव जोगळेकर
											1210/क, शिवाजीनगर, ऑफ आपटे रोड, पुणे
											- 411 004. फोन नं : 25532164
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											{" "}
											2) कार्यक्षेत्र{" "}
										</td>
										<td className="px-4 py-3">
											: संपूर्ण भारतात व परदेशांत
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											{" "}
											3) हिशोबाचे वर्ष{" "}
										</td>
										<td className="px-4 py-3">
											: 1 एप्रिल ते 31 मार्च (12महिने)
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											{" "}
											4)सभासद व त्यांची नोंदण्याची पद्धत{" "}
										</td>
										<td className="px-4 py-3">
											: या संस्थेचे सभासद अत्री गोत्रीय
											जोगळेकर (व अन्य जोगळेकर) आडनाव धारण
											करणाऱ्या कोणत्याही सज्ञान स्त्री,
											पुरुष व्यक्तीस होता येईल. जोगळेकर
											आडनाव धारण करणाऱ्या अविवाहित
											स्त्रीचे (माहेरवाशीण) लग्न
											झाल्यावरसुद्धा तिचे तहहयात सभासदत्व
											रद्द होणार नाही. सध्या जोगळेकरांच्या
											माहेरवाशीण स्त्रीस सभासद व्हावयाचे
											असल्यास तिला तहहयात सभासदच होता
											येईल. संस्थेकडे लेखी अर्ज केल्यावरच
											व तो मंजूर झाल्यावरच योग्य ती सभासद
											वर्गणी भरून संस्थेचा सभासद होता
											येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											{" "}
											5) सभासदाचे प्रकार{" "}
										</td>
										<td className="px-4 py-3">
											: अ : कोणत्याही जोगळेकर व्यक्तीस
											प्रतिवर्षी रु.100/- भरून सर्वसाधारण
											सभासद होता येईल. त्या व्यक्तीस एक
											वर्ष पूर्ण झाल्यानंतरच निवडणुकीच्या
											मतदानाचा अधिकार प्राप्त होईल.
											निवडणुकीच्या मतदानाचा अधिकार अबाधित
											राहण्यासाठी प्रतिवर्षी रु.100/-
											वर्गणी भरणे आवश्यक आहे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											आ : एकदाच रु.1000/- भरणाऱ्यास आजीव
											सभासद म्हणून मान्यता देण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											इ : भविष्यात अ व आ च्या वर्गणीत जरुर
											वाटल्यास बदल करण्याचे अधिकार
											कार्यकारी मंडळासच असतील
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											ई : मध्यवर्ती कार्यकारिणीच्या
											निवडणुकीचा अर्ज भरण्याचा अधिकार हा
											फक्त तहहयात सभासद झाल्यानंतर 1
											वर्षाने प्राप्त होईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 ">
											{" "}
											6) सभासद रद्द करणे{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											: सभासद झालेल्या व्यक्तीने
											संस्थेच्या हिताविरुद्ध आणि संस्थेची
											बदनामी होईल असे वर्तन केल्यास अगर
											विहीत वर्गणी नाकारल्यास, मयत
											झाल्यास, राजीनामा दिल्यास, अधिसूचना,
											दत्तविधान अगर आडनावात बदल झाल्यास
											यापैकी कोणत्याही एका कारणास्तव
											सभासदत्व रद्द करण्याचा अधिकार
											कार्यकारी मंडळास राहील. रद्द
											झालेल्या सभासदाने पुन्हा रीतसर अर्ज
											करून हमीपत्र दिल्यास पुन्हा सभासद
											करून घेणे हे कलम 4 मधील शर्तीवर
											अवलंबून आहे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											7) सर्वसाधारण सभा व तिचे अधिकार आणि
											कार्य{" "}
										</td>
										<td className="px-4 py-3 ">
											{" "}
											: अ. संस्थेची वार्षिक सभा - वर्ष
											संपल्यानंतर पाच महिन्यांच्या आत पुणे
											येथे वर्षातून एकदा घेण्यात येईल.
											सदरचे सभेचे 21 दिवस अगोदर सर्व
											सभासदांना अध्यक्षांचे परवानगीने लेखी
											सूचना पाठवून माहिती देण्यात येईल.
											सभेची सूचना सर्व सभासदांना प्रत्यक्ष
											अथवा टपालाने पाठविण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 "></td>
										<td className="px-4 py-3">
											{" "}
											ब. सर्वसाधारण सभेत मागील वर्षात
											कार्यकारी मंडळाने जे ठराव मंजूर केले
											असतील त्यास मान्यता देणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3 ">
											{" "}
											क. कार्यकारी मंडळाने केलेल्या खर्चास
											मान्यता देणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3 ">
											{" "}
											ड. वार्षिक अंदाजपत्रक मंजूर करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											इ. हिशोब तपासणीसाठी सनदी लेखापालाची
											नेमणूक करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											प. वार्षिक जमाखर्च, ताळेबंदाबाबत
											चर्चा करून त्यास मान्यता देणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 "></td>
										<td className="px-4 py-3">
											{" "}
											भ. आयत्या वेळचे विषय चर्चेस घेणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 ">
											{" "}
											8) सर्वसाधारण सभेची सूचना व गणसंख्या{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											: सर्वसाधारण सभेची सूचना संस्थेचे
											कार्यवाह, अध्यक्षांच्या परवानगीने
											सर्व सभासदांना सभेचे आधी 21 दिवस
											अगोदर प्रत्यक्ष अथवा टपालाने कळवतील.
											सूचनापत्रात सभेची तारीख वेळ व ठिकाण
											कळविणे जरूरीचे आहे. सर्वसाधारण सभेस
											एकुण सभासद संख्येच्या 2/5 गणसंख्या
											असल्यास सभेचे काम सुरू होईल. जर तशी
											गणसंख्या झाली नाही. तर अर्धा तास
											थांबून सदरची सर्वसाधारण सभा त्याच
											ठिकाणी त्याच दिवशी घेण्यात येईल. अशा
											सभेला गणसंखेचेे बंधन राहणार नाही.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 ">
											{" "}
											9)विशेष सर्वसाधारण सभा{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											: काही महत्त्वाचे निर्णय घेणे
											असल्यास कमीत कमी 50 सभासदांनी विशेष
											सर्वसाधारण सभा बोलविणेविषयी
											अध्यक्षांना लेखी कळविले असेल तर ती
											सभा संस्थेचे कार्यवाह अध्यक्षांच्या
											परवानगीने 15 दिवस अगोदर लेखी सूचना
											देऊन बोलावतील. सदरच्या सभेची
											गणसंख्या एकूण सभासद संख्येच्या 2/5
											असली पाहिजे, जर तशी गणसंख्या झाली
											नाही, तर सदरची सभा त्याच दिवशी
											अर्ध्या तासाने त्याच ठिकाणी घेण्यात
											येईल. अशा सभेला गणसंख्ोचे बंधन
											राहणार नाही.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 ">
											{" "}
											10)कार्यकारी मंडळ, पदाधिकारी <br />व
											कार्यकारी मंडळाची रचना{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											: कार्यकारी मंडळात, कमीत कमी 7 व
											जास्तीत जास्त 15 व्यक्ती राहतील.
											त्याची रचना पुढीलप्रमाणे - अध्यक्ष 1
											उपाध्यक्ष 1 कार्यवाह 1 कोषाध्यक्ष 1
											सह कार्यवाह 1 सदस्य संख्या 2 ते 10
											सभासद कसे असावेत व त्यांचे
											कार्यक्षेत्र : सभासद संख्या
											कार्यक्षेत्र 5 बृहन्‌मुंबई, मुंबई
											उपनगरे, ठाणे व रायगड जिल्हा, नवी
											मुंबई. 1 सर्व विदर्भातील जिल्हे व
											मध्य प्रदेश प्रांत 1 रत्नागिरी व
											सधुदुर्ग जिल्हे. 1 नाशिक, धुळे,
											जळगाव जिल्हे. 5 पुणे शहर, पुणे
											उपनगरे, पपरी चचवड, उर्वरित
											महाराष्ट्र व उर्वरित भारत व
											परदेशातील. 2 स्वीकृत 15 एकूण
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 ">
											11)कार्यकारी मंडळाचा कार्यकाळ व
											निवडणुकीची पद्धत{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											: संस्था नोंदणी झाल्यापासून पहिले
											कार्यकारी मंडळ दोन वर्षे कार्यान्वित
											राहील. त्यानंतर दर पाच वर्षानी
											कार्यकारी मंडळाची नवीन निवड प्रत्येक
											विभागाला दिलेल्या प्रतिनिधी -
											तत्त्वाप्रमाणे निवडणूक मध्यवर्ती
											कार्यालयात गुप्त मतदान पद्धतीने
											होईल. कार्यक्षेत्राला सभासदांची जी
											संख्या दिलेली आहे, त्यापेक्षा त्या
											कार्यक्षेत्रातून जास्त उमेदवारांचे
											अर्ज आल्यासच निवडणूक होईल. मध्यवर्ती
											कार्यकारी मंडळाचा कार्यकाळ
											संपण्याच्या अगोदर 3 महिने निवडणूक
											प्रक्रिया सुरु करण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 ">
											12)कार्यकारी मंडळाची पदाधिकारी व
											त्यांची कामे{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											अध्यक्ष : मंडळाच्या सर्व सभांचे
											अध्यक्षस्थान भूषविणे, मंडळाचे
											कारभारावर देखरेख ठेवणे, समान मतदान
											झाल्यास एक जादा मत देण्याचा अधिकार
											अध्यक्षांना राहील. कार्यवाह यांनी
											सभा न बोलविल्यास अध्यक्ष आपल्या
											अधिकारात सर्व सभा बोलवतील.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 "></td>
										<td className="px-4 py-3 ">
											उपाध्यक्ष : अध्यक्षांच्या गैरहजेरीत
											अध्यक्षांची कामे पाहणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3 "></td>
										<td className="px-4 py-3">
											उपाध्यक्ष : अध्यक्षांच्या गैरहजेरीत
											अध्यक्षांची कामे पाहणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											कार्यवाह : मंडळाचा सर्व पत्रव्यवहार
											पाहाणे, मंडळाच्या सर्व आर्थिक बाबीवर
											लक्ष ठेवणे, मंडळाच्या सर्व सभा
											बोलविणे, त्या सभांचा वृत्तांत ठेवणे,
											दैनंदिन कारभार पाहाणे,
											कोर्टकचेऱ्याची सर्व कामे पाहणे, नोकर
											वर्गाची नियुक्ती करणे आणि मंडळाचे
											जमाखर्च ठेवणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											सहकार्यवाह : वेळोवेळी कार्यवाह यांस
											मदत करणे. कार्यवाह यांच्या गैरहजेरीत
											समितीचा कारभार सांभाळणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											कोषाध्यक्ष : मंडळाचा सर्व आर्थिक
											व्यवहार पाहाणे, रोखीच्या पावत्या
											देणे, रोजच्या रोज देणगी, वर्गणीच्या
											रकमा स्वीकारून जमा होणाऱ्या रकमा
											बँकेत संस्थेच्या खात्यात जमा करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											12अ)राजीनामा द्यावयाचा असल्यास{" "}
										</td>
										<td className="px-4 py-3">
											: वरील पदाधिकाऱ्यापैकी अध्यक्षांना
											राजीनामा देण्याचा असल्यास त्यांनी तो
											उपाध्यक्षांकडे द्यावा व अन्य
											पदाधिकाऱ्यांना राजीनामा देण्याचा
											असल्यास तो त्यांनी अध्यक्षांकडे
											द्यावा. कार्यकारी मंडळाने अशा
											राजीनाम्यासंबंधी निर्णय द्यावा.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											13)कार्यकारी मंडळाची सभा व मागणी सभा{" "}
										</td>
										<td className="px-4 py-3">
											: कार्यकारी मंडळाची सभा दर तीन
											महिन्यांनी घेण्यात येईल. सभेची सूचना
											किमान 7 दिवस अगोदर देण्यात येईल.
											कार्यकारी मंडळातील कमीत कमी 7
											सभासदांनी लेखी सभेची मागणी
											अध्यक्षांकडे केल्यास तशी सभा
											बोलविण्याचा अधिकार कार्यवाह यांना
											आहे व अशी सभा कार्यवाह यांनी बोलावली
											पाहिजे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											14)कार्यकारी मंडळाच्या सभेची सूचना व
											गणसंख्या{" "}
										</td>
										<td className="px-4 py-3">
											: कार्यकारी मंडळाच्या सभेची लेखी
											सूचना संस्थेचे कार्यवाह किमान 7 दिवस
											अगोदर अध्यक्षांच्या परवानगीने
											कळवतील. सदर सभेस किमान 2/5 इतकी
											गणसंख्या असणे आवश्यक आहे. जर तशी
											गणसंख्या झाली नाही, तर अर्धा तास
											थांबून सदरची सभा त्याच दिवशी अर्ध्या
											तासाने त्याच ठिकाणी घेण्यात येईल.
											अशा सभेला गणसंख्ोचे बंधन राहणार
											नाही.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											15)कार्यकारी मंडळाचे निवडणुकीचे नियम{" "}
										</td>
										<td className="px-4 py-3">
											: संस्थेचे पहिले कार्यकारी मंडळ
											संस्था नोंदणी झाल्यापासून दोन वर्षे
											कार्यान्वित राहील. त्यानंतर दर पाच
											वर्षानी कार्यकारी मंडळाची नव्याने
											निवड त्या त्या शाखेला दिलेल्या
											प्रतिनिधी - तत्त्वाप्रमाणे केली
											जाईल. कार्यकारी सदस्य आपल्यामधून
											पदाधिकारी निवडतील.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											16)कार्यकारी मंडळातील रिक्तपद
											भरण्याबाबत{" "}
										</td>
										<td className="px-4 py-3">
											: कार्यकारी मंडळातील एखादी जागा काही
											कारणास्तव रिक्त झाली तर ती रिक्त
											जागा त्या विभागातील सभासदास स्वीकृत
											सभासद म्हणून घेतले जाईल व तो
											भरण्याचा अधिकार मध्यवर्ती कार्यकारी
											मंडळास राहील. अशा समाविष्ठ सभासदांचा
											कालावधी संबंधित कार्यकारिणी
											पर्यन्तचा असेल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											17)कार्यकारी मंडळाचे अधिकार व
											कर्तव्ये{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											1. जोगळेकर कुलवृत्तान्त (पुस्तक)
											प्रसिद्ध करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											2. जोगळेकर कुलवृत्तान्ताची
											(पुस्तकाची) आवश्यकतेनुसार पुरवणी
											काढणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"></td>
										<td className="px-4 py-3">
											{" "}
											3. संस्थेची मालमत्ता व कागदपत्रे
											सुरक्षित ठेवणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											4. सर्वसाधारण सभा बोलविणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											{" "}
											5. जमा - खर्चाचा हिशोब ठेवणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											6. संस्थेचे वार्षिक अंदाजपत्रक तयार
											करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											{" "}
											7. वेळ पडल्यास समित्या व उपसमित्या
											स्थापन करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											8. नोकरवर्गाची नियुक्ती करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											{" "}
											9. संस्थेच्या सर्व शाखांना
											मार्गदर्शन करणे. निरनिराळ्या ठिकाणी
											शाखा स्थापन करणे. त्यांचे अंतर्गत
											नियम व पोट नियम तयार करणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											{" "}
											10. सभासद बोलविणे व संस्था नोंदणी
											अधिनियम 1860 चे कलम 16 अन्वये सभासद
											नोंदवही ठेवणे.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											{" "}
											11. कार्यकारीमंडळाच्या सभेत
											आयत्यावेळी निघणाऱ्या विषयांवर चर्चा
											करून होणारे ठराव बहुमताने संमत करणे.
										</td>
									</tr>

									<tr>
										<td className="px-4 py-3">
											17अ)कार्यकारी मंडळ कवा{" "}
										</td>
										<td className="px-4 py-3">
											:अन्य कोणतेही सभासद यांच्याबद्दल
											काही तक्रार करावयाची असल्यास ती
											कार्यकारी मंडळाकडे लेखी स्वरुपात
											करावी. तक्रारीसंबंधी आवश्यक ती चौकशी
											करुन कार्यकारी मंडळ त्यासंबंधीचा
											निर्णय कळवेल. नंतरच धर्मादाय
											आयुक्तांकडे तक्रार करता येईल.{" "}
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											{" "}
											18) संस्थेचा निधी{" "}
										</td>
										<td className="px-4 py-3">
											{" "}
											अ) जमा : संस्थेत/समितीत देणगी,
											वर्गणी विंवा अनुदान रुपाने जमा
											होणाऱ्या रकमा व जंगम मिळकती या
											संस्थेच्या नावाने जमा होतील.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											ब) विनियोग : या धर्मादाय संस्थेचा
											सर्व निधी व मालमत्ता ही संस्थेच्या
											उद्देश्यपूर्तीसाठी वापरण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3"> </td>
										<td className="px-4 py-3">
											क) गुंतवणुक : आयकर कायद्यातील कलम 11
											(5) मधील तरतुदींना अनुसरुन
											न्यासाच्या निधीची गुंतवणुक करता
											येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											19)उद्दिष्टनिहाय खर्चाची तरतूद
											(टक्केवारी प्रमाणे)
										</td>
										<td className="px-4 py-3 ">
											: संस्थेकडे जमा होणाऱ्या रकमेपैकी
											35% रक्कम शैक्षणिक मदतीसाठी व 35%
											सांस्कृतिक व सामाजिक कार्यासाठी खर्च
											केला जाईल. 10% कॉर्पस कडे जमा करणे
											आणि 20% किरकोळ खर्चासाठी राहील.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											20) कर्ज कवा ठेवी या संबंधी तरतूद
										</td>
										<td className="px-4 py-3">
											: संस्थेस लागणारे कर्ज घेण्यासाठी
											ठेवी घेणे व ठेवीत संस्थेची रक्कम
											गुंतविणे झाल्यास सदरचे व्यवहार मुंबई
											सार्वजनिक विश्वस्त व्यवस्था
											अधिनियमातील कलम 35 व 36 च्या
											तरतुदीप्रमाणे करण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											21) स्थावर मालमत्ता खरेदी विक्री
											करणे
										</td>
										<td className="px-4 py-3">
											: संस्थेच्या उद्देशपूर्तीसाठी जर
											स्थावर मालमत्ता खरेदी करावयाची असेल
											तर मुंबई सार्वजनीक विश्वस्त व्यवस्था
											अधिनियम 1950 चे कलम 35 अन्वये व
											विक्री करावयाची असेल तर कलम 36
											अन्वये करण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											22) बँक खाते
										</td>
										<td className="px-4 py-3">
											: संस्थेकडे जमा होणाऱ्या सर्व रकमा
											कार्यकारी मंडळ ठरवील त्या बँकेत
											“जोगळेकर कुलवृत्तान्त समिती” या
											नावाच्या बचत खात्यात जमा करणे व अशा
											रकमांच्या गुंतवणुकीचा निर्णय
											कार्यकारी मंडळ वेळोवेळी घेईल. सदर
											खात्याचा व्यवहार समितीचे अध्यक्ष कवा
											उपाध्यक्ष यांच्यापैकी एक व कार्यवाह
											कवा कोषाध्यक्ष यांच्यापैकी एक असे
											दोघांच्या संयुक्त सहीने करण्यात
											येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											23) सभासदांची यादी
										</td>
										<td className="px-4 py-3">
											: सभासद यादी 1860 च्या संस्था नोंदणी
											अधिनियमांतील कलम 15 प्रमाणे जे
											संस्थेचे सभासद असतील त्यांची एक यादी
											1971 च्या संस्था नोंदणी (महाराष्ट्र)
											नियमातील नियम 15 अन्वये अनुसूची 6
											च्या नियमात ठेवण्यात येईल.
										</td>
									</tr>
									<tr>
										<td className="px-4 py-3">
											24)नियम व <br /> नियमावलीत <br />
											बदल करण्याची तरतूद
										</td>
										<td className="px-4 py-3">
											: संस्थेच्या नियम व नियमावलीत बदल
											करावयाचा असल्यास तो बदल सर्वसाधारण
											सभेत हजर सभासदांच्या 3/5 बहुमताने
											मंजूर केला जाईल.
										</td>
									</tr>
									<tr>
										<td className="px-5 py-3">
											25) संस्थेच्या नावात व <br />
											उद्देशात बदल करावयाची तरतूद
										</td>
										<td className="px-4 py-3">
											: संस्थेच्या नावात अगर उद्देशात बदल
											करावयाचा असल्यास तो बदल संस्था
											नोंदणी अधिनियम 1860 च्या कलम 12 व 12
											अ प्रमाणे करण्यात येईल.
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
				<hr/>
			</div>
		</div>
	);
};
