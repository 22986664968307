import React, {useEffect, useState} from 'react';
import {AccountContext} from '../../context/AccountProvider';
import {translateTextToDevnagri} from '../../service/translateApi';
import {t} from "i18next";
import {Modal} from "../../components/Modal";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Transition} from "@headlessui/react";
import {addAccountAndNode} from "../../service/accountApi";
import {countryCodes} from "../../data/CountryCode";

export const AddNodeForm = (props) => {
	const {
		showAddNodeForm,
		setShowAddNodeForm,
		restoreFlowFromDB
	} = props;

	const pfpBgColors = ["bg-[#0F1F2E]", "bg-[#FF8C42]", "bg-[#FF3C38]", "bg-[#A23E48]"];
	const {account} = React.useContext(AccountContext);
	const [error, setError] = useState({});

	const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
	const [isMaritalStatusDropdownOpen, setIsMaritalStatusDropdownOpen] = useState(false);
	const [isSideOfFamilyDropdownOpen, setIsSideOfFamilyDropdownOpen] = useState(false);
	const [isCountryCodeDropdownOpen, setIsCountryCodeDropdownOpen] = useState(false);

	const [isAddMemberButtonDisabled, setIsAddMemberButtonDisabled] = useState(false);

	const [member, setMember] = useState({
		firstName: '',
		middleName: '',

		firstNameDevanagariScript: '',
		middleNameDevanagariScript: '',

		dateOfBirth: '',
		gender: 'Male',

		maritalStatus: 'Single',
		dateOfMarriage: '',
		sideOfFamily: 'Mahervashin',

		firstNameAfterMarriage: '',
		spouseNameAfterMarriage: '',
		lastNameAfterMarriage: '',

		firstNameBeforeMarriage: '',
		fathersNameBeforeMarriage: '',
		lastNameBeforeMarriage: '',

		deceased: false,
		dateOfDeath: '',

		email: '',
		phone: {
			charCode: 'IN',
			dialingCode: '+91',
			countryName: 'India',
			number: ''
		},

		imageUrl: '',
		imageUrlBg: pfpBgColors[Math.floor(Math.random() * pfpBgColors.length)],
	});

	const [inviteUser, setInviteUser] = useState(false);

	const [needAssistance, setNeedAssistance] = useState(false);
	const [needAssistanceDescription, setNeedAssistanceDescription] = useState('');

	const translateToDevnagri = (e) => {
		if (e.target.name === 'firstName' || e.target.name === 'middleName') {
			if (e.target.value) {
				translateTextToDevnagri(e.target.value).then((res) => {
					console.log("(CreateProfile.jsx) Translation Response: ", res);
					setMember({
						...member,
						[e.target.name + 'DevanagariScript']: res.result
					});
					setError({
						...error,
						[e.target.name + 'DevanagariScript']: ""
					});
				});
			}
		}
	}

	const handleGenderSelect = (gender) => {
		setIsGenderDropdownOpen(false);
		setMember({
			...member,
			gender: gender
		});
	}

	const maritalStatusLabel = (maritalStatus) => {
		switch (maritalStatus) {
			case 'Single':
				return t("PROFILE_FORM.MARITAL_STATUS.SINGLE");
			case 'Married':
				return t("PROFILE_FORM.MARITAL_STATUS.MARRIED");
			case 'Widowed':
				return t("PROFILE_FORM.MARITAL_STATUS.WIDOWED");
			case 'Divorced':
				return t("PROFILE_FORM.MARITAL_STATUS.DIVORCED");
			case 'Minor':
				return t("PROFILE_FORM.MARITAL_STATUS.MINOR");
			default:
				return t("PROFILE_FORM.MARITAL_STATUS.SINGLE");
		}
	}

	const handleMaritalStatusSelect = (maritalStatus) => {
		setIsMaritalStatusDropdownOpen(false);
		setMember({
			...member,
			maritalStatus: maritalStatus
		});
	}

	const handleSideOfFamilySelect = (sideOfFamily) => {
		setIsSideOfFamilyDropdownOpen(false);
		setMember({
			...member,
			sideOfFamily: sideOfFamily
		});
	}

	function checkAge(dateOfBirth) {
		// Convert the date of birth to a Date object
		const birthDate = new Date(dateOfBirth);

		// Calculate the current age in years
		const today = new Date();
		const age = today.getFullYear() - birthDate.getFullYear();

		// Check if the age is less than 13 or greater than 70
		return (age < 13 || age > 70);
	}

	const validateEmail = (email) => {
		return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
	}

	const validateEmailId = (e) => {
		if (!validateEmail(e.target.value)) {
			setError({
				...error,
				[e.target.name]: t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.INVALID_ERROR")
			});
		} else {
			setError({
				...error,
				[e.target.name]: ""
			})
		}
	}

	const isNumberValid = (phone) => {
		let reg = /^\d+$/;
		return reg.test(phone);
	}

	const validatePhoneNumber = (e) => {
		if (inviteUser && !checkAge(member.dateOfBirth)) {
			if ((!isNumberValid(e.target.value)) || e.target.value.length !== 10) {
				setError({
					...error,
					[e.target.name]: t("ADD_NODE_FORM.SEND_REQUEST.PHONE_NUMBER.INVALID_ERROR")
				});
			}
		}
	}

	useEffect(() => {
		if (!inviteUser) {
			setMember({
				...member,
				phone: {
					charCode: 'IN',
					dialingCode: '+91',
					countryName: 'India',
					number: ''
				},
				email: ""
			});

			setError({
				...error,
				phone: "",
				email: ""
			})
		}
	}, [inviteUser]);

	const handleFormData = (e) => {
		console.log(e.target.name + ': ' + e.target.value);

		setMember({
			...member,
			[e.target.name]: e.target.value
		});

		setError({
			...error,
			[e.target.name]: ""
		});
	}

	const validate = () => {
		let err = {};

		if (member.firstName === '') {
			err['firstName'] = t("ADD_NODE_FORM.NAME.ENGLISH.FIRST_NAME_ERROR");
		}

		if (member.middleName === '') {
			err['middleName'] = t("ADD_NODE_FORM.NAME.ENGLISH.MIDDLE_NAME_ERROR");
		}

		if (member.firstNameDevanagariScript === '') {
			err['firstNameDevanagariScript'] = t("ADD_NODE_FORM.NAME.DEVANAGRI.FIRST_NAME_ERROR");
		}

		if (member.middleNameDevanagariScript === '') {
			err['middleNameDevanagariScript'] = t("ADD_NODE_FORM.NAME.DEVANAGRI.MIDDLE_NAME_ERROR");
		}

		if (member.dateOfBirth === '') {
			err['dateOfBirth'] = t("ADD_NODE_FORM.DOB.ERROR");
		}

		if (member.deceased) {
			if (member.dateOfDeath === '') {
				err['dateOfDeath'] = t("ADD_NODE_FORM.DOD.ERROR");
			}
		}

		if (inviteUser && !checkAge(member.dateOfBirth)) {
			if (member.phone.number === '') {
				err['phone'] = t("ADD_NODE_FORM.SEND_REQUEST.PHONE_NUMBER.REQUIRED_ERROR");
			}

			if (member.email === '') {
				err['email'] = t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.REQUIRED_ERROR");
			}
		}

		console.log("Member Details: ", member);
		return err;
	}

	const handleSubmit = async () => {
		setIsAddMemberButtonDisabled(true);
		const err = validate();

		if (Object.keys(err).length > 0) {
			setError(err);
			setIsAddMemberButtonDisabled(false);
			return;
		}

		member.imageUrl = member.firstName[0] + member.middleName[0];

		// if (inviteUser) {
		const newAccountAndNodeData = {
			creatorNodeId: account.nodeId,
			email: member.email,
			phone: member.phone,
			firstName: member.firstName,
			middleName: member.middleName,
			firstNameDevanagariScript: member.firstNameDevanagariScript,
			middleNameDevanagariScript: member.middleNameDevanagariScript,
			gender: member.gender,
			deceased: member.deceased,
			dateOfBirth: member.dateOfBirth,
			dateOfDeath: member.dateOfDeath,
			familyTreeName: account.familyTreeName,
			familyTreeId: account.familyTreeId,
			familyTreeAdminId: account.familyTreeAdminId,
			imageUrl: member.imageUrl,
			imageUrlBg: member.imageUrlBg,
			inviteUser: inviteUser,
			relativeName: account.firstName + " " + account.middleName,
			needAssistance: needAssistance,
			needAssistanceDescription: needAssistanceDescription
		};

		const addAccountAndNodeResponse = await addAccountAndNode(newAccountAndNodeData);

		console.log('(AddNodeForm) Add Invite Account Response: ', addAccountAndNodeResponse);

		if (addAccountAndNodeResponse.success) {
			await restoreFlowFromDB();
			setShowAddNodeForm(false);
			setIsAddMemberButtonDisabled(false);
			setMember({
				deceased: false,

				employmentTitle: '',
				businessOrCompanyName: '',

				firstNameDevanagariScript: '',
				middleNameDevanagariScript: '',

				startDate: '',
				employmentType: 'Male',
				endDate: '',

				email: '',
				phone: {
					charCode: 'IN',
					dialingCode: '+91',
					countryName: 'India',
					number: ''
				},

				imageUrl: '',
				imageUrlBg: pfpBgColors[Math.floor(Math.random() * pfpBgColors.length)],
			});
		}
	}

	return (
		<Modal isOpen={showAddNodeForm}>
			<div
				className="fixed flex flex-col justify-between max-h-[80vh] rounded-xl px-[1.5vw] py-[2vh] bg-white min-w-xl">
				{/* Header */}
				<div className={"max-h-[10vh]"}>
					<div className={"flex flex-row items-center justify-between"}>
						{/* Form Title */}
						<h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
							{t("ADD_NODE_FORM.TITLE")}
						</h2>

						<button
							onClick={() => setShowAddNodeForm(false)}
							type="button"
							data-drawer-hide="drawer-form"
							aria-controls="drawer-form"
							className="flex items-center justify-center rounded-lg w-[2vw] h-[2vw]
                            bg-transparent hover:bg-gray-200
                            text-sm text-gray-400 hover:text-gray-900"
						>
							<img
								className={"w-[75%] h-[75%]"}
								src={"assets/close-icon.png"}
								alt={"Close Icon"}
							/>
						</button>
					</div>

					{/* Divider */}
					<div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
				</div>

				<div className={"flex-1 no-scrollbar overflow-y-scroll max-h-[60vh] py-[1.5vh]"}>
					{/* Name in English */}
					<fieldset>
						<div className="mb-[2vh] flex flex-col gap-2">
							<label
								className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
								htmlFor="firstName"
							>
								{t("ADD_NODE_FORM.NAME.ENGLISH.LABEL")}
							</label>
							<div className="flex justify-between gap-4">
								{/* First Name */}
								<div className="flex-1 flex flex-col">
									<input
										id="firstName"
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										name='firstName'
										placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.FIRST_NAME")}
										value={member.firstName}
										onChange={handleFormData}
										onBlur={translateToDevnagri}
									/>
									{
										error['firstName'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['firstName']}
										</p>
									}
								</div>

								{/* Father's Name */}
								<div className="flex-1 flex flex-col">
									<input
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										id="middleName"
										name='middleName'
										placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME")}
										value={member.middleName}
										onChange={handleFormData}
										onBlur={translateToDevnagri}
									/>
									{
										error['middleName'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['middleName']}
										</p>
									}
								</div>
							</div>
						</div>
					</fieldset>

					{/* Name in Devnagari) */}
					<fieldset>
						<div className="mb-[2vh] flex flex-col gap-2">
							<label
								className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
								htmlFor="firstNameDevanagariScript"
							>
								{t("ADD_NODE_FORM.NAME.DEVANAGRI.LABEL")}
							</label>
							<div className="flex justify-between gap-4">
								{/* First Name */}
								<div className="flex-1 flex flex-col">
									<input
										id="firstNameDevanagariScript"
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										name='firstNameDevanagariScript'
										placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.FIRST_NAME_DEVANAGRI")}
										value={member.firstNameDevanagariScript}
										onChange={handleFormData}
										onBlur={translateToDevnagri}
									/>
									{
										error['firstNameDevanagariScript'] &&
										<span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['firstNameDevanagariScript']}
                                        </span>
									}
								</div>

								{/* Father's Name */}
								<div className="flex-1 flex flex-col">
									<input
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										id="middleNameDevanagariScript"
										name='middleNameDevanagariScript'
										placeholder={t("ADD_NODE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME_DEVANAGRI")}
										value={member.middleNameDevanagariScript}
										onChange={handleFormData}
										onBlur={translateToDevnagri}
									/>
									{
										error['middleNameDevanagariScript'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['middleNameDevanagariScript']}
										</p>
									}
								</div>
							</div>
						</div>
					</fieldset>

					{/* Deceased */}
					<fieldset>
						<div className={"mb-[2vh] flex flex-col gap-2"}>
							{/*<label*/}
							{/*    className="cursor-pointer min-h-[5vh] max-h-[5vh] w-fit flex flex-row items-center gap-2*/}
							{/*    rounded-md px-[1vw] py-[1vh] border-2 border-gray-300">*/}
							<label className="w-fit flex flex-row items-center gap-2">
								<input
									type="checkbox"
									name='deceased'
									checked={member.deceased}
									onChange={() => {
										setMember({
											...member,
											deceased: !member.deceased
										});
									}}
								/>
								<p className="font-SOHNE_REGULAR text-[1vw]">
									{t("ADD_NODE_FORM.DECEASED")}
								</p>
								<img
									className={"w-[1.5vw] aspect-auto"}
									src={"assets/deceased-icon.png"}
									alt={"Male"}
								/>
							</label>
						</div>
					</fieldset>

					{/* Gender | D.O.B | D.O.D */}
					<fieldset>
						<div className="mb-[2vh] flex flex-row justify-between gap-4">
							{/* Date of Birth */}
							<div className="flex flex-col gap-2">
								<label
									className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
									htmlFor='dateOfBirth'
								>
									{t("ADD_NODE_FORM.DOB.LABEL")}
								</label>
								<div className="w-full">
									<input
										type="date"
										name='dateOfBirth'
										required
										value={member.dateOfBirth}
										onChange={handleFormData}
										className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md text-[1vw]
                                            px-[0.5vw]
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]`}
									/>
									{
										error['dateOfBirth'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['dateOfBirth']}
										</p>
									}
								</div>
							</div>

							{/* Gender */}
							<div className="flex-1 flex flex-col gap-2">
								<label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="gender">
									{t("ADD_NODE_FORM.GENDER.LABEL")}
								</label>

								<div className="w-full flex flex-col">
									<button
										onBlur={() => setIsGenderDropdownOpen(false)}
										className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
										onClick={() => setIsGenderDropdownOpen(!isGenderDropdownOpen)}
									>
										<div className={"flex-1 flex flex-row items-center gap-2"}>
											<p className={"font-SOHNE_REGULAR text-[1vw]"}>
												{
													member.gender === "Male"
														? t("ADD_NODE_FORM.GENDER.MALE")
														: t("ADD_NODE_FORM.GENDER.FEMALE")
												}
											</p>
											<img
												className={"w-[1.5vw] aspect-auto"}
												src={member.gender === "Male" ? "assets/male-gender-icon.png" : "assets/female-gender-icon.png"}
												alt={member.gender === "Male" ? "Male" : "Female"}
											/>
										</div>
										<ChevronDownIcon
											className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isGenderDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
											aria-hidden="true"
											stroke="#292D32"
											strokeWidth={"0.1vh"}
										/>
									</button>

									{/* Gender Dropdown */}
									<div className={"relative"}>
										<Transition show={isGenderDropdownOpen}>
											<div
												className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
											>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
													onClick={() => handleGenderSelect("Male")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("ADD_NODE_FORM.GENDER.MALE")}
													</p>
													<img
														className={"w-[1.5vw] aspect-auto"}
														src={"assets/male-gender-icon.png"}
														alt={"Male"}
													/>
												</button>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center gap-2"
													onClick={() => handleGenderSelect("Female")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("ADD_NODE_FORM.GENDER.FEMALE")}
													</p>
													<img
														className={"w-[1.5vw] aspect-auto"}
														src={"assets/female-gender-icon.png"}
														alt={"Female"}
													/>
												</button>
											</div>
										</Transition>
									</div>
								</div>
							</div>

							{/* Date of Death | Marital Status & Date of Marriage (Male) */}
							{
								member.deceased ? (
									<div className="flex flex-col gap-2">
										<label
											className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
											htmlFor='dateOfDeath'
										>
											{t("ADD_NODE_FORM.DOD.LABEL")}
										</label>
										<div className="w-full">
											<input
												type="date"
												name='dateOfDeath'
												required
												value={member.dateOfDeath}
												onChange={handleFormData}
												className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                    flex-1 items-center justify-center rounded-md px-[0.75vw] text-[1vw]
                                                    leading-none outline-none border-2 border-gray-300
                                                    focus:border-[#5F0F40]"
											/>
											{
												error['dateOfDeath'] &&
												<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
													{error['dateOfDeath']}
												</p>
											}
										</div>
									</div>
								) : (
									<>
										{/* Marital Status | Date of Marriage */}

										{/* Marital Status */}
										{
											member.gender === "Male" && (
												<>
													<div className={`w-fit flex-1 flex flex-col gap-2`}>
														<label
															className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
															htmlFor="maritalStatus"
														>
															<img
																src={"/assets/marriage-icon.png"}
																className={"w-[1.5vw] aspect-auto"}
																alt={"Marriage"}
																title={"Marriage"}
															/>
															{t("PROFILE_FORM.MARITAL_STATUS.LABEL")}
														</label>
														<div className="relative w-full flex flex-col">
															<button
																onBlur={() => setIsMaritalStatusDropdownOpen(false)}
																className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                                                    rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
																onClick={() => setIsMaritalStatusDropdownOpen(!isMaritalStatusDropdownOpen)}
															>
																<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																	{maritalStatusLabel(member.maritalStatus)}
																</p>
																<ChevronDownIcon
																	className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                                        ${isMaritalStatusDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
																	aria-hidden="true"
																	stroke="#292D32"
																	strokeWidth={"0.1vh"}
																/>
															</button>

															{/* Marital Status Dropdown */}
															<div className={"relative"}>
																<Transition show={isMaritalStatusDropdownOpen}>
																	<div
																		className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                            origin-top-right rounded-md shadow-2xl 
                                                            border border-gray-700 overflow-y-scroll
                                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                                            data-[leave]:duration-75 data-[leave]:ease-in`}
																	>
																		<button
																			className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
																			onClick={() => handleMaritalStatusSelect("Minor")}
																		>
																			<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																				{t("PROFILE_FORM.MARITAL_STATUS.MINOR")}
																			</p>
																		</button>
																		<button
																			className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
																			onClick={() => handleMaritalStatusSelect("Single")}
																		>
																			<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																				{t("PROFILE_FORM.MARITAL_STATUS.SINGLE")}
																			</p>
																		</button>
																		<button
																			className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
																			onClick={() => handleMaritalStatusSelect("Married")}
																		>
																			<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																				{t("PROFILE_FORM.MARITAL_STATUS.MARRIED")}
																			</p>
																		</button>
																		<button
																			className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
																			onClick={() => handleMaritalStatusSelect("Divorced")}
																		>
																			<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																				{
																					member.gender === "Male"
																						? t("PROFILE_FORM.MARITAL_STATUS.WIDOWER")
																						: t("PROFILE_FORM.MARITAL_STATUS.WIDOWED")
																				}
																			</p>
																		</button>
																		<button
																			className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
																			onClick={() => handleMaritalStatusSelect("Widowed")}
																		>
																			<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																				{t("PROFILE_FORM.MARITAL_STATUS.DIVORCED")}
																			</p>
																		</button>
																	</div>
																</Transition>
															</div>
															{
																error['maritalStatus'] &&
																<p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
																   id="error">
																	{error['maritalStatus']}
																</p>
															}
														</div>
													</div>

													{/* Date of Marriage */}
													{
														(member.maritalStatus === 'Married' || member.maritalStatus === 'Widowed' || member.maritalStatus === 'Divorced') && (
															<div className="flex flex-col gap-2">
																<label
																	className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
																	htmlFor='dateOfMarriage'
																>
																	{t("PROFILE_FORM.DOM.LABEL")}
																</label>
																<div className="relative w-full">
																	<input
																		type="date"
																		name='dateOfMarriage'
																		required
																		value={member.dateOfMarriage}
																		onChange={handleFormData}
																		className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                                    flex-1 items-center justify-center rounded-md px-[0.5vw] text-[1vw]
                                                                    leading-none outline-none border-2 border-gray-300
                                                                    focus:border-[#5F0F40]`}
																	/>
																	{
																		error['dateOfMarriage'] &&
																		<p
																			className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
																			id="error"
																		>
																			{error['dateOfMarriage']}
																		</p>
																	}
																</div>
															</div>
														)
													}
												</>
											)
										}
									</>
								)
							}
						</div>
					</fieldset>

					{/* Marital Status | Date of Marriage | Side Of Family (Female) */}
					{
						!member.deceased && member.gender === "Female" && (
							<fieldset>
								<div className="mb-[2vh] flex flex-row justify-between gap-4">
									{/* Marital Status */}
									<div className={`flex-1 flex flex-col gap-2`}>
										<label
											className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
											htmlFor="maritalStatus"
										>
											<img
												src={"/assets/marriage-icon.png"}
												className={"w-[1.5vw] aspect-auto"}
												alt={"Marriage"}
												title={"Marriage"}
											/>
											{t("PROFILE_FORM.MARITAL_STATUS.LABEL")}
										</label>
										<div className="relative w-full flex flex-col">
											<button
												onBlur={() => setIsMaritalStatusDropdownOpen(false)}
												className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                                            rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
												onClick={() => setIsMaritalStatusDropdownOpen(!isMaritalStatusDropdownOpen)}
											>
												<p className={"font-SOHNE_REGULAR text-[1vw]"}>
													{maritalStatusLabel(member.maritalStatus)}
												</p>
												<ChevronDownIcon
													className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                    ${isMaritalStatusDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
													aria-hidden="true"
													stroke="#292D32"
													strokeWidth={"0.1vh"}
												/>
											</button>

											{/* Marital Status Dropdown */}
											<div className={"relative"}>
												<Transition show={isMaritalStatusDropdownOpen}>
													<div
														className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                            origin-top-right rounded-md shadow-2xl 
                                                            border border-gray-700 overflow-y-scroll
                                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                                            data-[leave]:duration-75 data-[leave]:ease-in`}
													>
														<button
															className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
															onClick={() => handleMaritalStatusSelect("Minor")}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{t("PROFILE_FORM.MARITAL_STATUS.MINOR")}
															</p>
														</button>
														<button
															className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
															onClick={() => handleMaritalStatusSelect("Single")}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{t("PROFILE_FORM.MARITAL_STATUS.SINGLE")}
															</p>
														</button>
														<button
															className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
															onClick={() => handleMaritalStatusSelect("Married")}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{t("PROFILE_FORM.MARITAL_STATUS.MARRIED")}
															</p>
														</button>
														<button
															className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
															onClick={() => handleMaritalStatusSelect("Divorced")}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{
																	member.gender === "Male"
																		? t("PROFILE_FORM.MARITAL_STATUS.WIDOWER")
																		: t("PROFILE_FORM.MARITAL_STATUS.WIDOWED")
																}
															</p>
														</button>
														<button
															className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                            hover:bg-gray-300 flex items-center justify-between gap-1"
															onClick={() => handleMaritalStatusSelect("Widowed")}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{t("PROFILE_FORM.MARITAL_STATUS.DIVORCED")}
															</p>
														</button>
													</div>
												</Transition>
											</div>
											{
												error['maritalStatus'] &&
												<p className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
												   id="error">
													{error['maritalStatus']}
												</p>
											}
										</div>
									</div>

									{/* Date of Marriage | Side Of Family */}
									{
										(member.maritalStatus === 'Married' || member.maritalStatus === 'Widowed' || member.maritalStatus === 'Divorced')
										&& member.gender === "Female" && (
											<>
												{/* Date of Marriage */}
												<div className="flex flex-col gap-2">
													<label
														className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
														htmlFor='dateOfMarriage'
													>
														{t("PROFILE_FORM.DOM.LABEL")}
													</label>
													<div className="relative w-full">
														<input
															type="date"
															name='dateOfMarriage'
															required
															value={member.dateOfMarriage}
															onChange={handleFormData}
															className={`font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                flex-1 items-center justify-center rounded-md px-[0.5vw] text-[1vw]
                                                leading-none outline-none border-2 border-gray-300
                                                focus:border-[#5F0F40]`}
														/>
														{
															error['dateOfMarriage'] &&
															<p
																className="text-[0.65vw] mt-0.5 text-red-600 font-SOHNE_REGULAR"
																id="error"
															>
																{error['dateOfMarriage']}
															</p>
														}
													</div>
												</div>

												{/* Side Of Family */}
												<div className="flex-1 flex flex-col gap-2">
													<label
														className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
														htmlFor="maritalStatus"
													>
														{t("PROFILE_FORM.SIDE_OF_FAMILY.LABEL")}
													</label>
													<div className="relative w-full flex flex-col">
														<button
															disabled={member.gender === "Female" &&
																(member.maritalStatus === 'Single' || member.maritalStatus === 'Minor')}
															onBlur={() => setIsSideOfFamilyDropdownOpen(false)}
															className={`flex flex-row justify-between items-center 
                                                            min-h-[5vh] max-h-[5vh] px-[0.5vw] gap-1 
                                                            rounded-md border-2 border-gray-300`}
															onClick={() => setIsSideOfFamilyDropdownOpen(!isSideOfFamilyDropdownOpen)}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{
																	member.sideOfFamily !== "" &&
																	(member.sideOfFamily === "MaherVashin"
																		? t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")
																		: t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN"))
																}
															</p>
															<ChevronDownIcon
																className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                                ${isSideOfFamilyDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
																aria-hidden="true"
																stroke="#292D32"
																strokeWidth={"0.1vh"}
															/>
														</button>

														{/* Side Of Family Dropdown */}
														<div className={"relative"}>
															<Transition show={isSideOfFamilyDropdownOpen}>
																<div
																	className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                                origin-top-right rounded-md shadow-2xl 
                                                                border border-gray-700 overflow-y-scroll
                                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                                data-[leave]:duration-75 data-[leave]:ease-in`}
																>
																	<button
																		className={`w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                                    flex items-center justify-between gap-1 
                                                                    hover:bg-gray-300`}
																		onClick={() => handleSideOfFamilySelect("MaherVashin")}
																	>
																		<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																			{t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")}
																		</p>
																	</button>
																	<button
																		className={`w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                                    flex items-center justify-between gap-1 
                                                                    hover:bg-gray-300`}
																		onClick={() => handleSideOfFamilySelect("SasurVashin")}
																	>
																		<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																			{t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN")}
																		</p>
																	</button>
																</div>
															</Transition>
														</div>
													</div>
												</div>
											</>
										)
									}
								</div>
							</fieldset>
						)
					}

					{/* Send Request to Join */}
					{
						!member.deceased &&
						<fieldset>
							<label className="mb-[2vh] flex flex-row items-center gap-2">
								<input
									type="checkbox"
									name='isUser'
									checked={inviteUser}
									onChange={() => setInviteUser(!inviteUser)}
								/>
								<p className="font-SOHNE_REGULAR text-[1vw]">
									{t("ADD_NODE_FORM.SEND_REQUEST.LABEL")}
								</p>
							</label>
						</fieldset>
					}

					{/* Mobile Number | Email Input if Send Request option is checked */}
					{!member.deceased &&
						<fieldset>
							<div className={`mb-[2vh] flex flex-row gap-4`}>
								{/* Mobile Number Input */}
								<div className={"flex-1 flex flex-col gap-2"}>
									<label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
										   htmlFor="phone">
										{
											checkAge(member.dateOfBirth)
												? t("ADD_NODE_FORM.SEND_REQUEST.PHONE_NUMBER.LABEL")
												: t("ADD_NODE_FORM.SEND_REQUEST.PHONE_NUMBER.LABEL_REQUIRED")
										}
									</label>
									<div className="w-full flex flex-col">
										<div
											className="flex flex-row justify-between items-center
                                                min-h-[5vh] max-h-[5vh] rounded-md border-2 border-gray-300"
										>
											<button
												disabled={!inviteUser}
												onBlur={() => setIsCountryCodeDropdownOpen(false)}
												className="bg-gray-300 min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                rounded-tl-md rounded-bl-md flex items-center justify-between gap-1"
												onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
											>
												<img
													src={`assets/country_flags/${member.phone.charCode}.png`}
													className={"w-[1vw] aspect-auto"}
													alt={member.phone.countryName}
												/>
												<p
													className={"font-SOHNE_REGULAR text-[0.75vw]"}
													aria-selected={"false"}
												>
													{member.phone.dialingCode}
												</p>
												<ChevronDownIcon
													// onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
													className={`h-[0.75vw] w-[0.75vw] transition-all duration-150 ease-in-out
                                                    ${isCountryCodeDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
													aria-hidden="true"
													stroke="#292D32"
													strokeWidth={"0.1vh"}
												/>
											</button>
											<input
												disabled={!inviteUser}
												type={"number"}
												maxLength={10}
												className="min-h-[5vh] max-h-[5vh] w-full flex-1 px-[1vw]
                                                font-SOHNE_MEDIUM text-[1vw] outline-none bg-transparent rounded-tr-md rounded-br-md"
												placeholder="7734567890"
												value={member.phone.number || ""}
												onChange={(e) => {
													setMember({
														...member,
														phone: {
															...member.phone,
															number: e.target.value
														}
													});
												}}
												onBlur={validatePhoneNumber}
												name="phone"
												required
											/>
										</div>

										{/* Country Code Dropdown */}
										<div className={"relative"}>
											<Transition show={isCountryCodeDropdownOpen}>
												<div
													className={`bg-white absolute left-0 right-0 z-[1000] max-h-[15vh]
                                                    origin-top-right rounded-md shadow-2xl mt-[0.5vh]
                                                    border border-gray-700 overflow-y-scroll
                                                    data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                    data-[enter]:duration-100 data-[enter]:ease-out 
                                                    data-[leave]:duration-75 data-[leave]:ease-in`}
												>
													{
														countryCodes.map((country, index) => (
															<button
																key={index}
																className="w-full cursor-pointer min-h-[4vh] max-h-[4vh]
                                                                px-[0.5vw] flex items-center gap-2 hover:bg-gray-300"
																onClick={() => {
																	setIsCountryCodeDropdownOpen(false);
																	setMember({
																		...member,
																		phone: {
																			...member.phone,
																			charCode: country.charCode,
																			dialingCode: country.dialingCode,
																			countryName: country.countryName,
																		}
																	});
																}}
															>
																<img
																	src={country.countryFlagSrc}
																	className={"w-[1vw] aspect-auto"}
																	alt={country.countryName}
																/>
																<p
																	className={"font-SOHNE_REGULAR text-[0.75vw] text-left"}
																	aria-selected={"false"}
																>
																	{country.countryName}
																</p>
																<p
																	className={"font-SOHNE_REGULAR text-[0.75vw]"}
																	aria-selected={"false"}
																>
																	{'(' + country.countryDialingCode + ')'}
																</p>
															</button>
														))
													}
												</div>
											</Transition>
										</div>
									</div>
									{
										error['phone'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['phone']}
										</p>
									}
								</div>

								{/* Email Input */}
								<div className="flex-1 flex flex-col gap-2">
									<label
										htmlFor="email"
										className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									>
										{
											checkAge(member.dateOfBirth)
												? t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.LABEL")
												: t("ADD_NODE_FORM.SEND_REQUEST.EMAIL.LABEL_REQUIRED")
										}
									</label>
									<input
										disabled={!inviteUser}
										type="email"
										id="email"
										name='email'
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										value={member.email}
										onChange={handleFormData}
										onBlur={validateEmailId}
										required
									/>
									{
										error['email'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['email']}
										</p>
									}
								</div>
							</div>
						</fieldset>
					}

					{/* Need Assistance */}
					<fieldset>
						<label className="flex flex-row items-center gap-2">
							<input
								type="checkbox"
								name='needAssistance'
								checked={needAssistance}
								onChange={() => setNeedAssistance(!needAssistance)}
							/>
							<p className="font-SOHNE_REGULAR text-[1vw]">
								{t("ADD_NODE_FORM.NEED_ASSISTANCE.LABEL")}
							</p>
						</label>
					</fieldset>

					{/* Assistance Description */}
					<fieldset>
						<div className="mt-[2vh] flex flex-col gap-2">
                            <textarea
								disabled={!needAssistance}
								id="needAssistanceDescription"
								name='needAssistanceDescription'
								className="font-SOHNE_MEDIUM inline-flex rounded-md px-[1vw] py-[1vh] text-[1vw]
                                        leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
								placeholder={t("ADD_NODE_FORM.NEED_ASSISTANCE.DESCRIPTION_PLACEHOLDER")}
								value={needAssistanceDescription}
								onChange={(e) => setNeedAssistanceDescription(e.target.value)}
							/>
						</div>
					</fieldset>
				</div>

				{/* Footer */}
				<div className={"max-h-[10vh]"}>
					{/* Divider */}
					<div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

					<div className="flex items-center justify-end">
						{/* Submit Button */}
						<button
							disabled={isAddMemberButtonDisabled}
							onClick={handleSubmit}
							className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
						>
							{t("ADD_NODE_FORM.ADD_MEMBER")}
							<svg
								viewBox="0 0 6 6"
								fill="none"
								id={"arrow-icon"}
								className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M3 1L5 3L3 5"
									id={"arrow-icon-tip"}
									stroke="black"
									strokeLinecap="square"
								/>
								<path
									d="M1 3L4 3"
									id={"arrow-icon-line"}
									stroke="black"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}