export const circleInfo1 = [
	{
		id: 1,
		title: "फोटो गॅलरी",
		img: "/kulvratant_landingPage/image-gallery.png",
	},
	{
		id: 2,
		title: "आठवणी",
		img: "/kulvratant_landingPage/image-memories.png",
	},
	{
		id: 3,
		title: "यलो पेजेस",
		img: "/kulvratant_landingPage/image-yellowpage.png",
	},
	{
		id: 4,
		title: "एकमेका सहाय्य करू",
		img: "/kulvratant_landingPage/image-help-each-other.png",
	},
];

export const circleInfo2 = [
	{ id: 5, title: "अभिमान", img: "/kulvratant_landingPage/image-pride.png" },
	{
		id: 6,
		title: "अनुरूप जोडीदार",
		img: "/kulvratant_landingPage/image-metromonial.png",
	},
	{
		id: 7,
		title: "सहाय्यक",
		img: "/kulvratant_landingPage/image-assistant-sahayak.png",
	},
	{
		id: 8,
		title: "सभासद",
		img: "/kulvratant_landingPage/image-sabhasad.png",
	},
];
