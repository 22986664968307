import React, {useContext, useEffect, useState} from "react";
import {AccountContext} from "../context/AccountProvider";
import {updateAccountById} from "../service/accountApi";
import {translateTextToDevnagri} from "../service/translateApi";
import {t} from "i18next";
import {Modal} from "./Modal";
import {Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {countryCodes} from "../data/CountryCode";
import {familyTreeNames} from "../data/FamilyTreeNames";

export const CreateProfile = () => {
	const pfpBgColors = ["bg-[#0F1F2E]", "bg-[#FF8C42]", "bg-[#FF3C38]", "bg-[#A23E48]"];
	const bloodGroups = ["A +", "A -", "B +", "B -", "O +", "O -", "AB +", "AB -"];

	const {account, setAccount} = useContext(AccountContext);
	const [error, setError] = useState({});

	const [filteredFamilyTreeNames, setFilteredFamilyTreeNames] = useState(familyTreeNames);
	const [isFamilyTreeNameDropdownOpen, setIsFamilyTreeNameDropdownOpen] = useState(false);

	const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState(false);
	const [isMaritalStatusDropdownOpen, setIsMaritalStatusDropdownOpen] = useState(false);
	const [isSideOfFamilyDropdownOpen, setIsSideOfFamilyDropdownOpen] = useState(false);

	const [isCountryCodeDropdownOpen, setIsCountryCodeDropdownOpen] = useState(false);
	const [isBloodGroupDropdownOpen, setIsBloodGroupDropdownOpen] = useState(false);

	const translateToDevnagri = (e) => {
		if (e.target.name === 'firstName' || e.target.name === 'middleName') {
			if (e.target.value) {
				translateTextToDevnagri(e.target.value).then((res) => {
					console.log("(CreateProfile.jsx) Translation Response: ", res);
					setProfile({
						...profile,
						[e.target.name + 'DevanagariScript']: res.result
					});
					setError({
						...error,
						[e.target.name + 'DevanagariScript']: ""
					});
				});
			}
		}
	};

	const [profile, setProfile] = useState({
		email: account.email || '',

		firstName: account.firstName || '',
		middleName: account.middleName || '',

		firstNameDevanagariScript: account.firstNameDevanagariScript || '',
		middleNameDevanagariScript: account.middleNameDevanagariScript || '',

		dateOfBirth: '',
		gender: account.gender || 'Male',
		bloodGroup: 'A+',

		phone: {
			charCode: account.charCode || 'IN',
			dialingCode: account.dialingCode || '+91',
			countryName: account.countryName || 'India',
			number: account.number || ''
		},

		maritalStatus: account.maritalStatus || 'Single',
		sideOfFamily: account.sideOfFamily || '',

		firstNameAfterMarriage: '',
		spouseNameAfterMarriage: '',
		lastNameAfterMarriage: '',

		firstNameBeforeMarriage: '',
		fathersNameBeforeMarriage: '',
		lastNameBeforeMarriage: '',

		dateOfMarriage: '',

		familyTreeName: '',

		biography: '',

		imageUrl: account.imageUrl || '',
		imageUrlBg: pfpBgColors[Math.floor(Math.random() * pfpBgColors.length)]
	});

	const [needAssistance, setNeedAssistance] = useState(false);
	const [needAssistanceDescription, setNeedAssistanceDescription] = useState('');

	function checkAge(dateOfBirth) {
		// Convert the date of birth to a Date object
		const birthDate = new Date(dateOfBirth);

		// Calculate the current age in years
		const today = new Date();
		const age = today.getFullYear() - birthDate.getFullYear();

		// Check if the age is less than 13 or greater than 70
		return (age < 13 || age > 70);
	}

	const handleGenderSelect = (gender) => {
		setIsGenderDropdownOpen(false);
		setProfile({
			...profile,
			gender: gender
		});
	}

	const maritalStatusLabel = (maritalStatus) => {
		switch (maritalStatus) {
			case 'Single':
				return t("PROFILE_FORM.MARITAL_STATUS.SINGLE");
			case 'Married':
				return t("PROFILE_FORM.MARITAL_STATUS.MARRIED");
			case 'Widowed':
				return t("PROFILE_FORM.MARITAL_STATUS.WIDOWED");
			case 'Divorced':
				return t("PROFILE_FORM.MARITAL_STATUS.DIVORCED");
			case 'Minor':
				return t("PROFILE_FORM.MARITAL_STATUS.MINOR");
			default:
				return t("PROFILE_FORM.MARITAL_STATUS.SINGLE");
		}
	}

	const handleMaritalStatusSelect = (maritalStatus) => {
		setIsMaritalStatusDropdownOpen(false);
		setProfile({
			...profile,
			maritalStatus: maritalStatus
		});
	}

	const handleSideOfFamilySelect = (sideOfFamily) => {
		setIsSideOfFamilyDropdownOpen(false);
		setProfile({
			...profile,
			sideOfFamily: sideOfFamily
		});
	}

	const handleFamilyTreeNameSelect = (familyTreeName) => {
		setIsFamilyTreeNameDropdownOpen(false);
		setProfile({
			...profile,
			familyTreeName: familyTreeName
		});
	}

	useEffect(() => {
		console.log("(CreateProfile) (Changed) Gender: ", profile.gender);
		console.log("(CreateProfile) (Changed) Marital Status: ", profile.maritalStatus);
		if (profile.gender === 'Female' && (profile.maritalStatus === 'Single' || profile.maritalStatus === 'Minor')) {
			setProfile({
				...profile,
				sideOfFamily: 'MaherVashin'
			})
		}
	}, [profile.gender, profile.maritalStatus]);

	const isNumberValid = (phone) => {
		let reg = /^\d+$/;
		return reg.test(phone);
	}

	const validatePhoneNumber = (e) => {
		if ((!isNumberValid(e.target.value)) || e.target.value.length !== 10) {
			setError({
				...error,
				[e.target.name]: t("PROFILE_FORM.PHONE_NUMBER.INVALID")
			});
		}
	}

	const handleBloodGroupSelect = (bloodGroup) => {
		setIsBloodGroupDropdownOpen(false);
		setProfile({
			...profile,
			bloodGroup: bloodGroup
		});
	}

	const handleFormData = (e) => {
		console.log(e.target.name + ': ' + e.target.value);

		setProfile({
			...profile,
			[e.target.name]: e.target.value
		});

		setError({
			...error,
			[e.target.name]: ""
		});
	};

	const validate = () => {
		let err = {};

		if (profile.familyTreeName === '') {
			err['familyTreeName'] = t("PROFILE_FORM.FAMILY_TREE_NAME.ERROR");
		}

		if (profile.firstName === '') {
			err['firstName'] = t("PROFILE_FORM.NAME.ENGLISH.FIRST_NAME_ERROR");
		}

		if (profile.middleName === '') {
			err['middleName'] = t("PROFILE_FORM.NAME.ENGLISH.MIDDLE_NAME_ERROR");
		}

		if (profile.firstNameDevanagariScript === '') {
			err['firstNameDevanagariScript'] = t("PROFILE_FORM.NAME.DEVANAGRI.FIRST_NAME_ERROR");
		}

		if (profile.middleNameDevanagariScript === '') {
			err['middleNameDevanagariScript'] = t("PROFILE_FORM.NAME.DEVANAGRI.MIDDLE_NAME_ERROR");
		}

		if (profile.gender === '') {
			err['gender'] = t("PROFILE_FORM.GENDER.ERROR");
		}

		if (profile.dateOfBirth === '') {
			err['dateOfBirth'] = t("PROFILE_FORM.DOB.ERROR");
		}

		if (profile.maritalStatus === 'Married' || profile.maritalStatus === 'Widowed' || profile.maritalStatus === 'Divorced') {
			if (profile.dateOfMarriage === '') {
				err['dateOfMarriage'] = t("PROFILE_FORM.DOM.ERROR");
			}

			if (profile.gender === 'Female') {
				if (profile.sideOfFamily === "MaherVashin") {
					if (profile.firstNameAfterMarriage === '') {
						err['firstNameAfterMarriage'] = t("PROFILE_FORM.NAME.AFTER_MARRIAGE.FIRST_NAME_ERROR");
					}

					if (profile.spouseNameAfterMarriage === '') {
						err['spouseNameAfterMarriage'] = t("PROFILE_FORM.NAME.AFTER_MARRIAGE.SPOUSE_NAME_ERROR");
					}

					if (profile.lastNameAfterMarriage === '') {
						err['lastNameAfterMarriage'] = t("PROFILE_FORM.NAME.AFTER_MARRIAGE.LAST_NAME_ERROR");
					}
				} else {
					if (profile.firstNameBeforeMarriage === '') {
						err['firstNameBeforeMarriage'] = t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FIRST_NAME_ERROR");
					}

					if (profile.fathersNameBeforeMarriage === '') {
						err['fathersNameBeforeMarriage'] = t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FATHERS_NAME_ERROR");
					}

					if (profile.lastNameBeforeMarriage === '') {
						err['lastNameBeforeMarriage'] = t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.LAST_NAME_ERROR");
					}
				}
			}
		}

		if (!checkAge(profile.dateOfBirth)) {
			if (profile.phone.number === '') {
				err['phone'] = t("PROFILE_FORM.PHONE_NUMBER.REQUIRED");
			} else if (profile.phone.number?.length !== 10 && !isNumberValid(profile.phone.number)) {
				err['phone'] = t("PROFILE_FORM.PHONE_NUMBER.INVALID");
			}
		}

		return err;
	}

	const handleSubmit = () => {
		console.log("Handle Submit Begin!");

		// Error validations
		const err = validate();

		if (Object.keys(err).length > 0) {
			setError(err);
			return;
		}

		// Populate new profile data
		const accountData = {
			...profile,
			imageUrl: profile.imageUrl || (profile.firstName[0] + profile.middleName[0]),
			needAssistance: needAssistance,
			needAssistanceDescription: needAssistanceDescription
		};

		// Update account with the new profile data
		updateAccountById(account._id, accountData)
			.then((response) => {
				console.log("(CreateProfile) Update Account By Id Response: ", response);

				if (response?.success) {
					setAccount(response.data);
				}
			})
			.catch((error) => {
				console.log("(CreateProfile) Update Account By Id Error: ", error);
			});
	};

	return (
		<Modal isOpen={true}>
			<div className="min-w-xl max-w-xl px-[1.5vw] py-[2vh] bg-white rounded-lg">
				{/* Header */}
				<div className={"max-h-[10vh]"}>
					{/* Form Title */}
					<h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
						{t("PROFILE_FORM.CREATE_TITLE")}
					</h2>

					{/* Divider */}
					<div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
				</div>

				{/* Form Sub-Title */}
				{/*<p className="mb-[2vh] text-sm text-[#0F4C5C] font-SOHNE_REGULAR_ITALIC">*/}
				{/*    {t("PROFILE_FORM.REQUIRED_DETAILS_MESSAGE")}*/}
				{/*</p>*/}

				<div className={"no-scrollbar overflow-y-scroll max-h-[60vh] pt-[1.5vh]"}>
					{/* Name in English */}
					<fieldset>
						<div className="mb-[2vh] flex flex-col gap-2">
							<label
								className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
								htmlFor="firstName"
							>
								{t("PROFILE_FORM.NAME.ENGLISH.LABEL")}
							</label>
							<div className="flex justify-between gap-4">
								{/* First Name */}
								<div className="flex-1 flex flex-col">
									<input
										id="firstName"
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										name='firstName'
										placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.FIRST_NAME")}
										value={profile.firstName}
										onChange={handleFormData}
										onBlur={translateToDevnagri}
									/>
									{
										error['firstName'] &&
										<span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['firstName']}
                                        </span>
									}
								</div>

								{/* Father's Name */}
								<div className="flex-1 flex flex-col">
									<input
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										id="middleName"
										name='middleName'
										placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME")}
										value={profile.middleName}
										onChange={handleFormData}
										onBlur={translateToDevnagri}
									/>
									{
										error['middleName'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['middleName']}
										</p>
									}
								</div>
							</div>
						</div>
					</fieldset>

					{/* Name in Devnagari */}
					<fieldset>
						<div className="mb-[2vh] flex flex-col gap-2">
							<label
								className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
								htmlFor="firstNameDevanagariScript"
							>
								{t("PROFILE_FORM.NAME.DEVANAGRI.LABEL")}
							</label>
							<div className="flex justify-between gap-4">
								{/* First Name in Devnagari */}
								<div className={"flex-1 flex flex-col"}>
									<input
										className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] items-center justify-center rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
										id="firstNameDevanagariScript"
										name='firstNameDevanagariScript'
										placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.FIRST_NAME")}
										value={profile.firstNameDevanagariScript}
										onChange={handleFormData}
									/>
									{
										error['firstNameDevanagariScript'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['firstNameDevanagariScript']}
										</p>
									}
								</div>

								{/* Father's Name in Devnagari */}
								<div className={"flex-1 flex flex-col"}>
									<input
										className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                        inline-flex items-center justify-center rounded-md
                                        px-[1vw] text-[1vw] outline-none
                                        border-2 border-gray-300 focus:border-[#5F0F40]"
										id="middleNameDevanagariScript"
										name='middleNameDevanagariScript'
										placeholder={t("PROFILE_FORM.NAME.PLACEHOLDER.MIDDLE_NAME")}
										value={profile.middleNameDevanagariScript}
										onChange={handleFormData}
									/>
									{
										error['middleNameDevanagariScript'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['middleNameDevanagariScript']}
										</p>
									}
								</div>
							</div>
						</div>
					</fieldset>

					{/*  DOB | Gender | Blood Group */}
					<fieldset>
						<div className="mb-[2vh] flex flex-row justify-between gap-4">
							{/* Date of Birth */}
							<div className="flex flex-col gap-2">
								<label
									className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
									htmlFor='dateOfBirth'
								>
									{t("PROFILE_FORM.DOB.LABEL")}
								</label>
								<div className="relative w-full">
									<input
										type="date"
										name='dateOfBirth'
										required
										value={profile.dateOfBirth}
										onChange={handleFormData}
										className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                        flex-1 items-center justify-center rounded-md px-[1vw] text-[1vw]
                                        leading-none outline-none border-2 border-gray-300
                                        focus:border-[#5F0F40]"
									/>
									{
										error['dateOfBirth'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['dateOfBirth']}
										</p>
									}
								</div>
							</div>

							{/* Gender */}
							<div className="flex-1 flex flex-col gap-2">
								<label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="gender">
									{t("PROFILE_FORM.GENDER.LABEL")}
								</label>

								<div className="relative w-full flex flex-col">
									<button
										onBlur={() => setIsGenderDropdownOpen(false)}
										className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
										onClick={() => setIsGenderDropdownOpen(!isGenderDropdownOpen)}
									>
										<div className={"flex-1 flex flex-row items-center gap-2"}>
											<p className={"font-SOHNE_REGULAR text-[1vw]"}>
												{
													profile.gender === "Male"
														? t("ADD_NODE_FORM.GENDER.MALE")
														: t("ADD_NODE_FORM.GENDER.FEMALE")
												}
											</p>
											<img
												className={"w-[1.5vw] aspect-auto"}
												src={profile.gender === "Male" ? "assets/male-gender-icon.png" : "assets/female-gender-icon.png"}
												alt={profile.gender === "Male" ? "Male" : "Female"}
											/>
										</div>
										<ChevronDownIcon
											className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isGenderDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
											aria-hidden="true"
											stroke="#292D32"
											strokeWidth={"0.1vh"}
										/>
									</button>

									{/* Gender Dropdown */}
									<div className={"relative"}>
										<Transition show={isGenderDropdownOpen}>
											<div
												className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
											>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleGenderSelect("Male")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("PROFILE_FORM.GENDER.MALE")}
													</p>
												</button>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleGenderSelect("Female")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("PROFILE_FORM.GENDER.FEMALE")}
													</p>
												</button>
											</div>
										</Transition>
									</div>
								</div>
							</div>

							{/* Blood Group */}
							<div className={"flex-1 flex flex-col gap-2"}>
								<label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="phone">
									{t("PROFILE_FORM.BLOOD_GROUP.LABEL")}
								</label>
								<div className="relative w-full flex flex-col">
									<button
										onBlur={() => setIsBloodGroupDropdownOpen(false)}
										className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                    rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
										onClick={() => setIsBloodGroupDropdownOpen(!isBloodGroupDropdownOpen)}
									>
										<p className={"font-SOHNE_REGULAR text-[1vw]"}>
											{profile.bloodGroup}
										</p>
										<ChevronDownIcon
											className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isBloodGroupDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
											aria-hidden="true"
											stroke="#292D32"
											strokeWidth={"0.1vh"}
										/>
									</button>

									{/* Marital Status Dropdown */}
									<div className={"relative"}>
										<Transition show={isBloodGroupDropdownOpen}>
											<div
												className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
											>
												{
													bloodGroups.map((bloodGroup, index) => (
														<button
															key={index}
															className="w-full min-h-[5vh] max-h-[5vh]
                                                            cursor-pointer px-[0.5vw] hover:bg-gray-300
                                                            flex items-center justify-between gap-1"
															onClick={() => handleBloodGroupSelect(bloodGroup)}
														>
															<p className={"font-SOHNE_REGULAR text-[1vw]"}>
																{bloodGroup}
															</p>
														</button>
													))
												}
											</div>
										</Transition>
									</div>
								</div>
							</div>
						</div>
					</fieldset>

					{/* Mobile Number | Marital Status */}
					<fieldset>
						<div className={`mb-[2vh] flex flex-row gap-4`}>
							{/* Mobile Number Input */}
							<div className={"flex-1 flex flex-col gap-2"}>
								<label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="phone">
									{/* I want to check whether the age of the person is less than 13 or greater than 70 */}
									{
										checkAge(profile.dateOfBirth)
											? t("PROFILE_FORM.PHONE_NUMBER.LABEL")
											: t("PROFILE_FORM.PHONE_NUMBER.LABEL_REQUIRED")
									}
								</label>
								<div className="relative w-full flex flex-col">
									<div
										className="flex flex-row justify-between items-center
                                        min-h-[5vh] max-h-[5vh] rounded-md border-2 border-gray-300"
									>
										<button
											onBlur={() => setIsCountryCodeDropdownOpen(false)}
											className="cursor-pointer bg-gray-300 min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                            rounded-tl-md rounded-bl-md flex items-center justify-between gap-1"
											onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
										>
											<img
												src={`assets/country_flags/${profile.phone.charCode}.png`}
												className={"w-[1vw] aspect-auto"}
												alt={profile.phone.countryName}
											/>
											<p
												className={"font-SOHNE_REGULAR text-[0.75vw]"}
												aria-selected={"false"}
											>
												{profile.phone.dialingCode}
											</p>
											<ChevronDownIcon
												// onClick={() => setIsCountryCodeDropdownOpen(!isCountryCodeDropdownOpen)}
												className={`h-[0.75vw] w-[0.75vw] transition-all duration-150 ease-in-out
                                                ${isCountryCodeDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
												aria-hidden="true"
												stroke="#292D32"
												strokeWidth={"0.1vh"}
											/>
										</button>
										<input
											type="phone"
											name="phone"
											className="min-h-[5vh] max-h-[5vh] w-full flex-1 px-[1vw]
                                            font-SOHNE_MEDIUM text-[1vw] outline-none bg-transparent rounded-tr-md rounded-br-md"
											placeholder="7734567890"
											value={profile.phone.number || ""}
											onChange={(e) => {
												setProfile({
													...profile,
													phone: {
														...profile.phone,
														number: e.target.value
													}
												});

												setError({
													...error,
													[e.target.name]: ""
												});
											}}
											onBlur={validatePhoneNumber}
											required
										/>
									</div>

									{/* Country Code Dropdown */}
									<div className={"relative"}>
										<Transition show={isCountryCodeDropdownOpen}>
											<div
												className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
											>
												{
													countryCodes.map((country, index) => (
														<button
															key={index}
															className="w-full cursor-pointer min-h-[4vh] max-h-[4vh]
                                                            px-[0.5vw] flex items-center gap-2 hover:bg-gray-300"
															onClick={() => {
																setIsCountryCodeDropdownOpen(false);
																setProfile({
																	...profile,
																	phone: {
																		...profile.phone,
																		charCode: country.countryCode,
																		dialingCode: country.countryDialingCode,
																		countryName: country.countryName,
																	}
																});
															}}
														>
															<img
																// src={`assets/country_flags/${country.countryDialingCode}.png`}
																src={country.countryFlagSrc}
																className={"w-[1vw] aspect-auto"}
																alt={country.countryName}
															/>
															<p
																className={"font-SOHNE_REGULAR text-[0.75vw] text-left"}
																aria-selected={"false"}
															>
																{country.countryName}
															</p>
															<p
																className={"font-SOHNE_REGULAR text-[0.75vw]"}
																aria-selected={"false"}
															>
																{'(' + country.countryDialingCode + ')'}
															</p>
														</button>
													))
												}
											</div>
										</Transition>
									</div>
								</div>
								{
									error['phone'] &&
									<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
										{error['phone']}
									</p>
								}
							</div>

							{/* Marital Status */}
							<div className={`${profile.gender === "Female" ? 'w-1/5' : 'w-1/3'} flex flex-col gap-2`}>
								<label
									className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									htmlFor="maritalStatus"
								>
									<img
										src={"assets/marriage-icon.png"}
										className={"w-[1.5vw] aspect-auto"}
										alt={"Marriage"}
										title={"Marriage"}
									/>
									{t("PROFILE_FORM.MARITAL_STATUS.LABEL")}
								</label>
								<div className="relative w-full flex flex-col">
									<button
										onBlur={() => setIsMaritalStatusDropdownOpen(false)}
										className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
										onClick={() => setIsMaritalStatusDropdownOpen(!isMaritalStatusDropdownOpen)}
									>
										<p className={"font-SOHNE_REGULAR text-[1vw]"}>
											{maritalStatusLabel(profile.maritalStatus)}
										</p>
										<ChevronDownIcon
											className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isMaritalStatusDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
											aria-hidden="true"
											stroke="#292D32"
											strokeWidth={"0.1vh"}
										/>
									</button>

									{/* Marital Status Dropdown */}
									<div className={"relative"}>
										<Transition show={isMaritalStatusDropdownOpen}>
											<div className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                            origin-top-right rounded-md shadow-2xl 
                                            border border-gray-700 overflow-y-scroll
                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                            data-[leave]:duration-75 data-[leave]:ease-in`}
											>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleMaritalStatusSelect("Minor")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("PROFILE_FORM.MARITAL_STATUS.MINOR")}
													</p>
												</button>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleMaritalStatusSelect("Single")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("PROFILE_FORM.MARITAL_STATUS.SINGLE")}
													</p>
												</button>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleMaritalStatusSelect("Married")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("PROFILE_FORM.MARITAL_STATUS.MARRIED")}
													</p>
												</button>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleMaritalStatusSelect("Divorced")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{
															profile.gender === "Male"
																? t("PROFILE_FORM.MARITAL_STATUS.WIDOWER")
																: t("PROFILE_FORM.MARITAL_STATUS.WIDOWED")
														}
													</p>
												</button>
												<button
													className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handleMaritalStatusSelect("Widowed")}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{t("PROFILE_FORM.MARITAL_STATUS.DIVORCED")}
													</p>
												</button>
											</div>
										</Transition>
									</div>
									{
										error['maritalStatus'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['maritalStatus']}
										</p>
									}
								</div>
							</div>

							{/* Side Of Family */}
							{
								profile.gender === "Female" &&
								<div className="w-1/4 flex flex-col gap-2">
									<label
										className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
										htmlFor="maritalStatus"
									>
										{t("PROFILE_FORM.SIDE_OF_FAMILY.LABEL")}
									</label>
									<div className="relative w-full flex flex-col">
										<button
											disabled={profile.gender === "Female" &&
												(profile.maritalStatus === 'Single' || profile.maritalStatus === 'Minor')}
											onBlur={() => setIsSideOfFamilyDropdownOpen(false)}
											className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 px-[0.5vw] gap-1"
											onClick={() => setIsSideOfFamilyDropdownOpen(!isSideOfFamilyDropdownOpen)}
										>
											<p className={"font-SOHNE_REGULAR text-[1vw]"}>
												{
													profile.sideOfFamily !== "" &&
													(profile.sideOfFamily === "MaherVashin"
														? t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")
														: t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN"))
												}
											</p>
											<ChevronDownIcon
												className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isSideOfFamilyDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
												aria-hidden="true"
												stroke="#292D32"
												strokeWidth={"0.1vh"}
											/>
										</button>

										{/* Side Of Family Dropdown */}
										<div className={"relative"}>
											<Transition show={isSideOfFamilyDropdownOpen}>
												<div
													className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                origin-top-right rounded-md shadow-2xl 
                                                border border-gray-700 overflow-y-scroll
                                                data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                data-[enter]:duration-100 data-[enter]:ease-out 
                                                data-[leave]:duration-75 data-[leave]:ease-in`}
												>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleSideOfFamilySelect("MaherVashin")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")}
														</p>
													</button>
													<button
														className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                    hover:bg-gray-300 flex items-center justify-between gap-1"
														onClick={() => handleSideOfFamilySelect("SasurVashin")}
													>
														<p className={"font-SOHNE_REGULAR text-[1vw]"}>
															{t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN")}
														</p>
													</button>
												</div>
											</Transition>
										</div>
									</div>
								</div>
							}

							{/*<div className={"flex flex-col justify-evenly gap-2"}>*/}
							{/*    <label className="flex flex-row items-center">*/}
							{/*        <input*/}
							{/*            type="radio"*/}
							{/*            name="sideOfFamily"*/}
							{/*            value='MaherVashin'*/}
							{/*            onClick={handleFormData}*/}
							{/*            checked={profile.sideOfFamily === 'MaherVashin'}*/}
							{/*            onChange={handleFormData}*/}
							{/*            className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"*/}
							{/*        />*/}
							{/*        <p className={"text-[1vw] leading-tight font-SOHNE_MEDIUM"}>*/}
							{/*            {t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")}*/}
							{/*        </p>*/}
							{/*    </label>*/}
							{/*    <label className="flex flex-row items-center">*/}
							{/*        <input*/}
							{/*            type="radio"*/}
							{/*            name="sideOfFamily"*/}
							{/*            value='SasurVashin'*/}
							{/*            onClick={handleFormData}*/}
							{/*            checked={profile.sideOfFamily === 'SasurVashin'}*/}
							{/*            onChange={handleFormData}*/}
							{/*            className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"*/}
							{/*        />*/}
							{/*        <p className={"text-[1vw] leading-tight font-SOHNE_MEDIUM"}>*/}
							{/*            {t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN")}*/}
							{/*        </p>*/}
							{/*    </label>*/}
							{/*</div>*/}
						</div>
					</fieldset>

					{/* Name Before Marriage */}
					{
						(profile.maritalStatus === 'Married' || profile.maritalStatus === 'Widowed' || profile.maritalStatus === 'Divorced')
						&& profile.gender === "Female" && profile.sideOfFamily === "SasurVashin" &&
						<fieldset>
							<div className="mb-[2vh] flex flex-col gap-2">
								<label
									className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									htmlFor="nameBeforeMarriage"
								>
									{t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.LABEL")}
								</label>

								<div className="flex flex-col justify-between gap-2">
									{/* First Name | Father's Name */}
									<div className="flex justify-between gap-2">
										{/* First Name Before Marriage */}
										<div className={"flex-1 flex flex-col"}>
											<input
												id="firstNameBeforeMarriage"
												type="text"
												className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                                inline-flex items-center justify-center rounded-md
                                                px-[1vw] text-[1vw] outline-none
                                                border-2 border-gray-300 focus:border-[#5F0F40]"
												placeholder={t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FIRST_NAME_PLACEHOLDER")}
												name='firstNameBeforeMarriage'
												value={profile.firstNameBeforeMarriage || ""}
												onChange={handleFormData}
											/>
											{
												error['firstNameBeforeMarriage'] &&
												<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
													{error['firstNameBeforeMarriage']}
												</p>
											}
										</div>

										{/* Father's Name Before Marriage */}
										<div className={"flex-1 flex flex-col"}>
											<input
												id="fathersNameBeforeMarriage"
												type="text"
												className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                                inline-flex items-center justify-center rounded-md
                                                px-[1vw] text-[1vw] outline-none
                                                border-2 border-gray-300 focus:border-[#5F0F40]"
												placeholder={t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.FATHERS_NAME_PLACEHOLDER")}
												name='fathersNameBeforeMarriage'
												value={profile.fathersNameBeforeMarriage || ""}
												onChange={handleFormData}
											/>
											{
												error['fathersNameBeforeMarriage'] &&
												<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
													{error['fathersNameBeforeMarriage']}
												</p>
											}
										</div>
									</div>

									{/*<div className="flex justify-between gap-2">*/}
									{/* Last Name Before Marriage */}
									<div className={"flex-1 flex flex-col"}>
										<input
											id="lastNameBeforeMarriage"
											type="text"
											className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                            inline-flex items-center justify-center rounded-md
                                            px-[1vw] text-[1vw] outline-none
                                            border-2 border-gray-300 focus:border-[#5F0F40]"
											placeholder={t("PROFILE_FORM.NAME.BEFORE_MARRIAGE.LAST_NAME_PLACEHOLDER")}
											name='lastNameBeforeMarriage'
											value={profile.lastNameBeforeMarriage || ""}
											onChange={handleFormData}
										/>
										{
											error['lastNameBeforeMarriage'] &&
											<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
												{error['lastNameBeforeMarriage']}
											</p>
										}
									</div>

									{/*/!* Side of Family *!/*/}
									{/*<div className="flex-1 flex gap-2">*/}
									{/*    <label className="flex-1 flex flex-row items-center">*/}
									{/*        <input*/}
									{/*            type="radio"*/}
									{/*            name="sideOfFamily"*/}
									{/*            value='MaherVashin'*/}
									{/*            onClick={handleFormData}*/}
									{/*            checked={profile.sideOfFamily === 'MaherVashin'}*/}
									{/*            onChange={handleFormData}*/}
									{/*            className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"*/}
									{/*        />*/}
									{/*        <p className={"text-[1vw] font-SOHNE_MEDIUM"}>*/}
									{/*            {t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")}*/}
									{/*        </p>*/}
									{/*    </label>*/}
									{/*    <label className="flex-1 flex flex-row items-center">*/}
									{/*        <input*/}
									{/*            type="radio"*/}
									{/*            name="sideOfFamily"*/}
									{/*            value='SasurVashin'*/}
									{/*            onClick={handleFormData}*/}
									{/*            checked={profile.sideOfFamily === 'SasurVashin'}*/}
									{/*            onChange={handleFormData}*/}
									{/*            className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"*/}
									{/*        />*/}
									{/*        <p className={"text-[1vw] font-SOHNE_MEDIUM"}>*/}
									{/*            {t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN")}*/}
									{/*        </p>*/}
									{/*    </label>*/}
									{/*</div>*/}
									{/*</div>*/}
								</div>
							</div>
						</fieldset>
					}

					{/* Name After Marriage */}
					{
						(profile.maritalStatus === 'Married' || profile.maritalStatus === 'Widowed' || profile.maritalStatus === 'Divorced')
						&& profile.gender === "Female" && profile.sideOfFamily === "MaherVashin" &&
						<fieldset>
							<div className="mb-[2vh] flex flex-col gap-2">
								<label
									className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									htmlFor="nameAfterMarriage"
								>
									{t("PROFILE_FORM.NAME.AFTER_MARRIAGE.LABEL")}
								</label>

								{/*<div className="flex flex-col justify-between gap-2">*/}
								{/* First Name | Father's Name */}
								<div className="flex justify-between gap-2">
									{/* First Name Before Marriage */}
									<div className={"flex-1 flex flex-col"}>
										<input
											id="firstNameAfterMarriage"
											type="text"
											className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                                inline-flex items-center justify-center rounded-md
                                                px-[1vw] text-[1vw] outline-none
                                                border-2 border-gray-300 focus:border-[#5F0F40]"
											placeholder={t("PROFILE_FORM.NAME.AFTER_MARRIAGE.FIRST_NAME_PLACEHOLDER")}
											name='firstNameAfterMarriage'
											value={profile.firstNameAfterMarriage || ""}
											onChange={handleFormData}
										/>
										{
											error['firstNameAfterMarriage'] &&
											<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
												{error['firstNameAfterMarriage']}
											</p>
										}
									</div>

									{/* Spouse Name After Marriage */}
									<div className={"flex-1 flex flex-col"}>
										<input
											id="spouseNameAfterMarriage"
											type="text"
											className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                                inline-flex items-center justify-center rounded-md
                                                px-[1vw] text-[1vw] outline-none
                                                border-2 border-gray-300 focus:border-[#5F0F40]"
											placeholder={t("PROFILE_FORM.NAME.AFTER_MARRIAGE.SPOUSE_NAME_PLACEHOLDER")}
											name='spouseNameAfterMarriage'
											value={profile.spouseNameAfterMarriage || ""}
											onChange={handleFormData}
										/>
										{
											error['spouseNameAfterMarriage'] &&
											<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
												{error['spouseNameAfterMarriage']}
											</p>
										}
									</div>
								</div>

								{/*<div className="flex justify-between gap-2">*/}

								{/* Last Name After Marriage */}
								<div className={"flex-1 flex flex-col"}>
									<input
										id="lastNameAfterMarriage"
										type="text"
										className="font-SOHNE_REGULAR min-h-[5vh] max-h-[5vh]
                                            inline-flex items-center justify-center rounded-md
                                            px-[1vw] text-[1vw] outline-none
                                            border-2 border-gray-300 focus:border-[#5F0F40]"
										placeholder={t("PROFILE_FORM.NAME.AFTER_MARRIAGE.LAST_NAME_PLACEHOLDER")}
										name='lastNameAfterMarriage'
										value={profile.lastNameAfterMarriage || ""}
										onChange={handleFormData}
									/>
									{
										error['lastNameAfterMarriage'] &&
										<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
											{error['lastNameAfterMarriage']}
										</p>
									}
								</div>

								{/*/!* Side of Family *!/*/}
								{/*<div className="flex-1 flex gap-2">*/}
								{/*    <label className="flex-1 flex flex-row items-center">*/}
								{/*        <input*/}
								{/*            type="radio"*/}
								{/*            name="sideOfFamily"*/}
								{/*            value='MaherVashin'*/}
								{/*            onClick={handleFormData}*/}
								{/*            checked={profile.sideOfFamily === 'MaherVashin'}*/}
								{/*            onChange={handleFormData}*/}
								{/*            className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"*/}
								{/*        />*/}
								{/*        <p className={"text-[1vw] font-SOHNE_MEDIUM"}>*/}
								{/*            {t("PROFILE_FORM.SIDE_OF_FAMILY.MAHER_VASHIN")}*/}
								{/*        </p>*/}
								{/*    </label>*/}
								{/*    <label className="flex-1 flex flex-row items-center">*/}
								{/*        <input*/}
								{/*            type="radio"*/}
								{/*            name="sideOfFamily"*/}
								{/*            value='SasurVashin'*/}
								{/*            onClick={handleFormData}*/}
								{/*            checked={profile.sideOfFamily === 'SasurVashin'}*/}
								{/*            onChange={handleFormData}*/}
								{/*            className="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"*/}
								{/*        />*/}
								{/*        <p className={"text-[1vw] font-SOHNE_MEDIUM"}>*/}
								{/*            {t("PROFILE_FORM.SIDE_OF_FAMILY.SASUR_VASHIN")}*/}
								{/*        </p>*/}
								{/*    </label>*/}
								{/*</div>*/}
								{/*</div>*/}
								{/*</div>*/}
							</div>
						</fieldset>
					}

					{/* Date of Marriage | Family Tree Name */}
					<fieldset>
						<div className="mb-[2vh] flex flex-row gap-4">
							{/* Date of Marriage */}
							{
								profile.maritalStatus === 'Married' && (
									<div className="flex flex-col gap-2">
										<label
											className='text-gray-600 text-[1vw] font-SOHNE_REGULAR'
											htmlFor='dateOfMarriage'
										>
											{t("PROFILE_FORM.DOM.LABEL")}
										</label>
										<div className="flex flex-col relative w-full">
											<input
												type="date"
												name='dateOfMarriage'
												required
												value={profile.dateOfMarriage}
												onChange={handleFormData}
												className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                                flex-1 items-center justify-center rounded-md px-[1vw] text-[1vw]
                                                leading-none outline-none border-2 border-gray-300
                                                focus:border-[#5F0F40]"
											/>
											{
												error['dateOfMarriage'] &&
												<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
													{error['dateOfMarriage']}
												</p>
											}
										</div>
									</div>
								)
							}

							{/* Family Tree Name */}
							{/*<div className={"flex-1 flex flex-col gap-2"}>*/}
							{/*    <label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR" htmlFor="familyTreeName">*/}
							{/*        {t("PROFILE_FORM.FAMILY_TREE_NAME.LABEL")}*/}
							{/*    </label>*/}

							{/*    <div className="relative w-full flex flex-col">*/}
							{/*        <div className="flex flex-col relative w-full">*/}
							{/*            <button*/}
							{/*                onBlur={() => setIsFamilyTreeNameDropdownOpen(false)}*/}
							{/*                className={`flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]*/}
							{/*                    rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1`}*/}
							{/*                onClick={() => setIsFamilyTreeNameDropdownOpen(!isFamilyTreeNameDropdownOpen)}*/}
							{/*            >*/}
							{/*                <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
							{/*                    {profile.familyTreeName}*/}
							{/*                </p>*/}
							{/*                <ChevronDownIcon*/}
							{/*                    className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out*/}
							{/*                    ${isFamilyTreeNameDropdownOpen ? 'rotate-180' : 'rotate-0'} `}*/}
							{/*                    aria-hidden="true"*/}
							{/*                    stroke="#292D32"*/}
							{/*                    strokeWidth={"0.1vh"}*/}
							{/*                />*/}
							{/*            </button>*/}

							{/*            {*/}
							{/*                error['familyTreeName'] &&*/}
							{/*                <span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">*/}
							{/*                        {error['familyTreeName']}*/}
							{/*                    </span>*/}
							{/*            }*/}
							{/*        </div>*/}

							{/*        /!* Family Tree Name Dropdown *!/*/}
							{/*        <div className={"relative"}>*/}
							{/*            <Transition show={isFamilyTreeNameDropdownOpen}>*/}
							{/*                <div*/}
							{/*                    className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]*/}
							{/*                        origin-top-right rounded-md shadow-2xl */}
							{/*                        border border-gray-700 overflow-y-scroll*/}
							{/*                        data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 */}
							{/*                        data-[enter]:duration-100 data-[enter]:ease-out */}
							{/*                        data-[leave]:duration-75 data-[leave]:ease-in`}*/}
							{/*                >*/}
							{/*                    {*/}
							{/*                        familyTreeNames.map((familyTreeName, index) => (*/}
							{/*                            <button*/}
							{/*                                key={index}*/}
							{/*                                className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] gap-1*/}
							{/*                                px-[0.5vw] hover:bg-gray-300 flex items-center justify-between"*/}
							{/*                                onClick={() => handleFamilyTreeNameSelect(familyTreeName.value)}*/}
							{/*                            >*/}
							{/*                                <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
							{/*                                    {familyTreeName.label}*/}
							{/*                                </p>*/}
							{/*                            </button>*/}
							{/*                        ))*/}
							{/*                    }*/}
							{/*                </div>*/}
							{/*            </Transition>*/}
							{/*        </div>*/}
							{/*    </div>*/}
							{/*</div>*/}

							<div className={"flex-1 flex flex-col gap-2"}>
								<label className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
									   htmlFor="familyTreeName">
									{t("PROFILE_FORM.FAMILY_TREE_NAME.LABEL")}
								</label>

								<div className="relative w-full flex flex-col">
									<div
										className={`flex flex-row justify-between items-center 
                                            px-[0.5vw] gap-1 min-h-[5vh] max-h-[5vh] rounded-md border-2 
                                            ${isFamilyTreeNameDropdownOpen ? 'border-[#5F0F40]' : 'border-gray-300'}`}
									>
										<input
											id="familyTreeName"
											className="w-full h-full font-SOHNE_MEDIUM text-[1vw] leading-none outline-none"
											name="familyTreeName"
											value={profile.familyTreeName}
											onBlur={(e) => {
												setIsFamilyTreeNameDropdownOpen(false);
												translateToDevnagri(e);
											}}
											onClick={() => setIsFamilyTreeNameDropdownOpen(true)}
											onKeyUp={(e) => {
												if (e.key === "Escape") {
													setIsFamilyTreeNameDropdownOpen(false);
												}
											}}
											onChange={(e) => {
												if (e.target.value === "") {
													setFilteredFamilyTreeNames(familyTreeNames);
												} else {
													console.log("Family Tree Name Input Value: ", e.target.value);
													setFilteredFamilyTreeNames(
														familyTreeNames
															.filter((familyTreeName) => {
																return familyTreeName.toLowerCase().includes(e.target.value.toLowerCase());
															})
													);
												}

												setProfile({
													...profile,
													familyTreeName: e.target.value
												});

												setError({
													...error,
													[e.target.name]: ""
												});
											}}
										/>

										<ChevronDownIcon
											className={`h-[1vw] w-[1vw] cursor-pointer
                                                transition-all duration-150 ease-in-out
                                                ${isFamilyTreeNameDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
											aria-hidden="true"
											stroke="#292D32"
											strokeWidth={"0.1vh"}
											onClick={() => setIsFamilyTreeNameDropdownOpen(!isFamilyTreeNameDropdownOpen)}
										/>
									</div>

									{/* Family Tree Name Dropdown */}
									<div className={"relative"}>
										<Transition show={isFamilyTreeNameDropdownOpen}>
											<div
												className={`bg-white absolute left-0 right-0 z-[100] max-h-[15vh]
                                                    origin-top-right rounded-md shadow-2xl 
                                                    border border-gray-700 overflow-y-scroll
                                                    data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                                    data-[enter]:duration-100 data-[enter]:ease-out 
                                                    data-[leave]:duration-75 data-[leave]:ease-in`}
											>
												{
													filteredFamilyTreeNames.length > 0
														? filteredFamilyTreeNames.map((familyTreeName, index) => (
															<button
																key={index}
																className={`flex items-center text-left justify-between
                                                                    w-full min-h-[5vh] max-h-[5vh] gap-1
                                                                    px-[0.5vw] hover:bg-gray-300`}
																onClick={() => handleFamilyTreeNameSelect(familyTreeName)}
															>
																<p
																	className={`no-scrollbar text-nowrap w-full 
                                                                    font-SOHNE_REGULAR overflow-x-scroll text-[1vw]`}
																>
																	{familyTreeName}
																</p>
															</button>
														)) : (
															<button
																className={`w-full min-h-[5vh] max-h-[5vh]
                                                                    px-[0.5vw] hover:bg-gray-300
                                                                    flex items-center gap-1`}
																onClick={() => setIsFamilyTreeNameDropdownOpen(false)}
															>
																<p className={"text-left text-nowrap font-SOHNE_REGULAR text-[1vw]"}>
																	{profile.familyTreeName}
																</p>
															</button>
														)
												}
											</div>
										</Transition>
									</div>
								</div>

								{
									error['familyTreeName'] &&
									<span className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR"
										  id="error">
                                        {error['familyTreeName']}
                                    </span>
								}
							</div>
						</div>
					</fieldset>

					{/* Biography */}
					<fieldset>
						<div className="mb-[2vh] flex flex-col gap-2">
							<label
								className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
								htmlFor="bio"
							>
								{t("PROFILE_FORM.BIO.LABEL")}
							</label>
							<textarea
								id="description"
								rows={2}
								className="no-scrollbar h-[9vh] w-full rounded-md
                                px-[1vw] py-[1vh] text-[1vw] font-SOHNE_REGULAR
                                outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
								placeholder={t("PROFILE_FORM.BIO.PLACEHOLDER")}
								name='biography'
								value={profile.biography || ""}
								onChange={handleFormData}
							/>
						</div>
					</fieldset>

					{/* Need Assistance */}
					<fieldset>
						<label className="flex flex-row items-center gap-2">
							<input
								type="checkbox"
								name='needAssistance'
								checked={needAssistance}
								onChange={() => setNeedAssistance(!needAssistance)}
							/>
							<p className="font-SOHNE_REGULAR text-[1vw]">
								{t("PROFILE_FORM.NEED_ASSISTANCE.LABEL")}
							</p>
						</label>
					</fieldset>

					{/* Assistance Description */}
					<fieldset>
						<div className="mt-[2vh] flex flex-col gap-2">
                            <textarea
								disabled={!needAssistance}
								id="needAssistanceDescription"
								name='needAssistanceDescription'
								className="font-SOHNE_MEDIUM inline-flex rounded-md px-[1vw] py-[1vh] text-[1vw]
                                        leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
								placeholder={t("PROFILE_FORM.NEED_ASSISTANCE.DESCRIPTION_PLACEHOLDER")}
								value={needAssistanceDescription}
								onChange={(e) => setNeedAssistanceDescription(e.target.value)}
							/>
						</div>
					</fieldset>
				</div>

				{/* Footer */}
				<div className={"max-h-[10vh]"}>
					{/* Divider */}
					<div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full my-[1.5vh]"}/>

					<div className="flex justify-end">
						{/* Submit Button */}
						<button
							onClick={handleSubmit}
							className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
						>
							{t("PROFILE_FORM.SUBMIT")}
							<svg
								viewBox="0 0 6 6"
								fill="none"
								id={"arrow-icon"}
								className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M3 1L5 3L3 5"
									id={"arrow-icon-tip"}
									stroke="black"
									strokeLinecap="square"
								/>
								<path
									d="M1 3L4 3"
									id={"arrow-icon-line"}
									stroke="black"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
