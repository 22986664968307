import axios from 'axios';

import {BASE_URL} from '../utils/config';

const URL = BASE_URL;

export const addAccount = async (account) => {
    try {
        const response = await axios.post(`${URL}/account/signup`, account);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const addAccountAndNode = async (account) => {
    try {
        const response = await axios.post(`${URL}/account/add-account-and-node`, account);
        console.log("Add Invite Account Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getAccount = async (data) => {
    try {
        const response = await axios.get(`${URL}/account/login`, {params: data});
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error) {
        console.error(error);
    }
}

export const updateAccountById = async (id, updatedAccountDetails) => {
    try {
        const response = await axios.put(`${URL}/account/update/${id}`, updatedAccountDetails);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const deleteAccount = async (account) => {
    try {
        const response = await axios.delete(`${URL}/account/delete/${account.email}`, account);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getPincodeDetails = async (pincode, countryCode) => {
    try {
        const response = await axios.get(`${URL}/account/get-pincode-details`, {params: {pincode, countryCode}});
        console.log("(accountApi.js) Get Pincode Details Response: ", response);
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Get Pincode Details Error: ", error);
    }
}

export const getUsers = async (searchQuery) => {
    try {
        const response = await axios.get(`${URL}/account/search-users?query=${searchQuery}`);
        console.log("(accountApi.js) Get Users Response: ", response);
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Get Users Error: ", error);
    }
}

export const addProfession = async (professionDetails) => {
    try {
        const response = await axios.post(`${URL}/account/add-profession`, professionDetails);
        console.log("(accountApi.js) Add Profession Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Add Profession Error: ", error);
    }
}

export const addEducation = async (educationDetails) => {
    try {
        const response = await axios.post(`${URL}/account/add-education`, educationDetails);
        console.log("(accountApi.js) Add Education Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Add Education Error: ", error);
    }
}

export const addAward = async (awardDetails) => {
    try {
        const response = await axios.post(`${URL}/account/add-award`, awardDetails);
        console.log("(accountApi.js) Add Award Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Add Award Error: ", error);
    }
}

export const addHobby = async (hobbyDetails) => {
    try {
        const response = await axios.post(`${URL}/account/add-hobby`, hobbyDetails);
        console.log("(accountApi.js) Add Hobby Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Add Hobby Error: ", error);
    }
}

export const addLanguage = async (languageKnownDetails) => {
    try {
        const response = await axios.post(`${URL}/account/add-language`, languageKnownDetails);
        console.log("(accountApi.js) Add Language Response: ", response);
        if (response.status === 409) {
            return null;
        }
        return response.data;
    } catch (error) {
        console.log("(accountApi.js) Add Language Error: ", error);
    }
}

export const updateProfilePicture = async (updatePfpFormData) => {
    try {
        const response = await axios.post(`${URL}/account/update-pfp`, updatePfpFormData);
        console.log("(accountApi.js) Update Pfp Response: ", response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getUserById = async (id) => {
    try {
        const response = await axios.get(`${URL}/account/get-user-by-id/${id}`);
        console.log("(accountApi.js) Get User By Id Response: ", response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
