import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { chapters } from "../utils/ChaptersData"; 

export const Chapter = () => {
  const [open, setOpen] = useState(false);

  // Lock scrolling when the drawer is open
  if (open) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

  return (
    <>
      {/* Button to open the drawer */}
      <button
        onClick={() => setOpen(true)}
        className="font-SOHNE_REGULAR px-4 py-2"
      >
        Chapters
      </button>

      {/* Drawer */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-[9999]"
      >
        {/* Backdrop */}
        <DialogBackdrop
          className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out"
        />

        {/* Drawer Panel */}
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full md:w-[500px] ">
              <DialogPanel
                className={`pointer-events-auto w-screen max-w-md transform transition-transform duration-500 ease-in-out bg-white ${
                  open ? "translate-x-0" : "-translate-x-full"
                }`}
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-[#ffe6d1] py-6 shadow-xl">
                  {/* Header */}
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-lg font-semibold text-gray-900">
                        अध्याय/Chapters
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="rounded-md bg-[#ffe4cc] text-gray-500 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Content */}
                  <div className="relative mt-6 flex-1  px-4 sm:px-6">
                    <ul className="space-y-4">
                      {/* Loop through the chapters */}
                      {chapters.map((chapter, index) => (
                        <li
                          key={index}
                          className="border rounded-md p-3 w-[350px] bg-white hover:bg-gray-100 shadow-sm"
                        >
                          <Link
                            to={chapter.link}
                            className="block text-lg font-medium text-pink-800 "
                          >
                            {chapter.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
