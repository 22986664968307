import axios from "axios";
import {BASE_URL} from "../utils/config";

const URL = BASE_URL;//"http://localhost:3001";

export const generateOtp = async (verifier, verificationType, route) => {
	try {
		const {data} = await axios.post(
			`${URL}/api/otp/create`,
			{
				verifier,
				verificationType,
				route
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		return data;
	} catch (error) {
		console.log("(userOtpApi.js) Error in generateOtp: ", error.response);
		return Promise.reject(error.response);
	}
}

export const matchOtp = async (userId, otp, route) => {
	try {
		const {data} = await axios.post(
			`${URL}/api/otp/match`,
			{
				userId,
				otp,
				route
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		return data;
	} catch (error) {
		console.log("(userOtpApi.js) Error in matchOtp: ", error);
		return Promise.reject(error.response);
	}
}
