export const familyTreeNames = [
    'Adur-Palshet',
    'Indore-Dewas-Karjat',
    'Kotwade-Akola',
    'Kotwade-Agadi',
    'Kotwade-Kalyan',
    'Kotwade-Kiwale',
    'Kotwade-Gokarna-Mahabaleshwar',
    'Kotwade-Pune-Kala Houd',
    'Kotwade-Pune',
    'Kotwade-Bhiwandi (Dixit)',
    'Kotwade-Mirjole',
    'Kotwade-Mithgawane-Mumbai',
    'Kotwade-Mumbai',
    'Kotwade-Ratnagiri',
    'Kotwade-Reel-Kespuri',
    'Kotwade-Varwade-Reel',
    'Kotwade-Vathar-Kiroli',
    'Kotwade-Bijapur-Kirloskarwadi',
    'Kotwade-Bijapur-Pune',
    'Kotwade-Sakharpa',
    'Kotwade-Hangal',
    'Kotwade-Hubli-Pune',
    'Dhokamale-Awas',
    'Dhokamale-Kotwade',
    'Dhokamale-Jamkhandi',
    'Dhokamale-Nagpur',
    'Dhokamale-dancing',
    'Dhokamale-Nevere-Mumbai',
    'Dhokamale-Pune',
    'Dhokamale-Baroda',
    'Dhokamale-Mumbai',
    'Dhokamale-Warawade-Mumbai',
    'Narvan-Ahmedabad',
    'Narvan-Trimbakeshwar',
    'Narvan-Neral',
    'Narvan-Parita',
    'Narvan-Pune (Nene-Ghat)',
    'Nevere-Dhokamale-Mumbai',
    'Satara-Chaul',
    'Hedavi-Agashi',
    'Hedavi-Aayni-Mete',
    'Hedavi-Avlas-Ghadashi',
    'Hedavi-Indore',
    'Hedavi-Karchunde',
    'Hedavi-Kalyan',
    'Hedavi-Kalki',
    'Hedavi-Kase-Burambad',
    'Hedavi-Ambernath',
    'Hedavi-Gajrath-Navi Mumbai',
    'Hedavi-Chafal-Mumbai',
    'Hedavi-Jamkhandi',
    'Hedavi-Jambulpada',
    'Hedavi-Jalgaon',
    'Hedavi-Junnar',
    'Hedavi-Thane',
    'Hedavi-Tasgaon',
    'Hedavi-Third-Mahajan',
    'Hedavi-Thal-Bhiwandi',
    'Hedavi-Dapoli-Akshi Nagaon',
    'Hedavi-Second Mahajan',
    'Hedavi-Jabalpur-Nagpur',
    'Hedavi-Nagpur',
    'Hedavi-Nagaon',
    'Hedavi-Nagaon-Badhwai (M.P.)',
    'Hedavi-Nagaon-Mumbai',
    'Hedavi-Nashik',
    'Hedavi-Pune',
    'Hedavi-Pune-Cuttack',
    'Hedavi-Poud-Mulshi',
    'Hedavi-Barode 555-561',
    'Hedavi-Banaras-Mumbai',
    'Hedavi-Bhilawadi',
    'Hedavi-Bhiwandi (Dixit)',
    'Destination-Mumbai',
    'Hedavi-Mokadam',
    'Hedavi-Maisal',
    'Hedavi-Reel-Mangaon',
    'Hedavi-Wardha',
    'Hedavi-Warsaw-Mumbai',
    'Hedavi-Bijapur-Buldhana',
    'Hedavi-Bijapur-Pane',
    'Hedavi-Vita 620-631',
    'Hedavi-Sugar-Agar',
    'Hedavi-Supe-Baramati',
    'Palshet',
    'Bilaspur',
    'Miraj',
    'Mumbai',
    'Roha-Mumbai'
]