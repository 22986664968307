const detailcard = [
	{
		id: 1,
		title: "जोगळेकर कुलवृक्ष",
		para1: "रत्नागिरी जिल्ह्यातील हेदवी या मूळगावापासून सुरू झालेला जोगळेकरांचा प्रवास अनेक वळणे घेत दूरदेशापर्यंत पसरलेला आहे. या अनेक पिढ्यांचा अभ्यास, यातून उलगडत जाणारे रहस्य, निर्माण होणारे कुतूहल आणि मिळणारा आत्मिक आनंद म्हणजेच 'जोगळेकर कुलवृत्तांत' अर्थात 'जोगळेकर कुलवृक्ष'",
		para2: "",

		url: "https://www.google.com/",
	},
	{
		id: 2,
		title: "शोध हा कोणाचा?",
		para1: "मी कोण ? माझे घराणे कुठले ? माझे पूर्वज, त्यांचा विस्तार, त्यांच्यामध्ये होत गेलेली प्रगती, यशाची त्यांनी गाठलेली उत्तुंग शिखरे याची माहिती घेऊन माझा ऊर नक्कीच अभिमानाने भरून येईल. यासाठी शोध सग्यासोयरांचा अर्थात त्यासाठी योग्य ठिकाण 'जोगळेकर कुलवृक्ष'",
		para2: "",

		url: "https://www.google.com/",
	},
	{
		id: 3,
		title: "विविध माहिती",
		para1: "जोगळेकर आडनावाची व्यक्ती जवळपास प्रत्येक क्षेत्रात महत्त्वाची भूमिका बजावताना दिसते. आपल्याला त्यांचीएकत्रितरित्या माहिती संकलन करणे आवश्यक आहे. त्यासाठी आपली आणि आपल्या कुटुंबीयांची सविस्तर माहिती उपलब्ध करून दिल्यास त्याचा सर्वांना उपयोग होईल. आपणच एकमेकांचे दिशादर्शक, मार्गदर्शक होऊ या! चला, जोगळेकर कुलवृत्तान्त समजू या ! समृद्ध करूया!",
		para2: "",

		url: "https://www.google.com/",
	},
];
export default detailcard;
