import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import { Header } from "../components/Header";

export const Chapter15 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					जोगळेकर - कुलनाम
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> ‘जोगळेकर‌’</span> हे
						कुलनाम, अत्रिगोत्री, कोकणस्थ चित्पावन ब्राह्मणकुलाचे
						नामनिर्देशक आहे. ‘जोगळेकर‌’ कुल हा चित्पावन शाखेतील
						महत्त्वाचा घटक आहे.
					</p>
					<p className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
						<span className="pl-6 sm:pl-8">प्रथमदर्शनी</span>{" "}
						‘जोगळेकर‌’ आडनाव / कुलनाम / उपनाव मूळ गावावरून पडले
						असावे असे वाटते. जोगळेकर उपनाव केव्हा पडले व कसे पडले
						याचा इतिहास उपलब्ध नाही. जोगळेकरांची मूळ वसतिस्थाने व
						त्यांची ग्रामनामे शोधल्यास ती सर्व बहतांशी रत्नागिरी
						जिल्ह्यातील आहेत. पण त्यात गकही ‘जोगळे‌’ असे नाही. तरीपण
						दापोली तालुक्यात ‘जोगेळे‌’ नावाचे गाव आहे. जोगळेकर हे
						उपनाव ‘जोगेळे‌’ या गावावरून तर पडले नाही ना ? पण
						इतिहासात तसा लेखी उल्लेख सापडत नाही. या ‘जोगेळे‌’ गावांत
						कुणी जोगळेकर वसतीला आहे का? शोध घ्यायला हवा. याशिवाय
						‘जोगेळे‌’ ह्या गावासारखी अनेक गावे आहेत. त्यापैकी
						‘जोग्याळ‌’, जोगापूर, जोगसारवरा, जोगतळा, जोगलखेडा,
						जोगळादेवी‌’, अशी अनेक ग्रामनामे आहेत.{" "}
					</p>
					<p className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
						<span className="pl-6 sm:pl-8"> गेतिहासिक</span> उल्लेख
						: पेशवाईच्या सुमारास त्र्यंबकेश्वर येथील दस्तगेवजामध्ये
						‘जोगळेकर‌’ कुलनाम व मूळ गाव ‘हेदवी‌’ असा आढळतो.
						‘जोगळेकर‌’ या उपनावाच्या व्युत्पत्तीविषयी खालील गेकीव
						माहिती उपलब्ध आहे. कर्नाटकात गिरसप्पा धबधब्याजवळ ‘जोग‌’
						नामक गक खेडे आहे. कानडी भाषेत खेड्याला ‘हळळे‌’ असा शब्द
						आहे. मराठी राज्य विस्तारात तेथपर्यंत लोक गेले होते असा
						इतिहास आहे. तेथून लोक परतल्यावर ‘जोगहळळेकर‌’ असे म्हणता
						म्हणता जोगळेकर नाव निर्माण झाले असावे असे इन्दूरचे
						कै.पी.जी.जोगळेकर यांचे मत होते. कर्नाटकात चिकमंगळूर
						विभागात गक जोगळेकर कुटुंब ‘जुगुळु‌’ या गावचे रहिवाशी.
						त्याच्या म्हणण्यानुसार जोगळेकर नाव ‘जुगुळु‌’ वरून पडले
						असावे. महाराष्ट्रातून कर्नाटकात गेलेली तसेच कर्नाटकातून
						महाराष्ट्रात आलेली बरीच जोगळेकर कुटुंबीय आहेत असे दिसते.
						गकंदरीत पहाता ‘जोगळेकर‌’ ह्या कुलनामाबद्दल अजूनही
						संशोधनाची जरुरी आहे.
					</p>
					<p className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
						<span className="pl-6 sm:pl-8">१८५५</span> साली प्रसिद्ध
						झालेल्या चित्पावनांच्या १४ गोत्रे व ६० उपनावांच्या यादीत
						‘जोगळेकर‌’ नामाचा उल्लेख नाही; पण अलीकडील पंचांगातील
						‘अत्रि‌’ गोत्रिय चित्पावनाच्या यादीत ‘जोगळेकर‌’
						आडनाव/उपनावाचा उल्लेख आहे.
					</p>
					<p className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
						<span className="pl-6 sm:pl-8">‘जोगळ‌’</span> नावाचे गक
						छोटेसे बेट ढोकंबळ्याजवळ होते. ते हळू हळू समुद्रात बुडू
						लागल्याचे लक्षात आल्यावर त्यांनी ढोकंबळ्यात पहिले
						स्थलांतर केले. जोगळ बेटावरून आल्यामुळे त्यांना जोगळेकर
						नावाने संबोधले गेले व तेच नाव कायम झाले.
					</p>

					<div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>

					<hr />
				</div>
			</div>
		</div>
	);
};
