import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";


export const Chapter11 = () => {
    // Scroll to Top function
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
        }; 
  return (
    <div>
      <Header />
      <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
        <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
          अत्रिकुलाचे कुलगुरु - अत्रिऋषी
        </h1>
        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">जोगळेकर</span> कुल ज्या कुलगुरूमुळे
            अत्रिगोत्रीय म्हटले जाते त्या अत्रिऋषीसंबंधी माहिती अशी :
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">प्रजापती</span> व वाक्‌देवी यांचा
            अत्रि हा पुत्र. आईवडिलांप्रमाणेच तेजस्वी, प्रभावी, ज्ञानी असा हा
            बालक मोठा होऊन गक अग्रगण्य ऋषी बनला. सप्तर्षीपैकी गक महत्त्वाचे ऋषी
            म्हणून त्यांची महती आहे. त्यांच्या काळातील गवढा सूज्ञ द्रष्टा,
            बुद्धिमत्तेला प्राधान्य देणारा ऋषी क्वचितच आढळेल असे मानतात.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">अत्रि </span>म्हणजे अ = नाही, त्रि :
            म्हणजे सत्व, रज, तम हे त्रिगुण ज्यांचे ठिकाणी हे त्रिगुण नाहीत, जे
            त्रिगुणातीत आहेत ते अत्रि. अत्रि ऋषींनी फार प्रचंड कार्य करून आपल्या
            मातेची इच्छा पूर्ण केली. सर्व-सामान्यांतील पशुवृत्ती, भोगवृत्ती कमी
            करून अत्रिऋषींनी ज्ञान उत्क्रांत केले. अत्रिऋषींनी गूढ भाषेतील ज्ञान
            सोप्या, सरळ व मधुर भाषेत समजावून सांगून लोकांना मानव म्हणून जगण्यास
            शिकविले. अत्रिऋषींनी अत्रिसूत्र अत्रिसंहिता व अत्रिस्मृती असे ग्रंथ
            रचिले व पर्जन्यसूक्त गाइले. त्यांनी तेजस्वी लोकांची,
            तत्त्ववेत्यांची, कवीजनांची, निडर, नि:स्वाथी व समर्थ संस्था निर्माण
            केली. अत्रिऋषींकडे सर्व थरातील लोक जात. अज्ञानी, पापी सर्वांनाच ते
            संस्कारी करीत. भक्तियोग, कर्मयोग व ज्ञानयोग अंगिकारत. प्रत्येक
            पातकाला प्रायश्चित्त आहे अशी विचारधारा असणारे अत्रि हे पहिले ऋषी
            होत. अत्रींचा पट्टशिष्य आर्य नानस यांनी अिंत्रच्या विचारांना
            प्रसिद्धी दिली. अिंत्रच्या गुणामुळेच ‘अत्रि-जगमैत्री‌’ हे समीकरण रूढ
            झाले असावे. अत्रिऋषी विनंती िंकवा प्रार्थना करीत नसत, ते प्रत्येक
            विचार कृतीत उतरवत. अत्रिऋषींनी खऱ्या अर्थाने ‘लोकसत्ता‌’ प्रत्यक्षात
            आणली व सदैव लोकसत्तेचा आग्रह धरला. जगांत लोकशाहीचा आवज उठविणारे व
            अपात्र राजाला राजा न मानण्याचे धाडस दाखविणारे, अत्रिऋषी हे पहिले
            ऋषी. सूर्यग्रहणाचे खरे स्वरूप उघड करून सांगणारे अत्रि हे संशोधक ऋषी
            होत.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8"> हाक</span> मारताच ज्यांच्यापुढे श्री
            विष्णू उभे ठाकत अशा कर्दम ऋषींची द्वितीय कन्या अनुसूया हिजबरोबर
            अत्रींचा विवाह झाला होता. त्यांना कर्तृत्त्ववान दत्तात्रय, तपोनिष्ठ
            दुर्वास, प्रजापतीरूप सोम व देवस्वरूप आर्यवान असे चार पुत्र व अमला ही
            कन्या अशी मुले झाली. अशा ह्या अत्रिऋषींच्या अत्रिकुलातील आपण सर्व
            जोगळेकर म्हणून अत्रिगोत्रीय असा आपला निर्देश होत आहे. चित्पावनांची
            जी चौदा गोत्रे आहेत, त्यांच्या कुलगुरूंना अत्रिऋषींनी गकत्र आणून
            सर्व विद्वानांना गकत्रितपणे विचारविनिमय करण्यास प्रवृत्त केले असले
            पाहिजे.
          </p>
        </div>
        <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
        <hr/>
      </div>
    </div>
  );
};
