import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import { ReaderIcon } from "@radix-ui/react-icons";
import { t } from "i18next";

export const FlyoutMenu = () => {
  const optionsData = [
    {
      title: t("HEADER.FLYOUT_MENU.GALLERY.TITLE"),
      description: t("HEADER.FLYOUT_MENU.GALLERY.DESCRIPTION"),
      icon: (
        <ChartPieIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.DHARMIC_VIDHI.TITLE"),
      description: t("HEADER.FLYOUT_MENU.DHARMIC_VIDHI.DESCRIPTION"),
      icon: (
        <ArrowPathIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.DEV_DARSHAN.TITLE"),
      description: t("HEADER.FLYOUT_MENU.DEV_DARSHAN.DESCRIPTION"),
      icon: (
        <TvIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.EVENTS.TITLE"),
      description: t("HEADER.FLYOUT_MENU.EVENTS.DESCRIPTION"),
      icon: (
        <CalendarDaysIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.GEN_AI.TITLE"),
      icon: (
        <CursorArrowRaysIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.JOGLEKAR_KULBHUSHAN.TITLE"),
      icon: (
        <FingerPrintIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.JOGLEKAR_YELLOW_PAGE.TITLE"),
      description: t("HEADER.FLYOUT_MENU.JOGLEKAR_YELLOW_PAGE.DESCRIPTION"),
      icon: (
        <SquaresPlusIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      title: t("HEADER.FLYOUT_MENU.JOGLEKAR_ANURUP.TITLE"),
      icon: (
        <ArrowPathIcon
          className="h-full w-auto aspect-square text-gray-600 group-hover:text-white"
          aria-hidden="true"
        />
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <Popover className="relative">
      {({}) => {
        // To prevent the body from scrolling when the popover is open
        document.body.classList.toggle("overflow-y-hidden", open);

        return (
          <>
            <button
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className={`inline-flex items-center gap-x-1 outline-none
                                    py-[1vh] text-[1vw] font-SOHNE_MEDIUM leading-6 text-gray-900`}
            >
              <span>{t("HEADER.FLYOUT_MENU.TITLE")}</span>
              <ChevronDownIcon
                className={`h-[1.25vw] w-[1.25vw] ${
                  hover || open ? "rotate-180" : "rotate-0"
                } transition-all duration-150 ease-in-out`}
                aria-hidden="true"
              />
            </button>

            <Transition
              show={open || hover}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                className="absolute left-1/2 z-10 mt-[1vh] flex w-screen max-w-max -translate-x-1/2 rounded-3xl"
              >
                <div
                  className={`bg-white w-fit min-w-[25vw] max-w-[25vw] 
                                            xl:min-w-[25vw] xl:max-w-[27.5vw] 
                                            flex-auto overflow-hidden shadow-lg  
                                            text-[0.75vw] leading-6 
                                            rounded-3xl xl:rounded-[2rem] 
                                            ring-2 ring-orange-300/5`}
                >
                  {/* Menu Options List*/}
                  <div className="h-[40vh] no-scrollbar overflow-y-auto p-[0.5vw]">
                    {optionsData.map((option, index) => (
                      <div
                        key={index}
                        className="group relative flex gap-x-4 p-[1.5vh]
                                                        hover:bg-gray-200 rounded-2xl xl:rounded-3xl
                                                        transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]"
                      >
                        <div
                          className={`flex flex-none items-center justify-center 
                                                                h-[6vh] w-auto aspect-square bg-gray-50 p-[1.5vh]
                                                                rounded-xl xl:rounded-2xl group-hover:bg-orange-400 
                                                                transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]`}
                        >
                          {option.icon}
                        </div>
                        <div className={"flex flex-col"}>
                          <a
                            href="#"
                            className="font-SOHNE_MEDIUM leading-normal text-[1vw] text-gray-900"
                          >
                            {option.title}
                            <span className="absolute inset-0" />
                          </a>
                          {option.description && (
                            <p className="font-SOHNE_REGULAR leading-normal text-[0.75vw] text-gray-600">
                              {option.description}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Footer Buttons */}
                  <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    <a
                      key="Watch demo"
                      href="#"
                      className="group flex items-center justify-center gap-x-2.5
                                                py-[1.5vh] px-[1vw] xl:px-[1vw] xl:py-[2vh]
                                                font-SOHNE_MEDIUM text-gray-900 text-[1vw]
                                                hover:bg-orange-400 hover:text-white
                                                transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]"
                    >
                      <PlayCircleIcon
                        className="h-[2vh] w-auto flex-none text-gray-400
                                                        group-hover:text-white"
                        aria-hidden="true"
                      />
                      {t("HEADER.FLYOUT_MENU.ACTION_BUTTON_1")}
                    </a>
                    <a
                      key="Read-Instruction"
                      href="#"
                      className="group flex items-center justify-center gap-x-2.5
                                                py-[1.5vh] px-[1vw] xl:px-[1vw] xl:py-[2vh]
                                                font-SOHNE_MEDIUM text-gray-900 text-[1vw]
                                                hover:bg-orange-400 hover:text-white
                                                transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]"
                    >
                      <ReaderIcon
                        className="h-[2vh] w-auto flex-none text-gray-400
                                                        group-hover:text-white"
                        aria-hidden="true"
                      />
                      {t("HEADER.FLYOUT_MENU.ACTION_BUTTON_2")}
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
