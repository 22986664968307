import {Link} from "react-router-dom";

const DetailCard = ({info}) => {
	return (
		<Link to={""}>
			<div
				className="bg-white shadow-2xl rounded-xl my-10 w-[90%] max-w-sm border-2 border-pink-800 mx-auto p-6 relative  ">
				{/* Title */}
				<h1 className="text-center text-xl text-pink-800 font-semibold mb-4">
					{info.title}
				</h1>

				{/* Paragraphs */}
				<p className="text-justify text-gray-700 mb-2 ">
					{info.para1}
				</p>
				<p className=" text-justify  text-gray-700">
					{info.para2}
				</p>

				{/* 'More' Link */}
				<span className="text-red-600 flex justify-end mr-4 mt-4 font-semibold text-sm">
                    {info.end}
                </span>
			</div>
		</Link>
	);
};

export default DetailCard;
