import {Transition, TransitionChild} from "@headlessui/react";

export const Modal = ({children, isOpen, zIndex = "z-[100]"}) => {
    return (
        <Transition show={isOpen}>
            <div
                className={`min-h-[100vh] max-h-[100vh] w-screen flex items-center justify-center 
                    fixed inset-0 ${zIndex}
                    overflow-y-scroll no-scrollbar bg-black/75 backdrop-blur-sm
                    data-[closed]:opacity-0
                    data-[enter]:duration-75 data-[enter]:data-[closed]:ease-out
                    data-[leave]:duration-300 data-[leave]:data-[closed]:ease-out`}
            >
                <TransitionChild>
                    <div
                        className="h-fit max-h-[80vh] min-w-[40vw] max-w-[40vw]
                            transform z-50 rounded-xl flex items-center justify-center
                            data-[enter]:duration-100 data-[enter]:data-[closed]:scale-75
                            data-[leave]:duration-300 data-[leave]:data-[closed]:scale-95"
                    >
                        {children}
                    </div>
                </TransitionChild>
            </div>
        </Transition>
    )
}