import React, {useState} from 'react';
import {BaseEdge, EdgeLabelRenderer, getSmoothStepPath, useReactFlow} from 'reactflow';
import {deleteRelation} from '../../service/relationApi';
import {t} from "i18next";
import {relationHandle} from "../../utils/relation";

const CustomEdgeLabel = ({id, label, labelBg, transform}) => {
	return (
		<EdgeLabelRenderer>
			<div
				style={{
					position: "absolute",
					transform: transform,
					backgroundColor: labelBg
				}}
				className={`px-[0.75vw] py-[0.75vh] rounded-full`}
			>
				<p className={"text-[0.75vw] font-SOHNE_MONO_MEDIUM"}>
					{t(`CONSTANTS.RELATIONSHIP.${label}`)}
				</p>
			</div>
		</EdgeLabelRenderer>
	);
}

const RelationEdge = ({id, data, markerEnd, label, style, ...props}) => {
	const instance = useReactFlow();
	const [edgePath, labelX, labelY] = getSmoothStepPath(props);
	const [relationshipType, setRelationshipType] = useState(data.relationshipType);

	const deleteEdge = (evt) => {
		evt.stopPropagation();
		instance.setEdges((edges) => edges.filter((ed) => ed.id !== id));
	};

	const deleteEdgeFromDB = (evt) => {
		evt.stopPropagation();
		console.log("id: ", id);
		const reversedId = id.split('-').reverse().join('-');
		console.log("reversedId: ", reversedId);
		deleteRelation(id).then((res) => {
			if (res.status === 200) {
				deleteRelation(reversedId);
				instance.setEdges((edges) => edges.filter((ed) => (ed.id !== id && ed.id !== reversedId)));
			} else {
				console.log("error");

			}
		});
	};

	const getLabelTransformPosition = () => {
		if (props.sourceHandleId === "a") {
			return `translate(-50%, -175%) translate(${props.sourceX}px, ${props.sourceY}px)`;
		}

		if (props.sourceHandleId === "b") {
			return `translate(-125%, -50%) translate(${props.sourceX}px, ${props.sourceY}px)`;
		}

		if (props.sourceHandleId === "c") {
			return `translate(25%, -50%) translate(${props.sourceX}px, ${props.sourceY}px)`;
		}

		if (props.sourceHandleId === "d") {
			return `translate(-50%, 75%) translate(${props.sourceX}px, ${props.sourceY}px)`;
		}
	}

	return (
		<>
			<BaseEdge
				id={id}
				path={edgePath}
				markerEnd={markerEnd}
				style={style}
				interactionWidth={20}
			/>

			<CustomEdgeLabel
				label={relationshipType}
				labelBg={relationHandle[relationshipType].color}
				id={id}
				transform={getLabelTransformPosition()}
			/>

			<foreignObject
				x={labelX}
				y={labelY - 7}
				className='group w-[1vw] h-[1vw]'

			>
				<button
					className="bg-green-100 border-gray-300 text-gray-900 rounded-full hover:bg-red-400 block w-full p-1"
					onClick={(event) => relationshipType ? deleteEdgeFromDB(event) : deleteEdge(event)}
				>
					<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
						<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
							  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
					</svg>

				</button>
			</foreignObject>
		</>
	);
};

export default RelationEdge;
