import React, {useEffect, useState,} from "react";
import {useReactFlow} from "reactflow";
import {relationHandle} from "../../utils/relation";
import {t} from "i18next";
import {Modal} from "../../components/Modal";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Transition} from "@headlessui/react";
import {addRelation} from "../../service/relationApi";
import {toast} from "react-toastify";

export const AddEdgeForm = (props) => {
	const {showAddEdgeForm, setShowAddEdgeForm, currentEdge} = props;
	const instance = useReactFlow();

	const relationshipTypes = [
		{
			label: t("CONSTANTS.RELATIONSHIP.father"),
			value: "father"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.mother"),
			value: "mother"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.brother"),
			value: "brother"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.sister"),
			value: "sister"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.son"),
			value: "son"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.daughter"),
			value: "daughter"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.husband"),
			value: "husband"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.wife"),
			value: "wife"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.father-in-law"),
			value: "father-in-law"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.mother-in-law"),
			value: "mother-in-law"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.son-in-law"),
			value: "son-in-law"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.daughter-in-law"),
			value: "daughter-in-law"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.brother-in-law"),
			value: "brother-in-law"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.sister-in-law"),
			value: "sister-in-law"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.adopted-son"),
			value: "adopted-son"
		},
		{
			label: t("CONSTANTS.RELATIONSHIP.adopted-daughter"),
			value: "adopted-daughter"
		}
	];

	const [isPersonARelationDropdownOpen, setIsPersonARelationDropdownOpen] = useState(false);
	const [isPersonBRelationDropdownOpen, setIsPersonBRelationDropdownOpen] = useState(false);

	const [relationshipDetails, setRelationshipDetails] = useState({
		personARelation: {
			label: "",
			value: ""
		},
		personBRelation: {
			label: "",
			value: ""
		},
	});

	const [error, setError] = useState({});

	const [isCreateRelationButtonDisabled, setIsCreateRelationButtonDisabled] = useState(true);

	const handlePersonARelationshipSelect = (relationType) => {
		setIsPersonARelationDropdownOpen(false);
		setRelationshipDetails({
			...relationshipDetails,
			personARelation: relationType
		});
	}

	const handlePersonBRelationshipSelect = (relationType) => {
		setIsPersonBRelationDropdownOpen(false);
		setRelationshipDetails({
			...relationshipDetails,
			personBRelation: relationType
		});
	}

	useEffect(() => {
		if (relationshipDetails.personARelation.value && relationshipDetails.personBRelation.value) {
			setIsCreateRelationButtonDisabled(false);
		}
	}, [relationshipDetails])

	const handleSubmit = () => {
		setIsCreateRelationButtonDisabled(true);

		const edge = currentEdge;
		const relationA = {
			id: `${edge.params.source}-${edge.params.target}`,
			source: edge.params.source,
			target: edge.params.target,
			data: {
				relationshipType: relationshipDetails.personARelation.value,
				createdAt: new Date(),
				updatedAt: new Date()
			},
			sourceHandle: relationHandle[relationshipDetails.personARelation.value].sourceHandle,
			targetHandle: relationHandle[relationshipDetails.personARelation.value].targetHandle,
			type: 'relation',
			markerEnd: {type: 'arrow', color: 'green', width: 20, height: 20},
			label: relationshipDetails.personARelation.label,
		}
		const relationB = {
			id: `${edge.params.target}-${edge.params.source}`,
			source: edge.params.target,
			target: edge.params.source,
			data: {
				relationshipType: relationshipDetails.personBRelation.value,
				createdAt: new Date(),
				updatedAt: new Date()
			},
			sourceHandle: relationHandle[relationshipDetails.personBRelation.value].sourceHandle,
			targetHandle: relationHandle[relationshipDetails.personBRelation.value].targetHandle,
			type: 'relation',
			markerEnd: {type: 'arrow', color: 'green', width: 20, height: 20},
			label: relationshipDetails.personBRelation.label,
		}

		if ((relationshipDetails.personARelation.value === "brother" && relationshipDetails.personBRelation.value === "brother") || (relationshipDetails.personARelation.value === "sister" && relationshipDetails.personBRelation.value === "sister")) {
			console.log("Brothers || Sisters");
			relationB.sourceHandle = relationA.targetHandle;
			relationB.targetHandle = relationA.sourceHandle;
		}

		console.log("Relation A: ", relationA);
		console.log("Relation B: ", relationB);

		addRelation(relationA)
			.then((res) => {
				console.log('Add personARelation Result: ', res);
				if (res) {
					addRelation(relationB)
						.then((res2) => {
							console.log('Add personBRelation Result: ', res2);
							if (res2) {
								instance.setEdges((edges) => ([
									...edges,
									relationA,
									relationB
								]));
								toast.success("Relation created successfully!");
								setRelationshipDetails({
									personARelation: {
										label: "",
										value: ""
									},
									personBRelation: {
										label: "",
										value: ""
									},
								});
								setIsCreateRelationButtonDisabled(true);
								setShowAddEdgeForm(false);
							}
						})
						.catch((error) => {
							console.log("Error while adding personBRelation: ", error);
							toast.error("An error occurred while adding the relation. Please contact administrator!");
						});
				}
			})
			.catch((error) => {
				console.log("Error while adding personARelation: ", error);
				toast.error("An error occurred while adding the relation. Please contact administrator!");
			});
	}

	useEffect(() => {
		return () => {
			setRelationshipDetails({
				personARelation: {
					label: "",
					value: ""
				},
				personBRelation: {
					label: "",
					value: ""
				},
			});
		}
	}, []);


	return (
		<Modal isOpen={showAddEdgeForm}>
			<div
				className="flex flex-col justify-between
                    max-h-[50vh] min-w-[27.5vw] rounded-xl
                    px-[1.25vw] py-[2vh] bg-white ">
				{/* Header */}
				<div className={"mb-[2vh] max-h-[10vh]"}>
					<div className={"flex flex-row items-center justify-between"}>
						{/* Form Title */}
						<h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
							{t("ADD_EDGE_FORM.TITLE")}
						</h2>

						<button
							onClick={() => {
								setRelationshipDetails({
									personARelation: {
										label: "",
										value: ""
									},
									personBRelation: {
										label: "",
										value: ""
									},
								});
								setShowAddEdgeForm(false);
							}}
							type="button"
							className="flex items-center justify-center
                            rounded-md w-[1.5vw] h-[1.5vw] bg-[#D4D4D4]
                            hover:bg-[#F7F7F7] hover:ring hover:ring-[#D4D4D4]
                            transition duration-200 ease-out
                            text-sm text-gray-400 hover:text-gray-900"
						>
							<img
								className={"w-[75%] h-[75%]"}
								src={"assets/close-icon.png"}
								alt={"Close Icon"}
							/>
						</button>
					</div>

					{/* Divider */}
					<div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
				</div>

				{/* Relations Menu */}
				<div className="mb-[2vh] w-full flex gap-x-[1vw]">
					{/* Person A Relation */}
					<div className={`flex-1 mb-[2vh] flex flex-col gap-2`}>
						<label
							className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
							htmlFor="personARelation"
						>
							{t("ADD_EDGE_FORM.RELATION.PERSON_A_RELATION.LABEL", {
								firstName: currentEdge?.sourceData?.data.firstName
							})}
						</label>
						<div className="w-full flex flex-col">
							<button
								onBlur={() => setIsPersonARelationDropdownOpen(false)}
								className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
								onClick={() => setIsPersonARelationDropdownOpen(!isPersonARelationDropdownOpen)}
							>
								<p className={"font-SOHNE_REGULAR text-[1vw]"}>
									{
										relationshipDetails.personARelation.label
											? relationshipDetails.personARelation.label
											: t("ADD_EDGE_FORM.RELATION.PERSON_A_RELATION.PLACEHOLDER")
									}
								</p>
								<ChevronDownIcon
									className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isPersonARelationDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
									aria-hidden="true"
									stroke="#292D32"
									strokeWidth={"0.1vh"}
								/>
							</button>

							{/* Relationship Types Dropdown */}
							<div className={"relative"}>
								<Transition show={isPersonARelationDropdownOpen}>
									<div
										className={`bg-white absolute left-0 right-0 max-h-[25vh]
                                            origin-top-right rounded-md shadow-2xl mt-[0.5vh]
                                            border border-gray-700 overflow-y-scroll
                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                            data-[leave]:duration-75 data-[leave]:ease-in`}
									>
										{
											relationshipTypes.map((relation, index) => (
												<button
													key={index}
													className="w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handlePersonARelationshipSelect(relation)}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{relation.label}
													</p>
												</button>
											))
										}
									</div>
								</Transition>
							</div>

							{
								error['personARelation'] &&
								<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
									{error['personARelation']}
								</p>
							}
						</div>
					</div>

					{/* Person B Relation */}
					<div className={`flex-1 mb-[2vh] flex flex-col gap-2`}>
						<label
							className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
							htmlFor="personBRelation"
						>
							{t("ADD_EDGE_FORM.RELATION.PERSON_B_RELATION.LABEL", {
								firstName: currentEdge?.targetData?.data.firstName
							})}
						</label>
						<div className="w-full flex flex-col">
							<button
								onBlur={() => setIsPersonBRelationDropdownOpen(false)}
								className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
								onClick={() => setIsPersonBRelationDropdownOpen(!isPersonBRelationDropdownOpen)}
							>
								<p className={"font-SOHNE_REGULAR text-[1vw]"}>
									{
										relationshipDetails.personBRelation.label
											? relationshipDetails.personBRelation.label
											: t("ADD_EDGE_FORM.RELATION.PERSON_B_RELATION.PLACEHOLDER")
									}
								</p>
								<ChevronDownIcon
									className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isPersonBRelationDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
									aria-hidden="true"
									stroke="#292D32"
									strokeWidth={"0.1vh"}
								/>
							</button>

							{/* Relationship Types Dropdown */}
							<div className={"relative"}>
								<Transition show={isPersonBRelationDropdownOpen}>
									<div
										className={`w-[12vw] bg-white absolute left-0 right-0 max-h-[25vh]
                                            origin-top-right rounded-md shadow-2xl mt-[0.5vh]
                                            border border-gray-700 overflow-y-scroll
                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                            data-[leave]:duration-75 data-[leave]:ease-in`}
									>
										{
											relationshipTypes.map((relation, index) => (
												<button
													key={index}
													className="w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
													onClick={() => handlePersonBRelationshipSelect(relation)}
												>
													<p className={"font-SOHNE_REGULAR text-[1vw]"}>
														{relation.label}
													</p>
												</button>
											))
										}
									</div>
								</Transition>
							</div>

							{
								error['personBRelation'] &&
								<p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
									{error['personBRelation']}
								</p>
							}
						</div>
					</div>
				</div>

				{/* Create Relation Button */}
				<button
					disabled={isCreateRelationButtonDisabled}
					type="submit"
					onClick={handleSubmit}
					className={`${isCreateRelationButtonDisabled ? '' : 'arrow-button'}
						inline-flex items-center justify-center rounded-full
						pl-[1.25vw] pr-[0.75vw] py-[1vh] font-SOHNE_MEDIUM 
						bg-green-400 hover:bg-green-500 
						hover:ring-green-300 hover:ring-4 hover:ring-offset-1
						disabled:cursor-not-allowed disabled:bg-slate-400/50
						disabled:ring-0 disabled:ring-offset-0 
						transition-all duration-300 ease-in-out`}
				>
					{t("ADD_EDGE_FORM.CREATE")}
					<svg
						viewBox="0 0 6 6"
						fill="none"
						id={"arrow-icon"}
						className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3 1L5 3L3 5"
							id={"arrow-icon-tip"}
							stroke="black"
							strokeLinecap="square"
						/>
						<path
							d="M1 3L4 3"
							id={"arrow-icon-line"}
							stroke="black"
							strokeLinecap="square"
						/>
					</svg>
				</button>
			</div>
		</Modal>
	)
		;
}
