import youtube from "../../utils/youtube";
import VideoPop from "./VideoPop";
import { useState, useEffect } from "react";

const YoutubeCard = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleOpenModal = (video) => {
    setSelectedVideo(video); // Store the clicked video details
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedVideo(null); // Clear selected video
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "unset"; // Enable scrolling
    }
    return () => {
      document.body.style.overflow = "unset"; // Cleanup to ensure scrolling is re-enabled
    };
  }, [openModal]);

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 m-8 p-4">
      {youtube.map((link) => (
        <div key={link.id} className="my-4 px-4">
          <div className="bg-white shadow-2xl rounded-lg cursor-pointer">
            <img
              src={link.img}
              alt={link.title}
              className="rounded-lg object-contain w-full"
              onClick={() => handleOpenModal(link)}
            />
          </div>
          <div className="text-center mt-4 font-bold text-xl">
            <h1>{link.title}</h1>
            <p className="text-red-600 text-md mt-2">{link.content}</p>
          </div>
        </div>
      ))}
      {openModal && selectedVideo && (
        <VideoPop
          title={selectedVideo.title}
          video={selectedVideo.url}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default YoutubeCard;
