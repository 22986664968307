import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";


export const Chapter17 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	return (
		<div>
			<Header />
			<div className="mt-[100px] mb-10  mx-[150px] ">
				<h1 className="text-center text-3xl underline">
					जोगळेकर कुटुंबीयांची महत्त्वाची, कुलस्वामी व कुलदेवतांची
					देवस्थाने
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="text-center font-bold">कुलस्वामी</p>
					<div className="flex items-center justify-center mt-6">
						<table className="table-auto w-[550px] text-left">
							<thead>
								<tr>
									<th className="px-4 py-2">क्रमांक</th>
									<th className="px-4 py-2">देवस्थान</th>
									<th className="px-4 py-2">स्थान</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="px-4 py-2">१.</td>
									<td className="px-4 py-2">
										श्री व्याडेश्वर
									</td>
									<td className="px-4 py-2">गुहागर</td>
								</tr>
								<tr>
									<td className="px-4 py-2">२.</td>
									<td className="px-4 py-2">श्री वीरेश्वर</td>
									<td className="px-4 py-2">महाड</td>
								</tr>
								<tr>
									<td className="px-4 py-2">३.</td>
									<td className="px-4 py-2">
										श्री त्र्यंबकेश्वर
									</td>
									<td className="px-4 py-2">
										त्र्यंबक-नाशिक
									</td>
								</tr>
								<tr>
									<td className="px-4 py-2">४.</td>
									<td className="px-4 py-2">
										श्री कुसमेश्वर
									</td>
									<td className="px-4 py-2">कोतवडे</td>
								</tr>
								<tr>
									<td className="px-4 py-2">५.</td>
									<td className="px-4 py-2">
										श्री हरिहरेश्वर
									</td>
									<td className="px-4 py-2">श्रीवर्धन</td>
								</tr>
								<tr>
									<td className="px-4 py-2">६.</td>
									<td className="px-4 py-2">
										श्री गणपतीपुळे
									</td>
									<td className="px-4 py-2">रत्नागिरी</td>
								</tr>
								<tr>
									<td className="px-4 py-2">७.</td>
									<td className="px-4 py-2">श्री व्यंकटेश</td>
									<td className="px-4 py-2">
										भिलवडी (सांगली)
									</td>
								</tr>
								<tr>
									<td className="px-4 py-2">८.</td>
									<td className="px-4 py-2">
										श्री दशभुजालक्ष्मीगणेश
									</td>
									<td className="px-4 py-2">हेदवी</td>
								</tr>
								<tr>
									<td className="px-4 py-2">९.</td>
									<td className="px-4 py-2">
										श्री लक्ष्मीनारायण
									</td>
									<td className="px-4 py-2">हेदवी</td>
								</tr>
								<tr>
									<td className="px-4 py-2">१०.</td>
									<td className="px-4 py-2">श्री सोमेश्वर</td>
									<td className="px-4 py-2">नाशिक</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<p className="text-center mt-10 font-bold">कुलदेवता</p>
						<div className="flex items-center justify-center mt-6">
							<table className="table-auto w-[550px] text-left">
								<thead>
									<tr>
										<th className="px-4 py-2">क्रमांक</th>
										<th className="px-4 py-2">कुलदेवता</th>
										<th className="px-4 py-2">स्थान</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="px-4 py-2">१.</td>
										<td className="px-4 py-2">
											योगेश्वरीदेवी
										</td>
										<td className="px-4 py-2">अंबाजोगाई</td>
									</tr>
									<tr>
										<td className="px-4 py-2">२.</td>
										<td className="px-4 py-2">
											रेणुकादेवी
										</td>
										<td className="px-4 py-2">
											माहूर (विदर्भ)
										</td>
									</tr>
									<tr>
										<td className="px-4 py-2">३.</td>
										<td className="px-4 py-2">
											करवीरनिवासिनी अंबा महालक्ष्मी
										</td>
										<td className="px-4 py-2">कोल्हापूर</td>
									</tr>
									<tr>
										<td className="px-4 py-2">४.</td>
										<td className="px-4 py-2">
											महिषासुरमर्दिनी
										</td>
										<td className="px-4 py-2">
											सप्तश्रृंग-वणी-नाशिक
										</td>
									</tr>
									<tr>
										<td className="px-4 py-2">५.</td>
										<td className="px-4 py-2">
											तुळजाभवानी देवी
										</td>
										<td className="px-4 py-2">तुळजापूर</td>
									</tr>
									<tr>
										<td className="px-4 py-2">६.</td>
										<td className="px-4 py-2">
											महालक्ष्मी
										</td>
										<td className="px-4 py-2">कोतवडे</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							जोगळेकर कुटुंबीयांचे कुलस्वामी श्री व्याडेश्वर,
							गुहागर
						</h1>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> श्री </span>
							व्याडेश्वर मंदिर गुहागर येथील गस्‌.टी.स्टँडपासून
							अगदी जवळ असून, आजूबाजूला गुहागरची बाजारपेठ आहे.
							गुहागर हे गांव रत्नागिरी जिल्ह्यात असून तालुक्याचे
							ठिकाण आहे. चिपळूणपासून गुहागर सुमारे ४५ किलोमीटर
							अंतरावर आहे. गुहागर हे गाव इ.स.१२०० चे सुमारास वसले
							असले, तरी श्री व्याडेश्वर मंदिर सुमारे २०० / २५०
							वर्षांचे जुने आहे. व्याडेश्वर मंदिरातील शिविंलग बरेच
							प्राचीन असून स्वयंभू आहे. जमदग्नीपुत्र परशुरामाने
							कोकणभूमीची निर्मिती केल्यानंतर त्या भागात अनेक ऋषी
							आले व राहिले. त्यापैकी व्याड ऋषीने याची स्थापना केली
							म्हणून याला व्याडेश्वर असे संबोधले गेले. या मंदिराला
							चारी बाजूने तट असून उत्तर, दक्षिण व पूर्वेला
							प्रवेशद्वारे आहेत. मंदिर पूर्वाभिमुख असले तरी
							दक्षिणदिशेला असलेल्या प्रवेशद्वाराचा प्रामुख्याने
							वापर होतो.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">बहतेक </span>सर्व
							चित्पावन कोकणस्थ ब्राह्मण घराण्याचा श्री व्याडेश्वर
							हा कुलस्वामी आहे. अनेक जोगळेकर कुटुम्बांचा हा
							व्याडेश्वर कुलस्वामी आहे. मंदिराजवळ गक तळे असून
							विठ्ठल-रखुमाईचे देवालय पण आहे. मंदिराच्या आवारात
							मोकळी जागा असून मंदिर पंचायतन पद्धतीचे आहे. श्री
							सूर्य, श्री गणपती, श्री अंबिका व श्री लक्ष्मी-विष्णु
							अशी छोटी छोटी मंदिरे, श्री व्याडेश्वर मंदिराचे चारी
							बाजूला आहेत. मंदिराचे उपाध्याय धर्मकार्यासाठी
							भक्तांना मदत करतात. कार्तिकी गकादशीला येथे विशेष
							उत्सव होत असतो. माघ महिन्यात पाच दिवसांचा
							व्याडेश्वराचा उत्सव असतो. उत्सवात कथा-कीर्तनाचे
							कार्यक्रम होतात. पालखीची मिरवणूक असते. चातुर्मासात
							व्याडेश्वर िंलगावर सतत रुद्राभिषेक असतो. आषाढी,
							कार्तिकी गकादशी व महाशिवरात्र या दिवशी मंदिरात
							शिवाची चांदीची मूर्ती ठेवितात. महाशिवरात्र, श्रावण व
							कार्तिक सोमवारी बरेच भक्तगण श्री व्याडेश्वराच्या
							दर्शनाला येतात
						</p>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							जोगळेकर कुटुंबीयांचे कुलस्वामी - आद्य ज्योतिर्लिंग
							त्र्यंबकेश्वर{" "}
						</h1>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">श्रीक्षेत्र</span>{" "}
							त्र्यंबकेश्वर प्राचीन असून पुराणकालापासून हे आद्य
							ज्योतिर्लिंग असल्याचा उल्लेख आढळतो. त्र्यंबकेश्वरला
							ब्रह्मगिरीवर गोदावरीचे उगमस्थान आहे. नैसर्गिक
							सौंदर्याचा वारसा लाभलेल्या या भूमीवर पूर्वीपासून
							अनेक ऋषीमुनींचा तसेच सिद्ध पुरुषांचा वावर येथे कायम
							असे असा महाभारतातही उल्लेख आहे. श्री परशुरामाने या
							क्षेत्री तप:श्चर्या केली होती. श्री प्रभु
							रामचंद्रांनी सिंहस्थ यात्रेला येऊन तेथे आपल्या
							वडिलांचे श्राद्ध केले होते. श्री गुरु दत्तात्रेयांना
							तर येथेच सिद्धी प्राप्त झाली. ज्येष्ठ पांडवराजे श्री
							युधिष्ठिर येथे गोदावरी तीरावर-कुशावर्त तीर्थावर आले
							होते.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">
								न्यायशास्त्रप्रवर्तक
							</span>{" "}
							महर्षी गौतम यांचे वास्तव्यही त्र्यंबकेश्वर देवालयाचे
							दक्षिणेस होते. तेथे आजही गौतमेश्वर देवालय आहे. ह्या
							देवालयाजवळच गक प्राचीन गौतम तलावही आहे.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> या</span> प्राचीन
							मंदिराचा जीर्णोद्धार करण्याचे श्रीमंत बाळाजी बाजीराव
							ऊर्फ नानासाहेब पेशवे यांनी मनावर घेतले व त्याच्या
							कामास आरंभ केला तो दिवस होता मार्गशीर्ष कृष्णाष्टमी
							शके १६७७. (शुक्रवार दि.२६ डिसेंबर १७५५) हे काम सतत
							३१ वर्षे चालले. श्रीमंत नानासाहेबांचे नातू सवाई
							माधवराव यांच्या कारकीर्दीत हे काम पूर्ण झाले.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> िंहदू </span>
							शिल्पशास्त्रानुसार ‘मेरूप्रासादाकार‌’ वास्तुशिल्पाची
							ही रचना आहे. काही शिल्पशास्त्रज्ञांच्या मते ‘मेरु‌’
							व ‘माळवा‌’ या संमिश्र वास्तुशिल्पाची रचना यात आढळते.
							याचा आराखडा तत्कालीन वास्तुशिल्पज्ञ श्री.यशवंतराव
							हर्षे यांनी तयार केला होता व पेशव्यांचे मुख्य कारकून
							नारायण भगवंत यांच्या देखरेखीखाली या कामास प्रारंभ
							झाला व त्यांचे पुत्र नागेश नारायण यांनी ते काम तडीस
							नेले.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">या</span> मंदिराकरिता
							काळा दगड वापरलेला आहे तो स्थानिकच आहे पण संगमरवर
							मात्र राजस्थानातील मकराणा येथून - उंट, हत्ती व
							घोड्यांवरून वाहून आणला त्या काळात मंदिराचे कळस व
							वृषभध्वज वगळता बांधकामास ९ लक्ष रुपये गवढा खर्च आला.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">पूर्व </span>पश्चिम
							२६५ फूट व दक्षिणोत्तर २१८ फूट लांबी-रुंदी अलेल्या
							फरसबंद आवारास तटबंदी आहे. चारही दिशांना दरवाजे आहेत.
							उत्तरेकडील दरवाजा म्हणजेच महाद्वार होय. त्याचेवर
							नगारखाना आहे. मुख्यमंदिर पूर्वाभिमुख आहे. मंदिराचे
							आत भव्य असा सभामंडप आहे. त्याच्यावर मेघडंबरी आकाराचे
							छत आहे. आत मध्यभागी दगडी फरसबंदीवर ६इंच उंचीच्या
							संगमरवरी चबुतऱ्यावर सुबक कासव आहे. सभामंडप ४० फूट द
							४० फूट आहे पश्चिमेस मुख्य गाभारा आहे तेथे तीन
							पायऱ्या उतरून जावे लागते. आत संगमरवरी फरशी बसवलेली
							आहे. त्यातच वालुकामय दगडाची स्वयंभूशालुका आहे. त्यात
							अंगुष्ठाकार ब्रम्हा, विष्णू व महेशाचे तीन बाण आहेत.
							महेशाचे बाणावरून अव्याहत वाहणारा गंगाप्रवाह आहे.
							सभामंडपात कोनाड्यातून भैरवादिकांच्या मूर्ती
							बसवलेल्या आहेत.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">पटांगणातील</span> या
							मुख्य देवालयाची पूर्व पश्चिम लांबी १६० फूट आहे तर
							दक्षिणोत्तर १३१ फूट आहे. मंदिराची उंची ५६ फूट असून
							मंदिराचा गोलाकार व्यास १८५ फुटांचा आहे. मंदिरावरील
							तीन सुवर्णकळस व लाकडी वृषभध्वज पेशव्यांचे सरदार
							िंवचूरकरांनी बसवलेला आहे. वृषभध्वजाची उंची १४ फूट
							आहे. मंदिराचे प्रांगणात दक्षिण दिशेच्या िंभतीत ३१
							शिवमंदिरे आहेत. मंदिराच्या बांधकामाचा गक टप्पा पूर्ण
							झाला की गक मंदिर उभारले जाई.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">पुढे</span> १९३२ साली
							नागपूरच्या रामचंद्र पदे या शिल्पशास्त्रज्ञाच्या
							मार्गदर्शनाखाली ग्रामस्थ व विश्वस्तांच्या सहकार्याने
							जीर्णेाद्धार झाल्याचा दाखला आहे.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">पेशवेकालापासून</span>{" "}
							१९७७ पर्यंत या देवस्थानाची सर्व व्यवस्था जोगळेकर
							कुटुंबाकडे होती. महाशिवरात्र, वैकुंठचतुर्दशी व
							ग्रहणकाळ या मुख्य दिवशी खास पूजाअर्चा होते. दर
							सोमवारी निघणाऱ्या पालखीनंतर १६ किलो वजनाच्या
							सोन्याचा मुकुट दाखविला जातो. पिंडीवर असलेला सध्याचा
							मुकुट हा मात्र कायमच असतो. दर सोमवारी निघणाऱ्या
							पालखीची प्रथा १७ व्या शतकापासून चालू आहे. तिन्ही
							त्रिकाल पूजा अर्चा व नैवेद्य असतो. १७३८ मध्ये
							रावबाजींनी सोन्याचा मुकुट दिला आहे.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							जोगळेकर कुटुंबीयांचा कुलस्वामी <br />- श्री वीरेश्वर
							- श्री राजेश्वर महाड / श्री राजराजेश्वर
						</h1>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">बऱ्याच </span>जोगळेकर
							कुटुंबीयांचा श्री वीरेश्वर / श्री राजराजेश्वर
							कुलस्वामी आहे. मुंबई-गोवा हमरस्त्यावर महाड शहरात
							श्री वीरेश्वराचे मंदिर आहे. महाड येथील गस्‌.टी.
							स्टँडपासून हे शिवमंदिर जवळच आहे.
						</p>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">श्री</span> वीरेश्वर
							मंदिर सुमारे ३०० वर्षांचे जुने आहे व हे शंकराचे
							स्वयंभू स्थान असल्याचे भाविक मानतात. श्री वीरेश्वर
							महाडचे ग्रामदैवत मानले जाई. रायगडावरील श्रीमंत अनगळ
							यांनी पहिले जुने मंदिर बांधले असे म्हणतात. त्याकाळी
							फार दुष्काळ पडला होता, लोकांना वाईट दिवस आले, तेव्हा
							लोकांना पुरेसे काम मिळावे, त्यांचा उदरनिर्वाह व्हावा
							म्हणून ह्या मंदिराचे बांधकाम हाती घेतले गेले. जेथे
							मंदिर उभे आहे त्याचेच बाजूचे खडक फोडून या मंदिराचे
							बांधकाम पुरे केले गेले. ज्या ठिकाणाडून दगड फोडून
							काढले त्या पोकळीत गक तळे निर्माण झाले. हेच ते महाडचे
							चवदार तळे. शिवाजी महाराज येथे अनेक वेळा दर्शनास येऊन
							गेल्याचे सांगतात. पेशवाईच्या मध्यकाळातील रायगडचा
							सुभेदार व महाडचा रहिवासी श्री.नानासाहेब पोतनीस याने
							देवळाचा पुढील भाग व नंदीची मेघडंबरी बांधली.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> देवळाची </span>गकंदर
							बांधणी, घडीव दगडाच्या बांधणीचा उत्कृष्ट नमुना आहे.
							या बांधकामाची पद्धत व घाटणी, कर्नाटकातील
							शिवमंदिराच्या नमुन्यावर आधारित वाटते. कर्नाटकातील
							शिल्पकार या कामासाठी आणले असावेत असे वाटते. ह्या
							शिवाला, वीरेश्वर / राजराजेश्वर संबोधण्याचे कारण असे
							असावे की, रायगड व आजूबाजूच्या परिसरातील योद्धे, वीर,
							राजे, याचे दर्शनासाठी, अभिषेकासाठी वरचेवर येत
							असावेत.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">महाडच्या</span>{" "}
							सुभेदार पोतनिसांनी, या मंदिराच्या दैनंदिन खर्चासाठी
							व देखभालीसाठी जमिनी लावून दिल्या. पूजेसाठी व अन्य
							व्यवस्थेसाठी गुरवाची नेमणूक केली.
						</p>

						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">येथे</span>{" "}
							शिवरात्रीला सात दिवस उत्सव चालतो. वीरेश्वराचा
							‘छबिना‌’ दरवर्षी फाल्गुन शुद्ध द्वितीयेला निघतो. यात
							झोलाईदेवी, बहिरीनाथ, रवळनाथ, जाखमाता, कोटेश्वरीमाता
							इत्यादी देवांच्या पालख्याही येथे येतात. १९४६ मध्ये
							महाडच्या राधाबाई करवा यांच्या देणगीतून मंदिर व
							नंदीची मेघडंबरी यामध्ये भव्य सभामंडप उभारण्यात आला.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							जोगळेकर कुटुंबीयांची कुलस्वामिनी <br /> - श्री
							योगेश्वरीदेवी / श्री राजराजेश्वरी देवी
						</h1>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">मराठवाड्यातील</span>{" "}
							आंबेजोगाईची अंबापुरवासिनी श्री योगेश्वरी देवी ही
							बहतेक सर्वच चित्तपावन कोकणस्थ ब्राह्मणांची कुलदेवता
							आहे. त्यामुळे महाराष्ट्रातील सर्व भागांतून या
							देवीच्या दर्शनास भक्तगण येत असतात. अत्रिगोत्रीय
							चित्पावन जोगळेकर कुटुंबीयांची तर ही दंतासुरमर्दिनी,
							राजराजेश्वरीदेवी, विशेष शक्ती असलेली कुलस्वामिनीच
							आहे. ह्या श्री योगेश्वरी देवीच्या देवस्थानाच्या
							स्थापनेचा काळ सुमारे दीड हजार वर्षांपूर्वींचा मानला
							जातो. आंबेजोगाई शहराच्या मध्यभागातून वाहणाऱ्या जयंती
							नदीच्या पश्चिमकाठावर श्री योगेश्वरी देवीचे,
							हेमाडपंती रचनेचे, उत्तराभिमुख मंदिर वसलेले आहे. श्री
							योगेश्वर देवीचे वार्षिक उत्सव, अश्विन व मार्गशीर्ष
							महिन्यात साजरे केले जातात. अष्टमी व नवमी तिथीच्या
							संधिकालावर शतचंडी हवनावर पूर्णाहती होते. मार्गशीर्ष
							महिन्यांत शुध्द सप्तमीपासून घटस्थापना होऊन
							पौर्णिमेच्या दिवशी शतचंडी यज्ञाची पूर्णाहती होते.
							मार्गशीर्ष पौर्णिमा हा श्री योगेश्वरी देवीचा
							अवतारदिवस आहे. या दिवशी देवीला भोग चढवितात. जोगळेकर
							कुटुंबीय व्यक्तींना, कुलस्वामिनीचे आयुष्यात गकदा तरी
							दर्शन घेणे आवश्यक आहे असे मानतात. लग्नानंतर
							जोडप्याने कुलस्वामिनीचे दर्शन घेण्याची प्रथा आहे.
							ह्या आंबेजोगाईच्या श्री योगेश्वरी देवीच्या दर्शनाला
							िंकवा धर्मकृत्यांकरिता आंबेजोगाईस जाणाऱ्यांनी ह्या
							देवस्थानाचे क्षेत्रउपाध्याय यांची मदत घ्यावी.
							<br /> १. क्षेत्र उपाध्याय भिकाजी काशिनाथ
							पाठकशास्त्री.
							<br /> २. क्षेत्र उपाध्याय वसंत मार्तंड औसेकर-पाठक.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							श्री सप्तश्रृंगनिवासिनी महिषासुरमर्दिनी
						</h1>
						<p>
							नाशिक जिल्ह्यातील कळवण तालुक्यात असलेले सप्तश्रृंग
							निवासिनी महिषासुरमर्दिनीचे हे मंदिर नाशिकपासून
							साधारणत: ५० किलोमीटर अंतरावर आहे. काही जोगळेकर
							घराण्यांची ही कुलस्वामिनी आहे. या क्षेत्री
							जाण्यासाठी दोन मार्ग आहेत. पहिला वणीमार्गे व दुसरा
							नांदुरीमार्गे. हे अर्धपीठ सप्तश्रृंग स्थान बरेच
							प्राचीन आहे. इ.स.पाचव्या िंकवा सहाव्या शतकात
							डोंगरकपार तोडून हे स्थान बनविलेले आहे. ही देवी गुहेत
							असून, देवीची मूर्ती भव्य व अत्यंत तेजस्वी आहे. आठ
							फूट उंचीची - अठरा हातांची ही देवी दर्शनार्थींना
							प्रसन्नता देणारी आहे. या स्थानापर्यंत पोचण्यास
							नांदुरीमार्गे ४७५ व वणीमार्गे ३५० दगडी पायऱ्या चढून
							जाव्या लागतात. हा डोंगर म्हणजे सह्याद्री पर्वताचा गक
							भाग असून, येथे मार्कंडेय ऋषीने तपश्चर्या केली होती.
							ह्या डोंगराला सात शिखरे आहेत, म्हणून याला सप्तश्रृंग
							असे म्हणतात. ह्या देवीच्या क्षेत्राविषयी शिवकालीन
							कागदपत्रांत, श्रीरामदास स्वामींच्या उल्लेखात, तसेच
							पेशवेकालीन, कागदोपत्री निर्देश आढळतो. ह्या परिसरात
							कालीकुंड, सूर्यकुंड, दत्तात्रयकुंड ही तीन मोठी कुंडे
							असून, सरस्वती, लक्ष्मी, तांबुल, अंबालय व शीतला अशी
							पाच छोटी कुंडे आहेत. श्रीसिद्धेश्वर मंदिरही आहे.
							दर्शनार्थी भाविकांसाठी येथील ट्रस्टमार्फत सोयी
							उपलब्ध केल्या गेल्या आहेत.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							जोगळेकर कुटुुंबीयांची कुलस्वामिनी <br /> -
							महालक्ष्मी (अंबा) - कोल्हापूर
						</h1>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">
								{" "}
								कोल्हापूर
							</span>{" "}
							करवीरक्षेत्र व करवीरनिवासिनी अंबा महालक्ष्मी देवतेचे
							मंदिर व देवता हे अतिप्राचीन शक्तिपीठ आहे. इ.सनाच्या
							सुरुवातीपासून हे क्षेत्र अस्तित्वात असल्याचे इतिहास
							सांगतो.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> बहतांशी</span>{" "}
							जोगळेकर घराण्याची कुलस्वामिनी अंबेजोगाईची श्री
							योगेश्वरीदेवी असली, तरी कोल्हापूरची महालक्ष्मीही
							काही कुटुंबांची कुलस्वामिनी आहे. दक्षिण
							महाराष्ट्रातील ‘काशी‌’ नावाने ओळखले जाणारे हे
							तीर्थस्थान, भक्तांचे आवडते उपासनास्थान आहे.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> कोल्हापूरचे</span>{" "}
							महालक्ष्मी मंदिर पश्चिमाभिमुख आहे ते कोल्हापूर
							शहराच्या मध्यावर भरवस्तीत वसलेले असून त्याला चारी
							दिशांनी प्रवेशद्वारे आहेत. पश्चिमेकडील प्रवेशद्वार
							हे महाद्वार असून, मंदिरात या द्वारानेच प्रवेश करतात.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">महालक्ष्मीची </span>
							मूर्ती काळ्या पाषाणाची आहे. तीन फूट उंची असणारी ही
							मूर्ती चतुर्भुज आहे. मूर्तीच्या भ्रूमध्यावर
							पद्मरागिणी आहे. मस्तकी शिविंलग धारण केलेले असून,
							शिविंलगावर नागफणा शोभायमान दिसतो. वर्षातून कमीत कमी
							दोनदा- म्हणजे दरवर्षी २१ मार्च व २१ सप्टेंबर या
							दिवशी सायंकाळी सूर्याची सोनेरी किरणे नेमकी देवीच्या
							मुखमंडलावर पडून देवीचे मुख तेजाने उजळून निघते.
						</p>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">
								{" "}
								देवालयाची
							</span>{" "}
							रचना तीन गाभाऱ्यांची असून ह्या देवालयाचे
							वास्तु-शिल्प िंहदूवास्तु-शिल्पाचा उत्तम नमुना आहे.
							चालुक्य, शिलाहार व यादवांच्या राजवटीत या देवालयाचा
							कलाविस्तार व श्रीमंती वाढतच गेली. कोल्हापुरात, श्री
							महालक्ष्मी देवतेचा श्री अंबाबाई म्हणूनच सतत उल्लेख
							होत असतो.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							दशभुजा लक्ष्मीगणेश - हेदवी
						</h1>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">
								निसर्गरम्य
							</span>{" "}
							परिसर लाभलेल्या हेदवी या गावाचे गका कुशीत डोंगराचे
							मध्यभागी, भव्य किल्लेवजा तटबंदीने वेढलेली पेशवेकालीन
							बांधणीची वास्तू आहे ती म्हणजे, सर्वांचे आराध्यदैवत
							असलेल्या दशभुजा श्री लक्ष्मीगणेशाचे मंदिर.
							पेशवेकालापासून थेट १९४४ पर्यंत अनेक गणेश भक्त
							मंडळीनी ह्या मंदिराची देखभाल केली. १९४४ ते १९५७
							पर्यंत श्री.वामन त्र्यंबक ऊर्फ तात्यासाहेबांनी
							वेळोवेळी आपल्या कुटुंबियांना सेवाव्रतात गुंतवून
							मंदिराची व उत्सवाची जबाबदारी पार पाडली; पण
							पेशवेकालीन ही वास्तू हळूहळू जीर्ण होऊन मोडकळीस आली.
							श्री.शिवराम गोिंवद ऊर्फ काकासाहेब यांनी
							जीर्णोद्धाराची योजना तयार करून कार्यान्वित केली.
							अनेक मित्र, स्नेही व गणेशभक्त यांचे सहकार्य, जबरदस्त
							जिद्द व धडपड करून अनेक वर्षे पायरीपायरीने
							जीर्णोद्धाराचे कार्य पूर्ण करून सफल केले. गेली
							कित्येक वर्षे माघी पौर्णिमेला हेदवीस गणेश जन्मोत्सव
							मोठ्या उत्साहात साजरा केला जातो. मुंबई, पुुणे व अन्य
							ठिकाणाहून ह्या उत्सवासाठी भक्तगण मोठ्या संख्येने येत
							असतात. काकासाहेबांच्या ह्या कार्यामुळे अनेक जोगळेकर
							मंडळी हेदवीला भेट देऊन गणेशदर्शनाचा लाभ घेतात. काही
							जोगळेकर घराण्यांचा हा गणेश कुलस्वामीच बनला आहे असे
							म्हटले तर वावगे वाटण्यासारखे काही नाही.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">हेदवी</span> हे गाव
							रत्नागिरी जिल्ह्यात असून चिपळूण, गुहागरमार्गे
							हेदवीला गस.टी.ने िंकवा खाजगी गाडीने पोचता येते.
							मंदिराशेजारीच उतरण्यास, भक्तनिवासत सोय होऊ शकते.
							ह्या मंदिराच्या जीर्णोद्धाराने काकासाहेबांनी इतर
							जोगळेकर व अन्य मंडळींनी गक आदर्शच घालून दिला आहे.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							दशभुजा लक्ष्मीगणेश - हेदवी
						</h1>
						<p className="mb-5 leading-relaxed">
							{" "}
							निसर्गरम्य परिसर लाभलेल्या हेदवी या गावाचे गका कुशीत
							डोंगराचे मध्यभागी, भव्य किल्लेवजा तटबंदीने वेढलेली
							पेशवेकालीन बांधणीची वास्तू आहे ती म्हणजे, सर्वांचे
							आराध्यदैवत असलेल्या दशभुजा श्री लक्ष्मीगणेशाचे
							मंदिर. पेशवेकालापासून थेट १९४४ पर्यंत अनेक गणेश भक्त
							मंडळीनी ह्या मंदिराची देखभाल केली. १९४४ ते १९५७
							पर्यंत श्री.वामन त्र्यंबक ऊर्फ तात्यासाहेबांनी
							वेळोवेळी आपल्या कुटुंबियांना सेवाव्रतात गुंतवून
							मंदिराची व उत्सवाची जबाबदारी पार पाडली; पण
							पेशवेकालीन ही वास्तू हळूहळू जीर्ण होऊन मोडकळीस आली.
							श्री.शिवराम गोिंवद ऊर्फ काकासाहेब यांनी
							जीर्णोद्धाराची योजना तयार करून कार्यान्वित केली.
							अनेक मित्र, स्नेही व गणेशभक्त यांचे सहकार्य, जबरदस्त
							जिद्द व धडपड करून अनेक वर्षे पायरीपायरीने
							जीर्णोद्धाराचे कार्य पूर्ण करून सफल केले. गेली
							कित्येक वर्षे माघी पौर्णिमेला हेदवीस गणेश जन्मोत्सव
							मोठ्या उत्साहात साजरा केला जातो. मुंबई, पुुणे व अन्य
							ठिकाणाहून ह्या उत्सवासाठी भक्तगण मोठ्या संख्येने येत
							असतात. काकासाहेबांच्या ह्या कार्यामुळे अनेक जोगळेकर
							मंडळी हेदवीला भेट देऊन गणेशदर्शनाचा लाभ घेतात. काही
							जोगळेकर घराण्यांचा हा गणेश कुलस्वामीच बनला आहे असे
							म्हटले तर वावगे वाटण्यासारखे काही नाही. हेदवी हे गाव
							रत्नागिरी जिल्ह्यात असून चिपळूण, गुहागरमार्गे
							हेदवीला गस.टी.ने िंकवा खाजगी गाडीने पोचता येते.
							मंदिराशेजारीच उतरण्यास, भक्तनिवासत सोय होऊ शकते.
							ह्या मंदिराच्या जीर्णोद्धाराने काकासाहेबांनी इतर
							जोगळेकर व अन्य मंडळींनी गक आदर्शच घालून दिला आहे.
						</p>
					</div>
					<div>
						<h1 className="text-center font-bold text-xl mt-10 mb-3">
							परशुराम मंदिर - लोटे परशुराम
						</h1>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">चिपळूणजवळील</span>{" "}
							परशुराम मंदिर हे चित्पावनांचे गक सर्वो श्रध्दास्थान
							व तीर्थक्षेत्र आहे. सर्वच भारतीयांना ते प्रिय आहे.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">इ.स.१२६५</span> मध्ये
							चित्पावनांच्या सोपारा परिसरातून ‘‘समुद्रमार्गे
							स्थलांतरित झालेल्या पूर्वजांनी दाभोळच्या खाडीतून
							प्रवेश घेतला. अंदाजे दहा हजार लोकांच्या वाड्या राहू
							शकतील गवढी विस्तीर्ण चित्ततपवन नामक आद्य पुनर्वसन
							छावणी ह्या खाडीस मिळणाऱ्या वसिष्ठी नदीच्या
							उत्तरकाठावर वसवण्यात आली. पुनर्वसन छावणीत काहीसे
							स्थिरस्थावर झाल्यावर आगमनापासून १ वर्षाच्या आतच,
							पुनर्वसन छावणीच्या मध्यवर्ती भागात पूर व परचक्र
							ह्यांपासून सुरक्षित केंद्रस्थानी चित्पावनांनी
							परशुराम मंदिराची निर्मिती व मूतींची स्थापना केली.
							सोपाऱ्याहून येवढ्या युद्ध-धामधुमीच्या काळात, अनेक
							वादळांमध्ये गलबते सापडण्याची शक्यता असतानासुध्दा
							चिपळूण परिसरापर्यंत सुखरूप पोहोचविल्याबद्दल कृतज्ञता
							बुध्दीने आणि तसेच परशुराम हे त्यांचे आराध्यदैवत
							असल्याने ह्या मंदिराची ताबडतोब स्थापना होणे
							स्वाभाविकच होते. सोपारा-दाभोळ ह्या समुद्री प्रवासात
							अनेकांनी तसा नवस पण केला असावा. त्यामुळेच
							िंचपळूणच्या परशुराम दैवताला समुद्री प्रवासातील
							विघ्नहर्ता म्हणून विशेष ख्याती प्राप्त झाली. हे देऊळ
							सामान्य जनतेने उत्स्फूर्तपणे बांधले, त्यामुळे
							स्थापत्यदृष्टया ते फार मोठे व दिमाखदार नाही.
						</p>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8"> ह्या</span>{" "}
							मंदिराच्या बाजूला रेणुकामातेचे हेमाडपंती पद्धतीचे
							मंदिर आहे. ते १२८५ च्या सुमारास यादवसम्राट रामचंद्र
							ह्याच्या संगमेश्वर-खेड विजयानंतर बांधण्यात आले असावे
							हे उघड आहे.
						</p>
						<p>
							इ.स. १३१२ नंतर मलिक कफूर ह्या अलाउद्दीन खिलजीच्या
							सुभेदाराने ह्या मंदिराची नासधूस करण्याचा प्रयत्न
							केला.{" "}
						</p>
						<p className="mb-5 leading-relaxed">
							{" "}
							‘घरर्षीी ुरी र वर्शींर्ीीिं षश्रिश्रुिशी षि खीश्ररा
							ुह‌’ीि ठशश्रळर्ीीि र्वूीीं ुरी ींि वशाश्रिळीह
							ींशाश्रिशी रवि लर्ीीीीििंलीं ाीिर्ीिंशी र्ीिंशी
							ींहशा.‘ म धरवर्रींर‌’ी रवि ींहशळी ींळाशी. ज.झ.तरीार.
							रिसश १६२.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> तथापि </span>मलिक
							कफूरचे लोक मंदिरापर्यंत पोहोचण्यापूर्वीच भक्तजनांनी
							मंदिरातील मूर्ती सुरक्षितपणे गुप्त ठिकाणी हलविल्या
							होत्या. बहधा गुहागरजवळ कुठेतरी त्या लपवून ठेवण्यात
							आल्या असाव्यात व त्यांचीच पुढे पुन:स्थापना झाली
							असावी. त्यामुळे इ.स. १३१२ पासून, इ.स. १६९८ मध्ये
							ब्रह्मेंद्रस्वामींनी जीर्णोद्धार करेपर्यंत तेथे
							मूर्ती नव्हत्या. (इ.स. १६९८ मध्ये ब्रह्मेंद्रस्वामी
							धावडशीकर यांनी मंदिराचा जीर्णेाद्धार केला, तोवर तेथे
							मूर्ती नव्हत्या.) सर्व पूजा, उपचार शिलासनालाच होत
							होते. पुढे गुहागरच्या परचुरे गृहस्थाने मूर्तींची
							स्थापना केली.’’{" "}
						</p>
						<p className="mb-5 leading-relaxed">
							(-चित्तपावन ब्राह्मण संघ, धंतोली, नागपूर स्मरणिका -
							१९९२, पृ.८.) परशुरामाच्या मूर्तींची पुन:स्थापना
							होईपर्यंत दक्षिण कोकणाची भूमी काहीशी नापीक राहिली
							आणि मूर्तींची पुन:स्थापना झाल्यावर दक्षिण कोकणाची
							भूमी हळूहळू प्रतिवर्षी अधिकाधिक सुपीक होत जाईल.’’
							अशी लोकांची भावना होती असे म्हणतात. कोकणची भूमी
							निर्धारपूर्वक प्रयत्न केल्यास केरळ िंकवा
							सिलोनप्रमाणे उपजाऊ व सुपीक होऊ शकते, हे सहज
							पटण्यासारखे आहे. त्या दृष्टीने योजना आखणे आवश्यक
							आहे.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> मलिक</span> कफूरच्या
							तुलनेने आदिलशाहीतील सुलतान बरेच सहिष्णु होते.
							त्यामुळे मंदिरातील शिलासनाची पूजा-अर्चा मुक्तपणे
							करणे शक्य झाले. तसेच मंदिराच्या कळसाचा व घुमटाचा
							जीर्णोद्धार करणेसुद्धा शक्य झाले. तथापि ह्या क्रियेत
							घुमटावर अभावितपणे (िंकवा मशिदीपासूनचा फरक प्रकर्षाने
							लक्षात येऊन परत मूर्तिभंजकाचा रोष ओढवला जाऊ नये
							म्हणून) िंकचित मुसलमानी पद्धतीची झाक केल्याचे भासत
							असे. सुदैवाने सध्या ह्या घुमटाचे नूतनीकरण व संपूर्ण
							भारतीयकरण करण्याचे सुयोग्य काम चालू आहे, ते लवकरच
							पूर्ण होईल. आदिलशाहीमधील विविध सुलतानांची परशुराम
							मंदिरावर बरीच भक्ती बसली होती व ते त्याला मानीत
							होते. त्याकाळी दक्षिण भारतामधून हजला जाणारे अधिकांश
							मुसलमान मक्केला निघण्यासाठी दाभोळ बंदराचा उपयोग करीत
							व प्रवासाला निघण्यापूर्वी परशुरामाला नवस करीत असत.
							‘‘मन्नत कबूल करीत.’’ ‘‘दाभोळ म्हणजे विजापूरकर
							सुलतानाचे मुख्य बंदर, मक्का - मदिनेची यात्रा करून
							पुण्य जोडायचे तर दाभोळचे बंदर सोईचे पडे.’’ (आम्ही
							टिळक (कुलवृत्तान्त) इ.स. १९९४ पृष्ठ २९५.) परशुराम
							आणि समुद्रीप्रवासातील क्षेमता ह्याचे अतूट नाते
							सर्वधर्मीयांना मान्यहोते.
						</p>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">
								{" "}
								जंजिऱ्याच्या
							</span>{" "}
							सिद्दी सुलतानाच्या जावयाचे गलबत समुद्रामध्ये वादळात
							फुटून तो बेपत्ता झाला होता, तेंव्हा सुलतानाच्या
							मुलीने परशुरामाला नवस केला होता. सहा महिन्यांनी तिचा
							नवरा जंजिऱ्याला सुखरूप पोहोचला, त्यामुळे तिची ह्या
							देवस्थानावर असीम श्रद्धा बसली. तिने देवालयाला तिसरा
							घुमट बांधला व देवालयास वार्षिक बाराशे रुपयांचे
							उत्पन्न सिद्दीच्या खजिन्यातून लावून दिले.
						</p>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">मुंबई-गोवा </span>
							राष्ट्रीय महामार्गावर, मुंबईहून गोव्याच्या दिशेने
							जाताना (चिपळूण अंदाजे आठ किलोमीटर असताना) परशुराम
							फाटा लागतो. ह्या फाट्याने गक किलोमीटर आत गेल्यावर
							परशुराम गाव व परशुराम मंदिर लागते. ह्या फाट्याच्या
							ठिकाणी पर्यटकांसाठी ‘वसिष्ठीदर्शन विसावा‌’ म्हणून
							स्पॉट केला आहे. तेथून वसिष्ठी नदीचे पात्र फार सुंदर
							दिसते. नदीची गकूण लांबी फक्त ३० किलोमीटर असली तरी
							पात्र मोठे आहे. येथे बसल्यावर चित्पावनांची आद्य
							पुनर्वसन छावणी कशी असेल ह्याची कल्पना मनात डोकावते.
							परशुराम गावात आजही वीस-पंचवीस चित्पावनांची घरे आहेत.
						</p>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8"> मुख्य </span>
							गाभाऱ्यात परशुरामाच्या मूर्तीच्या उजव्या हाताला
							‘काम‌’ आणि डाव्या हाताला ‘काळ‌’ देवाची मूर्ती आहे.
							काळ आणि काम ह्या दोन्हीवर विजय मिळविणारे परशुराम असा
							त्याचा आशय आहे. मंदिराच्या परिसराचा पर्यटनकेंद्र व
							तीर्थक्षेत्र म्हणून योजनाबद्ध नीट विकास करणे आवश्यक
							आहे. ह्या क्षेत्राच्या प्रचंड महत्त्वाच्या तुलनेत
							ह्या परिसराकडे आवश्यक तेवढे लक्ष अजून दिले गेलेले
							नाही.
						</p>
					</div>
					<div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
					<hr />
				</div>
			</div>
		</div>
	);
};
