import React, { useContext, useEffect, useLayoutEffect, useState } from "react";

import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import { Header } from "../components/Header";
import { AccountContext } from "../context/AccountProvider";
import { CreateProfile } from "../components/CreateProfile";
import { FamilyTreeBuilder } from "../components/FamilyTreeBuilder";
import { getUsers } from "../service/accountApi";
import { Modal } from "../components/Modal";

export const Kulavruksh = () => {
  const { cookie, account, loading } = useContext(AccountContext);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchQuery === "") {
      setSearchResults([]);
    } else {
      getUsers(searchQuery)
        .then((response) => {
          if (response.success) {
            setSearchResults(response.data);
          }
        })
        .catch((error) => {
          console.log("Get Users Error: ", error);
        });
    }
  }, [searchQuery]);

  const handleClearButtonClick = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  useLayoutEffect(() => {
    if (
      (account && account.outsider) ||
      cookie === undefined ||
      cookie === null
    ) {
      setIsSearchModalOpen(true);
    }
  }, [account]);

  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <Header
          isSearchModalOpen={isSearchModalOpen}
          setIsSearchModalOpen={setIsSearchModalOpen}
        />

        {cookie &&
          !account.outsider &&
          (loading ? (
            <div>Loading....</div>
          ) : (
            <ReactFlowProvider>
              {account.nodeId ? <FamilyTreeBuilder /> : <CreateProfile />}
            </ReactFlowProvider>
          ))}

        {/* Search Modal */}
        <Modal zIndex={"z-[89]"} isOpen={isSearchModalOpen}>
          <div className="flex flex-col gap-y-3 max-h-[60vh] w-[35vw]">
            {/* Search Bar */}
            <div
              className={`w-full h-[6vh] flex items-center justify-between gap-x-2
                                rounded-full px-[0.75vw] py-[1vh] bg-white`}
            >
              <div
                className={`flex items-center justify-center rounded-full 
                                    h-[2vw] w-[2vw] p-[0.5vh] bg-[#F5F5F5]`}
              >
                <img
                  className="h-full w-full"
                  src="/assets/search-icon.png"
                  alt="Search"
                />
              </div>

              <input
                id="searchQuery"
                className="w-full flex-1 h-full font-SOHNE_MEDIUM text-[1vw] leading-none outline-none"
                name="Search Query"
                placeholder={"Search for people or family tree"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              {/* Clear Button */}
              {searchQuery && (
                <button
                  onClick={handleClearButtonClick}
                  className={`flex items-center justify-center rounded-full 
                                        h-[2vw] w-[2vw] p-[0.5vh] bg-[#F5F5F5]`}
                >
                  <img
                    className="h-full w-full"
                    src="/assets/close-icon.png"
                    alt="Clear Search"
                  />
                </button>
              )}
            </div>

            {/* Search Results */}
            <div
              className={`flex flex-1 flex-wrap justify-between 
                                gap-2 overflow-scroll no-scrollbar
                                transition duration-150 ease-[cubic-bezier(0.215,0.61,0.355,1)]`}
            >
              {searchResults.length !== 0 &&
                searchResults.map((data, index) => (
                  <div
                    className={`min-w-[49%] max-w-[49%] flex-1 flex items-center gap-x-2 rounded-xl 
                                                shadow-lg px-[1vh] py-[1vh] border-2 border-[#EAEAEA] bg-white`}
                  >
                    {/* Profile Picture */}
                    {data.imageUrl.length <= 2 ? (
                      <div
                        className={`z-10 w-[8vh] h-[8vh] overflow-hidden 
                                                                flex items-center justify-center rounded-lg 
                                                                shadow-xl border-2 border-[#F5F5F5] bg-[#D26A53]`}
                      >
                        <p
                          className={
                            "text-[1vw] text-white font-SOHNE_MONO_BOLD"
                          }
                        >
                          {data.imageUrl.toUpperCase()}
                        </p>
                      </div>
                    ) : (
                      <div className="z-10 w-[8vh] h-[8vh] overflow-hidden flex items-center justify-center rounded-lg bg-slate-500 border-2 border-white">
                        <img
                          src={
                            !(data.imageUrl === "")
                              ? data.imageUrl
                              : "assets/default-pfp.png"
                          }
                          referrerPolicy={"no-referrer"}
                          alt="Member Profile Picture"
                        />
                      </div>
                    )}

                    {/* Firstname | Middlename | Family Tree Name */}
                    <div className="h-full flex-1 flex flex-col items-start justify-between">
                      <p className="text-[1.75vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                        {data.firstName + " " + data.middleName}
                      </p>
                      <p className="text-[1.5vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                        {data.firstNameDevanagariScript +
                          " " +
                          data.middleNameDevanagariScript}
                      </p>
                      <div className="py-[0.25vh] px-[0.5vw] bg-[#F3BC50] rounded-full">
                        <p className="text-[1.25vh] font-SOHNE_MEDIUM text-[#3D3D3D] leading-snug">
                          {data.familyTreeName}
                        </p>
                      </div>
                    </div>

                    {/*/!* Edit | Delete Button *!/*/}
                    {/*{*/}
                    {/*    cookie !== undefined && data.familyTreeAdminId === account._id && (*/}
                    {/*        <div className={"flex flex-col items-center justify-between h-full"}>*/}
                    {/*            <button*/}
                    {/*                className={`flex items-center justify-center rounded-md */}
                    {/*            h-[1.75vw] w-[1.75vw] p-[0.5vh] bg-[#F5F5F5]*/}
                    {/*            hover:shadow-lg transition-all duration-300 ease-in-out`}*/}
                    {/*            >*/}
                    {/*                <img*/}
                    {/*                    className="h-full w-full"*/}
                    {/*                    src="/assets/edit-profile-icon.png"*/}
                    {/*                    alt="Edit"*/}
                    {/*                />*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className={`flex items-center justify-center rounded-md */}
                    {/*            h-[1.75vw] w-[1.75vw] p-[0.5vh] bg-[#F5F5F5]*/}
                    {/*            hover:shadow-lg transition-all duration-300 ease-in-out`}*/}
                    {/*            >*/}
                    {/*                <img*/}
                    {/*                    className="h-full w-full"*/}
                    {/*                    src="/assets/delete-icon.png"*/}
                    {/*                    alt="Edit"*/}
                    {/*                />*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
