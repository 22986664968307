const Hero = () => {
	

	return (
		<div id="top" className="relative h-[50vh] md:h-[90vh]">
			<img
				src="/kulvratant_landingPage/banner_amhi_joglekar 1920.jpg"
				alt=""
				className="w-full h-full object-cover shadow-lg"
			/>
			{/* Circle images */}
			<div className="absolute top-0 h-full right-[2vw] flex flex-col justify-between items-end pt-[6vw] pb-[4vw]">
				<div className="flex flex-row gap-[5vw]">
					{[
						{
							src: "/kulvratant_landingPage/circle image-shivapind.png",
							alt: "Vyadeshwar, Guhagar",
							text: "व्याडेश्वर, गुहागर",
						},
						{
							src: "/kulvratant_landingPage/circle image-kuldevi.png",
							alt: "Yogeshwari Devi, Ambajogai",
							text: "योगेश्वरी देवी, अंबाजोगाई",
						},
					].map((img, index) => (
						<div key={index} className="flex flex-col items-center">
							<img
								src={img.src}
								alt={img.alt}
								className="w-[15vw] aspect-square rounded-full border-4 border-white object-cover"
							/>
							<p className="text-white mt-2 text-center text-[1.25vw]">
								{img.text}
							</p>
						</div>
					))}
				</div>
				<div className="w-fit flex flex-col cursor-pointer">
					<a href="https://jogkulstorageuat.blob.core.windows.net/siteassets/myFolder/joglekar_kulvrutant_2010.pdf " target='_blank' rel="noreferrer">
						<img
						src="/kulvratant_landingPage/circle image-granth.png"
						alt="2010 - ग्रंथ"
						className="w-[10vw] aspect-square rounded-full border-4 border-white object-cover"
						
					/>
					</a>
					<p className="text-white mt-2 text-[1vw] text-center">
						2010 - ग्रंथ
					</p>
				</div>
			</div>
		</div>
	);
};

export default Hero;
