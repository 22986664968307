import React, {useState} from 'react';
import {t} from "i18next";
import {Modal} from "../components/Modal";
import {addEducation} from "../service/accountApi";

export const AddEducationForm = (props) => {
    const {showAddEducationForm, setShowAddEducationForm, accountDetails, setAccountDetails} = props;

    const [error, setError] = useState({});

    const [isAddEducationButtonDisabled, setIsAddEducationButtonDisabled] = useState(false);

    const [educationDetails, setEducationDetails] = useState({
        schoolOrCollegeName: '',
        degree: '',
        courseOfStudy: '',

        result: '',
        startDate: '',
        endDate: '',

        isCurrentEducation: false,

        city: '',
        state: '',
        country: '',
    });

    const [needAssistance, setNeedAssistance] = useState(false);
    const [needAssistanceDescription, setNeedAssistanceDescription] = useState('');

    const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

        setEducationDetails({
            ...educationDetails,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ""
        });
    }

    const validate = () => {
        let err = {};

        if (educationDetails.schoolOrCollegeName === '') {
            err['schoolOrCollegeName'] = t("ADD_EDUCATION_FORM.SCHOOL_COLLEGE_NAME.REQUIRED_ERROR");
        }

        if (educationDetails.degree === '') {
            err['degree'] = t("ADD_EDUCATION_FORM.DEGREE.REQUIRED_ERROR");
        }

        if (educationDetails.courseOfStudy === '') {
            err['courseOfStudy'] = t("ADD_EDUCATION_FORM.COURSE_OF_STUDY.REQUIRED_ERROR");
        }

        if (educationDetails.result === '') {
            err['result'] = t("ADD_EDUCATION_FORM.RESULT.REQUIRED_ERROR");
        }

        if (educationDetails.startDate === '') {
            err['startDate'] = t("ADD_EDUCATION_FORM.START_DATE.REQUIRED_ERROR");
        }

        if (!educationDetails.isCurrentEducation) {
            if (educationDetails.endDate === '') {
                err['endDate'] = t("ADD_EDUCATION_FORM.END_DATE.REQUIRED_ERROR");
            }
        }

        if (educationDetails.city === '') {
            err['city'] = t("ADD_EDUCATION_FORM.CITY.REQUIRED_ERROR");
        }

        if (educationDetails.state === '') {
            err['state'] = t("ADD_EDUCATION_FORM.STATE.REQUIRED_ERROR");
        }

        if (educationDetails.country === '') {
            err['country'] = t("ADD_EDUCATION_FORM.COUNTRY.REQUIRED_ERROR");
        }

        return err;
    }

    const handleSubmit = async () => {
        console.log("Inside Handle Submit!");
        setIsAddEducationButtonDisabled(true);
        const err = validate();

        if (Object.keys(err).length > 0) {
            setError(err);
            setIsAddEducationButtonDisabled(false);
            return;
        }

        console.log("Education Details: ", educationDetails);

        const educationData = {
            userAccountId: accountDetails._id,
            educationDetails: educationDetails,
        };
        const addEducationDataResponse = await addEducation(educationData);

        console.log('(AddEducationForm) Add Education Data Response: ', addEducationDataResponse);

        if (addEducationDataResponse.success) {
            setAccountDetails(addEducationDataResponse.data);
            setShowAddEducationForm(false);
            setIsAddEducationButtonDisabled(false);
            setEducationDetails({
                schoolOrCollegeName: '',
                degree: '',
                courseOfStudy: '',

                result: '',
                startDate: '',
                endDate: '',

                isCurrentEducation: false,

                city: '',
                state: '',
                country: '',
            });
        }
    }

    return (
        <Modal isOpen={showAddEducationForm}>
            <div className="flex flex-col justify-between max-h-[80vh] rounded-xl px-[1.5vw] py-[2vh] bg-white">
                {/* Header */}
                <div className={"max-h-[10vh]"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        {/* Form Title */}
                        <h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
                            {t("ADD_EDUCATION_FORM.TITLE")}
                        </h2>

                        <button
                            onClick={() => setShowAddEducationForm(false)}
                            type="button"
                            data-drawer-hide="drawer-form"
                            aria-controls="drawer-form"
                            className="flex items-center justify-center rounded-lg w-[2vw] h-[2vw]
                            bg-transparent hover:bg-gray-200
                            text-sm text-gray-400 hover:text-gray-900"
                        >
                            <img
                                className={"w-[75%] h-[75%]"}
                                src={"/assets/close-icon.png"}
                                alt={"Close"}
                                title={"Close"}
                            />
                        </button>
                    </div>

                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
                </div>

                <div className={"flex-1 no-scrollbar overflow-y-scroll max-h-[60vh] py-[1.5vh]"}>
                    {/* School / College Name */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="schoolOrCollegeName"
                            >
                                {t("ADD_EDUCATION_FORM.SCHOOL_COLLEGE_NAME.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <input
                                    id="schoolOrCollegeName"
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='schoolOrCollegeName'
                                    value={educationDetails.schoolOrCollegeName}
                                    onChange={handleFormData}
                                />
                                {
                                    error['schoolOrCollegeName'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['schoolOrCollegeName']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>

                    {/* Degree */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="degree"
                            >
                                {t("ADD_EDUCATION_FORM.DEGREE.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <input
                                    id="degree"
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='degree'
                                    value={educationDetails.degree}
                                    onChange={handleFormData}
                                />
                                {
                                    error['degree'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['degree']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>

                    {/* Course Of Study */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="courseOfStudy"
                            >
                                {t("ADD_EDUCATION_FORM.COURSE_OF_STUDY.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <input
                                    id="courseOfStudy"
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='courseOfStudy'
                                    value={educationDetails.courseOfStudy}
                                    onChange={handleFormData}
                                />
                                {
                                    error['courseOfStudy'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['courseOfStudy']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>

                    {/* Result | Start Date | End Date */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-row gap-4">
                            {/* Result */}
                            <div className="w-1/6 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="result"
                                >
                                    {t("ADD_EDUCATION_FORM.RESULT.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="result"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='result'
                                        value={educationDetails.result}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['result'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['result']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* Start Date */}
                            <div className="w-1/3 flex flex-col gap-2">
                                <label
                                    className='text-gray-600 text-[0.9vw] font-SOHNE_REGULAR'
                                    htmlFor='startDate'
                                >
                                    {t("ADD_EDUCATION_FORM.START_DATE.LABEL")}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type={"month"}
                                        name='startDate'
                                        required
                                        value={educationDetails.startDate}
                                        onChange={handleFormData}
                                        className={`w-fit font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md text-[1vw]
                                            ${educationDetails.isCurrentEducation ? 'px-[0.75vw]' : 'px-[1vw]'}
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]`}
                                    />
                                    {
                                        error['startDate'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['startDate']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* End Date */}
                            <div className="w-1/3 flex flex-col gap-2">
                                <label
                                    className='text-gray-600 text-[0.9vw] font-SOHNE_REGULAR'
                                    htmlFor='endDate'
                                >
                                    {t("ADD_EDUCATION_FORM.END_DATE.LABEL")}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        disabled={educationDetails.isCurrentEducation}
                                        type={"month"}
                                        name='endDate'
                                        value={educationDetails.endDate}
                                        onChange={handleFormData}
                                        className="font-SOHNE_REGULAR inline-flex min-h-[5vh] max-h-[5vh]
                                            flex-1 items-center justify-center rounded-md px-[0.75vw] text-[1vw]
                                            leading-none outline-none border-2 border-gray-300
                                            focus:border-[#5F0F40]"
                                    />
                                    {
                                        error['endDate'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['endDate']}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/* Current Education CheckBox */}
                    <fieldset>
                        <div className={"mb-[2vh] flex flex-col gap-2"}>
                            {/*<label*/}
                            {/*    className="cursor-pointer min-h-[5vh] max-h-[5vh] w-fit flex flex-row items-center gap-2*/}
                            {/*    rounded-md px-[1vw] py-[1vh] border-2 border-gray-300">*/}
                            <label className="flex flex-row items-center gap-2">
                                <input
                                    type="checkbox"
                                    name='isCurrentEducation'
                                    checked={educationDetails.isCurrentEducation}
                                    onChange={(e) => {
                                        setEducationDetails({
                                            ...educationDetails,
                                            isCurrentEducation: e.target.checked
                                        });
                                    }}
                                />
                                <p className="font-SOHNE_REGULAR text-[1vw]">
                                    {t("ADD_EDUCATION_FORM.PRESENT")}
                                </p>
                            </label>
                        </div>
                    </fieldset>

                    {/* City | State | Country */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-row gap-4 flex-wrap">
                            {/* City */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="city"
                                >
                                    {t("ADD_EDUCATION_FORM.CITY.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="city"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='city'
                                        value={educationDetails.city}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['city'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['city']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* State */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="state"
                                >
                                    {t("ADD_EDUCATION_FORM.STATE.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="state"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='state'
                                        value={educationDetails.state}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['state'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['state']}
                                        </p>
                                    }
                                </div>
                            </div>

                            {/* Country */}
                            <div className="flex-1 flex flex-col gap-2">
                                <label
                                    className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                    htmlFor="country"
                                >
                                    {t("ADD_EDUCATION_FORM.COUNTRY.LABEL")}
                                </label>

                                <div className="flex-1 flex flex-col">
                                    <input
                                        id="country"
                                        className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                        name='country'
                                        value={educationDetails.country}
                                        onChange={handleFormData}
                                    />
                                    {
                                        error['country'] &&
                                        <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                            {error['country']}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/*/!* Need Assistance *!/*/}
                    {/*<fieldset>*/}
                    {/*    <label className="flex flex-row items-center gap-2">*/}
                    {/*        <input*/}
                    {/*            type="checkbox"*/}
                    {/*            name='needAssistance'*/}
                    {/*            checked={needAssistance}*/}
                    {/*            onChange={() => setNeedAssistance(!needAssistance)}*/}
                    {/*        />*/}
                    {/*        <p className="font-SOHNE_REGULAR text-[1vw]">*/}
                    {/*            {t("ADD_EDUCATION_FORM.NEED_ASSISTANCE.LABEL")}*/}
                    {/*        </p>*/}
                    {/*    </label>*/}
                    {/*</fieldset>*/}

                    {/*/!* Assistance Description *!/*/}
                    {/*<fieldset>*/}
                    {/*    <div className="mt-[2vh] flex flex-col gap-2">*/}
                    {/*        <textarea*/}
                    {/*            disabled={!needAssistance}*/}
                    {/*            id="needAssistanceDescription"*/}
                    {/*            name='needAssistanceDescription'*/}
                    {/*            className="font-SOHNE_MEDIUM inline-flex rounded-md px-[1vw] py-[1vh] text-[1vw]*/}
                    {/*                    leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"*/}
                    {/*            placeholder={t("ADD_EDUCATION_FORM.NEED_ASSISTANCE.DESCRIPTION_PLACEHOLDER")}*/}
                    {/*            value={needAssistanceDescription}*/}
                    {/*            onChange={(e) => setNeedAssistanceDescription(e.target.value)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</fieldset>*/}
                </div>

                {/* Footer */}
                <div className={"max-h-[10vh]"}>
                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

                    <div className="flex items-center justify-end">
                        {/* Submit Button */}
                        <button
                            disabled={isAddEducationButtonDisabled}
                            onClick={handleSubmit}
                            className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
                        >
                            {t("ADD_EDUCATION_FORM.SUBMIT")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}