import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {GoogleOAuthProvider} from '@react-oauth/google';
import App from './App';
import AccountProvider from "./context/AccountProvider";
import NodeProvider from './context/NodeProvider';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import {Slide, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="634576573457-c188vi7gi1j7dsva35d3tkpc2880pd98.apps.googleusercontent.com">
            <AccountProvider>
                <NodeProvider>
                    <App/>
                    <ToastContainer
                        position="top-center"
                        autoClose={7500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                        transition={Slide}
                        bodyClassName={"font-SOHNE_MEDIUM"}
                        progressStyle={{
                            backgroundColor: "indianred"
                        }}
                    />
                </NodeProvider>
            </AccountProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
