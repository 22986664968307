import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";

export const Chapter13 = () => {
  	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					गोत्रे, प्रवरे कुलगुरु / कुलऋषि / महर्षि
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">भारताचे</span> अतिप्राचीन
						वाङ्मय. वेदवाङ्मयाच्या रचनेचा काळ ५ ते ६ हजार
						वर्षांपूर्वीचा मानतात. वेदांत मूळ ऋग्वेद व त्यानंतर
						यजुर्वेद, सामवेद व अथर्ववेद होत. ज्या ऋषि / महर्षींनी
						ऋग्वेदाचे अध्ययन / अध्यापन केले ते ऋग्वेदी झाले.
						चित्पावनांनी पूर्वजांची ही वेदगंगा वश केली. पूर्वी
						अध्ययन / अध्यापन आश्रमांत / कुलगुरू िंकवा कुलऋषीकडे /
						गुरुकुलांत होत असे. गुरुकुलात जे मुमुक्षु अध्ययनाला येत
						त्यांना कुलऋषीकडून गोत्र प्राप्त होत असे. कालांतराने
						त्या त्या शाखेतील त्या त्या गोत्राने ओळखले जाऊ लागले.
					</p>
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8"> चित्पावनांत</span> अशा
						प्रकारची चौदा गोत्रे वंशपरंपरेने चालत आलेली आढळतात.
					</p>
					<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg mx-auto">
						<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR">
							<div className="flex items-center justify-center mt-6">
								<table className="table-auto w-[350px]  text-left">
									<thead>
										<tr>
											<th className="px-4 py-3 ">
												कुलऋषि
											</th>
											<th className="px-4 py-3">
												: गोत्र
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="px-4 py-3">
												काश्यप
											</td>
											<td className="px-4 py-3">
												: काश्यप, शांडिल्य
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3 ">
												वासिष्ठ
											</td>
											<td className="px-4 py-3">
												: वासिष्ठ
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3">
												अंगिरस
											</td>
											<td className="px-4 py-3 ">
												: कौण्डिण्य
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3"></td>
											<td className="px-4 py-3">
												: विष्णुवर्धन
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3"></td>
											<td className="px-4 py-3">
												: नित्युंदन
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3 ">
												भारद्वाज
											</td>
											<td className="px-4 py-3 ">
												: भारद्वाज, गार्ग्य, कपि
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3">भृगू</td>
											<td className="px-4 py-3 ">
												: जामदग्नी, वत्स
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3 ">
												विश्वामित्र
											</td>
											<td className="px-4 py-3 ">
												: ब्राभ्रव्य, कौशिक
											</td>
										</tr>
										<tr>
											<td className="px-4 py-3 ">
												अत्रि
											</td>
											<td className="px-4 py-3 ">
												: अत्रि
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<p className="mb-5 leading-relaxed">
						{" "}
						<span className="pl-6 sm:pl-8"> गोत्रे,</span> प्रवरे व
						कुलऋषी यांवरून त्या कुलाच्या परंपरेचा बोध होतो. गोत्रे व
						प्रवरे यांची माहिती आश्वलायन व बौद्धायन या
						सूत्रग्रंथावरून मिळते. आश्वलनीय अग्नीची प्रार्थना
						करणाऱ्या ऋषीस प्रवर म्हणत. हे प्रवर वेगवेगळ्या गोत्राचे
						असत. गक, तीन, पाच प्रवर असत. प्रत्येक गोत्रांत ३ िंकवा ५
						प्रवरे मानतात. चित्पावनांमध्ये जास्तीत जास्त ऋग्वेदी
						आश्वलायनी असून बाकीचे हिरण्यकेशी (आपस्तंभ) आहेत.
						जामदग्नी व वत्स गोत्रे पंचप्रवरी असून बाकीची गोत्रे
						त्रिप्रवरी आहेत.
					</p>
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8"> अग्नीचे </span> आवाहन
						ज्या आदि महर्षींनी केले ते सप्तर्षी नावाने ओळखले जात.
					</p>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
					<hr />
				</div>
			</div>
		</div>
	);
};
