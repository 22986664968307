import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
export const Chapter16 = () => {
   	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					जोगळेकर कुलांची मूळ गावे
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8"> जोगळेकर</span>{" "}
						कुटुंबप्रमुखांनी भरून पाठविलेल्या माहितीचे संकलन करून
						त्याचा अभ्यास केल्यावर असे लक्षात येते की, जोगळेकर
						कुटुंबीयांची मूळ गावे खाली नमूद केल्याप्रमाणे आहेत. गकूण
						- माहिती पत्रकांपैकी बऱ्याचश्या माहितीपत्रकांत ‘मूळ
						गांवा‌’ विषयी माहिती भरलेलीच नाही. काही ठिकाणी ‘माहीत
						नाही‌’ असे नमूद केलेलेही दिसते.
					</p>
					<div className="flex items-center justify-center mt-6">
						<table className="table-auto w-[350px] text-left">
							<thead>
								<tr className="">
									<th className="px-4 py-2">क्रमांक</th>
									<th className="px-4 py-2">स्थान</th>
								</tr>
							</thead>
							<tbody className="">
								<tr>
									<td className="px-4 py-2">१.</td>
									<td className="px-4 py-2">हेदवी - नरवण</td>
								</tr>
								<tr>
									<td className="px-4 py-2">२.</td>
									<td className="px-4 py-2">साखरी आगर</td>
								</tr>
								<tr>
									<td className="px-4 py-2">३.</td>
									<td className="px-4 py-2">अडूर</td>
								</tr>
								<tr>
									<td className="px-4 py-2">४.</td>
									<td className="px-4 py-2">पालशेत</td>
								</tr>
								<tr>
									<td className="px-4 py-2">५.</td>
									<td className="px-4 py-2">रीळ - केसपुरी</td>
								</tr>
								<tr>
									<td className="px-4 py-2">६.</td>
									<td className="px-4 py-2">रीळ - माणगांव</td>
								</tr>
								<tr>
									<td className="px-4 py-2">७.</td>
									<td className="px-4 py-2">ढोकमळे</td>
								</tr>
								<tr>
									<td className="px-4 py-2">८.</td>
									<td className="px-4 py-2">साखरपा</td>
								</tr>
								<tr>
									<td className="px-4 py-2">९.</td>
									<td className="px-4 py-2">विटे</td>
								</tr>
								<tr>
									<td className="px-4 py-2">१०.</td>
									<td className="px-4 py-2">करचुंडे</td>
								</tr>
								<tr>
									<td className="px-4 py-2">११.</td>
									<td className="px-4 py-2">कोतवडे</td>
								</tr>
								<tr>
									<td className="px-4 py-2">१२.</td>
									<td className="px-4 py-2">
										गोकर्ण-महाबळेश्वर (कर्नाटक)
									</td>
								</tr>
								<tr>
									<td className="px-4 py-2">१४.</td>
									<td className="px-4 py-2">नेरळ</td>
								</tr>
								<tr>
									<td className="px-4 py-2">१६.</td>
									<td className="px-4 py-2">त्र्यंबकेश्वर</td>
								</tr>
								<tr>
									<td className="px-4 py-2">१७.</td>
									<td className="px-4 py-2">जुन्नर</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8"> जास्तीत </span>जास्त जोगळेकर कुलांचे मूळ गाव
						‘हेदवी‌’ असल्याचे दिसते. बाकीची मूळ गावेही रत्नागिरी
						जिल्हा िंकवा कोकणप्रदेशातील दिसतात.
					</p>
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8"> हेदवी</span> हे गाव रत्नागिरी जिल्ह्यात - गुहागर
						तालुक्यात असून चिपळूण-गुहागर-हेदवी या मार्गाने तेथे जाता
						येते. हे गाव फार प्राचीन नसले तरी पेशवेकाळात अस्तित्वात
						होते असे दिसते. श्री. नाना फडणवीस व अहिल्याबाई होळकर
						यांनी या गावास भेट दिल्याचे इतिहासात आढळते. हेदवी हे
						धारेकरी गाव म्हणजे रेव्हेन्यू व्हिलेज म्हणून पेशवाईत व
						नंतर ब्रिटिश सरकारमध्ये नमूद केलेले गाव आहे.
					</p>
					<p className="mb-5 leading-relaxed">हेदवी नाव कसे पडले ?</p>
					<p className="mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8"> हेदवी </span>नावाची व्युत्त्पती खालीलप्रमाणे
						असावी असे वाटते. हेदवी हे गावाचे नाव ‘हेदी‌’ ह्या
						वृक्षामुळे पडले असावे. पूर्वीच्या काळी ह्याचे आजूबाजूला
						ह्या हेदी वृक्षांची जंगले होती. हेदी हे मराठीतील नाव; पण
						त्याचे संस्कृत भाषेतील नाव ‘शाखोटक‌’ ‘शाकोटक िंकवा शाकोट
						असे आहे. या वृक्षासंबंधी संस्कृतातील काही श्लोकांमध्ये
						थोडेसे वर्णन १०व्या शतकातील कवीने केलेले आढळते. याचा
						अर्थ हा हेदी वृक्ष, वड, िंपपळ, शाल्मली, करीर, गेन,
						िंकजळ, पळस आदी वृक्षांसारखा प्राचीन आहे. हेदीच्या
						वर्णनांत ‘हेदी‌’ला पाने-फुले असतात पण गंध / वास नाही.
						हेदी वृक्ष, भरपूर सावली देणारा पण त्या सावलीत कुणी
						विश्रांती घेत नसत. कारण िंपपळावर जसा मुंजा तसा ह्या
						वृक्षावर भुते वास करतात, अशी समजूत आहे. या वृक्षाचे
						बोटॅनिकल नाव TROPHIS ASPERA असे असावे. हेदीचे लाकूड घरे
						बांधायला तसेच नक्षीकाम करायला उत्तम असते. घराच्या मुख्य
						दरवाज्यासाठी या लाकडाचा उपयोग करीत असत.
					</p>
					<p className="mb-5 leading-relaxed">हेदवी गावाच्या नावाची दुसरी व्युत्त्पती अशी असावी.</p>
					<p className="mb-5 leading-relaxed"> 
						<span className="pl-6 sm:pl-8">हेदवीची </span>ग्रामदेवता नवलाई देवी. या देवीस
						उद्देशून ‘‘हे देवी‌’’, ‘‘हे देवी‌’’ म्हणता म्हणता,
						‘हेदवी‌’ असे नाव.{" "}
					</p>
				</div>
        <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr/>
			</div>
		</div>
	);
};
