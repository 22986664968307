import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";

export const Chapter3 = () => {
  	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
  return (
    <div>
      <Header />
      <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
        <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
          संपादकीय
        </h1>
        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
          <p className="mb-5 leading-relaxed ">
            {" "}
            <span className="pl-6 sm:pl-8">ही</span> दसरी आवृत्ती प्रकाशित
            करताना संपादक मंडळास विशेष आनंद होत आहे.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">प्रत्येकाला</span> आपल्या कुलाचा
            (आडनावाचा िंकवा घराण्याचा) अभिमान वाटत असला तरी त्याची गेतिहासिक
            माहिती मात्र फारशी असत नाही. अनेकांना पणजोबांचे नावही सांगता येत
            नाही; मग त्या आधीच्या पिढ्यांबाबत ‘माहिती नाही‌’ यापलीकडे आपली मजल
            जात नाही. दोन-तीन पिढ्यांपूर्वी सर्वत्र गकत्र कुटुंबे होती. सख्खी,
            सावत्र, चुलत, जवळची, दूरची मिळून प्रत्येक घरात वीस-पंचवीस
            स्त्री-पुरुष तरी असतच. गकाच ठिकाणी वर्षानुवर्षांच्या सहजीवनामुळे
            नात्याचे पाश अधिक दृढ असत. गकमेकांवर अवलंबून राहावे लागत असल्यामुळे
            गकमेकांच्या उपयोगी पडण्याची वृत्तीही होती. त्या जुन्या
            कुटुंबव्यवस्थेत सगळे काही आबादीआबाद होते असे मात्र नाही. संसाराचा
            मुख्य भार कुणावर आहे, कुणाचे शिक्षण आणि वेतन कमी आहे की जास्त,
            यावरून बरीच धुसफूस चालत असे. कुटुंबातील कर्ता पुरुष खंबीर असेल तर
            अशाही परिस्थितीत ही भांडणे चव्हाट्यावर येत नसत पण प्रकट िंकवा
            प्रच्छन्न अशी भाऊबंदकी असेच. गेल्या पन्नास वर्षांमध्ये विशेषत:
            दुसऱ्या महायुद्धानंतर या सगळ्यात बदल होत गेला आहे.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">१९३०</span> मध्ये जी आर्थिक मंदीची
            लाट सर्वत्र आली तिचा आपल्या समाजव्यवस्थेवर मोठाच परिणाम झाला.
            घरातल्या प्रत्येकाने मिळवते होण्याची गरज निर्माण झाली. जिथे नोकरी
            मिळेल तिथे जाणे ओघानेच आले. दुसऱ्या महायुद्धाच्या काळात
            अल्पशिक्षितांनाही नोकऱ्या मिळू लागल्या. मुंबई-पुणे-नागपूर सारख्या
            मोठ्या शहरांकडे लोकांची रीघ लागली. जागेची अपूर्व टंचाई, जीवनावश्यक
            वस्तूंचा अपुरा पुरवठा, नियंत्रण (कंट्रोल) ठरावीक प्रमाणातच मिळणाऱ्या
            वस्तू (रेशन) हे सगळे हळूहळू अंगवळणी पडत गेले. स्त्री-शिक्षणात
            लक्षणीय वाढ झाल्यामुळे अर्थार्जनाची क्षेत्रे स्त्रियांनाही खुली
            झाली. ‘नोकरी करणारी स्त्री‌’ ही नवलाईची गोष्ट न राहता ती प्रत्येक
            कुटुंबाची गरज झाली. स्वातंत्र्यानंतरच्या कुळकायद्यामुळे शेतीशी आणि
            खेडेगावाशी असलेला संबंधही नाहीसा झाला. या सगळ्याचा परिणाम
            कुटुंबव्यवस्थेवर होणे अटळ होते. लग्नकार्यासारख्या प्रसंगी गकत्र
            जमण्यापुरते नातेसंबंध राहिले. माहेरवाशिणी पूर्वी वर्षातून गकदा तरी
            माहेरी येत असत, तेही आता राहिले नाही. परिस्थितीमुळे माणसे पांगली,
            गका शहरात असूनही वेगवेगळी राहू लागली. गेल्या तीस-पस्त्तीस
            वर्षांपासून तर शिक्षण, नोकरी यांमुळे परदेशी जाऊन तेथे स्थायिक
            होणाऱ्यांचे प्रमाणही पुष्कळ वाढले आहे. नात्यागोत्यातले
            येण्याजाण्याचे प्रमाण आणखीनच घटले.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">परिस्थितीमुळे</span> माणसामाणसांत असे
            अंतर पडले असल्यामुळेच कुलवृत्तान्तसारख्या ग्रंथाची गरज आज अधिक
            भासते. आपले आडनाव, घराणे, गोत्र, कुल यांबद्दल अभिमानाची आणि
            आत्मीयतेची भावना समाजमनात असणे स्वाभाविक आहे. आपल्या कुलातील
            बंधू-भगिनी कोण आहेत, कुठे आहेत, कसे आहेत हे जाणून घेण्याची ओढ
            प्रत्येकाला असते. त्यामुळेच आज अधिकाधिक घराण्यांचे ‘कुलवृत्तान्त‌’
            प्रकाशित होत आहेत.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">गेल्या</span> ६०-७० वर्षांत
            चित्पावनांचे जे जे कुलवृत्तान्त प्रकाशित झाले त्या सर्वांमध्ये काही
            समान बाबी आढळतात. बदलापूरचे समाजशास्त्रज्ञ कै.ना.गो.(नानासाहेब)
            चापेकर यांनी ‘चित्पावन‌’ हा ग्रंथ प्रसिद्ध केल्यानंतर या विषयाकडे
            अनेकांचे लक्ष वेधले. ‘चित्पावन‌’ या शब्दाची व्युत्पत्ती,
            चित्पावनांची आडनावे व त्यांची १४ गोत्रांमधील विभागणी, गकाच गोत्रात
            येणारी आडनावे, त्या त्या आडनाव - धारकांचे कोकणातील मूळ गाव (िंकवा
            गावे), चित्पावनांची ग्रामदैवते, कुलस्वामी, कुलस्वामिनी, कुलाचार,
            सण-समारंभ िंकवा विशिष्ट विधी यांसंबंधी प्रत्येक कुलवृत्तान्तात समान
            मजकूर दिलेला आढळतो. कोकणभूमी निर्माण करणारा ‘परशुराम‌’ व
            चित्पावनांची कुलस्वामिनी अंबाजोगाईची ‘योगेश्वरी‌’ यांच्या माहितीचा
            समावेशही अशा प्रत्येक ग्रंथात असतो. प्रस्तुत ग्रंथात या प्रकारच्या
            माहितीचा समावेश केला आहेच; याशिवाय चित्पावनांसंबंधी अलीकडे झालेल्या
            नव्या संशोधनाचाही परामर्श घेतला आहे.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">कोणत्याही</span> कुलवृत्तान्ताच्या
            गाभ्याचा भाग म्हणजे उपलब्ध माहितीच्या आधारे कुलातील बंधू-भगिनींची
            अकारानुक्रमाने व वंशावळीनुसार ग्रंथित केलेली नोंद होय. तसेच
            देश-विदेशांत विखुरलेल्या जोगळेकरांशी संपर्क साधणे, त्यांना वारंवार
            स्मरणपत्रे पाठविणे, आलेल्या प्रपत्रांची गाववार व वंशावळीनुसार
            विभागणी करणे, अपुऱ्या व त्रोटक माहितीचा छडा लावणे, विविध क्षेत्रे
            गाजविणाऱ्या जोगळेकर स्त्री-पुरुषांचा शोध घेणे, त्यांच्यासंबंधी
            विस्तृत माहिती संकलित करणे, माहितीचा खरेखोटेपणा पडताळून पाहणे, अन्य
            कुलांत गेलेल्या गुणसंपन्न माहेरवाशिणींचा शोध घेणे या सर्व गोष्टी
            खूपच महत्वाच्या ठरतात. प्रत्येक कुलाची कीर्ती आपल्या कर्तृत्वाने
            विविध क्षेत्रे गाजविणाऱ्या बंधू-भगिनींच्या गुणवत्तेवर अवलंबून असते.
            अशाच व्यक्ती त्या त्या कुलाचा लौकिक वाढवीत असतात, त्यांचा अभिमानाने
            नेहमी निर्देश केला जातो. अशा कर्तृत्ववान व्यक्तींची संख्या जितकी
            जास्त त्या प्रमाणात ते आडनाव समाजमनात विशेष मान्यता पावते.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">कर्तृत्वसंपन्न </span>
            स्त्री-पुरुषांमुळे चित्पावनांची काही आडनावे विशेष प्रसिद्ध आहेत.
            गोखले, फडके, गोडबोले, आपटे, रानडे, भावे, साठे, भिडे, जोशी (शांडिल्य
            गोत्राचे), पटवर्धन, गाडगीळ ही आडनावे संख्याबळ आणि कर्तृत्व यांमुळे
            विशेष आघाडीवर असल्याचे दिसते. परांजपे, चिपळूणकर, टिळक, कर्वे,
            सावरकर, राजवाडे या कुलांतील व्यक्तींची संख्या काही फार मोठी नाही; पण
            या कुलात इतिहास घडविणाऱ्या लोकोत्तर विभूती निर्माण झाल्यामुळे या
            कुलांना सार्वत्रिक मान्यता आहे. चित्पावनात काही आडनावे अशी आहेत की
            ती फारशी प्रसिद्ध नाहीत व त्या कुलात असामान्य योग्यतेच्या व्यक्तीही
            क्वचितच आढळतात. (उदा. किडमिडे, खंजणे, तिरुके, सुकाडकर इ.) बाकीची
            पन्नास-साठ आडनावे अशी आहेत की त्यांचे संख्याबळही लक्षणीय आहे आणि
            विविध क्षेत्र गाजविणाऱ्या स्त्री-पुरुषांमुळे समाजजीवनात ती चांगली
            प्रसिद्धीही पावली आहेत. आपले जोगळेकर कुल हे अशा कुलांपैकी गक आहे.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">या</span> कुलवृत्तान्ताच्या
            निमित्ताने गकूण जोगळेकर कुटुंबे किती आहेत याचा शोध घेण्याचा प्रयत्न
            करण्यात आला. आजपर्यंत सुमारे १७०० कुटुंबांकडून माहितीचे फॉर्म्स भरून
            मिळाले. हस्ते परहस्ते आणखी अनेक जोगळेकरांची माहिती मिळाली. ही सर्व
            या ग्रंथात समाविष्ट केली आहे. ज्यांचे पत्ते मिळाले नाहीत,
            ज्यांच्यापर्यन्त आमचे कार्यकर्ते पोहोचू शकले नाहीत आणि ज्यांचा शोध
            लागला पण या ना त्या कारणामुळे ज्यांच्याकडून प्रतिसाद मिळाला नाही अशी
            कुटुंबे विचारात घेतली तर जोगळेकर आडनाव धारण करणारी १६०० ते २०००
            कुटुंबे आहेत असे स्थूलमानाने म्हणता येते. गका कुटुंबात साधारणपणे पाच
            व्यक्ती असतात असे मानले तर आठ हजार ते दहा हजार गवढी विद्यमान
            जोगळेकरांची संख्या ठरते. आता आठ कोटीच्या मराठी लोकसंख्येत दहा हजार
            ही संख्या अत्यल्प आहे, हे उघड आहे. पण या दहा हजारात कर्तृत्वसंपन्न
            स्त्री-पुरुषांची संख्या सतत वाढती असल्यामुळे महाराष्ट्राच्या
            समाजजीवनात जोगळेकर या आडनावाने आपला ठसा उमटविला आहे हे मान्य करावे
            लागेल.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">लष्करी</span>
            सेवा, शासकीय सेवा, प्रशासन, शिक्षण, प्रसारमाध्यमे, साहित्य, संगीत,
            नाट्य, नृत्य इ. कला, दुकानदारी, व्यापार, उद्योग अशा बहतेक सर्व
            क्षेत्रांत जोगळेकरांचा संचार होत आहे. १८५७ च्या स्वातंत्र्ययुद्धात
            हौतात्म्य पत्करलेले त्र्यंबक येथील वासुदेव भगवंत जोगळेकर, किर्लोस्कर
            नाटक कंपनीचे मालक व बालगंधर्वांच्या बरोबरीने रंगभूमी गाजविणारे पहिले
            ‘धैर्यधर‌’ नानासाहेब जोगळेकर, भारतात कम्युनिस्ट पक्षाच्या
            संस्थापकांपैकी गक कॉ.के.नी.जोगळेकर, संशोधन-संपादनाचा मानदंड निर्माण
            करणारे रसिकाग्रणी लेखक ‘सह्याद्रिकर्ते‌’ स.आ.जोगळेकर, मागल्या
            पिढीतील सिद्धहस्त विनोदी लेखक बा.ज.जोगळेकर, हेदवी या जोगळेकरांच्या
            मूळ गावाचा कायापालट घडवून हेदवीला देखणे पर्यटनस्थळ म्हणून मान्यता
            मिळवून देणारे काकासाहेब जोगळेकर, खडकवासला येथील जलसंशोधन प्रकल्पाचे
            ‘पद्मश्री‌’ दि.वा.जोगळेकर, उत्तमोत्तम मराठी व हिन्दी चित्रपटांचे
            निर्माते - दिग्दर्शक वसंतराव जोगळेकर, कानपूर-अलाहाबाद येथील गाजलेले
            कार्यकर्ते वकील गो.का.जोगळेकर, १९ व्या शतकातील गाजलेल्या पौराणिक
            नाटकांचे व प्रहसनांचे (फार्स) लेखक द.बा.जोगळेकर, रंगभूमी गाजविलेले
            दांपत्य (श्रीधर व सौ.ललिता) जोगळेकर, नाटक, चित्रपट व दूरदर्शन
            यांमधील अभिनय कुशल नट अरुण जोगळेकर, वाटवे - नावडीकर कालखंडातील
            प्रसिद्ध भावगीतगायक अशोक जोगळेकर, ‘सोशल क्लबच्या‌’ हौशी रंगभूमीची
            धुरा वाहणारे रावसाहेब व बंडोपंत जोगळेकर, नाट्यसृष्टीतील नामांकित
            रंगभूषाकार नाना जोगळेकर पुण्याचे माहीतगार लेखक वि.श्री.जोगळेकर,
            ‘संजीवनी‌’ या गाजलेल्या कांदबरीच्या लेखिका चंद्रप्रभा जोगळेकर,
            स्वतंत्र भारतातील केंद्रशासनाचे पहिले वास्तू व नगर रचनाकार
            (आर्किटेक्ट) श्रीधर कृष्ण जोगळेकर, त्र्यंबकेश्वर देवस्थानचे पहिले
            कारभारी नारायण दामोदर जोगळेकर, काशी येथील वैदिक पंडितांच्या सभेत
            बहमान प्राप्त झालेले घनपाठी वेदमूर्ती नरहरभट जोगळेकर, बेळगाव येथील
            शैक्षणिक - सांस्कृतिक जीवनाचे नेतृत्व करणारे शं.वि.जोगळेकर, गोकर्ण
            येथील क्षेत्रोपाध्ये वेदमूर्ती यज्ञेश्वरभट जोगळेकर, बृहन्मुंबई
            महानगरपालिकेचे प्रमुख अभियंता व उपायुक्त बा.वि.जोगळेकर, दिल्ली येथील
            राष्ट्रीय पदार्थविज्ञान संस्थेचे माजी संचालक डॉ.गजानन दामोदर
            जोगळेकर, मे.ज.(निवृत्त) अरिंवद मोरेश्वर जोगळेकर, रिझर्व बँकेच्या
            नाबार्ड विभागातील कर्तव्यदक्ष अधिकारी महादेव कृ.जोगळेकर, चाळीसगाव
            येथील प्रख्यात वकील धों.के.जोगळेकर, शैक्षणिक पुस्तकांच्या
            प्रकाशनासाठी प्रसिध्द असलेले नूतन प्रकाशनचे गो.के.जोगळेकर, सर्व
            आयुष्य तामीळनाडूत संघ प्रचार कार्याला वाहिलेले चेन्नईचे शिवराम पंत
            जोगळेकर राजकीय घडामोडींचे दिल्ली आकाशवाणीचे भाष्यकार काशिनाथ गो.
            जोगळेकर, अपंग सेवेच्या कार्याला वाहून घेतलेले मुंबईचे अच्युत जयराम
            जोगळेकर, शिक्षण व साहित्य संस्था यात दिर्घकाळ कार्य केलेेले भाषा
            अभ्यासक समीक्षक डॉ. गं.ना. जोगळेकर, पुस्तक लेखनाचे पहिले शतक पूर्ण
            करून दुसऱ्या शतकाची वाटचाल करणाऱ्या लेखिका, गायिका, कवयित्री, योगिनी
            जोगळेकर, विविध प्रकारचे लेखन करणाऱ्या डॉ. मृणालिनी जोगळेकर, नाट्य
            समीक्षक डॉ. सुषमा जोगळेकर, हौशी रंगभुमीवरील नट व दिग्दर्शक श्री.
            महादेव आत्माराम जोगळेकर, सात्विकतेची मूर्ती असलेल्या ज्येष्ठ
            अभिनेत्री सुमती गुप्ते-जोगळेकर अशा कितीतरी कर्तबागर स्त्री-पुरुषांनी
            जोगळेकर कुलाची कीर्ती वाढविलीच, पण आपल्या समाजाचीही सेवा केली
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">आपल्या</span> पूर्वजांचा हा
            प्रेरणादायी वारसा अनेक विद्यमान जोगळेकर पुढे चालवत आहेत ही स्मरणीय
            गोष्ट होय. भारतीय सैन्यात कॅप्टन पासून मेजर जनरल अशा हद्यांपर्यंत
            प्रगती केलेले अनेक अधिकारी हे जोगळेकर कुलातील आहेत.{" "}
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">मे.ज.(निवृत्त)प्रभाकर</span> काशिनाथ
            तसेच मे.ज.(निवृत्त) विश्वास सदाशिव ही त्यांतील उल्लेखनीय नावे. इतर
            चित्पावनांप्रमाणे शिक्षण व वैद्यकीय सेवा हे जोगळेकरांचे विशेष
            क्षेत्र आहे. पुणे विद्यापीठाच्या िंहदी विभागातील निवृत्त प्रपाठक डॉ.
            न.िंच. जोगळेकर हे महाराष्ट्रातील िंहदीचे ज्येष्ठ प्राध्यापक होते.
            युद्धशास्त्राचे गाढे अभ्यासक पत्रकार ज.द.जोगळेकर, शीव येथील टिळक
            हॉस्पिटलचे माजी प्रमुख डॉ.श्री.वा.जोगळेकर, गेली पंचेचाळीस वर्षे
            गणिताचे अध्यापन करून दक्षिण महाराष्ट्रातील महाविद्यालयीन
            विद्यार्थ्यांच्या अनेक पिढ्या घडविणारे प्रा.भास्कर जोगळेकर, प्रतिकूल
            परिस्थितीत चिकाटीने काम करून मडगाव (गोवा) येथील शाळा नावारूपाला
            आणणारे मुख्याध्यापक र.वि. जोगळेकर, शैक्षणिक प्रश्नांवरील विपुल
            निबंधलेखनासाठी प्रसिद्ध असलेले बृहन्मुंबई महानगरपालिकेचे निवृत्त
            शिक्षणाधिकारी वें.कृ.जोगळेकर, जळगावच्या उत्तर महाराष्ट्र
            विद्यापीठाचे पहिले कुलसचिव श्री.प.जोगळेकर, सामाजिक प्रश्नांचा
            पाठपुरावा करून लोकजागृती साधणारे ॲड.ग.नी.जोगळेकर, मुंबई येथील
            इंग्रजीचे निवृत्त प्राध्यापक भास्कर नरहर जोगळेकर, दिल्ली येथील श्री.
            हरिहर जोगळेकर, रायपूर (मध्यप्रदेश) येथील सामाजिक व सांस्कृतिक
            क्षेत्रातील कार्यकर्ते प्रा.यशवंत गोिंवद जोगळेकर, उगार शुगर वर्क्सचे
            निवृत्त अधिकारी व लेखापरीक्षक रामचंद्र ग. जोगळेकर, नॅशनल इंटिग्रेटेड
            मेडिकल असोशिगशनचे िंपपरी येथील अध्यक्ष डॉ.आर्‌.व्ही.जोगळेकर, ससून
            हॉस्पिटलचे माजी डीन डॉ.ग.वि.जोगळेकर, तरुण भारत व लोकसत्ताचे प्रसिद्ध
            पत्रकार सुधीर जोगळेकर, विविध प्रकारचे लेखन करणाऱ्या डॉ.निवेदिता
            जोगळेकर, प्रसिद्ध कवी हेमंत गोिंवद जोगळेकर व मििंलद जोगळेकर,
            ज्योतिषशास्त्रावर कन्नड भाषेत विपुल ग्रंथ लिहिणारे धारवाडचे
            ज्योतिर्विद नागनाथ कल्लोपंत जोगळेकर अशा अनेकांच्या कार्याची नोंद
            अवश्य घेतली पाहिजे.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">कलेच्या</span> क्षेत्रात चमकण्ााऱ्या
            जोगळेकर स्त्री-पुरुष कलावंतांची संख्याही पुष्कळ मोठी आहे. अनेक
            चित्रपटांचे, लघुपटांचे व अनुबोधपटांचे छायालेखक श्री.बाळ जोगळेकर,
            आघाडीच्या नृत्यांगना व अभिनेत्री अशा मायलेकी सौ.आशा व अर्चना
            जोगळेकर, अल्पावधीत मान्यता मिळालेल्या पद्मश्री पद्मजा
            फेणाणी-जोगळेकर, संगीत समीक्षक अलका जोगळेकर, पी.डी.ग. व थिगटर
            ॲकाडमीचे गाजलेले कलावंत श्री.दिलीप जोगळेकर प्रथितयश गायिका रंजना
            जोगळेकर या सर्वांप्रमाणे क्रिकेटचे मैदान गाजविणारा मनोज जोगळेकर,
            आघाडीचे निसर्ग छायाचित्रकार म्हणून डॉ.प्रकाश जोगळेकर,मुंबईचा शाळेतील
            विद्यार्थी बालश्री पुरस्काराने सन्मानीत चि. आदित्य वैभव ही सर्वमान्य
            नावे आहेत.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">विज्ञानाच्या</span> आणि संगणकाच्या या
            युगात स्वतंत्र व्यवसायाच्या व उद्योगाच्या क्षेत्रात धडाडीने पाऊल
            टाकणारे अनेक जोगळेकर आज नाव कमावून आहेत. जमीन जुमल्याच्या देवघेवीचा
            व्यवसाय करणारे (इस्टेट ब्रोकर) श्री.प्र.शि.उर्फ मामासाहेब जोगळेकर हे
            नाशिक परिसरातील आदरणीय व्यक्तिमत्त्व आहे. श्री.श्रीकांत जोगळेकर,
            श्री.भारत रामकृष्ण जोगळेकर, सौ.वृंदा जोगळेकर, पार्ल्याचे श्री.
            विठ्ठल वा. जोगळेकर असे अनेक मुंबईकर, श्री.श्याम जोगळेकर, श्री. किशोर
            जोगळेकर, श्री.विद्याधर जोगळेकर, श्री.चारुदत्त जोगळेकर यांसारखे
            पुण्याचे जोगळेकर आज उद्योगाच्या क्षेत्रात कार्यरत आहेत. इंदोर येथील
            श्री.आनंद भालचंद्र जोगळेकर हे माळवा भागातील गाजलेले उद्योजक आहेत.
            रूढ नोकरीची चाकोरी न स्वीकारता लघुउद्योगाच्या क्षेत्रात जिद्दीने
            उतरलेले नवीन पिढीतील अनेक जोगळेकर आज आढळतात. लेखापरीक्षक म्हणून अमोल
            जोगळेकर यांचे कर्तृत्वही लक्षणीय आहे.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">जोगळेकर</span> कुलातील अनेक
            माहेरवाशिणींची गुणवत्ता दाद देण्यासारखी आहे. मुंबई विद्यापीठाच्या
            माजी विद्यमान कुलगुरु डॉ.स्नेहलता देशमुख हे यांपैकी अग्रगण्य
            व्यक्तिमत्त्व आहे. नागपूरच्या सामाजिक सांस्कृतिक क्षेत्रात कार्य
            करणाऱ्या सौ.स्वाती जोगळेकर-शहाणे हे आणखी गक उदाहरण. गृहोपयोगी
            कलाविषयक अनेक पुस्तकांचे लेखन केलेल्या श्रीमती प्रतिभा काळे आणि
            पाकक्रियेवरील गाजलेल्या पुस्तकांच्या लेखिका श्रीमती मंगला बर्वे या
            दोघी बहिणी आपल्या वडिलांचा (स.आ.जोगळेकर) वारसा समर्थपणे चालवीत आहेत.
            आजच्या आघाडीच्या गायिका सौ.पद्मा तळवलकर, दूरदर्शनच्या यशस्वी
            निर्मात्या विजया जोगळेकर-धुमाळे, अमेरिकेत स्थायिक झालेल्या गायिका
            सौ.अनुपमा सहस्रबुद्धे, सुगम संगीताच्या क्षेत्रातील निपुण कलावती
            सौ.संपदा जोगळेकर-कुलकर्णी पुण्यातील प्रसिध्द बायो कर्वे पुरस्काराने
            सन्मानीत दोन माहेरवाशिणी सौ. रंजना परांजपे व सौ. मीना इनामदार हा
            पुरस्कार प्रतिकूल परिस्थितीशी झगडून सामाजिक काम करणाऱ्या स्त्रियांना
            दिला जातो. या सर्वजणींनी जोगळेकर कुलासही गौरव प्राप्त करून दिला आहे.
            यांसारख्या अनेक माहेरवाशिणींचा जोगळकरांना अभिमान वाटणे स्वाभाविक
            आहे.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">बदलत्या</span> परिस्थितीनुसार जोगळेकर
            कुलातील अनेकांनी आता परदेशात वास्तव्य केले आहे. इंग्लंड,
            ऑस्ट्रेलिया, अमेरिका, कॅनडा अशा प्रमुख देशांत (आणि इतरत्रही) नोकरी व
            व्यवसायाच्या निमित्ताने अनेक जोगळेकर कुटुंबे आज स्थायिक झाली आहेत.
            आपला नोकरी-व्यवसाय उत्तम प्रकारे पार पाडून त्यातील अनेकजण
            सामाजिक-सांस्कृतिक उपक्रमात सहभागी होत असतात. ‘सुंदरा मनामधे भरली‌’
            या नृत्यसंगीतमय कार्यक्रमाचे संगीतनियोजन करणारे व तालवाद्यांच्या
            साथीची जबाबदारी पेलणारे श्री. जोगळेकर हे अशांपैकी गक प्रातिनिधिक नाव
            म्हणता येईल. पूर्वापार परंपरेने िंकवा नव्याने कर्नाटक, मध्यप्रदेश,
            गुजरात अशा प्रान्तांमधील अनेक जोगळेकर त्या त्या प्रदेशातील समाजाशी
            समरस झाले आहेत.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">आतापर्यन्त</span> दिवंगत िंकवा
            विद्यमान अशा ज्या बंधू-भगिनींचा निर्देश केला तो केवळ नमुन्यादाखल
            होय. यापेक्षा कितीतरी अधिक संख्येने जोगळेकर कुलोत्पन्नांनी आपल्या
            कुलाच्या लौकिकात भर घातली आहे. कुलवृत्तान्ताच्या व्यक्ती-नोंदीच्या
            मुख्य विभागात संबंधितांची माहिती देण्यात आली आहे, त्यावरून कल्पना
            येईल.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">आधी</span> म्हटल्याप्रमाणे,
            चित्पावनातील काही आडनावे विशेष प्रसिद्ध व अग्रगण्य आहेत. ‘जोगळेकर‌’
            हे आडनाव भरपूर संख्याबळ असलेले व कर्तृत्वसंपन्न स्त्री-पुरुषांनी
            विभूषित झाले असले तरी ते आघाडीचे वाटत नाही. जी घराणी पेशवे
            काळापासूनच कोकणाबाहेर पडली त्यांना आपले कर्तृत्व सिद्ध करायला अधिक
            वाव मिळाला. पेशवाई संपून इंग्रजी राजवट सुरू झाल्यानंतर त्यांनी नव्या
            काळातही आपली योग्यता सिद्ध केली. त्र्यंबकेश्वर येथील सरकारवाड्याच्या
            व्यवस्थापनाचे काम जोगळेकरांकडे होते हा अपवाद सोडला, तर पेशवेकाळात
            जोगळेकर नाव आढळत नाही. जोगळेकर हे गकाच गका गावातील मूळचे रहिवासी
            नव्हते आणि त्यांनी दीर्घकाळपर्यन्त आपली गावे सोडली नाहीत हे याचे
            प्रमुख कारण म्हणता येईल. जे कोकणातच राहिले ते वेदविद्येचे
            निष्ठापूर्वक जतन करीत राहिले आणि कष्टाची शेती करीत राहिले. साहजिकच
            त्यांची आर्थिक स्थिती नेहमीच बेताची राहिली. सरदार पटवर्धनांच्या
            आश्रयाने जेव्हा काही जोगळेकर देशावर आले तेव्हा त्यांच्या कर्तृत्वाला
            अनेक धुमारे फुटले. पण तोपर्यन्त इतर घराणी आधीच देशावर आली असल्यामुळे
            त्यांना आघाडीचे स्थान प्राप्त झाले. हा इतिहास लक्षात घेऊन पुढील
            शतकात ‘जोगळेकर‌’ या आडनावाला आघाडीचे स्थान प्राप्त होण्यासाठी नव्या
            पिढीला अधिक प्रयत्न करावे लागतील. दोनचार पिढ्यांपूर्वी जे जोगळेकर
            आपले पूर्वज होते त्यातील बहतेकांचे शिक्षण बेताचे होते; त्यामुळे
            मिळेल ती नोकरी पत्करून त्यांनी संसाराचा गाडा ओढला. शालेय शिक्षणाचा
            लाभ न झालेल्या त्या काळातील जोगळेकर माता-भगिनींना कष्टपूर्वक
            संसाराचा भार वाहावा लागला. त्या काळातील जोगळेकर स्त्री-पुरुषांनी
            अबोलपणे खस्ता खाल्लया म्हणून आजच्या पिढ्यांना सुखाचे दिवस दिसत आहेत,
            हे विसरून चालणार नाही.
          </p>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr/>
        </div>
      
      </div>
    </div>
  );
};
