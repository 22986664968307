import React, {useState} from 'react';
import {t} from "i18next";
import {Modal} from "../components/Modal";
import {addLanguage} from "../service/accountApi";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {Transition} from "@headlessui/react";

export const AddLanguageForm = (props) => {
    const {showAddLanguageForm, setShowAddLanguageForm, accountDetails, setAccountDetails} = props;

    const languageProficiencyOptions = [
        {
            label: t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_1"),
            value: 'Elementary Proficiency'
        },
        {
            label: t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_2"),
            value: 'Limited Working Proficiency'
        },
        {
            label: t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_3"),
            value: 'Professional Working Proficiency'
        },
        {
            label: t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_4"),
            value: 'Full Professional Proficiency'
        },
        {
            label: t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_5"),
            value: 'Native or Bilingual Proficiency'
        }
    ]

    const [error, setError] = useState({});

    const [isLanguageProficiencyDropdownOpen, setIsLanguageProficiencyDropdownOpen] = useState(false);

    const [isAddLanguageButtonDisabled, setIsAddLanguageButtonDisabled] = useState(false);

    const [languageDetails, setLanguageDetails] = useState({
        languageTitle: '',
        languageProficiency: 'Elementary Proficiency',
    });

    const languageProficiencyLabel = (languageProficiency) => {
        switch (languageProficiency) {
            case 'Elementary Proficiency':
                return t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_1");
            case 'Limited Working Proficiency':
                return t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_2");
            case 'Professional Working Proficiency':
                return t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_3");
            case 'Full Professional Proficiency':
                return t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_4");
            case 'Native or Bilingual Proficiency':
                return t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_5");
            default:
                return t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_1");
        }
    }

    const handleLanguageProficiencySelect = (languageProficiency) => {
        setIsLanguageProficiencyDropdownOpen(false);
        setLanguageDetails({
            ...languageDetails,
            languageProficiency: languageProficiency
        });
    }

    const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

        setLanguageDetails({
            ...languageDetails,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ""
        });
    }

    const validate = () => {
        let err = {};

        if (languageDetails.languageTitle === '') {
            err['languageTitle'] = t("ADD_LANGUAGE_FORM.LANGUAGE_TITLE.REQUIRED_ERROR");
        }

        if (languageDetails.languageProficiency === '') {
            err['languageProficiency'] = t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.REQUIRED_ERROR");
        }

        return err;
    }

    const handleSubmit = async () => {
        console.log("Inside Handle Submit!");

        setIsAddLanguageButtonDisabled(true);
        const err = validate();

        if (Object.keys(err).length > 0) {
            setError(err);
            setIsAddLanguageButtonDisabled(false);
            return;
        }

        console.log("Language Details: ", languageDetails);

        const languageData = {
            userAccountId: accountDetails._id,
            languageDetails: languageDetails,
        };
        const addLanguageDataResponse = await addLanguage(languageData);

        console.log('(AddLanguageForm) Add Language Data Response: ', addLanguageDataResponse);

        if (addLanguageDataResponse.success) {
            setAccountDetails(addLanguageDataResponse.data);
            setShowAddLanguageForm(false);
            setIsAddLanguageButtonDisabled(false);
            setLanguageDetails({
                languageTitle: '',
                languageProficiency: 'Elementary Proficiency',
            });
        }
    }

    return (
        <Modal isOpen={showAddLanguageForm}>
            <div
                className="fixed flex flex-col justify-between
                    max-h-[80vh] min-w-[30vw] rounded-xl
                    px-[1.5vw] py-[2vh] bg-white"
            >
                {/* Header */}
                <div className={"max-h-[10vh]"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        {/* Form Title */}
                        <h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
                            {t("ADD_LANGUAGE_FORM.TITLE")}
                        </h2>

                        <button
                            onClick={() => setShowAddLanguageForm(false)}
                            type="button"
                            className="flex items-center justify-center rounded-lg w-[2vw] h-[2vw]
                            bg-transparent hover:bg-gray-200
                            text-sm text-gray-400 hover:text-gray-900"
                        >
                            <img
                                className={"w-[75%] h-[75%]"}
                                src={"/assets/close-icon.png"}
                                alt={"Close"}
                                title={"Close"}
                            />
                        </button>
                    </div>

                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
                </div>

                <div className={"max-h-[60vh] py-[1.5vh]"}>
                    {/* Language Title */}
                    <div className={`mb-[2vh] flex flex-col gap-2`}>
                        <label
                            className="text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                            htmlFor="languageTitle"
                        >
                            {t("ADD_LANGUAGE_FORM.LANGUAGE_TITLE.LABEL")}
                        </label>

                        <div className="flex-1 flex flex-col">
                            <input
                                id="languageTitle"
                                className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                name='languageTitle'
                                value={languageDetails.languageTitle}
                                onChange={handleFormData}
                            />
                            {
                                error['languageTitle'] &&
                                <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                    {error['languageTitle']}
                                </p>
                            }
                        </div>
                    </div>

                    {/* Language Proficiency */}
                    <div className={`flex-1 mb-[2vh] flex flex-col gap-2`}>
                        <label
                            className="flex flex-row gap-1 text-gray-600 text-[1vw] font-SOHNE_REGULAR"
                            htmlFor="languageProficiency"
                        >
                            {t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.LABEL")}
                        </label>
                        <div className="w-full flex flex-col">
                            <button
                                onBlur={() => setIsLanguageProficiencyDropdownOpen(false)}
                                className="flex flex-row justify-between items-center min-h-[5vh] max-h-[5vh]
                                        rounded-md border-2 border-gray-300 cursor-pointer px-[0.5vw] gap-1"
                                onClick={() => setIsLanguageProficiencyDropdownOpen(!isLanguageProficiencyDropdownOpen)}
                            >
                                <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                    {languageProficiencyLabel(languageDetails.languageProficiency)}
                                </p>
                                <ChevronDownIcon
                                    className={`h-[1vw] w-[1vw] transition-all duration-150 ease-in-out
                                                ${isLanguageProficiencyDropdownOpen ? 'rotate-180' : 'rotate-0'} `}
                                    aria-hidden="true"
                                    stroke="#292D32"
                                    strokeWidth={"0.1vh"}
                                />
                            </button>

                            {/* Language Proficiency Dropdown */}
                            <div className={""}>
                                <Transition show={isLanguageProficiencyDropdownOpen}>
                                    <div
                                        className={`bg-white absolute left-0 right-0 max-h-[15vh]
                                            origin-top-right rounded-md shadow-2xl mt-[0.5vh] mx-[1.5vw]
                                            border border-gray-700 overflow-y-scroll
                                            data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 
                                            data-[enter]:duration-100 data-[enter]:ease-out 
                                            data-[leave]:duration-75 data-[leave]:ease-in`}
                                    >
                                        {
                                            languageProficiencyOptions.map((option, index) => (
                                                <button
                                                    key={index}
                                                    className="w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]
                                                        hover:bg-gray-300 flex items-center justify-between gap-1"
                                                    onClick={() => handleLanguageProficiencySelect(option.value)}
                                                >
                                                    <p className={"font-SOHNE_REGULAR text-[1vw]"}>
                                                        {option.label}
                                                    </p>
                                                </button>
                                            ))
                                        }
                                        {/*<button*/}
                                        {/*    className="w-full min-h-[5vh] max-h-[5vh] px-[0.5vw]*/}
                                        {/*        hover:bg-gray-300 flex items-center justify-between gap-1"*/}
                                        {/*    onClick={() => handleLanguageProficiencySelect("Elementary Proficiency")}*/}
                                        {/*>*/}
                                        {/*    <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
                                        {/*        {t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_1")}*/}
                                        {/*    </p>*/}
                                        {/*</button>*/}
                                        {/*<button*/}
                                        {/*    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]*/}
                                        {/*        hover:bg-gray-300 flex items-center justify-between gap-1"*/}
                                        {/*    onClick={() => handleLanguageProficiencySelect("Limited Working Proficiency")}*/}
                                        {/*>*/}
                                        {/*    <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
                                        {/*        {t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_2")}*/}
                                        {/*    </p>*/}
                                        {/*</button>*/}
                                        {/*<button*/}
                                        {/*    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]*/}
                                        {/*        hover:bg-gray-300 flex items-center justify-between gap-1"*/}
                                        {/*    onClick={() => handleLanguageProficiencySelect("Professional Working Proficiency")}*/}
                                        {/*>*/}
                                        {/*    <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
                                        {/*        {t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_3")}*/}
                                        {/*    </p>*/}
                                        {/*</button>*/}
                                        {/*<button*/}
                                        {/*    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]*/}
                                        {/*            hover:bg-gray-300 flex items-center justify-between gap-1"*/}
                                        {/*    onClick={() => handleLanguageProficiencySelect("Full Professional Proficiency")}*/}
                                        {/*>*/}
                                        {/*    <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
                                        {/*        {t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_4")}*/}
                                        {/*    </p>*/}
                                        {/*</button>*/}
                                        {/*<button*/}
                                        {/*    className="w-full cursor-pointer min-h-[5vh] max-h-[5vh] px-[0.5vw]*/}
                                        {/*            hover:bg-gray-300 flex items-center justify-between gap-1"*/}
                                        {/*    onClick={() => handleLanguageProficiencySelect("Native or Bilingual Proficiency")}*/}
                                        {/*>*/}
                                        {/*    <p className={"font-SOHNE_REGULAR text-[1vw]"}>*/}
                                        {/*        {t("ADD_LANGUAGE_FORM.LANGUAGE_PROFICIENCY.OPTION_5")}*/}
                                        {/*    </p>*/}
                                        {/*</button>*/}
                                    </div>
                                </Transition>
                            </div>

                            {
                                error['languageProficiency'] &&
                                <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                    {error['languageProficiency']}
                                </p>
                            }
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <div className={"max-h-[10vh]"}>
                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

                    <div className="flex items-center justify-end">
                        {/* Submit Button */}
                        <button
                            disabled={isAddLanguageButtonDisabled}
                            onClick={handleSubmit}
                            className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
                        >
                            {t("ADD_LANGUAGE_FORM.SUBMIT")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}