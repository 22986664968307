// chapterData.js
export const chapters = [
  { name: "प्रस्तावना", link: "/chapter1" },
  { name: "अध्यक्षीय मनोगत", link: "/chapter2" },
  { name: "संपादकीय", link: "/chapter3" },
  { name: "ऋणनिर्देश", link: "/chapter4" },
  { name: "जोगळेकर कुलवृत्तान्त समितीची वाटचाल", link: "/chapter5" },
  { name: "जोगळेकर कुलवृत्तान्त समिती घटना व नियमावली", link: "/chapter6" },
  { name: "कुलवृत्तान्त : काही तज्ञांचे विचार", link: "/chapter7" },
  { name: "कोकणस्थ कोकण - चित्पावन - आणि जोगळेकर", link: "/chapter8" },
  { name: "चित्पावन : गक गौरवशाली इतिहास", link: "/chapter9" },
  { name: "चित्पावनांचे स्वभावगुण व रंगरूप धार्मिक :", link: "/chapter10" },
  { name: "अत्रिकुलाचे कुलगुरु - अत्रिऋषी", link: "/chapter11" },
  { name: "कुलगुरु - श्री. परशुराम", link: "/chapter12" },
  { name: "गोत्रे, प्रवरे कुलगुरु / कुलऋषि / महर्षि", link: "/chapter13" },
  { name: "चित्पावनांची कुलनामे - उपनावे - आडनावे", link: "/chapter14" },
  { name: "जोगळेकर - कुलनाम", link: "/chapter15" },
  { name: "जोगळेकर कुलांची मूळ गावे", link: "/chapter16" },
  {
    name: "जोगळेकर कुटुंबीयांची महत्त्वाची, कुलस्वामी व कुलदेवतांची देवस्थाने",
    link: "/chapter17",
  },
  { name: "जोगळेकर कुलांसाठी कुळाचार", link: "/chapter18" },
  { name: "दिवंगत उल्लेखनीय व्यक्तींचा परिचय", link: "/chapter19" },
];
