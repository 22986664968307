import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {
	Background,
	Controls,
	getConnectedEdges,
	getIncomers,
	getOutgoers,
	MiniMap,
	Panel,
	useEdgesState,
	useNodesState,
	useReactFlow,
	useViewport,
} from 'reactflow';
import 'reactflow/dist/style.css';
import * as Dialog from '@radix-ui/react-dialog';

import {AccountContext} from '../context/AccountProvider';

import {NodeContext} from '../context/NodeProvider';
import MemberNode from './Nodes/MemberNode';
import RelationEdge from './Edges/RelationEdge';
import {deleteMember, getFamilyTreeById, getMemberById, updateMember} from '../service/memberApi';
import {updateAccountById} from '../service/accountApi';

import {t} from "i18next";

import {AddNodeForm} from "../components/Nodes/AddNodeform";
import {deleteRelationOnMemberDelete} from "../service/relationApi";
import {AddEdgeForm} from "./Edges/AddEdgeForm";

const flowKey = 'example-flow';

const nodeTypes = {Member: MemberNode};
const edgeTypes = {relation: RelationEdge};

export const FamilyTreeBuilder = () => {
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [newlyConnectedEdge, setNewlyConnectedEdge] = useState({});
	const [rfInstance, setRfInstance] = useState(null);

	const [showAddNodeForm, setShowAddNodeForm] = useState(false);
	const [showEditNodeForm, setShowEditNodeForm] = useState(false);
	const [showAddEdgeForm, setShowAddEdgeForm] = useState(false);

	const {x, y, zoom} = useViewport();

	const {setViewport} = useReactFlow();

	const {account} = React.useContext(AccountContext);
	const {setSelectedNode} = React.useContext(NodeContext);

	const restoreFlowFromDB = async () => {
		let nodeId = account.nodeId;
		const flow = await getFamilyTreeById(nodeId);
		console.log('(Screen) Family Tree: ', flow);

		if (flow.length === 0) {
			return;
		}

		flow[0].createdNodes.map((node) => {
			node.data.showEditNodeForm = showEditNodeForm;
			node.data.setShowEditNodeForm = setShowEditNodeForm;
			// node.data.deleteNode = deleteMemberNode;
		});

		flow[0].connectedNodes.map((node) => {
			node.data.showEditNodeForm = showEditNodeForm;
			node.data.setShowEditNodeForm = setShowEditNodeForm;
			// node.data.deleteNode = deleteMemberNode;
		});

		flow[0].connectedEdges.map((edge) => {
			edge.style = {stroke: 'lightgray', strokeWidth: 2};
		});

		console.log("(Screen) Created Nodes: ", flow[0].createdNodes);
		console.log("(Screen) Connected Nodes: ", flow[0].connectedNodes);
		console.log("(Screen) Connected Edges: ", flow[0].connectedEdges);
		setNodes([...flow[0].createdNodes, ...flow[0].connectedNodes]);
		setEdges(flow[0].connectedEdges || []);
		setViewport({x: 0, y: 0, zoom: 1});
	};

	useEffect(() => {
		setViewport({x: x, y: y, zoom: zoom});
		restoreFlowFromDB();
	}, []);

	// On Edge Connect
	const onConnect = useCallback(async (params) => {
		const sourceData = await getMemberById(params.source);
		const targetData = await getMemberById(params.target);
		setNewlyConnectedEdge({params: params, sourceData: sourceData, targetData: targetData});
		setShowAddEdgeForm(true);
	}, [setEdges]);

	// On Save
	const onSave = useCallback(() => {
		console.log('on save clicked:');
		if (rfInstance) {
			const flow = rfInstance.toObject();
			localStorage.setItem(flowKey, JSON.stringify(flow));
			console.log('flow saved:', flow);
		}
	}, [rfInstance]);

	// On Restore
	// const onRestore = useCallback(() => {
	//     restoreFlowFromDB();
	// }, [setNodes, setViewport]);

	// Delete Member Node
	const deleteMemberNode = useCallback((nodeToDelete) => {
		console.log('Member Node for Deletion: ', nodeToDelete);
		if (nodeToDelete[0].data.isUser) {
			updateAccountById(nodeToDelete[0].data.accountId, {nodeId: null}).then((res) => {
				console.log('account updated:', res);
			});
		}

		deleteMember(nodeToDelete[0].id)
			.then((response) => {
				console.log('response:', response);
				if (response && response.status === 200) {
					deleteRelationOnMemberDelete(nodeToDelete[0].id)
						.then((res) => {
							if (res && res.status === 200) {
								setEdges(
									nodeToDelete.reduce((acc, node) => {
										const incomers = getIncomers(node, nodes, edges);
										const outgoers = getOutgoers(node, nodes, edges);
										const connectedEdges = getConnectedEdges([node], edges);

										const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
										console.log('(Screen) Remaining Edges:', remainingEdges);
										const createdEdges = incomers.flatMap(({id: source}) =>
											outgoers.map(({id: target}) => ({
												id: `${source}->${target}`,
												source,
												target
											}))
										);

										return [...remainingEdges, ...createdEdges];
									}, edges)
								);
								setNodes((nds) => nds.filter((node) => !nodeToDelete.includes(node)));

								// setNodes((nds) => nds.filter((node) => !node.includes(node)));
								console.log('(Screen) Deleted Node Data:', nodeToDelete);
							} else {
								console.log('(Screen) Error while deleting node relationships: ', response);
							}
						});
				} else {
					console.log('(Screen) Error while deleting node: ', response);
				}
			});
	}, [nodes, edges]);

	const onNodeClick = (event, node) => {
		const connectedEdges = getConnectedEdges([node], edges);
		console.log("Connected Edges: ", connectedEdges);
		console.log('Clicked Node: ', node);
		console.log('Clicked Node Event: ', event);
		setSelectedNode(node);
	};

	const onNodeDragStop = useCallback((event, node) => {
		event.preventDefault();
		const position = node.position
		updateMember(node.id, {position: position}).then((response) => {
			console.log('response:', response);
		});
	}, [rfInstance]);

	return (
		<>
			<AddNodeForm
				showAddNodeForm={showAddNodeForm}
				setShowAddNodeForm={setShowAddNodeForm}
				restoreFlowFromDB={restoreFlowFromDB}
			/>

			<AddEdgeForm
				showAddEdgeForm={showAddEdgeForm}
				setShowAddEdgeForm={setShowAddEdgeForm}
				currentEdge={newlyConnectedEdge}
			/>

			<Dialog.Root>
				<div style={{width: '100vw', height: '100vh'}}>
					<ReactFlow
						nodes={nodes}
						edges={edges}
						onNodesChange={onNodesChange}
						// onEdgesChange={handleEdgeChange}
						// connectionLineComponent={ConnectionLine}
						onEdgesChange={onEdgesChange}
						onNodeClick={onNodeClick}
						onNodesDelete={deleteMemberNode}
						onConnect={onConnect}
						nodeTypes={nodeTypes}
						edgeTypes={edgeTypes}
						selectNodesOnDrag={true}
						onInit={setRfInstance}
						onNodeDragStop={onNodeDragStop}
						fitView="auto"
						onPaneClick={() => {
							console.log("Background Clicked!");
							setSelectedNode(null);
						}}
						className={"bg-[#F7F7F7]"}
					>
						<Panel position="vertical-center" className={"top-[5vw]"}>
							<div
								className="flex items-center justify-center flex-col
                                bg-white px-[1vw] py-[1vh] rounded-xl shadow-lg
                                text-[1vw] font-SOHNE_MONO_BOLD text-gray-900
                                border border-gray-300"
							>
								<h2>{account.familyTreeName}</h2>
							</div>
						</Panel>
						<Panel position="bottom-center">
							<div className="flex items-center justify-center flex-col">
								<button
									className="flex flex-row items-center justify-center rounded-xl
                                    m-[1vh] px-[1vw] py-[1vh] bg-blue-600 hover:bg-blue-700
                                    text-[1vw] text-white font-SOHNE_MEDIUM shadow-xl shadow-blue-600/75
                                    transition-transform duration-200 ease-in-out hover:scale-[1.02]"
									onClick={() => setShowAddNodeForm(true)}
								>
									+&nbsp;&nbsp;{t("SCREEN.MEMBER")}
								</button>
							</div>
						</Panel>
						<Controls/>
						<MiniMap/>
						<Background variant='dots' gap={16} size={1}/>
					</ReactFlow>
				</div>
			</Dialog.Root>
		</>
	);
}
