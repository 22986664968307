import {footerNav} from "../../utils/landingPageContent";
import {t} from "i18next";
import React from "react";

const Footer = () => {
	return (
		<div className="singleWave ">
			<footer className="-mt-10">
				<div
					className="bg-[#D26A53] flex flex-col md:flex-row items-center justify-between w-full overflow-hidden py-[2vh] px-[2vw] text-white text-center md:text-left "
				>
					<address className="flex font-SOHNE_REGULAR">
						{t("HOME_PAGE.FOOTER.EMAIL")}&nbsp;
						<a href="mailto:info@joglekarkul.com">info@joglekarkul.com</a>
					</address>

					<address className="flex justify-center font-SOHNE_REGULAR">
						{t("HOME_PAGE.FOOTER.CONTACT")}&nbsp;
						<a href="tel:+91 9820290243">+91 9820290243</a>
					</address>

					<div className="flex justify-center space-x-6 md:space-x-10 p-1">
						{footerNav.social.map((item) => (
							<a
								key={item.name}
								href={item.href}
								className="text-blue-800 hover:text-white"
							>
								<img src={item.icon} alt={item.name} className="h-6 w-6"/>
								<span className="sr-only">{item.name}</span>
							</a>
						))}
					</div>
				</div>
				<div
					className="bg-white flex flex-col md:flex-row justify-center items-center w-full overflow-hidden py-[2vh] px-[2vw] text-white text-center md:text-left "
				>
					<img
						className="h-[1.5vw] w-auto px-[1vw]"
						src="/landing/powered_by_csi.png"
						alt="Powered by CS Infocomm"
					/>
				</div>
			</footer>
		</div>
	);
};
export default Footer;
