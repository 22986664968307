import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";

export const Chapter9 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					चित्पावन : गक गौरवशाली इतिहास
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<h3 className="flex items-end justify-end mr-[100px] mb-5">
						लेखक : डॉ. अ.व्यं.सावजी, नागपूर. प्रास्ताविक
					</h3>
					<p className="mb-5 leading-relaxed ">
						{" "}
						<span className="pl-6 sm:pl-8"> लोकमान्य</span> टिळक,
						आगरकर, स्वातंत्र्यवीर सावरकर, विनोबा भावे अशी अनेक
						नररत्ने चित्पावन ब्राह्मण समाजाने आधुनिक भारताला दिलेली
						आहेत. छत्रपती शिवाजी महाराजांनी स्थापलेल्या िंहदवी
						स्वराज्याची ध्वजा िंसधूनदीपर्यंत पोहोचविण्याच्या
						पराक्रमात चित्पावनांचे योगदान बरेच मोठे आहे. बाजीराव
						बाळाजी आणि चिमाजी अप्पा ह्यांच्या पराक्रमास इतिहासात तोड
						नाही. पेशवाई हा महाराष्ट्राच्या इतिहासातील गक गौरवशाली
						कालखंड आहे हे कोणीही नाकारू शकणार नाही. आज अनेक चित्पावन
						अनेक क्षेत्रांत आपल्या कर्तृत्वाने तळपत आहेत. ‘‘पूर्व
						दिव्य ज्यांचे त्यांना रम्य भाविकाळ‌’’ ह्या नियमाच्या
						समग्र अर्थानुसार ह्या कर्तृत्वपूर्ण समाजाचा पूर्वेइतिहास
						हा भव्य-दिव्य असलाच पाहिजे हे उघड आहे.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">तथापी</span> चित्पावन
						ब्राह्मणांबद्दल काही आधाररहित अपसमज आहेत. हे अपसमज काही
						प्रमाणात ह्या शाखासमाजाबद्दल असणाऱ्या असूयेपोटीही असू
						शकतील, तथापी ते मुख्यत: चित्पावनांच्या इतिहासामधील काही
						गळलेल्या दुव्यांमुळे (मििंसग िंलक्समुळे) आहेत.{" "}
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> रामायणपूर्व</span>{" "}
						काळापासून ते शिलाहार-यादव संघर्षकाळापर्यंत (इ.स.१२६५
						पर्यंत) चित्पावनांची वस्ती सोपारा परिसरापुरतीच सीमित
						होती. शिलाहार सत्ता कोलमडल्यानंतर चित्पावन समुद्रमार्गे
						दाभोळ खाडीतून प्रवेश करून चिपळूण भागात स्थलांतरित व
						स्थायिक झाले.
					</p>
					<p>
						<h3 className="text-center">
							{" "}
							चित्त + तप + वन = चित्पावन
						</h3>
						<br />
						‘‘चित्त‌’’, ‘‘तप‌’’ व ‘‘वन‌’’ ह्या तीन शब्दांच्या
						संयुक्तीकरणामुळे चित्पावन हा शब्द निर्माण झालेला आहे.
						नुसते शारीरिक क्लेश म्हणजे तप नाही. तपासाठी चित्त
						प्रसन्न असणे आवश्यक आहे. तपामध्ये शरीरापेक्षा मनाचं,
						चित्ताचं महत्त्व कितीतरी पटीने जास्त आहे. ‘‘नाही शुद्ध
						चित्तवृत्ती नाही कैवल्याची प्राप्ती (२/४/१८) असे रामदास
						स्वामींनी म्हटले आहे. पूर्ण मौन न पाळता अधूनमधून
						आध्यात्मिक चित्तानंदासाठी सुगम सामगान करणाऱ्या तपस्यांना
						चित्ततप - चित्तप ही संज्ञा आहे. सामवेदीय ब्राह्मणास
						गृहीतसहस्र ब्राह्मण, चित्ततप ब्राह्मण, सामक ब्राह्मण
						चित्तप ब्राह्मण अशा विविध पर्यायी संज्ञा आहेत. सामवेदीय
						ब्राह्मणांना ‘चित्तप ब्राह्मण‌’ म्हणतात. हे
						डॉ.मधुकररावांसारख्या वेदविषयातील अधिकारी विद्वानांनी
						सुस्पष्ट केले आहे. ‘‘ ह्या कर्मप्रवणतेसाठी गाणे मोलाचे -
						त्याचे गायनही हवे तोलाचे. म्हणूनच ओंकार हा उद्गीथ म्हटला
						आहे. कामनापूर्तीसाठी सगळ्या ऋषींनी नैमिषारण्यात
						उद्गीथाची उपासना केली.... (गायन, पूजन आणि स्तवन करावे -
						ओंकाराचे उपनिषदांचा अभ्यास : नवे संदर्भ. डॉ.मधुकर
						आष्टीकर पृ.८४).
						<br />
						श्रीमद्भगवत्‌ गीता आणि ‘‘चित्त - तप‌’’
					</p>
					<p className=" mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8">गीतेच्या</span> सहाव्या
						अध्यायात चित्त आणि तप ह्यांचा संबंध फार सुंदर रीतीने
						सुस्पष्ट केला आहे. मुनी, तपस्वी आणि योगी ह्या तिन्ही
						संज्ञा वेगवेगळ्या आहेत. सामान्यत: जो मौन पाळतो, तो मौनी
						मुनी - असा समजला जातो. साधनेचा गक भाग म्हणजे जो स्वत:हून
						स्वत:ला शिस्तबद्ध रीतीने शारीरिक क्लेश करून घेतो तो
						तपस्वी आणि चित्ताचं, मनाचं महत्त्व जाणून जो तपाला
						चित्ताच्या प्रसन्नतेची जोड देतो, तो योगी. त्यामुळे
						‘‘योगी‌’’ आणि ‘‘चित्ततप‌’’ ह्या समानअर्थी संज्ञा आहेत.
						(योगी.... गकाकी यतचित्तात्मा निराशीरपरिग्रह : ६/१०)
						गीतेनुसार मुनीपेक्षा योगी श्रेष्ठ आहे आणि
						तपस्वीपेक्षासुद्धा योगी श्रेष्ठ आहे (योगयुक्तो
						मुनिर्बह्य न चिरेणाधिगच्छती ५/६ आणि तपस्विभ्यो ऽ धिको
						योगी ज्ञानिभ्यापि मतो ऽ धिक : ६/४६) योगी (म्हणजे
						चित्ततप) ही सर्वेा संज्ञा आहे. गीतेच्या दहाव्या
						अध्यायाच्या क्रमांक बाविसाव्या श्लोकामध्ये ‘‘सामवेद‌’’
						आणि ‘‘मन(चित्त)’’ ह्यांच्या सर्वो श्रेष्ठत्वाचे
						‘‘समीप‌’’ उल्लेख आले आहेत. ‘‘वेदानां सामवेदोस्मि....
						इंद्रियाणां मनश्चास्मि‌’’ मन(चित्त) हे सर्व
						इंद्रियांमध्ये श्रेष्ठ आहे स्पष्ट म्हटले आहे. सामगानाने
						चित्ताची प्रसन्नता हमखास मिळते. त्यामुळे ही समीपत असे
						सार्थ आहे. चित्ततप ही संज्ञा प्रत्यक्ष
						श्रीमद्भगवद्गीतेतील ‘‘यतचित्तात्मा‌’’ प्रभावातून निर्माण
						झालेली आहे.
					</p>
					<p className=" mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8">नालंदा</span> अधतन
						कोशामधील ‘‘तप‌’’ ह्या शब्दाच्या अर्थव्याखेवरूनसुद्धा तप
						आणि चित्त ह्यांचा अपेक्षित सुसंबंध लक्षात येईल. ‘‘तप :-
						शरीर को कष्ट देनेवाले व धार्मिक व्रत और नियम आदि कृत्य
						जो चित्त को भोग विलास से हटाने के लिये किये जाये.’’
						तात्पर्य, तप हे श्रेष्ठ आहे; पण चित्ताच्या
						प्रसन्नतेशिवाय त्यास पूर्णत्व येत नाही. चित्तप म्हणजे
						आदर्श तपस्वी - ‘‘कर्मनिष्ठ वंद्यु तो ज्ञानियांसी वेद्यु॥
						तापसांचा आद्यु ॥ तपोनाथु ॥‌’’ सोपाऱ्याहून समुद्रमार्गे
						दाभोळच्या खाडीतून प्रवेश करणारे आजच्या चित्तपावनांचे
						पूर्वज चित्तप होत, दाभोळ खाडीतून प्रवेश केल्यावर त्यांचे
						पुनर्वसनकेंद्र वसिष्ठी नदीच्या उत्तरकाठावरील परशुराम
						मंदिराच्या सभोवतालच्या वनामध्ये वसविण्यात आले. त्यांच्या
						वास्तव्यामुळे ह्या वनाला चित्तपवन ही संज्ञा प्राप्त
						झाली. ह्या चित्तपवनामधून दक्षिणकोकणांत विखुरलेल्या
						लोकांना चित्तपावनातून आलेले म्हणून चित्तपवन ही संज्ञा
						दक्षिण कोकणात निर्माण झाली. चित्तपावन हा शब्द चित्ततपवन
						असा लिहिणे जास्त योग्य आहे.
					</p>
					<p className=" mb-5 leading-relaxed">
						तप : <br />
						चित्पावन ह्या शब्दाच्या जडणघडणीमध्ये ‘‘तप‌’’ हा शब्द फार
						महत्त्वाचा आहे. ह्या संदर्भात तप ह्या शब्दाचे महत्त्व
						जितके वर्णन करावे तेवढे थोडेच आहे. ‘‘आम्हां घरी धन
						शब्दांचींच रत्ने शब्दांचींच शस्रे यत्न करूं ॥ शब्दचि
						आमुच्या जीवाचें जीवन शब्द वांटू धन जनलाकां ॥ तुकाम्हणे
						पाहा शब्द चि हा देव शब्देंची गौरव पूजा करू ॥ (अभंग १६२७)
						तप हा शब्द चित्पावन संज्ञेचे शब्दधन आहे - चित्पावनांचे
						संचित आहे.
					</p>
					<p className=" mb-5 leading-relaxed">
						वन : <br />
						वन ह्या शब्दाचा वापर आपणास पुष्कळच परिचयाचा आहे. रामदास
						स्वामी महाराष्ट्राला आनंदवनभुवन म्हणत. ‘‘गोव्यातील
						चंद्रमंडळ िंकवा चंद्रवाडीच्या सीमेवर रिवण नावाचा गक गाव
						आहे. ऋषीवन ह्या संस्कृत शब्दापासून रिवण हा शब्द बनला
						आहे.’’ (-श्री गौडपादाचार्य संस्थान कैवल्यपूर समग्र
						इतिहास, ल. गायतोंडे इ.स.१९७९. पृष्ठ ५०) वृन्दावन,
						सुन्दरवन, गोंडवन असे अनेक प्रसिद्ध परिसर आहेत. नंतरच्या
						काळातसुद्धा नृपतींनी व सद्गृहस्थांनी तपस्यांकरिता वने
						निर्माण करावी व त्यांची मने प्रसन्न ठेवावीत असे रामदास
						स्वामींनी म्हटले आहे. ‘‘लावी वने उपवने पुष्पवाटिका जीवने
						निववी (निववी = प्रसन्न ठेवणे) तपस्व्यांची (तापसांची मने
						तो सत्त्व गुण ॥‌’’ (दशक २, समास ७, श्लोक २१ दासबोध.)
						ह्या श्लोकामध्ये वन, तपस्वी आणि चित्त ह्यांचा अतूट संबंध
						प्रकट झाला आहे. पुष्प, संगीत आदी गोष्टींनी, तपस्यांनी तप
						करताना आपले चित्त प्रसन्न ठेवणे आवश्यक आहे हे पण त्या
						श्लोकांवरून लक्षात येते. ‘‘विरक्ते लावावे भजन विरक्ते
						विशेष ब्रह्मज्ञान प्रगटवावे (२/९/८) अथवा करणे सुगम भजन
						भगवंताचे ॥‌’’ (२/४/६) असेही रामदासांनी म्हटले आहे आणि
						तपस्येमधील व चित्ततपमधील संगीताच्या व सामगानाच्या
						महत्त्वाचा अस्पष्ट - स्पष्ट निर्देश केला आहे असे
						म्हणतात.{" "}
					</p>
					<p className=" mb-5 leading-relaxed">
						‘‘चित्ततपवन‌’’ आणि ‘‘चिपळूण‌’’ ह्या शब्दांची
						संबंधमीमांसा
					</p>
					<p className=" mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8">चित्ततपवन</span> परिसर
						आणि चिपळूण गावाचा परिसर हे अगदी खेटून आहेत. ह्या दोन्ही
						परिसरांमध्ये फक्त वसिष्ठी नदी आहे. अगदी खेटून असले तरी
						हे दोन परिसर भिन्न होते व आहेत. आजही ह्या दोन परिसरांत ८
						ते ९ किलोमीटर गवढे अंतर आहे. चित्तप ब्राह्मणांची आद्य
						पुनर्वसन छावणी परशुराम मंदिरासभोवताली वसिष्ठी नदीच्या
						उत्तरकाठावर होती.
					</p>
					<p className=" mb-5 leading-relaxed">
						<span className="pl-6 sm:pl-8">चित्तपवन</span> ह्या
						शब्दावरून चिपळूण शब्द आला नाही. चिपळूण ह्या शब्दावरून
						चित्तपवन हा शब्द आला नाही हे मनावर नीट िंबबविणे आवश्यक
						आहे. चित्तपवन हे चिपळूण नगराचे गक स्वतंत्र उपनगर आहे हे
						लक्षात ठेवले, म्हणजे कोणत्याही वैचारिक गोंधळाला जागा
						राहात नाही. चिपळूण हे चित्तपावनांचे दक्षिण कोकणातील मूळ
						गाव आहे. हे स्थूलमानाने बरोबरच आहे चुकीचे नाही. तरीपण
						शुद्ध काटेकोरपणे विचार करताना चित्पावनांचे दक्षिण
						कोकणातील मूळ गाव चिपळूण गावाला खेटून, पण तरीही वेगळे
						असणारे परशुराम मंदिराभोवतीचे चित्ततपवन परिसर हे आहे.
						खुद्द चिपळूण नाही. त्यामुळेच चित्तपावनांना ‘‘चिपळुणे
						ब्राह्मण‌’’ असे म्हणत असले, तरी देवरुखे ब्राह्मण िंकवा
						कऱ्हाडे ब्राह्मण ह्या संज्ञा जितक्या लोकप्रिय झाल्या
						तितकी ‘‘चिपळुणे ब्राह्मण‌’’ ही संज्ञा लोकप्रिय होऊ शकली
						नाही. चिपळूण शहर इ.स.१२६५ सालीसुद्धा गक राष्ट्रीय व
						आंतरराष्ट्रीय व्यापाराचे मोठे केंद्र व व्यापारपेठ होती.
						त्यामुळे ह्या शहराच्या अंतर्गत सुमारे दहा हजार लोकसंख्या
						- वस्ती गकदम सामावून घेणे सहज शक्य नव्हते. त्यामुळे
						त्याची व्यवस्था वसिष्ठी नदीच्या उत्तरकाठावर करण्यात आली.
						चित्पावन ब्राह्मणांची दक्षिण कोकणातील आद्य-वसाहत चिपळूण
						गाव परिसरातील परशुराम मंदिराच्या सभोवार होती. गुहागर
						येथे पुढे काही वर्षांनी काही चित्पावन कुटुंबे गेली, तरी
						गुहागर येथे चित्पावनांची आद्य पुनर्वसन छावणी मुळीच
						नव्हती. चाचे व परचक्र ह्यांच्या भीतीमुळे प्रत्यक्ष
						समुद्रकिनाऱ्यावर असणाऱ्या गुहागर ह्या गावी स्थलांतरित व
						अजूनही भांबावलेल्या मन:स्थितीतील लोक वस्ती करतील हे
						असंभव आहे. गुहागर हे चित्पावनांचे मूळ गाव नसले तरी
						त्यांचे पवित्र तीर्थक्षेत्र आहे.
					</p>
				</div>
				<div
					onClick={scrollToTop}
					className="flex items-end justify-end my-5"
				>
					<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
				</div>
				<hr />
			</div>
		</div>
	);
};
