import React, {createContext, useEffect, useState} from "react";

// Create the NodeContext
export const NodeContext = createContext();

// Create the NodeProvider component
const NodeProvider = ({children}) => {

    const [relativeNode, setRelativeNode] = useState([]);

    const [selectedNode, setSelectedNode] = useState({});

    useEffect(() => {
        const interval = setInterval(() => {
            setRelativeNode([]);
        }, 5 * 60 * 1000); // 5 minutes in milliseconds

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Define any functions or methods you need for manipulating the node data
    useEffect(() => {
        setRelativeNode([]);
    }, [selectedNode]);

    return (
        <NodeContext.Provider value={{relativeNode, setRelativeNode, selectedNode, setSelectedNode}}>
            {children}
        </NodeContext.Provider>
    );
};

export default NodeProvider;