  import React, {useState} from 'react';
import {t} from "i18next";
import {Modal} from "../components/Modal";
import {addAward} from "../service/accountApi";

export const AddAwardForm = (props) => {
    const {showAddAwardForm, setShowAddAwardForm, accountDetails, setAccountDetails} = props;

    const [error, setError] = useState({});

    const [isAddAwardButtonDisabled, setIsAddAwardButtonDisabled] = useState(false);

    const [awardDetails, setAwardDetails] = useState({
        awardTitle: '',
        awardDescription: '',
    });

    const handleFormData = (e) => {
        console.log(e.target.name + ': ' + e.target.value);

        setAwardDetails({
            ...awardDetails,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ""
        });
    }

    const validate = () => {
        let err = {};

        if (awardDetails.awardTitle === '') {
            err['awardTitle'] = t("ADD_AWARD_FORM.AWARD_TITLE.REQUIRED_ERROR");
        }

        if (awardDetails.awardDescription === '') {
            err['awardDescription'] = t("ADD_AWARD_FORM.AWARD_DESCRIPTION.REQUIRED_ERROR");
        }

        return err;
    }

    const handleSubmit = async () => {
        console.log("Inside Handle Submit!");
        setIsAddAwardButtonDisabled(true);
        const err = validate();

        if (Object.keys(err).length > 0) {
            setError(err);
            setIsAddAwardButtonDisabled(false);
            return;
        }

        console.log("Award Details: ", awardDetails);

        const awardData = {
            userAccountId: accountDetails._id,
            awardDetails: awardDetails,
        };
        const addAwardDataResponse = await addAward(awardData);

        console.log('(AddAwardForm) Add Award Data Response: ', addAwardDataResponse);

        if (addAwardDataResponse.success) {
            setAccountDetails(addAwardDataResponse.data);
            setShowAddAwardForm(false);
            setIsAddAwardButtonDisabled(false);
            setAwardDetails({
                awardTitle: '',
                awardDescription: '',
            });
        }
    }

    return (
        <Modal isOpen={showAddAwardForm}>
            <div
                className="flex flex-col justify-between
                    max-h-[80vh] min-w-[30vw] rounded-xl
                    px-[1.5vw] py-[2vh] bg-white"
            >
                {/* Header */}
                <div className={"max-h-[10vh]"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        {/* Form Title */}
                        <h2 className="text-[1.25vw] font-SOHNE_MEDIUM">
                            {t("ADD_AWARD_FORM.TITLE")}
                        </h2>

                        <button
                            onClick={() => setShowAddAwardForm(false)}
                            type="button"
                            className="flex items-center justify-center rounded-lg w-[2vw] h-[2vw]
                            bg-transparent hover:bg-gray-200
                            text-sm text-gray-400 hover:text-gray-900"
                        >
                            <img
                                className={"w-[75%] h-[75%]"}
                                src={"/assets/close-icon.png"}
                                alt={"Close"}
                                title={"Close"}
                            />
                        </button>
                    </div>

                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mt-[1.5vh]"}/>
                </div>

                <div className={"flex-1 no-scrollbar overflow-y-scroll max-h-[60vh] py-[1.5vh]"}>
                    {/* Award Title */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="awardTitle"
                            >
                                {t("ADD_AWARD_FORM.AWARD_TITLE.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <input
                                    id="awardTitle"
                                    className="font-SOHNE_MEDIUM inline-flex min-h-[5vh] max-h-[5vh] rounded-md px-[1vw] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='awardTitle'
                                    value={awardDetails.awardTitle}
                                    onChange={handleFormData}
                                />
                                {
                                    error['awardTitle'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['awardTitle']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>

                    {/* Award Description */}
                    <fieldset>
                        <div className="mb-[2vh] flex flex-col gap-2">
                            <label
                                className="text-gray-600 text-[0.9vw] font-SOHNE_REGULAR"
                                htmlFor="awardDescription"
                            >
                                {t("ADD_AWARD_FORM.AWARD_DESCRIPTION.LABEL")}
                            </label>

                            <div className="flex-1 flex flex-col">
                                <textarea
                                    id="awardDescription"
                                    className="font-SOHNE_MEDIUM inline-flex rounded-md min-h-[6vh] px-[1vw] py-[1.5vh] text-[1vw] leading-none outline-none border-2 border-gray-300 focus:border-[#5F0F40]"
                                    name='awardDescription'
                                    value={awardDetails.awardDescription}
                                    onChange={handleFormData}
                                />
                                {
                                    error['awardDescription'] &&
                                    <p className="text-[0.75vw] text-red-600 font-SOHNE_REGULAR" id="error">
                                        {error['awardDescription']}
                                    </p>
                                }
                            </div>
                        </div>
                    </fieldset>
                </div>

                {/* Footer */}
                <div className={"max-h-[10vh]"}>
                    {/* Divider */}
                    <div className={"h-[0.2vh] w-full bg-[#ECECEC] rounded-full mb-[1.5vh]"}/>

                    <div className="flex items-center justify-end">
                        {/* Submit Button */}
                        <button
                            disabled={isAddAwardButtonDisabled}
                            onClick={handleSubmit}
                            className="inline-flex items-center arrow-button font-SOHNE_MEDIUM
                            bg-green-400 hover:bg-green-500 hover:ring-white hover:ring-2
                            disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full
                            pl-[1.25vw] pr-[0.75vw] py-[1vh] transition-all duration-300 ease-in-out"
                        >
                            {t("ADD_AWARD_FORM.SUBMIT")}
                            <svg
                                viewBox="0 0 6 6"
                                fill="none"
                                id={"arrow-icon"}
                                className={"h-[1vw] w-[1vw] ml-[0.75vw]"}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 1L5 3L3 5"
                                    id={"arrow-icon-tip"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                                <path
                                    d="M1 3L4 3"
                                    id={"arrow-icon-line"}
                                    stroke="black"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}