import { IoMdClose } from "react-icons/io";

const VideoPop = ({ title, video, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center overflow-y-auto">
      <div className="bg-white rounded-md flex flex-col w-full max-w-4xl max-h-[80vh] overflow-hidden">
        <button className="place-self-end" onClick={onClose}>
          <IoMdClose className="text-3xl m-2" />
        </button>
        <h1 className="text-red-600 font-bold text-3xl text-center">{title}</h1>
        <div className="p-4 flex justify-center items-center">
          <iframe
            src={video ? video.replace("watch?v=", "embed/") : ""}
            className="w-full max-h-[60vh]"
            allow="autoplay; encrypted-media"
            title={title}
            style={{ minHeight: "50vh" }}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPop;
