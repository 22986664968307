import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon"

export const Chapter2 = () => {
  // Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
  return (
    <div>
      <Header />
      <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
        <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
          अध्यक्षीय मनोगत
        </h1>
        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">भगवान</span> घराणे, कुल, गोत्र, जात,
            वंश इत्यादी विविध शब्दांच्या अर्थच्छटा काही प्रमाणात वेगळ्या असल्या
            तरी त्यात समान सूत्रही आहे. व्यक्ती म्हणून प्रत्येक माणूस वेगळा असला
            तरी तो कोणत्या ना कोणत्या समाजाचा िंकवा समूहाचा घटक असतो. व्यक्तीचा
            समाजाशी आणि समाजाचा व्यक्तीशी अन्योन्य संबंध अपरिहार्य असतो. समाजाचे
            गुण-दोष व्यक्तीला स्वीकारावे लागतात आणि व्यक्तीमुळे समाजाला जसा
            लौकिक प्राप्त होतो तशी व्यक्तीमुळे समाजाची हानीही होऊ शकते. सर्वांना
            धारण करणारा ‘धर्म‌’ ही मुळात व्यापक संकल्पना असली तरी कालांतराने
            प्रत्येक धर्मात पंथ-उपपंथ, गट, तट पडलेले दिसतात. तेव्हा विभक्त होणे
            आणि पुन्हा गकत्र येणे या परस्परविरोधी वाटणाऱ्या प्रवृत्ती सर्व
            मानवसमूहांत दिसून येतात.
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8">प्राचीन</span> काळापासून भारतीयांच्या
            मनावर धर्माचा आणि जातीचा पगडा आहे. विशिष्ट धर्माला अवकळा आली, त्याला
            धक्के बसले तरी कालांतराने त्याला ऊर्जितावस्था प्राप्त झाली असेही
            दिसून येते. बदलत्या काळात ‘मी कोणताही धर्म मानत नाही‌’ असे कुणी
            म्हणू शकेल; पण हे म्हणणेही टिकू शकत नाही. कारण शासकीय पातळीवर
            विद्यमान धर्मांपैकी कोणत्या ना कोणत्या धर्मात त्याची गणना केली
            जातेच. स्वेच्छेने िंकवा सक्तीमुळे दुसरा धर्म स्वीकारला तरी मुळातली
            जात (निदान भारतात) विसरली जात नाही. गेल्या शतकापासून ज्यांनी
            ख्रिस्ती धर्मात प्रवेश केला व गेल्या तीस-चाळीस वर्षांत जे नवबौद्ध
            झाले ते आपल्या मूळच्या जातीवरूनच ओळखले जात आहेत. आपल्या मुळातील
            जातीच्या सवलती मिळाव्यात असे त्यांना धर्मान्तरानंतरही वाटते.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">‘जात‌’</span> हा शब्द जन-जन्मणे या
            धातूपासून बनला आहे. जन्मत: प्राप्त झालेली जात आयुष्यभर आपली साथ
            करते. कोणत्या जातीत जन्म घ्यायचा ते जन्मणाऱ्याच्या हाती नाही, मात्र
            कोणत्या जातीशी सोयरीक करायची ते ठरविता येते. आजवरचा भारतीय
            जातिसंस्थेचा इतिहास पाहिला तर या विसाव्या शतकातही माणसे आपल्या
            जातीबाहेर सहसा सोयरीक जुळवत नाहीत. जातीजमातींचे विचार झुगारून आणि
            धर्माचेही बंधन न मानता काही जणांनी गेल्या शतकभरात विवाह केले आहेत.
            पण अशांची संख्या अजून तरी फारच थोडी आहे. अशा बंडखोर स्त्री-पुरुषांचे
            जीवन सुखी झाले असेलही, पण त्यांच्या पुढच्या पिढीचे काय, असा नवा
            प्रश्न निर्माण होतो. कित्येकदा अशा संततीला समाजात स्थान नाही असे
            दिसून येते. ‘जी जात नाही ती जात‌’ याची अशा वेळी प्रचीती तीव्रतेने
            येते. जातीमुळे आपल्याला संरक्षण मिळते अशी भारतात तरी बहतेकांची समजूत
            आहे. त्यामुळे जातिबहिष्कृत होणे हे अजूनही अनेकांना संकट वाटते.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> या</span> सगळ्याचा अर्थ जातीजमातींचे
            समर्थन करणे योग्य असा मात्र नाही. जात अटळ असेल तर ती पत्करून तिला
            विधायक वळण लावणे हाच यातून मार्ग काढावा लागतो. जात वाईट नाही, पण
            जातीयतेची म्हणजे भेदाभेदाची िंकवा उनीचतेची प्रवृत्ती मात्र त्याज्य
            मानावी हा विचार स्वीकारावा लागतो. ‘‘मानव तितुका गकच आहे; उ न कोणी,
            नीच न कोणी‌’’ हे कविवचन आदर्श आहे, उदात्त आहे, यात शंका नाही. पण
            इथपर्यंत सर्वसामान्यांची मजल जात नाही, ही वस्तुस्थिती आहे. सगळे जग
            फिरून झाले तरी ‘गड्या आपुला गाव बरा‌’ ही भावना सुटत नाही. ‘‘हे
            विश्वचि माझे घर‌’’ असे ज्ञानेश्वरांनी म्हटले त्यातही ‘माझे घर‌’ हा
            भाव आहेच. स्वकीय-परकीय, स्वदेश-परदेश हे शब्द राष्ट्राच्या जीवनातही
            टिकून राहतात. विनोबांप्रमाणे ‘जयजगत्‌’ म्हणणारा गखादाच; (‘जय जगत्‌’
            तरी कशाला ? कशाचाच जयजयकार नको असे का नाही ?) ‘जयहिंद‌’, ‘जय
            महाराष्ट्र‌’ या शब्दांचे आवाहन मात्र नेमके पोचते.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">आपण</span> चित्पावन आहोत याचा गर्वही
            बाळगण्याचे कारण नाही आणि त्यात लाज वाटण्यासारखेही काही नाही. इतरांना
            वाटतो त्यानुसार आपले वाडवडील, पूर्वज, आपले मूळ गाव, घराणे, कुल,
            कुलाचार याबद्दल कुतूहल आणि आपलेपणा वाटण्यात अनैसर्गिक काही नाही.
            चित्पावनांनी आपल्या कुलाबद्दल, कुलातील कर्तबगार स्त्री-पुरुषांबद्दल
            आपलेपणाने त्यांची माहिती ग्रथित करणे हे समाजविघातक तर नाहीच, उलट ते
            समाजपोषकच आहे. अशा अनेक लहान-मोठ्या गटांमुळेच समाज बनत असतो आणि
            समाजाच्या अनेक समूहांमुळे प्रदेश आणि राष्ट्र बनत असते. राष्ट्राचा
            इतिहास म्हणजे त्या राष्ट्रातील समाजांचा इतिहास होय. आपल्याकडील
            आजवरचे बहतेक इतिहास हे राजघराण्यांचे इतिहास आहेत. त्यांमध्ये
            सर्वसामान्य माणसाला स्थान बहधा नाहीच. कुलवृत्तान्त लेखनामुळे
            इतिहासाला अज्ञात अशा व्यक्ती, त्यांचे कुलधर्म-कुलाचार सर्वांपुढे येत
            असल्यामुळे त्याचे स्वागतच केले पाहिजे. तात्यासाहेब केळकरांवरील
            लेखामध्ये (‘रसिकांचा राजा गेला‌’) श्री.म.माटे यांनी असे म्हटले आहे
            की, जितक्या अधिक घराण्यांमधून (आडनावांमधून) कर्तृत्वसंपन्न माणसे
            पुढे येतील, तेवढ्या प्रमाणात समाजाची कूस समृद्ध असल्याचे दिसून येईल.
            कुलवृत्तान्त लेखनामुळे आत्मगौरवाच्या प्रेरणेतून का होईना, पण
            समाजाच्या इतिहास लेखनाला चालना मिळेल. गकंदरीने कुलवृत्तान्त हा
            राष्ट्राच्या इतिहासाचा प्रारंभिंबदू असल्याने त्याचे स्वागतच केले
            पाहिजे.{" "}
          </p>
          <div className="ml-20">
            <span>अनिल गंगाधर जोगळेकर</span>
            <br />
            <span> अध्यक्ष</span>
            <br />
            <span> जोगळेकर कुलवृत्तान्त समिती</span>
          </div>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr />
        </div>
      </div>
    </div>
  );
};
