const carouselImg = [
	{
		id: 1,
		img: "/kulvratant_landingPage/image-shubecha.png",
	},
	{
		id: 2,
		img: "/kulvratant_landingPage/image-kalavnyas-anand-hot-aahe.png",
	},
	{
		id: 3,
		img: "/kulvratant_landingPage/image-smuran-din.png",
	},
	{
		id: 4,
		img: "/kulvratant_landingPage/image-dharmik.png",
	}, {
		id: 5,
		img: "/kulvratant_landingPage/image-batmya.png",
	},
];
export default carouselImg;
