import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";


export const Chapter12 = () => {




  // Scroll to Top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify  mx-auto ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					कुलगुरु - श्री. परशुराम
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> जमदग्नी</span> व रेणुका
						यांचा पुत्र परशुराम. परशुरामाला दशावतारातील सहावा अवतार
						मानले आहे. दशावतारापैकी जवळजवळ सर्व अवतारांवर वेगवेगळी
						पुराणे रचली गेली असली तरी परशुराम अवतारावर गकही पुराण
						आढळत नाही. प्राचीन पुराणांमधून अतिशयोक्तिपूर्ण चमत्कार
						कथन केले गेलेले दिसत असले तरी अशा पुराणांमधून आपला
						प्राचीन इतिहासही दडलेला आहे हेही खरेच. परशुरामासंबधी
						उल्लेख आढळतात ते वाल्मिकी रामायण, महाभारत व हरिवंशात.
						भृगुकुलोत्पन्न जमदग्नीचा आश्रम नर्मदेच्या तीरावर होता.
						गुजरातेतील भडोचजवळ हा आश्रम होता. भृगुकुलांतील म्हणून
						परशुरामाला भार्गव नावाने संबोधले गेले आहे. परशुरामाचा
						काळ सुमारे साडेसात हजार वर्षांपूर्वीचा मानतात. त्या
						काळातील गुणवैशिष्ट्यांचे गकवटलेले प्रतीक या युगपुरुषांत
						आढळल्यामुळे व परशु धारण करणाऱ्या या पुरुषाला परशुराम हे
						सार्थ नांव प्राप्त झाले. भार्गव हाच परशुराम, वीर व
						पराक्रमी ऋषी.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> वैशाख</span> शुद्ध
						तृतीया म्हणजे अक्षय तृतीयेला परशुरामाची जन्मतिथी येते.
						पिता जमदग्नी व माता रेणुका यांना चार पुत्र झाले. त्यात
						परशुराम सर्वांत धाकटा. परशुराम बालपणापासून तल्लख
						बुद्धीचा, अपार धडाडीचा, प्रखर आत्मविश्वासी, तांबूस
						गोऱ्या रंगाचा, तेज:पुंज, प्रसन्न व सदा तरतरीत दिसणारा
						होता. उपनयनानंतर शालग्राम पर्वतावर जाऊन त्याने कश्यप
						ऋषींकडून मंत्रोपदेश घेतला. ब्रह्मविद्या व क्षात्रविद्या
						त्याने लवकरच हस्तगत केल्या. धर्नुविद्येत तर तो अपराजित
						होता. त्या काळातील युद्धकौशल्ये महादेवाकडून शिकून घेऊन,
						परशुरामाने परशु हे शस्त्रही मिळविले. परशु हे अतिशय
						धारदार लखलखीत, कुऱ्हाडीसारखे शस्त्र. रानटी पशूंचा िंकवा
						रानटी-राक्षसी मानवांचा प्रतिकार करण्यासाठी तसेच
						झाडेझुडपे तोडून शेतीस अनुकूल धरती मिळविण्यासाठी परशु
						ह्या साधनाचा उपयोग त्याकाळी करण्यात आला असला पाहिजे.
					</p>
					<p className=" mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> कार्तवीर्य</span> ऊर्फ
						सहस्रार्जुन राजाने वशिष्ठ महर्षीचा आश्रम जाळला, जमदग्नी
						ऋषीच्या गाई पळविल्या, हे कळताच श्री परशुरामाने
						सहस्रार्जुनाशी युद्ध करून त्याचा पराभव केला. गाई परत
						मिळविल्या. कार्तवीर्याच्या पुत्रांना आपल्या वडिलांचा
						पराभव सहन झाला नाही, तेव्हा त्यांनी परशुराम नसताना
						जमदग्नीच्या आश्रमात घुसून मोडतोड, नासधूस तर केलीच; पण
						जमदग्नीलाही जीवे मारिले. मग मात्र परशुराम खवळले.
						माजलेल्या व अतिरेकी व राक्षसी वृत्तीला आळा घालण्यासाठी
						दुष्ट गटांचाच नायनाट करायचा असे परशुरामाने ठरविले. प्रथम
						नर्मदा किनाऱ्यावरील उत्तरेस असणाऱ्या कार्तवीय व त्याची
						उद्धट मुले यांचेशी युद्ध करून परशुरामाने त्याला ठार
						केले. अनेक माजलेल्या राजांवर मात करून सत्तेचा गैरवापर
						करून जनतेची छळवणूक करणाऱ्या अतिरेकी गटांचा परशुरामाने
						नि:पात केला. त्यामुळेच परशुरामाला युगपुरुष असे
						संबोधण्यात येऊ लागले.
					</p>
					<p className=" mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">उत्तर </span>भारतातील
						भूमी परशुरामाने काश्यप ऋषींना दान करून, दक्षिणेकडे
						प्रस्थान केले. त्यांनी फेकलेले परशु शूर्पारक प्रदेशात
						स्थिरावले. हा प्रदेश म्हणजे जवळील पर्वतावर परशुराम
						राहिल्याने त्या प्रदेशाला परशुराम क्षेत्र म्हणून संबोधले
						जाऊ लागले.
					</p>
				</div>
        
            <div onClick={scrollToTop} className="flex items-end justify-end my-5" >
              <ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
              </div>
      
				<hr />
			</div>
		</div>
	);
};
