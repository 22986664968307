import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";

export const Chapter5 = () => {
  // Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
  return (
    <div>
      <Header />
      <div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg md:w-[1300px] text-justify mx-auto ">
        <h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
          जोगळेकर कुलवृत्तान्त समितीची वाटचाल
        </h1>
        <div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">जोगळेकरांचा</span> कुलवृत्तान्त तयार
            करण्याचा हा तिसरा प्रयत्न. यापूर्वी 1936-37 मध्ये मुंबईला असलेल्या
            श्री जयराम लक्ष्मण यांनी प्रथम प्रयत्न केला व त्या नंतर 1960 च्या
            सुमारास बेळगावचे श्री. रघुनाथ कृष्ण व मुंबईचे श्री विष्णू रघुनाथ
            यांनी प्रयत्न केला होता. हे दोन्ही प्रयत्न का यशस्वी होऊ शकले नाहीत,
            याची कारणे काय असावीत याचा आता विचार करण्यात अर्थ नाही.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8"> 1991</span> च्या मे महिन्यात 12
            तारखेला पुण्यातील श्री विकास नारायण व श्री श्रीधर राम यांनी जोगळेकर
            कुलवृत्तान्त तयार करावा अशा विचाराने पुण्यातील भिकारदास मारुती
            मंदिरात गक बैठक बोलावली व तशा अर्थाचे गक निवेदन पुण्याच्या
            वर्तमानपत्रांतून प्रसिद्ध केले. श्री.गोिंवद केशव व श्री.परशुराम
            गंगाधर यांनीही प्रयत्न केला व त्या बैठकीला 27 जोगळेकर उपस्थित होते.
            या सर्वांनी कुलवृत्तान्त व्हावा व त्याकरिता प्रयत्न करण्याचे मान्य
            केले. या बैठकीला उपस्थित असलेले मेजर जनरल (निवृत्त) प्रभाकर काशिनाथ
            यांच्या अध्यक्षतेखाली कुलवृत्तान्त समितीची स्थापना करण्यात आली.
            श्री.गोिंवद केशव यांना उपाध्यक्ष तर सर्वश्री विकास नारायण व विनायक
            गणेश यांना अनुक्रमे कार्यवाह व कोषाध्यक्ष म्हणून निवडण्यात आले.
            सर्वश्री परशुराम गंगाधर, श्रीनिवास बाळाजी, श्रीधर राम व सौ.गीताली
            श्रीधर यांना कार्यकारिणीचे सभासद म्हणून निवडण्यात आले व रीतसर गका
            कार्यकारिणीची स्थापना झाली.
          </p>
          <p className="mb-5 leading-relaxed ">
            <span className="pl-6 sm:pl-8">12</span> जानेवारी 1992 ला श्री
            परशुराम गंगाधर यांच्या प्रयत्नाने दादरच्या छबिलदास हायस्कूलमध्ये के.
            ई. गम्‌. हॉस्पिटलचे माजी डीन डॉ.श्रीकृष्ण वासुदेव यांच्या
            अध्यक्षतेखाली मुंबईतील जोगळेकरांची गक बैठक आयोजिण्यात आली होती.
            त्याच बैठकीत मुंबई शाखेची स्थापना करण्यात आली. सर्वश्री शशिकांत
            लक्ष्मण, रामचंद्र काशिनाथ व विजय महेश्वर यांची अनुक्रमे अध्यक्ष,
            कार्यवाह व कोषाध्यक्ष अशी निवड करण्यात आली व जोगळेकर कुलवृत्तान्त
            समितीच्या मुंबई शाखेच्या कामकाजास सुरुवात झाली.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">पुढे</span> याच प्रकारे 15 नोव्हेंबर
            1992 ला नागपूर येथे ही सर्वश्री. रवींद्र परशुराम, संजय दिनकर व
            कै.सुधीर रामचंद्र यांची अनुक्रमे अध्यक्ष, कार्यवाह व कोषाध्यक्ष अशी
            निवड करण्यात आली व नागपूर शाखा रीतसर स्थापन झाली.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">याच</span> धर्तीवर नाशिक व चिपळूण
            येथे ही शाखा स्थापन झाल्या. नाशिकला सर्वश्री प्र.शि.तथा मामासाहेब,
            नरहर शंकर व सतीश मधुसूदन यांची अनुक्रमे अध्यक्ष, कोषाध्यक्ष व
            कार्यवाह म्हणून निवड झाली. चिपळूण येथे अध्यक्ष म्हणून कै.जयराम
            जनार्दन तर उपाध्यक्षा सौ. विजया वसंत व कार्यवाह विजय गणेश यांची निवड
            झाली.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">हे </span>सर्व होत असतानाच गक
            माहितीपत्रकाचा नमुना तयार करण्यात आला व तो प्रत्येकाकडून भरून
            घेण्याला आरंभ करण्यात आला. श्री. परशुराम गंगाधर यांनी प्रत्यक्ष
            भेटीवर जास्त भर दिला व जास्तीत जास्त जोगळेकर कुटुंबांशी संपर्क
            साधला. श्री.रामचंद्र काशिनाथ यांनी देशविदेशातील सुमारे 150
            महाराष्ट्र मंडळांचे पत्ते मिळवले व त्या माध्यमातून ठिकठिकाणच्या
            जोगळेकरांशी संपर्क साधण्याचा प्रयत्न केला. 1992 अखेर अंदाजे 600
            जोगळेकरांच्याकडून माहितीपत्रके मिळवण्यात आली.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">5/12/1993</span> ला मुंबईला पार्ले
            येथे दीनानाथ मंगेशकर नाट्यगृहात जोगळेकर कुटुंबीयांचे पहिले
            स्नेहसंमेलन आयोजण्यात आले होते. त्याला उत्तम प्रतिसाद मिळाला व
            सगळ्यांचा उत्साह वाढला व त्या उत्साहाच्या भरातच जोगळेकर चॅरिटेबल
            ट्रस्टचा विचार पुढे आला व 8/9/1994 ला रीतसर ट्रस्टची स्थापना झाली.
            या संमेलनाला मोठ्या संख्येने मंडळी उपस्थित होती व जोगळेकरांना गकत्र
            आणण्याला हा उपक्रम चांगला आहे असे जाणवले व पुढील वर्षी पुण्याला
            संमेलन भरवण्याचे ठरले.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">10/12/1994 </span>ला पुण्याच्या टिळक
            स्मारक मंदिरात उत्साहाच्या वातावरणात जोगळेकरांचे द्वितीय संमेलन
            साजरे झाले. सुमारे 1400 उपस्थिती असलेले हे संमेलन त्यातील सांस्कृतिक
            कार्यक्रमामुळे अत्यंत संस्मरणीय ठरले. या संमेलनाला पुण्यातील व
            पुण्याबाहेरील जोगळेकरांची उपस्थिती चांगलीच होती व परस्पर संबंध
            स्थापण्याच्या दृष्टीने संमेलनाची उपयुक्तता जाणवली व तेव्हाच तिसरे
            संमेलन नागपूरला घेण्याचे ठरले.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">24/12/1995</span> रोजी नागपूर येथे
            इंडियन मेडिकल असोसिगशनच्या हॉलमध्ये तृतीय संमेलन साजरे झाले. या
            संमेलनाकरिता नागपूर, अमरावती, अकोला व विदर्भातील इतर भागातील जोगळेकर
            मंडळी मोठ्या संख्येने उपस्थित होती. त्यांच्याबरोबर पुण्या-मुंबईहन
            आलेली मंडळी यांचे गकत्र येणे हा गक फार मोठा योग आला. या आनंदातच
            पुढील वर्षाच्या संमेलनाचे नाशिककरांनी निमंत्रण दिले. या दरम्यान
            जोगळेकर चॅरिटेबल ट्रस्टचे रीतसर रजिस्ट्रेशन झालेले होते पण त्या
            न्यासाची पालकसंस्था जोगळेकर कुलवृत्तान्त समितीचे मात्र रजिस्ट्रेशन
            झालेले नव्हते व त्याची आवश्यकता आहे असे लक्षात आल्यावर तत्कालीन
            कार्यवाह श्री.विकास नारायण यांनी कार्यकारिणी सभासद श्री.श्रीधर राम व
            सौ.गीताली श्रीधर यांच्या साह्याने समितीची नोंदणी धर्मादाय
            आयुक्तांकडे करून घेतली.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">नाशिक </span>शाखेचे अध्यक्ष प्र.शि.
            तथा मामासाहेब यांच्या कुशल नेतृत्वाखाली 10/11/1996 रोजी नाशिकच्या
            स्वामी नारायण मंदिराच्या आवारात सुनियोजित असे जोगळेकरांचे चवथे
            संमेलन भरले होते. दिवाळी नुकतीच झाली होती त्यामुळे अपेक्षेइतकी
            उपस्थिती नव्हती. पण संमेलन मात्र उत्साहात झाले.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> ही</span> स्नेहसंमेलने आवश्यकच आहेत
            पण त्यामुळे मूळ विचार जो कुलवृत्तान्त तयार करणे हा मागे पडतो आहे,
            असे जाणवू लागले होते. व आता कुलवृत्तान्ताच्या कामावर भर देण्याचे
            ठरविण्यात आले. व त्या दृष्टीने काम करण्यास प्रारंभ झाला. काही
            मंडळींच्या मते काम करण्याच्या दृष्टीने मूळ घटनेमध्ये काही बद्दल करणे
            आवश्यक आहे. तो सुचविण्याकरता गका समितीची नियुक्ती करण्यात आली त्या
            समितीने घटनेचा व्यवस्थित अभ्यास करून काही महत्त्वाचे बदल सुचविले.
            त्याप्रमाणे घटनादुरुस्ती करिता रीतसर सर्वांची (जनरल बॉडी) गक बैठक
            घेण्यात आली व नवीन बदलास मान्यता घेण्यात आली व त्यानुसार आतापर्यंत
            जी अस्थाई कार्यकारिणी होती ती जाऊन नवीन स्थायी कार्यकारिणी
            आस्तित्वात आली.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">जुलै</span> 1997 मध्ये जी
            माहितीपत्रके आधी भरुन घेतली होती, ती मुंबईहून पुण्याला आणण्यात आली.
            त्या माहितीपत्रकांची नीट तपासणी केली असता त्या माहितीपत्रकांच्या
            आधारे कुलवृक्ष तयार करणे खूपच अवघड, जवळजवळ अशक्यच आहे हे लक्षात आले.
            व नीट विचारपूर्वक गक नवीन माहितीपत्रक तयार करण्यात आले. पहिली
            माहितीपत्रके साधारणपणे तीन ते चार वर्षांपूर्वी घेतली होती. त्यांतील
            माहिती अद्ययावत करणे जरूरीचे होते व ते भरून घेण्याकरिता सर्व
            सभासदांकडे पाठविण्यात आले.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> ही</span> माहितीपत्रके जसजशी भरून
            येऊ लागली तशी गक गोष्ट जाणवली की आपल्याकडे बऱ्याच मंडळींची नावे
            नोंदवलेली नाहीत. नवीन माहितीपत्रकांच्या आधारे मिळालेल्या
            नाव-पत्त्यावरून त्यांना पत्रे व माहिती पत्रके पाठविण्यात आली. त्याला
            बऱ्याच जणांनी चांगला प्रतिसाद दिला. सप्टेंबर 1998 पर्यंत सुमारे 250
            नवीन सभासद नोंदले गेले व त्यांची माहितीपत्रकेही मिळाली.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> काही </span>ठिकाणी प्रत्यक्ष जाऊन
            माहितीपत्रके आणण्यात आली व त्यास उत्तम प्रतिसाद मिळाला. प्रत्यक्ष
            भेटीमुळे माहिती चांगल्या प्रकारे मिळवता आली. जेथे जेथे घरी गेलो तेथे
            तेथे अत्यंत आपुलकीने माहितीपत्रके भरून मिळाली. शक्यतर प्रत्येक
            ठिकाणी जावे असे वाटत होते पण ते शक्य झाले नाही. पुणे, नागपूर व नाशिक
            मधून मात्र माहितीपत्रके चांगल्या संख्येने मिळाली.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> प्रत्यक्ष </span>भेटल्यावर काही
            मंडळीनी माहितीपत्रके भरून देण्यास साफ नकार दिला. अर्थात नाइलाजाने
            त्यांचा विचार सोडून द्यावा लागला. काहींनी कुलवृत्तान्ताची गरजच काय ?
            असा प्रश्न उपस्थित केला. त्यांना त्याबाबत समजावण्याचा प्रयत्न केला,
            काहींनी समजून घेतले तर काहींनी समजून घेण्याचे नाही असे ठरविले आहे
            असे जाणवले. त्यांचापण विचार सोडून दिला. काहींच्या मताने नवीन माहिती
            पत्रकांची गरजच काय? असा प्रश्न आला त्यास संयुक्तिक उत्तर देण्याचा
            प्रयत्न केला. काहींना पटला, काहींना नाही.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> वास्तविक</span> नवीन
            माहितीपत्रकामुळेच घराण्यांची माहिती व्यवस्थित प्रकारे समजू शकत होती
            तसेच त्यांच्याविषयी इतर माहिती शैक्षाणिक गुणवत्ता यादीत आलेल्यांची
            माहिती तसेच कला, क्रीडा व साहित्य यांतील सहभाग व प्राविण्य, परदेश
            प्रवास, काही आगळ्या वेगळ्या कामाची माहिती मिळू शकत होती.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> कित्येकांना</span> आपल्या आजोबांचे
            पूर्ण नाव सांगता येत नाही. त्यांची आजी व काहींना त्यांची आई कोणत्या
            घराण्यातून आली हे नीटपणे माहिती नाही असे लक्षात आले. काहींना आपल्या
            आई-वडिलांच्या जन्म-निधन तारखा सुद्धा नीट माहीत नाहीत. या परिस्थितीत
            जी काय माहिती आहे ती तरी कोठे तरी नोंदवलेली राहणे आवश्यक आहे.
            पुढच्या पिढ्यांना आज असलेली माहिती व्यवस्थित रीत्या उपलब्ध होण्याचे
            गकमेव साधन म्हणजे कुलवृत्तान्त होय.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> कुलवृत्तान्तामुळे </span>आपले
            कुलबंधू कोणत्या क्षेत्रात आहेत व त्यात त्यांनी कितपत यश मिळवले आहे
            हे समजते. कोणत्याही क्षेत्रात वरच्या स्थानावर जाणे सोपे नाही. पण ते
            कितीजणांना साधले आहे ? 1857 च्या स्वातंत्र्य संग्रामात वासुदेव भगवंत
            यांचा सहभाग होता. त्या वासुदेव भगवंतांची माहिती नोंदवलेली असणे
            आपल्याला आवश्यकच आहे. पद्मपुरस्काराने सन्मानित केलेले डॉ.दिगंबर
            वासुदेव तसेच दोनदा राष्ट्रपती पदकाचा मान मिळविलेले गजानन दामोदर
            यांची माहिती, साहित्य क्षेत्रात मौलिक काम केलेल्यांची माहिती यांची
            कायम स्वरूपी नोंद कुलवृत्तान्तामुळेच राहणार आहे.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8">धारवाडसारख्या</span> कर्नाटकातील
            शहरात श्री.नागनाथ कल्लोपंत राहतात ते उत्तम ज्योतिषी आहेत. त्यांनी
            ज्योतिष या विषयात कन्नडमध्ये अठरा ग्रंथ लिहिले आहेत. ज्योतिष
            जाणण्याकरिता त्यांच्याकडे मोठ्या मोठ्या व्यक्तींचे येणे जाणे असते.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">कै.डॉ.रघुनाथ</span> केशव व
            श्री.शिवरामपंत यशवंत यांनी अविवाहित राहून संघकार्याला जीवन वाहिले.
            श्री.शिवरामपंत तर गेली 46 वर्ष तामिळनाडूत संघप्रचारकाचे काम सांभाळत
            आहेत.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8">कलेच्या</span> प्रांतात
            श्री.वसंतरावांनी सिनेमा क्षेत्रात स्वत:च्या नावाचा गक ठसा उमटवला
            होता. आज वयोमानानुसार बाजुला झालेल्या त्याच्या सुविद्य पत्नी सुमती
            गुप्ते यांनी रुपेरी पडदा गाजविला होता. आज अर्चना ही गक नामवंत
            नृत्यांगना व अभिनेत्री आहे. पण तिच्यावर संस्कार करणाऱ्या तिच्या
            मातोश्री आशाबाई स्वत: विवाहानंतर नृत्य शिकल्या व त्यात पारंगत
            झाल्या. आज त्या गक मान्यवर नृत्यशिक्षिका आहेत.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">मुंबई</span> विद्यापीठाच्या कुलगुरू
            डॉ.स्नेहलता देशमुख या जोगळेकर कन्या आहेत. श्री.भारत रामकृष्ण हे गक
            सर्वस्वी वेगळा असा हाडांची भुकटी परदेशात निर्यात करण्याचा व्यवसाय
            करतात.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> श्री.सतीश</span> सदाशिव उर्फ बापू हे
            खऱ्या अर्थाने शून्यातून वर आलेले व्यक्तिमत्त्व अत्यंत खडतर
            परिस्थितीत कोणाचा आधार, पैसा नसताना िंकवा उ शिक्षण नसताना त्यांनी आज
            पुण्यात स्वत:चे वर्कशॉप उभारले आहे.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">आई-वडिलांचे</span> छत्र हरविलेली
            अल्पवयीन मुले कोणाच्या तरी सल्ल्याने कोकणातून दौंडसारख्या गावात येऊन
            चहाचे दुकान सुरू करतात. कोणाचा आधार नाही, अनुभव नाही, शिक्षण नाही व
            पैसा तर नाहीच नाही या परिस्थितीत सुरू केलेला व्यवसाय आज त्यांच्या
            दुसऱ्या तिसऱ्या पिढीत केवढा विस्तारला आहे. केवळ घोर मेहनत व कामावरील
            निष्ठा यामुळेच ही मंडळी नावारूपाला आली.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">वर </span>उल्लेखलेली (अर्थात ही काही
            उदाहरणार्थ दिलेली) माहिती कुलवृत्तान्तामुळेच कायम स्वरूपी नोंदवलेली
            राहणार आहे व पुढील पिढ्यांना समजणार आहे. कुलवृत्तान्ताची गरज काय आहे
            असे म्हणणाऱ्यांना हे समजणे अगत्याचे आहे.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">आज</span> परदेशातही बऱ्यापैकी
            संख्येने जोगळेकर आहेत ते तेथे जे गेले ते स्वत:च्या अंगभूत गुणांनी
            गेलेले आहेत पण दुर्देवाने त्याही मंडळींनी माहिती पत्रके भरून पाठविली
            नाहीत त्यामुळे त्यांच्या गुणवत्तेचा यथायोग्य परिचय या ग्रंथात देणे
            शक्य झालेले नाही. त्यांची नावे व पत्ते फक्त मिळू शकले.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> जमवलेल्या</span> सर्व नव्या जुन्या
            माहिती पत्रकांची प्रथम छाननी करण्यात आली. त्यावरून त्यांचे कुलवृक्ष
            तयार करण्यात आले. हा गक महत्त्वाचा भाग होता. त्याकरिता आधुनिक
            सुविधांचा वापर करण्यात आला.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> हे </span>सर्व काम प्रथम संगणकावर
            करण्याचा प्रयत्न केला पण संगणक वापरावयास मिळणे व तो वापरण्याचे ज्ञान
            असणारे व त्याकरिता आवश्यक तेवढा वेळ देणारी माणसे यांच्या कमतरतेमुळे
            तो विचार सोडून द्यावा लागला.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">नवीन</span> माहितीपत्रकांतून
            मिळालेल्या माहितीचा वापर करून नवीन मंडळींशी पत्रव्यवहार केला.
            त्यांच्याकडूनही माहितीपत्रके मिळविण्याचा प्रयत्न करण्यात आला.
            त्यामुळे मुळात 1100 च्या आसपास सभासद नोंदवले होते. ती संख्या 1350 वर
            गेली. तरी अंदाजे अद्याप 200 ते 250 मंडळींची माहिती मिळणे आवश्यक होते
            पण...........
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">कुलवृत्तान्त</span> अद्ययावत व्हावा
            याकरिता शक्य तेवढ्या कसोशीने प्रयत्न केलेला आहे तरीसुद्धा त्यात
            बऱ्याच त्रुटी आहेत याची पण जाणीव आहे. पण कोठेतरी थांबणे भागच पडते
            त्यामुळे बऱ्याच गोष्टी राहून गेल्या.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> साधारणपणे </span>1991 मध्ये व
            नंतरच्या वर्षात वझे, साने, गोडबोले वगैरे मंडळींनीही कुलवृत्तान्ताचे
            प्रकल्प हाती घेतले. त्यांचे ग्रंथ पूर्ण होऊन प्रकाशित झाले व आपण
            मात्र मागे रखडलो. पहिल्या प्रयत्नानंतर 60 वर्षांनी आपल्या
            कुलवृत्तान्ताचे काम पूर्ण होत आहे व ते सुद्धा परिपूर्ण नाही हे शल्य
            कायम राहाणार यात वाद नाही. पहिला प्रयत्न यशस्वी झाला असता तर आता
            त्याची आवृत्ती निघाली असती.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            {" "}
            <span className="pl-6 sm:pl-8">आता</span> या प्रथम ग्रंथानंतर यातील
            त्रुटी कमी करून व ज्यांची माहिती या ग्रंथात समाविष्ट नाही त्यांची
            माहिती मिळवून अद्ययावत अशी पुढील आवृत्ती लवकर निघावी ही अपेक्षा आपण
            बाळगू या.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">कुलवृत्तान्त</span> प्रसिद्ध झाला
            म्हणजे कुलवृत्तान्त समितीचे काम संपले असे नाही. आपले आणखी दोन
            प्रकल्प बाकी आहेत. त्यातील पहिला म्हणजे आपल्यातील गकमेव हतात्मा
            झालेले वासुदेव भगवंत यांचे यथोचित स्मारक उभारून वासुदेव भगवंत यांची
            स्मृती जागृत ठेवणे हे समस्त जोगळेकर परिवाराचेच काम आहे. या
            स्मारकासाठी वासुदेव भगवंत यांचे गक वंशज व आपल्या नागपूरच्या
            संमेलनाचे अध्यक्ष श्री.आनंद भालचंद्र यांनी व त्यांचे नातेवाईक यांनी
            देणगी (रु.15,000/-) दिलेली आहे. पुढील काम आता आपणच केले पाहिजे.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">दुसरा</span> प्रकल्प म्हणजे
            वृद्धाश्रम. नाशिकच्या श्री.प्र.शि. तथा मामासाहेब यांच्या
            दूरद्दष्टीने व दिलदारपणाने आज कुलवृत्तान्त समितीची काही लाखात िंकमत
            असलेली जमीन मालकीची झालेली आहे. हे सर्वश्री मामांच्या मुळेच शक्य
            झाले आहे. आता उर्वरित निम्मे म्हणजे इमारत बांधणे वगैरे काम आपण
            सर्वांनी करावयाचे आहे.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">कुलवृत्तान्त</span> ग्रंथाची पहिली
            आवृत्ती दिनांक 28-02-1999 रोजी प्रकाशित झाल्यावर लगेचच नाशिक येथील
            वृध्दाश्रमाच्या उभारणीचे काम हाती घेण्यात आले. विद्यमान कोषाध्यक्ष
            सिव्हील इंजिनियर असल्यामुळे त्यांनी नाशिकला जाऊन स्वत:च्या
            देखरेखीखाली वृध्दाश्रमाच्या बांधकामास सुरूवात केली. सहा महिन्यात
            सुमारे 6000 चौ.फुटाचे जोत्यापर्यंतचे बांधकाम पूर्ण करण्यात आले. पुढे
            काही अपरिहार्य कारणामुळे समिती हा प्रकल्प पूर्ण करू शकली नाही.
            मध्यवर्ती कार्यकारिणीने हा प्रकल्प विकण्याचे ठरविले. सर्वसाधारण
            सभेची संमती घेऊन हा प्रकल्प विकण्याचा प्रस्ताव धर्मादाय आयुक्तांपुढे
            ठेवण्यात आला. दिनांक 22 जानेवारी 2007 रोजी धर्मादाय आयुक्तांची,
            प्रकल्प विकण्यासाठीची परवानगी मिळविण्यात आली. त्यानुसार रितसर
            खरेदीखत करून दिनांक 21 फेब्रुवारी 2007 रोजी प्रकल्प विकण्यात आला.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">त्यातून</span> आलेले पैसे बँकेत मुदत
            ठेवीत गुंतवून येणाऱ्या व्याजातून गरजू व्यक्ति, संस्था यांना पहिल्या
            वर्षी रू. 71,000/- व दुसऱ्या वर्षी 1,03,000/- मदत करण्यात आली. चालू
            आर्थिक वर्षात आत्तापर्यंत रू. 30,000/- ची मदत करण्यात आली आहे.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8">दुसरा </span>प्रकल्प हतात्मा वासुदेव
            भगवंत ह्यांचे स्मारक उभारणी अजून मुर्त स्वरूपांत येऊ शकलेला नाही.
            गप्रिल 2008 मध्ये कुलवृतान्त ग्रंथाची दुसरी आवृत्ती प्रकाशित
            करण्याचे मध्यवर्ती कार्यकारिणीने ठरविले.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> त्याप्रमाणे </span>नवीन जोगळेकर
            कुटुंबाचा शोध नवीन तंत्रज्ञान वापरून घेण्यास सुरूवात केली. 10
            वर्षापूर्वी शिकत असलेल्या स्त्रिया व पुरूष यांची आता लग्ने झालेली
            असावीत असे धरून त्यांची माहिती मिळविण्यासाठी त्यांचेकडे फॉर्मस
            पाठविण्यात आले. त्याप्रमाणे गकूण नवीन सुमारे 700 पुरूषांची व 250
            स्त्रियांची माहिती उपलब्ध झाली. त्यातील जवळ जवळ 250 पुरूषांची व 200
            स्त्रियांची माहिती फोनवरून मिळविण्यात आली. 250 पुरूषांची माहिती
            प्रत्यक्ष त्यांचेकडे जाऊन मिळविण्यात आली. उरलेली माहिती पोस्टाने
            आली. तरी अजून 100 जणांची माहिती मिळालेली नाही असे वाटते.
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> पुन्हा </span>तोच विचार करून ऑक्टोबर
            2009 अखेरपर्यंत आलेली माहिती नवीन आवृत्तीत समाविष्ट करण्यात आलेली
            आहे. नवीन ग्रंथात सुमारे 200 पानांची भर पडलेली आहे.{" "}
          </p>
          <p className="mb-5 leading-relaxed">
            <span className="pl-6 sm:pl-8"> कुलवृत्तान्त</span> ग्रंथाची दुसरी
            आवृत्ती आपल्या हातात ठेवताना आनंद होत आहे. ह्यापुढील आवृत्ती तरूण
            पिढीने पुढे येऊन प्रकाशित करावी अशी अपेक्षा आम्ही धरतो. आमची अपेक्षा
            पूर्ण होईल. अशी श्री व्याडेर व श्री येोगेश्वरी देवीकडे प्रार्थना
            करतो.
          </p>
          <div className="ml-20">
            <p>
              परशुराम उर्फ शाम गंगाधर विजय केशव जोगळेकर
              <p> कार्यवाह कोषाध्यक्ष</p>
            </p>
          </div>
          <div
						onClick={scrollToTop}
						className="flex items-end justify-end my-5"
					>
						<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
					</div>
          <hr/>
        </div>
      </div>
    </div>
  );
};
