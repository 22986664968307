import { Header } from "../components/Header";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";

export const Chapter7 = () => {
	// Scroll to Top function
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
  
	return (
		<div>
			<Header />
			<div className="mt-16 mb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 max-w-screen-lg mx-auto md:w-[1300px] text-justify ">
				<h1 className="text-center text-xl sm:text-2xl md:text-3xl underline md:mt-[120px]">
					कुलवृत्तान्त : काही तज्ञांचे विचार
				</h1>
				<div className="mt-5 text-lg sm:text-xl font-SOHNE_REGULAR ">
					<h3 className="text-center font-bold">
						कृष्णाजी विनायक पेंडसे
					</h3>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">राष्ट्राचा</span> इतिहास
						हा त्यातील कुळांच्या इतिहासाचाच बनलेला असतो. यामुळे खरे
						व विस्तृत कुलेतिहास जितके होतील तितके अवश्य पाहिजे आहेत
						व त्यामुळे महाराष्ट्राच्या इतिहासाचे संगतवार ज्ञान
						होण्यास पुष्कळ मदत होईल यात शंका नाही. कुलवृत्तान्त बरेच
						प्रसिद्ध झाले व होत आहेत. यावरून विचारी लोकांस त्याची
						आवश्यकता पटली आहे हे उघड होय.{" "}
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8"> कुलवृत्तान्ताचे</span>{" "}
						कालदृष्ट्या साधारणत: दोन भाग पडतात. गक गेतिहासिक कालातील
						माहितीचा व दुसरा चालू पिढीचा. या दुसऱ्यास परिचय प्रकरण
						अगर डायरेक्टरी म्हणता येईल. गेतिहासिक कालातील माहितीच्या
						प्रसिध्दीकरणाने राष्ट्रीय इतिहासाच्या दृष्टीने फायदे
						होतीलच. शिवाय, परिचय प्रकरणात चालू पिढीची माहिती गकत्र
						प्रसिध्द झाल्याने ती सर्व कुलबंधूंस कळून त्यांच्यात
						आपलेपणाचा भाव उत्पन्न होईल व त्या योगाने परस्परांस
						गकमेकांची माहिती होऊन यथाशक्ती साहाय्यही होण्याचा संभव
						वाढेल. कुलसंघटन घडून येईल.
					</p>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">महाराष्ट्रात</span>{" "}
						ब्राह्मणसमाज हा शिक्षित व पुढारलेला समजला जातो. त्या
						समाजातील गका पोटविभागास, कोकणस्थास चार-पाचशे
						वर्षांपूर्वी आपले पूर्वज कोठे होते ? मूळ आपले वास्तव्य
						कोठे होते ? कोकणात केव्हा-कोठून आलो वगैरे गोष्टी निश्चित
						सांगता येत नाहीत. याचे कारण इतिहास लिहून ठेवण्याची
						अनास्था अगर त्या प्रांती राज्यकर्त्यांचे वारंवार होणार
						खांदेबदल अथवा समुद्रसान्निध्यामुळे वारंवार होणारी
						चाच्यांची लूटमार. तेव्हा आज मिळेल तेवढी माहिती तरी गकत्र
						करून ठेवावी व पुढे जास्त संशोधन व संकलन व्हावयास ती
						साधनीभूत व्हावी हे आणखी गक कारण हे कार्य हाती घेण्याचे
						आहे. आज गक लहानशी गोष्ट सापडली, उद्या तिच्याशी संबंध
						नसणारी दुसरी गोष्ट सापडली, तशीच भलती तिसरी गक परवा
						सापडली. ह्या गोष्टी अशा स्वरूपात पाहणाऱ्याला त्यांचे
						फारसे महत्त्व वाटत नाही; पण ज्यावेळी या सर्व शोधांचा
						उपयोग करणारा संकलनकार पुढे येतो त्यावेळी या शोधाची खरी
						िंकमत ध्यानात येते. माहितीसाठी मागणी केली असता असेल
						तेवढी माहिती तत्परतेने दिली जाते असे नाही. पुष्कळांजवळ
						माहिती नसते. जुनी श्राध्द-तर्पणाची प्रथा मोडू
						लागल्यामुळे पणज्याचे नावही माहीत नसलेली माणसे आढळतात.
						श्राध्द-तर्पण करणाऱ्या व्यक्तीजवळ निदान पूर्वजांच्या तीन
						पिढ्या व वडिलांचे व मातोश्रींचे जवळचे संबंधी ह्यांची
						माहिती असेल. नाव लिहितांना महाराष्ट्रात व्यक्तीचे नाव,
						पित्याचे नाव व आडनाव लिहिण्याची प्रथा असल्याने वडिलांचे
						नावाबरोबर आज्याचे नाव सहजच येते. म्हणून ते माहीत असते
						(बंगाल अथवा पंजाबातील) अन्य प्रांतातील प्रघाताप्रमाणे
						नावाची प्रथा असती तर आज्याचे नाव स्मरणात राहावयाचे नाही.
						मग वडिलांचे चुलते, आत्या, मावशी व मामा यांचे नावाची
						गोष्टच नको. कालपरत्वे श्राध्दतर्पणाची प्रथा जरी
						मोडावयाची असली तरी त्याची जागा इतर तऱ्हेने माहिती कोठे
						तरी लिहून ठेवली तर भरून निघेल; पण असे होत आहेसे दिसत
						नाही. पाश्चात्य देशात तर घराण्यांचे इतिहास परिश्रमपूर्वक
						व काटेकोर पद्धतीने लिहिले गेले आहेत. घराण्यातील लोकांचे
						इतरांबरोबर झालेले पत्रव्यवहार तिकडे काळजीपूर्वक जतन करून
						ठेवलेले दिसतात. त्याचप्रमाणे त्यांच्या दैनंदिनी,
						आत्मचरित्रविषयक टिपणे इत्यादी अस्सल दर्जाच्या साधनांचाही
						उपयोग अशा घराण्यांचे इतिहास लिहिण्याच्याकामी होत असतो.
						त्यांचेकडे घराण्यातील काही कर्तबगार गृहस्थांची माहिती
						अत्यंत तपशीलवार पद्धतीने व बारकाव्याने आणि गुणदोषांचे
						वर्णन करून चटकदार रीतीने लिहिली गेली आहे. यु.गस्‌.ग.मधील
						सध्याच्या बहसंख्य लोकांचे धागेदोरे युरोपमधील निरनिराळ्या
						देशातील लोकांपर्यंत पोचत असतात. त्यांच्या वंशावळी लावून
						देणाऱ्या तिकडे अनेक संस्था आहेत. इंग्लंडमध्येही आपापल्या
						स्कॉटिश अथवा इंग्लिश कुळांचा अभिमान बाळगून घराण्याची व
						त्यांच्यातील गृहस्थांची माहिती देणारा तीन हजार पृष्ठांचा
						ग्रंथ प्रतिवर्षी प्रसिद्ध करण्याची प्रथा गेली गकशे गेंशी
						वर्षे चालू आहे. पाश्चात्य देशातील प्रत्येक कुटुंबाच्या
						घरी बायबल हा धर्मग्रंथ असतो व त्याच्या शेवटच्या पृष्ठावर
						घराण्यातील व्यक्तींचे जन्मदिन व कुटुंबातील ठळक गोष्टींची
						नोंद करण्याची पद्धत आहे.{" "}
					</p>
					<div>
						<h3 className="text-center font-bold">लोकमान्य टिळक</h3>
						<p className="mb-5 leading-relaxed ">
							<span className="pl-6 sm:pl-8"> आपल्या</span>{" "}
							घराण्याचा पूर्वापार व खरा इतिहास कळल्यास आपल्या
							पूर्वजांच्या मनोवृत्ती कश्या होत्या, त्यांच्या अंगी
							मुख्यत्वेकरून कोणते गुण होते, त्यांचे शील कसे होते,
							त्यांच्यावेळच्या परिस्थितीत व आजच्या परिस्थितीत जो
							काय फरक झाला असेल तो राष्ट्रहिताच्या दृष्टीने कितपत
							इष्ट िंकवा अनिष्ट आहे याचा तुलनात्मक विचार करण्यास
							असे घराण्याचे स्वतंत्र इतिहास बाहेर पडणे अत्यंत
							जरुरीचे आहे. महाराष्ट्राच्या इतिहासात भर पडण्यास
							साधनीभूत होणाऱ्या अशा प्रसत्नांना महाराष्ट्राकडून
							योग्य उत्तेजन मिळेल अशी आशा आहे.
						</p>
					</div>
					<div>
						<h3 className="text-center font-bold">
							रँगलर डॉ. र. पु. परांजपे
						</h3>
						<p className="mb-5 leading-relaxed ">
							{" "}
							<span className="pl-6 sm:pl-8"> आपले</span> पूर्वज,
							त्यांची राहणी, त्यांचे उद्योग, त्यांच्या चालीरीती
							वगैरे गोष्टी समजल्याने मनास गक प्रकारचे समाधान होते
							यात शंका नाही. आपल्याकडे असल्या प्रयत्नाकरता पुरेशी
							लेखी माहिती मिळण्याची अडचण असते ही दुदैवाची गोष्ट
							आहे.{" "}
						</p>
					</div>
					<div>
						<h3 className="text-center font-bold">
							रा. शं. वािंळबे
						</h3>
						<p className="mb-5 leading-relaxed ">
							<span className="pl-6 sm:pl-8"> अनेक</span> कुळांनी
							आपापले वृत्तांत तयार केले तर सामाजिक इतिहास सिद्ध
							करू इच्छिणाऱ्या महत्त्वाकांक्षी संंशोधकाला या
							वृत्तांतातून उपलब्ध झालेल्या विपुल सामुग्रीचा
							(Source Material) यशोचित उपयोग करून घेता येईल. गका
							विशिष्ट कुळातील व्यक्तींना आपल्या कुळाची प्राचीन उवल
							परंपरा समजेल आणि विद्यमान व्यक्तीच्या कर्तृत्वाचे
							ज्ञान होईल. हे आत्मज्ञानही फारच महत्वाचे आहे.
						</p>
					</div>
					<p className="mb-5 leading-relaxed ">
						<span className="pl-6 sm:pl-8">आज</span> तरी माझे ज्ञान
						ब्राह्मण समाजातील कुलवृत्तान्तापुरतेच मर्यादित आहे,
						म्हणून माझे निष्कर्ष या वृत्तांतापुरतेच मर्यादित आहेत.
						परंपरेने चालत आलेली ब्राह्मणांची (यजन, याजन, अध्ययन,
						अध्यापन दान आणि प्रतिग्रह ही) षटकर्मे, त्यांचे कुलधर्म
						आणि कुलाचार, त्यांची कुलदैवते, त्यांच्या विशिष्ट रूढी,
						त्यांची वृत्ती, उपजीविकेचे साधन, इत्यादी बाबतीत गेल्या
						दोनशे वर्षांमध्ये कोणकोणते बदल घडून आले याचे ज्ञान
						सामाजिक इतिहासाच्या दृष्टीने अतिशय महत्त्वाचे आहे.
						17व्या शतकात शिवाजी महाराजांच्या उत्तरचरित्रात काही
						ब्राह्मणांनी क्षात्रधर्माचा अंगिकार केला, ते राजकारणात
						शिरले, मुत्सद्दी झाले. 18व्या शतकाच्या सुरुवातीलाच
						बाळाजी विश्वनाथाच्याबरोबर अनेक ब्राह्मण कुटुंबे कोकणातील
						समुद्रकाठची गावे सोडून देशावर आली. अनेक ब्राह्मणांनी
						आपली परंपरागत याज्ञिकी सोडून क्षात्रधर्माचा अवलंब केला.
						लढाईत मर्दुमकी गाजवली, सरदारक्या व जहागिऱ्या मिळविल्या.
						हे वृत्तिपरिवर्तन (Change of Vocation) असेच चालू राहिले
						आणि गकोणिसाव्या शतकामध्ये इंग्रजी अंमलात ब्राह्मणांनी
						अनेक प्रकारच्या सरकारी नोकऱ्या पत्करल्या, त्यांनी
						व्यापारउदीम सुरू केला, लहानसहान उद्योगधंदेही सुरू केले.
						20व्या शतकात वृत्तिपरिवर्तन शतमुख झाले हे तर आज आपण
						प्रत्यक्षच पाहात आहोत. सीनियर रँग्लर डॉ.रघुनाथ
						पुरुषोत्तम परांजपे यांचे वडील प्राचीन परंपरेतील गक
						विद्वान दशग्रंथी ब्राह्मण होते. मुर्डी हे त्यांचेे
						कोकणातील गाव सोडून रँग्लर पुण्याला आले नसते तर ते
						मुर्डीमध्ये याज्ञिकी करीत राहिले असते.
					</p>
					<div>
						<h3 className="text-center font-bold">
							विद्याधर गोखले
						</h3>
						<p className="mb-5 leading-relaxed ">
							<span className="pl-6 sm:pl-8"> कुलवृत्तान्त</span>{" "}
							तयार करण्याचे बिकट-किचकट काम तडीस नेण्यासाठी कुणीतरी
							पुढाकार घेऊन, तसेच सर्वांना बरोबर घेऊन, स्वत:ला या
							कार्यात गकाग्रतेने झोकून देण्याची आवश्यकता असते.
							बऱ्याच वर्षांच्या अथक परिश्रमाचे फल दिसते.
							ज्यांच्यात कुलाभिमान नि सामाजिक बांधिलकी आहे तेच लोक
							अशा रुक्ष कामात ‘थँकलेस‌’ कष्टात, रस घेऊ शकतात.
							व्यक्तींची कुळे बनतात. अनेक कुळांच्या गकत्रीकरणाने
							जाती व समाज घडतात आणि असे अनेकानेक समाज गका
							संस्कृतीत बांधले गेले की देश आकारास येतो, राष्ट्र
							उभे राहते. राष्ट्राचा इतिहास शेकडो कुळांच्या
							कर्तबगारीवरच उभा असतो. म्हणून साधार नि साक्षेपी
							कुलवृत्तान्ताचे ग्रंथ, राष्ट्राच्या इतिहासाची उत्तम
							साधने ठरतात. कुलवृत्तान्त म्हणजे आपल्या पूर्वजांच्या
							कुलसेवेची नि कर्तृत्वाची कृतज्ञ आठवण-त्यांचे वाङमयीन
							श्राध्द होय. नेहमी जवळची उदाहरणे जास्त परिणामकारक
							होत असतात. गखाद्या तरुणाला, ‘पहा तुझा बाप असा
							कर्तबगार होता, तुझ्या आजोबाने दु:स्थितीवर अशी मात
							केली, तुझ्या बंधूने स्वकष्टाने असा असा नावलौकिक
							कमावला‌’ असे सांगितल्यास, त्याच्या मनावर परिणाम होऊ
							शकतो. आकाशस्थ तेज:पुंज ग्रह गोलापेक्षाही घरचा दिवा
							कित्येकदा अधिक उपयुक्त ठरतो.
						</p>
						<p className="mb-5 leading-relaxed ">
							<span className="pl-6 sm:pl-8"> कुलवृत्तान्त</span>{" "}
							तयार करणे म्हणजे वेळेचा आणि पैशाचा अपव्यय आहे असे
							म्हणणारे, आपल्या पूर्वजांची कीर्ती वर्णन करून िंकवा
							सांगून अधिक समाधान कोणते? जेव्हा आपल्याच घराण्यातील
							व्यक्तींचा इतिहास सांगणे म्हणजे जातीयता आणि
							संकुचितपणा दिसतो असे आक्षेप घेणारेही व कुलवृत्तान्त
							प्रकल्पाविषयी आपणास काही आत्मीयता आणि कर्तव्यही वाटत
							नाही, असे म्हणणारे, विशेषत: तरुण पिढीतील कुलबंधू
							अद्यापही भेटतात. परंतु अशा विचारांविषयी खेद वाटतो.
							अशा व्यक्तींनी इतिहासाचा उपयोग आणि महत्त्व यांचा
							सम्यक्‌ विचार केलेला नाही असेच नाईलाजाने म्हणावे
							लागते. व्यक्ती, कुटुंब, समाज आणि राष्ट्र यांच्या
							जीवनातील मागील इतिहासरूपी अनुभव जाणून पुढील काळासाठी
							तो मार्गदर्शक ठरावा म्हणून इतिहासाच्या दृष्टिकोनातून
							गकूण समाजाचे अवलोकन केले असता, गेल्या 40-50
							वर्षांतील चरितार्थासाठी झालेले स्थलांतर आर्थिक
							स्वातंत्र्य आणि त्यामुळे निर्माण झालेली विभक्त
							कुटुंबपध्दती, बदलती आर्थिक आणि सामाजिक परिस्थिती
							यामुळे गकत्र कुटुंबपध्दतीतील संस्कारांचा व
							आत्मीयतेचा अभाव, आर्थिक विषमतेमुळे होणारा दुरावा इ.
							गोष्टींमुळे तरुण पिढीतील बऱ्याच जणांमध्ये अशा
							प्रकारची विचारधारा निर्माण होत असावी असे आम्हांस
							वाटते. घरात होणाऱ्या मुख्य गोष्टी म्हणजे जन्म,
							बाप्तिस्मा, विवाह, मृत्यू या घरातील मोठ्या
							बायबलमध्ये न विसरता लिहून ठेवण्याची चाल आहे. हजार
							वर्षांपूर्वी ‘डूमस्डे बुक‌’ म्हणून जो ग्रंथ तयार
							केला गेला त्यावरून त्यावेळच्या समाजाची तपशीलवार
							माहिती समजते. चर्चमध्ये जे जे संस्कार होतात ते तेथे
							व्यवस्थित लिहून ठेवलेले असतात. हे लेख म्हणजे
							इतिहासाची अव्वल साधने समजली जातात. पूर्वीची आठवण
							जागृत ठेवावयाची असेल तर त्याकरिता समजून-उमजून असे
							प्रयत्न केले पाहिजेत.
						</p>
					</div>
					<div>
						<h3 className="font-bold text-center">ग. वि. केतकर</h3>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">खरोखर</span> प्रत्येक
							कुलाने आपला गक हस्तलिखित कुलनोंदणीचा ग्रंथ देवघरातील
							देवाप्रमाणे जपून ठेवावा. त्यात क्रमश: जन्म, मृत्यू,
							विवाह, प्रवास इत्यादींच्या नोंदी करण्यात याव्यात.
							खिस्ती लोकांमध्ये कुटुंबाचे म्हणून जे बायबल असते
							त्यांच्या मागे-पुढे असणाऱ्या कोऱ्या पृष्ठांवर त्या
							वंशांच्या माहितीची नोंद केली जात असते आणि जुने बायबल
							पिढ्यानपिढ्या आस्थेने व्यवस्थित राखण्यात येते.
						</p>
					</div>
					<div>
						<h3 className="text-center font-bold">
							म.म. दत्तो वामन पोतदार
						</h3>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">मरणावर </span>स्मरण
							हा बेमालूम तोडगा आहे. घराण्याचे इतिहास राष्ट्राच्या
							सर्वांगीण व्यापक इतिहासाला अत्यंत उपयोगी होत.
						</p>
					</div>
					<div>
						<h3 className="text-center font-bold">ग. ह. खरे</h3>
						<p className="mb-5 leading-relaxed">
							<span className="pl-6 sm:pl-8">कुलवृत्तान्त</span>{" "}
							हे पूज्य पितरांचे वाङमयश्राद्ध आहे. पूर्वइतिहासाने
							स्वत:ची जाणीव उत्पन्न होण्यास मदत होते. कुलवृत्तान्त
							आपल्या पूर्वजांच्या आठवणी कायम ठेवील.
						</p>
					</div>
					<div>
						<h3 className="text-center font-bold">ज. स. करंदीकर</h3>
						<p className="mb-5 leading-relaxed">
							{" "}
							<span className="pl-6 sm:pl-8">
								{" "}
								कुलवृत्तान्त
							</span>{" "}
							तयार झाला म्हणजे आपले कर्तव्य संपले असे मात्र मानू
							नये. कुलवृत्तान्त हे ध्येय नसून आपले ध्येय गाठण्याचे
							ते गक साधन आहे. स्वकुल उन्नती व तद्द्वारा समाजाची
							आणि परंपरेने देशाची उन्नती हे आपले ध्येय आहे. ते
							ध्येय गाठण्यासाठी मार्गदर्शक नकाशा िंकवा आराखडा
							गवढीच कुलवृत्तान्ताची योग्यता होय. जागृती करण्याचे
							खरे कार्य यापुढेच आहे. त्यासाठी कुलसंघ स्थापून त्या
							संघामार्फत कुलातील प्रत्येक कुटुंबातील अडीअडचणींचा
							विचार केला जावा. आपल्या कुलातील कोण कोठे आहे, त्याचे
							कार्य काय व कसे चालले आहे यावर कुलसंघाची नजर असावी.
							त्या योगाने कुलघटकाला जबाबदारीची जाणीव होते.
						</p>
					</div>
				</div>
				<div
					onClick={scrollToTop}
					className="flex items-end justify-end my-5"
				>
					<ChevronUpIcon className="size-8 bg-orange-600 text-white rounded-full" />
				</div>
				<hr />
			</div>
		</div>
	);
};
